import React from 'react'
import { useTranslation } from 'react-i18next'
import { AutocompleteValue, CircularProgress, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

export type AutoCompleteOptions<ItemType> = {
    getOptions: (inputValue: string) => Promise<any>
    getOptionLabel: (item: ItemType) => string
    getOptionValue: (item: ItemType) => string
    isOptionEqualToValue: (option: ItemType, value: ItemType) => boolean
    defaultObject: any
    noOptionsText?: string
    filterOnServer?: boolean
    getOptionDisabled?: (option: ItemType) => boolean
    disableClearable?: boolean
    freeText?: boolean
}

type Props = {
    label: string
    name: string
    autoCompleteOptions: AutoCompleteOptions<any>
    readonly?: boolean
    help?: string
    required?: boolean
    invalid: boolean
    errorMessage?: string
    onChange: (element: AutocompleteValue<any, any, any, any>) => void
    innerRef?: any
    preventDefaultEnterBehavior?: boolean
    controlled: boolean
    inputValue: string
    setInputValue: (val: string) => void
    clearAfterSelect?: boolean
}

export default function AutocompleteWithAsyncFetchAndState({
                                                               label,
                                                               name,
                                                               help,
                                                               required,
                                                               autoCompleteOptions,
                                                               readonly,
                                                               invalid,
                                                               errorMessage,
                                                               onChange,
                                                               innerRef,
                                                               preventDefaultEnterBehavior,
                                                               controlled,
                                                               inputValue,
                                                               setInputValue,
                                                               clearAfterSelect
                                                           }: Props) {
    const [open, setOpen] = React.useState(false)
    const [autoCompleteItems, setOptions] = React.useState<readonly object[] | null>(null)
    const loading = open && !autoCompleteOptions.filterOnServer && !autoCompleteItems

    const { t } = useTranslation()

    React.useEffect(() => {
        let active = true

        if (autoCompleteOptions.filterOnServer && inputValue === '') {
            setOptions([])
            return undefined
        } else if (!autoCompleteOptions.filterOnServer && !loading) {
            return undefined
        }

        ;(async () => {
            const objects: object[] = await autoCompleteOptions.getOptions(inputValue)
            if (active) {
                setOptions(objects)
            }
        })()

        return () => {
            active = false
        }
    }, [loading, autoCompleteOptions, inputValue])

    React.useEffect(() => {
        if (!open) {
            setOptions(null)
        }
    }, [open])

    return (
        <Autocomplete
            filterSelectedOptions={!autoCompleteOptions.freeText}
            disableClearable={autoCompleteOptions.disableClearable ?? false}
            inputValue={clearAfterSelect? '' : (controlled || autoCompleteOptions.freeText) ? inputValue : undefined}
            freeSolo={autoCompleteOptions.filterOnServer || autoCompleteOptions.freeText}
            disabled={readonly}
            id={name}
            open={open}
            onOpen={() => {
                setOpen(true)
            }}
            onClose={() => {
                setOpen(false)
            }}
            isOptionEqualToValue={clearAfterSelect? () => false : autoCompleteOptions.isOptionEqualToValue}
            getOptionLabel={autoCompleteOptions.getOptionLabel}
            options={autoCompleteItems || []}
            filterOptions={autoCompleteOptions.filterOnServer ? x => x : undefined}
            loading={loading}
            noOptionsText={!(autoCompleteOptions.filterOnServer && !inputValue) && autoCompleteOptions.noOptionsText}
            loadingText={t('general.loading')}
            onChange={(event, data) => {
                if (autoCompleteOptions.freeText) {
                    setInputValue(data ? autoCompleteOptions?.getOptionValue(data) : '')
                } else if (clearAfterSelect){
                    setInputValue('')
                }
                onChange && onChange(data ? autoCompleteOptions?.getOptionValue(data) : null)
            }}
            defaultValue={controlled ? undefined : autoCompleteOptions.defaultObject}
            onInputChange={(event, newInputValue, reason) => {
                ((autoCompleteOptions.freeText && ['input', 'clear'].includes(reason)) || autoCompleteOptions.filterOnServer)
                && setInputValue(newInputValue)
                autoCompleteOptions.freeText && ['input', 'clear'].includes(reason) && onChange(newInputValue)
            }}
            getOptionDisabled={autoCompleteOptions.getOptionDisabled}
            onKeyPress={e => {
                preventDefaultEnterBehavior && e.key === 'Enter' && e.preventDefault()
            }}
            renderInput={params => {
                return (
                    <TextField
                        required={required}
                        error={invalid}
                        helperText={invalid ? t(`general.form-error.${errorMessage}`, { label: label }) : help}
                        {...params}
                        variant={'standard'}
                        label={label}
                        ref={innerRef}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color='inherit' size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )
            }}
        />
    )
}
