import {useTranslation} from "react-i18next";
import React, {ReactElement, ReactNode, useCallback, useMemo} from "react";
import {FormSection} from "../../common/components/SectionedForm";
import {CpeConfigResult, CPESettings, HotDeskSettings, PhoneUser} from "../../app/types";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import {api} from "../../common/api";
import {useFetchData} from "../../common/servercall/useFetchData";
import PaperStack from "../../common/form/PaperStack";
import FormGroup from "../../common/form/FormGroup";
import {LabelValues} from "../../common/components/LabelValues";
import {CPEConfigMapping, getConfigLabelValuesForModel} from "../../cpes/util/CPEHelpers";
import DeviceUnknownIcon from "@mui/icons-material/DeviceUnknown";
import CpeMPPConfigForm from "../../cpes/config/CpeMPPConfigForm";
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";


export const useHotDeskSection = (userId: string, enabled: boolean, user ?: PhoneUser) : FormSection<HotDeskSettings> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const {buildFormButtons} = useFormSubmissionButtons(false)
    const fetchHotDeskSettings = useCallback(
        async (userId: string): Promise<HotDeskSettings | undefined> => {
            if (!site || !enabled) return
            const { data } = await api.get<HotDeskSettings>(`/api/site/${site.productId}/user/${userId}/hot-desk-settings`)
            return data
        },
        [site, enabled],
    )
    const { data, isFetching, error, refetch } = useFetchData<HotDeskSettings>(useCallback(() => fetchHotDeskSettings(userId), [fetchHotDeskSettings, userId]))

    const configs = useMemo(() => data ? CPEConfigMapping(data.cpeSettings) : [], [data])

    const onSubmit = useCallback((onSucceeded: () => void) =>
        async (formData: CpeConfigResult) => {
                if (!site) return
                await api.put(`/api/site/${site.productId}/user/${userId}/hot-desk-settings`, {
                    ...formData.hotDeskSettings,
                    cpeSettings: {
                        model: 'MPP_HOT_DESK',
                        ...formData.cpeSettings
                    }})
                await refetch()
                onSucceeded()
            }, [site, userId, refetch],)

    const renderForm = useCallback((settings: HotDeskSettings, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode =>
        !!data && !!settings?.cpeSettings ? <CpeMPPConfigForm
                user={user}
                cpeSettings={settings.cpeSettings}
                hotDeskSettings={settings}
                onSubmit={onSubmit(onSucceeded)}
                buildFormButtons={buildFormButtons(cancelEditButton)}/>
            : <></>, [onSubmit, data, buildFormButtons, user])
            //

    const generalLabelValues = useCallback((value: HotDeskSettings) =>
       [
           {
               label: t('users.edit.hot-desking'),
               value: <>{value.hotDeskingEnabled?
                   `${t('general.yes')} (${value.hotDeskAssociationLimitEnabled ? t('users.edit.hours-association-limit', {hours: value.hotDeskAssociationLimitHours})
                       : t('users.edit.no-association-limit')})` : t('general.no')}</>,
           }
       ],  [t]
    )

    const configLabelValues = useCallback((value: CPESettings | null) =>
        getConfigLabelValuesForModel(configs, "Voicecloud-Cisco-MPP", t, user),  [configs, t, user]
    )

    const renderHotDesk = useCallback(
        (value: HotDeskSettings): ReactNode => {
            const labelValues = configLabelValues(value.cpeSettings) || []
            return (
                <PaperStack>
                    <FormGroup key={1} label={t('cpes.mpp.settings-title')} fullWidth={true}>
                        <LabelValues labelValues={generalLabelValues(value)} />
                    </FormGroup>
                    <FormGroup key={2} label={t('cpes.detail.configuration')} fullWidth={true}>
                        <LabelValues labelValues={labelValues} />
                    </FormGroup>
                </PaperStack>
            )
        },
        [t, configLabelValues, generalLabelValues]
    )

    return {
        title: t('users.sections.hot-desk'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderHotDesk,
        renderForm: renderForm,
        enabled: () => enabled,
        icon: () => <DeviceUnknownIcon />
    }
}