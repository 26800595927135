import React, {useCallback, useMemo} from 'react'
import {Grid} from '@mui/material'
import {PhoneNumber} from "../../app/types";
import {find} from "ramda";
import TextInput from "../form/TextInput";
import {Control, useWatch} from "react-hook-form";

type Props = {
    phoneNumbersToForwardTo: PhoneNumber[]
    defaultNumber: string | null
    readonly: boolean | undefined
    required: boolean
    label: string
    name: string
    control: Control<any>
}

export const ForwardToNumberField = ({ phoneNumbersToForwardTo, defaultNumber, readonly, label, required, control, name }: Props) => {
    const currentValue = useWatch({
        control,
        name: name,
    })

    const formatOverflowNumber = useCallback((extension: string | null) => {
        const nr = find((nr: PhoneNumber) => nr.extension === extension)(phoneNumbersToForwardTo)
        return nr? `${nr.extension} (${nr.assignedTo})` : extension
    } , [phoneNumbersToForwardTo])

    const assignedTo = useMemo(() => {
        const nr = find((nr: PhoneNumber) => nr.extension === currentValue)(phoneNumbersToForwardTo)
        return nr? nr.assignedTo : ''
    } , [phoneNumbersToForwardTo, currentValue])

    const forwardNumberOptionParams = useMemo(() => {
        return {
            getOptions: async (searchTerm: string) => phoneNumbersToForwardTo,
            getOptionLabel: (item: any) => {
                return typeof item === 'string' ? formatOverflowNumber(item) || '' : `${item.extension} (${item.assignedTo})`
            },
            getOptionValue: (item: any) => {
                return typeof item === 'string' ? item : item.extension
            },
            isOptionEqualToValue: (option: PhoneNumber, value: PhoneNumber) => option?.assignedTo === value?.assignedTo,
            filterOnServer: false,
            defaultObject: defaultNumber,
            freeText: true,
        }
    }, [defaultNumber, phoneNumbersToForwardTo, formatOverflowNumber])

    return (
        <Grid container item>
            <Grid item xs={6}>
                <TextInput
                    readonly={readonly}
                    label={label}
                    name={name}
                    control={control}
                    required={required}
                    autoCompleteOptions={forwardNumberOptionParams}
                />
            </Grid>
            <Grid item alignSelf={'end'} pl={2}>
                {assignedTo}
            </Grid>
        </Grid>
    )
}
