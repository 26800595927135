import React, { MouseEventHandler } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Fab, useMediaQuery } from '@mui/material'

type Props = {
    onClick: MouseEventHandler
    label: string
    small?: boolean
}

export const AddButton = ({ onClick, label, small }: Props) => {
    // @ts-ignore
    const matches = useMediaQuery(theme => theme.breakpoints.up('md'))

    return matches && !small ? (
        <Fab title={label} onClick={onClick} color="primary" variant="extended" size={'medium'}>
            <AddIcon sx={{ mr: 1 }} />
            {label}
        </Fab>
    ) : (
        <Fab title={label} onClick={onClick} color="primary" size={small ? 'small' : 'medium'}>
            <AddIcon />
        </Fab>
    )
}
