import {useCallback} from 'react'
import {clear, meSelector} from "../../authentication/redux";
import {useDispatch, useSelector} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {useHistory} from "react-router";
import {useOktaAuth} from "@okta/okta-react";

export function useOktaSession(): { logout: () => void, loggedOut: () => void } {
    const dispatch = useDispatch<ThunkDispatch<RootState, any, any>>()
    const history = useHistory()
    const me = useSelector(meSelector)
    const oktaAuth = useOktaAuth();

    const signout = useCallback( (url: string) => async() => {
        await dispatch(clear())
        if (!!oktaAuth) {
            await oktaAuth.oktaAuth.tokenManager.clear()
        }

        await sessionStorage.clear()
        history.push(`${url}?RelayState=${me?.customerProductId}`)
    }, [me, dispatch, history, oktaAuth])


    const logout = useCallback( () => signout('/sign-out'), [signout])
    const loggedOut = useCallback( () => signout('/unauthorized'), [signout])

    return { logout: logout(), loggedOut: loggedOut() }
}
