import { CPE } from '../app/types'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormLabel, Grid, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CancelButton from '../common/form/CancelButton'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectedSiteSelector } from '../authentication/redux'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { api } from '../common/api'
import { Spinner } from '../common/components/Spinner'
import RefreshIcon from '@mui/icons-material/Refresh'
import ErrorAlert from '../common/components/ErrorAlert'
import { format, parseISO } from 'date-fns'
import {formatMPPActivationCode} from "../common/util/formatters";

type Props = {
    cpe: CPE | null
    handleClose: () => void
}

type ActivationCode = {
    code: string | null
    state: string | null
    expiryTime: string | null
}

export const ActivationCodeDialog = ({ cpe, handleClose }: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const [activationCode, setActivationCode] = useState<ActivationCode>({ code: null, state: 'DEACTIVATED', expiryTime: null })
    const [fetching, setFetching] = useState<boolean>(false)
    const [fetchError, setFetchError] = useState<boolean>(false)

    const fetchActivationCode = useCallback(async () => {
        setFetchError(false)
        if (!site || !cpe) return

        setFetching(true)
        try {
            const { data } = await api.get<ActivationCode>(`/api/site/${site.productId}/cpe/${cpe.uuid}/activation-code`)

            setActivationCode(data)
        } catch (e) {
            setFetchError(true)
        }

        setFetching(false)
    }, [site, cpe])

    const toggleState = useCallback(async () => {
        if (!site || !cpe || !activationCode.state) return

        await api.put(`/api/site/${site.productId}/cpe/${cpe.uuid}/activation-code/${!['ACTIVATED', 'ACTIVATING'].includes(activationCode.state)}`).catch(console.error)
    }, [site, cpe, activationCode])

    const generateActivationCode = useCallback(async () => {
        if (!site || !cpe) return
        setFetching(true)

        try {
            if (activationCode.state !== 'DEACTIVATED') {
                await toggleState()
            }
            await api.put(`/api/site/${site.productId}/cpe/${cpe.uuid}/activation-code`).catch(console.error)
        } catch (e) {
            setFetchError(true)
            setFetching(false)
            return
        }

        await fetchActivationCode()
    }, [site, cpe, activationCode.state, fetchActivationCode, toggleState])

    useEffect(() => {
        setActivationCode({ code: null, state: 'DEACTIVATED', expiryTime: null })
        setFetchError(false)
        fetchActivationCode().catch(console.error)
    }, [fetchActivationCode, setActivationCode, cpe])

    return (
        <Dialog open={!!cpe} onClose={handleClose} fullWidth={true}>
            <DialogTitle>
                {t('cpes.activation-dialog.title')}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'space-between'}>
                    {fetching && (
                        <Grid item xs={12} alignSelf={'center'}>
                            <Spinner size={36} />
                        </Grid>
                    )}
                    {!fetching && fetchError && (
                        <Grid item xs={12} alignSelf={'center'} width={'100%'}>
                            <ErrorAlert showAlert={true} />
                        </Grid>
                    )}
                    {!fetching && !activationCode.code && (
                        <>
                            <Grid item>
                                <DialogContentText>{t('cpes.activation-dialog.generate-question')}</DialogContentText>
                            </Grid>
                            <Grid item mt={1}>
                                <Button size={'small'} onClick={generateActivationCode} variant="contained" key={'generate'} title={t('cpes.activation-dialog.generate')}>
                                    {t('cpes.activation-dialog.generate')}
                                </Button>
                            </Grid>
                        </>
                    )}
                    {!fetching && activationCode.code && (
                        <>
                            <Grid item container spacing={3} alignItems="center">
                                <Grid item xs={2}>
                                    <FormLabel>{t('cpes.activation-dialog.code')}: </FormLabel>
                                </Grid>
                                <Grid item container alignItems="center" justifyContent={'flex-start'} xs={10} flexWrap={'nowrap'}>
                                    <Grid item>{formatMPPActivationCode(activationCode.code)}</Grid>
                                    <Grid item>
                                        <Tooltip title={t('cpes.activation-dialog.regenerate')} placement="right">
                                            <IconButton onClick={generateActivationCode} sx={{ color: 'primary' }}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={3} alignItems="center">
                                <Grid item xs={2}>
                                    <FormLabel>{t('cpes.activation-dialog.status')}: </FormLabel>
                                </Grid>
                                <Grid item>{`${t(`cpes.activation-dialog.status-values.${activationCode.state?.toLowerCase()}`)}${
                                    activationCode.state === 'ACTIVATING' && activationCode.expiryTime
                                        ? ` (${t('cpes.activation-dialog.expiry')}: ${format(parseISO(activationCode.expiryTime), 'dd/MM/yyyy HH:mm')})`
                                        : ''
                                }`}</Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <CancelButton title={t('general.close')} onClick={handleClose} />
            </DialogActions>
        </Dialog>
    )
}
