import React, {ReactElement, ReactNode, useCallback} from 'react'
import {FormSection} from "../../../common/components/SectionedForm";
import {api} from "../../../common/api";
import FormButtons from "../../../common/form/FormButtons";
import SpinnableSubmitButton from "../../../common/form/SpinnableSubmitButton";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../../authentication/redux";
import {useTranslation} from "react-i18next";
import PaperStack from "../../../common/form/PaperStack";
import FormGroup from "../../../common/form/FormGroup";
import {LabelValues, LabelValueType} from "../../../common/components/LabelValues";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import {ExecutiveScreeningAlert} from "../../../app/types";
import {useFetchData} from "../../../common/servercall/useFetchData";
import {ScreenAlertForm} from "./screeningAlertForm";

export const useScreeningAlertSection = (executiveId: string) : FormSection<ExecutiveScreeningAlert> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)

    const fetchScreeningAlert = useCallback(
        async (userId: string): Promise<ExecutiveScreeningAlert | undefined> => {
            if (!site) return
            const { data: settings } = await api.get<ExecutiveScreeningAlert>(`/api/site/${site.productId}/executive/${executiveId}/screening-alert`)
            return settings
        },
        [site, executiveId]
    )
    const { data, isFetching, error, refetch } = useFetchData<ExecutiveScreeningAlert>(useCallback(() => fetchScreeningAlert(executiveId), [fetchScreeningAlert, executiveId]))

    const onSubmit = useCallback((onSucceeded: () => void) =>
            async (formData: ExecutiveScreeningAlert) => {
                if (!site) return
                await api.put(`/api/site/${site.productId}/executive/${executiveId}/screening-alert`, formData)
                await refetch()
                onSucceeded();
            },
        [site, executiveId, refetch],
    )

    const buildFormButtons = useCallback( (cancelEditButton: ReactElement) => (isSubmitting: boolean) =>
            <FormButtons buttons={[<SpinnableSubmitButton label={t('general.form-update')} showSpinner={isSubmitting} />, cancelEditButton]} fullWidth={true}/>
        , [t])

    const renderForm = useCallback((screeningAlert: ExecutiveScreeningAlert, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode => <ScreenAlertForm screeningAlert={screeningAlert} buildFormButtons={buildFormButtons(cancelEditButton)} onSubmit={onSubmit(onSucceeded)} />, [onSubmit, buildFormButtons])

    const labelValues = useCallback(
        (value: ExecutiveScreeningAlert): LabelValueType[] => [
            {
                label: t('executives.screening-alerting.alerting-mode'),
                value: <>{t(`executives.screening-alerting.alerting-types.${value.alertingMode}`)} {value.alertingMode === 'SEQUENTIAL' ? `(${t('executives.screening-alerting.seconds-between-assistants', {seconds: value.ringTimePerAssistant}) })` : ''}</>,
            },
            {
                label: t('executives.screening-alerting.rollover'),
                value: <>{!value.enableRollover? t('executives.screening-alerting.disabled') : `${t(`executives.screening-alerting.rollover-types.${value.rolloverAction}`)}${value.rolloverAction === 'FORWARD' ? ` ${value.rolloverForwardNumber}` : ''} ${t('executives.screening-alerting.rollover-after-seconds', {seconds: value.rolloverWaitTimeSeconds})}`}</>,
            },
            {
                label: t('executives.screening-alerting.call-push-recall-time'),
                value: <>{`${value.callPushRecallRingTime} ${t('general.seconds-abbrev')}`}</>,
            },
        ],
        [t]
    )

    const renderFiltering = useCallback(
        (screeningAlert: ExecutiveScreeningAlert): ReactNode => {
            return (
                <PaperStack>
                    {[<FormGroup key={1} label={''} fullWidth={true}>
                        <LabelValues labelValues={labelValues(screeningAlert)} />
                    </FormGroup>]}
                </PaperStack>
            )
        },
        [labelValues])

    return {
        title: t('executives.section-screening-alerting'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderFiltering,
        renderForm: renderForm,
        enabled: () => true,
        icon: () => <RecentActorsIcon/>
    }
}