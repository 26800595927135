import React, {ReactElement, useCallback, useEffect, useMemo, useState} from 'react'
import {AutoCompleteOptions} from "../../form/AutocompleteWithAsyncFetch";
import {alpha, Button, Grid, IconButton, InputBase, Popper, Tooltip} from "@mui/material";
import useTableInput, {moveItem, removeItem} from "../../form/useTableInput";
import {Control} from "react-hook-form";
import {ColumnDefinition} from "./Table";
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {useTranslation} from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {makeStyles} from "@mui/styles";
import {findIndex} from "ramda";

interface ReturnProps {
    selector: ReactElement,
    table: () => ReactElement
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        fontSize: 13,
        width: '100%',
        textAlign: 'left',
        paddingBottom: 8,
        color: '#586069',
        fontWeight: 600,
        '&:hover,&:focus': {
            color: '#0366d6',
        },
        '& span': {
            width: '100%',
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    tag: {
        marginTop: 3,
        height: 20,
        padding: '.15em 4px',
        fontWeight: 600,
        lineHeight: '15px',
        borderRadius: 2,
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        width: 500,
        zIndex: 3000,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    header: {
        borderBottom: '1px solid #e1e4e8',
        padding: '8px 10px',
        fontWeight: 600,
    },
    inputBase: {
        padding: 10,
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 8,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
    },
    text: {
        flexGrow: 1,
    },
    close: {
        opacity: 0.6,
        width: 18,
        height: 18,
    },
}));

export function useTableWithAdjustableOrder<T>(
                                               fieldName: string,
                                               options: AutoCompleteOptions<T>,
                                               control: Control<any>,
                                               formatEntity: (entity: T) => string,
                                               getUniqueKey: (entity: T) => string,
                                               selectButtonText: string,
                                               disableOrdering?: boolean,
                                               nameColumnLabel?: string,
                                               disablePortal?: boolean
): ReturnProps {
    const classes = useStyles();

    const { t } = useTranslation()
    const [optionsToShow, setOptionsToShow] = useState<T[]>([])
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [pendingValue, setPendingValue] = useState<T[]>([]);

    useEffect(() => {
        ;(async () => {
            const objects: T[] = await options.getOptions('')
            setOptionsToShow(objects)
        })()
    }, [options]);

    const columns: (remove: removeItem, move: moveItem) => ColumnDefinition<T>[] = useCallback(
        (remove, move) => [
            {
                title: nameColumnLabel || t('users.edit.busy-lamp-users.table.name'),
                sorter: false,
                render: (text: string, record: T) => <>{formatEntity(record)}</>,
            },
            ...(disableOrdering ? [] : [{
                title: t('users.edit.busy-lamp-users.table.level'),
                sorter: false,
                render: (text: string, record: T, index: number) => <>{index}</>,
            }]),
            {
                title: '',
                sorter: false,
                render: (text: string, record: T, index, length: number) => (
                    <>
                        <Grid container>
                            {!disableOrdering && index !== 0 && (
                                <Tooltip placement={'right-end'} title={`${t('users.edit.busy-lamp-users.table.move-upward')}`}>
                                    <IconButton
                                        disabled={index === 0}
                                        size={'small'}
                                        aria-haspopup="true"
                                        onClick={() => {
                                            index !== 0 && move(index, index - 1)
                                        }}>
                                        <ArrowUpward fontSize={'inherit'} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {!disableOrdering && index === 0 && (
                                <IconButton disabled={true} size={'small'}>
                                    <ArrowUpward fontSize={'inherit'} />
                                </IconButton>
                            )}
                            {!disableOrdering && index !== length - 1 && (
                                <Tooltip placement={'right-end'} title={`${t('users.edit.busy-lamp-users.table.move-downward')}`}>
                                    <IconButton
                                        disabled={index === length - 1}
                                        size={'small'}
                                        aria-haspopup="true"
                                        onClick={() => {
                                            move(index, index + 1)
                                        }}>
                                        <ArrowDownward fontSize={'inherit'} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {!disableOrdering && index === length - 1 && (
                                <IconButton disabled={true} size={'small'}>
                                    <ArrowDownward fontSize={'inherit'} />
                                </IconButton>
                            )}
                            <Tooltip placement={'right-end'} title={`${t('users.edit.busy-lamp-users.table.delete')}`}>
                                <IconButton
                                    size={'small'}
                                    aria-haspopup="true"
                                    onClick={() => {
                                        remove(index)
                                    }}>
                                    <DeleteIcon fontSize={'inherit'} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </>
                ),
            },
        ], [t, formatEntity, disableOrdering, nameColumnLabel])

    const { table: ItemTable, replace, data } = useTableInput(fieldName, control, columns, t('general.nothing-selected'))

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setPendingValue(data);
        setAnchorEl(event.currentTarget);
    }, [data]);

    const handleClose = useCallback((event: React.SyntheticEvent, reason: string) => {
        if (reason === 'toggleInput') {
            return;
        }
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
        replace(pendingValue)
    }, [anchorEl, replace, pendingValue]);

    const menuOpen = Boolean(anchorEl);
    const id = menuOpen ? 'itemSelector' : undefined;

    const Selector = useMemo(() =>
        <div className={classes.root}>
            <Button onClick={handleClick} id={'selectButton'} aria-describedby={id} variant='contained' size={'medium'}>
                <span>{selectButtonText}</span>
            </Button>
            <Popper
                id={id}
                open={menuOpen}
                anchorEl={anchorEl}
                placement="bottom-start"
                className={classes.popper}
            >
                <Autocomplete
                    open
                    noOptionsText={t('general.no-data')}
                    onClose={handleClose}
                    multiple
                    value={pendingValue}
                    onChange={(event, newValue) => {
                        setPendingValue(newValue);
                        //replace(newValue)
                    }}
                    classes={{
                        paper: classes.paper,
                        option: classes.option,
                        popperDisablePortal: disablePortal || (disablePortal === undefined) ? classes.popperDisablePortal : undefined,
                    }}
                    isOptionEqualToValue={options.isOptionEqualToValue}
                    disableCloseOnSelect
                    disablePortal={disablePortal === undefined? true : disablePortal}
                    renderTags={() => null}
                    renderOption={(props, option, state) => {
                        return <li {...props} key={getUniqueKey(option)}>
                            <DoneIcon
                                className={classes.iconSelected}
                                style={{visibility: state.selected ? 'visible' : 'hidden'}}
                            />
                            <div className={classes.text}>
                                {formatEntity(option)}
                            </div>
                            <CloseIcon
                                className={classes.close}
                                style={{visibility: state.selected ? 'visible' : 'hidden'}}
                            />
                        </li>
                    }}
                    options={[...optionsToShow].sort((a, b) => {
                        // Display the selected labels first.
                        let ai = findIndex(user => getUniqueKey(user) === getUniqueKey(a), data);
                        ai = ai === -1 ? data.length + findIndex(user => getUniqueKey(user) === getUniqueKey(a), optionsToShow) : ai;
                        let bi = findIndex(user => getUniqueKey(user) === getUniqueKey(b), data);
                        bi = bi === -1 ? data.length + findIndex(user => getUniqueKey(user) === getUniqueKey(b), optionsToShow) : bi;
                        return ai - bi;
                    })}
                    getOptionLabel={options.getOptionLabel}
                    renderInput={(params) => (
                        <InputBase
                            placeholder={t('general.search')}
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            autoFocus
                            className={classes.inputBase}
                        />
                    )}
                    getOptionDisabled={options.getOptionDisabled}
                />
            </Popper>
        </div>,
        [t, selectButtonText, getUniqueKey, options.getOptionLabel,
            data, optionsToShow, formatEntity, anchorEl, handleClose, menuOpen, pendingValue, id, handleClick,
            options.isOptionEqualToValue, classes, options.getOptionDisabled, disablePortal])

    return {
        selector: Selector,
        table: () => <ItemTable/>
    }
}