import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { api } from '../common/api'
import { useSelector } from 'react-redux'
import { selectedSiteSelector } from '../authentication/redux'
import { useTranslation } from 'react-i18next'
import { getAutocompleteMainNumberCandidates } from '../common/util/helpers'
import { ErrorOption } from 'react-hook-form/dist/types/errors'
import { useFormSubmission } from '../common/servercall/useFormSubmission'
import { Box, Grid, IconButton, Stack } from '@mui/material'
import TextInput from '../common/form/TextInput'
import { Cancel, Check } from '@mui/icons-material'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Spinner } from '../common/components/Spinner'

const schema = yup.object({
    number: yup.string().nullable().required('required-m'),
})

type form = {
    number: string
}

type props = {
    mainNumberForm: form,
    onCancel: () => void
    onSuccess: () => void
}

export const MainNumberForm = ({ mainNumberForm, onSuccess, onCancel }: props) => {
    const site = useSelector(selectedSiteSelector)
    const { t } = useTranslation()

    const { control, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: { number: mainNumberForm.number },
    })

    const onSubmit = useCallback(
        async (formData: form) => {
            if (!site) return
            await api.put(`/api/site/${site.productId}/numbers/main-number`, { ...formData })
            onSuccess()
        },
        [site, onSuccess],
    )

    const checkError = useCallback(
        (name: any, error: ErrorOption) => {
            setError(name, error)
        },
        [setError],
    )

    const { submit, isSubmitting } = useFormSubmission<form>(onSubmit, checkError)

    const numberOptionParams = useMemo(() => {
        return getAutocompleteMainNumberCandidates(t, site, mainNumberForm.number)
    }, [site, t, mainNumberForm])


    return (
        <form onSubmit={handleSubmit(submit)}>
            <Stack direction={'row'}>
                <Grid item xs={12} sm={8} md={6} lg={4} alignSelf={'center'} sx={{ marginTop: -2 }}>
                    <TextInput readonly={false} label={' '} name={'number'}
                               control={control} required={false} autoCompleteOptions={numberOptionParams} />
                </Grid>
                {
                    isSubmitting ?
                        <Box alignSelf={'center'} sx={{ marginLeft: 2 }}>
                            <Spinner size={16} />
                        </Box> :
                        <>
                            <Box alignSelf={'center'} sx={{ marginLeft: 1 }}>
                                <IconButton type={'submit'} size={'small'} color='success'
                                            aria-label='Change main number'>
                                    <Check />
                                </IconButton>
                            </Box>
                            <Box alignSelf={'center'} sx={{ marginLeft: 1 }}>
                                <IconButton size={'small'} color='secondary' aria-label='Cancel' onClick={onCancel}>
                                    <Cancel />
                                </IconButton>
                            </Box>
                        </>

                }

            </Stack>
        </form>
    )
}