import React, {ReactElement, useCallback, useMemo, useState} from 'react'
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import SpinnableSubmitButton from "../../form/SpinnableSubmitButton";
import CancelButton from "../../form/CancelButton";
import {useTranslation} from "react-i18next";
import ErrorAlert from "../ErrorAlert";


interface ReturnProps {
    warningDialog: () => ReactElement
    calls: (checkWarningCall: () => Promise<string[]>, continuation: () => Promise<void>) => void
}

export function useWarningDialog(): ReturnProps {
    const [continuationFunction, setContinuationFunction] = useState<(() => Promise<void>) | null>(null)
    const [warningCode, setWarningCode] = useState<string | null>(null)
    const { t } = useTranslation()

    const dialog = useMemo(() =>
            <Dialog open={continuationFunction !== null} onClose={() => setContinuationFunction(null)}>
                <DialogTitle>{t('')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <ErrorAlert severity={'warning'} showAlert={true} errorKey={`general.warnings.${warningCode}`} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SpinnableSubmitButton label={t('general.yes')} showSpinner={false} onClick={async () => {if (continuationFunction != null) { await continuationFunction(); setContinuationFunction(null);}}} />
                    <CancelButton title={t('general.no')} onClick={() => setContinuationFunction(null)} />
                </DialogActions>
            </Dialog>,
        [continuationFunction, t, warningCode])

    const doCalls = useCallback(async (checkWarningCall: () => Promise<string[]>, continuation: () => Promise<void>) => {
        const warnings = await checkWarningCall()
        if (warnings.length === 0) {
            await continuation()
        } else {
            setWarningCode(warnings[0])
            setContinuationFunction(() => continuation)
        }
    }, [])

    return {
        warningDialog: () => dialog,
        calls: doCalls
    }
}
