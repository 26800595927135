import React, {ReactElement, ReactNode, useCallback, useEffect, useState} from 'react'
import {FormSection} from "../../../common/components/SectionedForm";
import {api} from "../../../common/api";
import FormButtons from "../../../common/form/FormButtons";
import SpinnableSubmitButton from "../../../common/form/SpinnableSubmitButton";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../../authentication/redux";
import {useTranslation} from "react-i18next";
import PaperStack from "../../../common/form/PaperStack";
import FormGroup from "../../../common/form/FormGroup";
import {LabelValues, LabelValueType} from "../../../common/components/LabelValues";
import {Link, Paper, Table, TableContainer} from "@mui/material";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import {Executive, ExecutiveAssistantSetting, ExecutiveWithAssistantsForm} from "../../../app/types";
import {detailUrl} from "../../../users";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {UserLink} from "../../../users/UserLink";
import Form from "../Form";
import {AssistantOptInSwitch} from "./AssistantOptInSwitch";
import {find, propEq} from "ramda";
import {AssistantDivert} from "./AssistantDivert";
import {useAccessHelpers} from "../../../common/util/hooks";

export const useGeneralSection = (executiveId: string, isFetching: boolean, error: any, refetch: () => void, data?: Executive) : FormSection<Executive> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const [assistantSettings, setAssistantSettings] = useState<ExecutiveAssistantSetting[]>([])
    const {hasWriteAccess} = useAccessHelpers()

    useEffect(() => {
            const fetch = async () => {
                try {
                    if (!site || !data) return
                    const {data: settings} = await api.get(`/api/site/${site.productId}/executive/${executiveId}/assistants`)
                    setAssistantSettings(settings)
                } catch (err: any) {

                }
            }
            fetch();
        },
        [data, site, executiveId]
    )

    const onGeneralSubmit = useCallback((onSucceeded: () => void) =>
            async (formData: ExecutiveWithAssistantsForm) => {
                if (!site) return
                await api.put(`/api/site/${site.productId}/executive/${executiveId}`, {
                    assistantUserIds: formData.assistantUsers.map(assistant => assistant.uuid)
                })
                await refetch()
                onSucceeded();
            },
        [site, executiveId, refetch],
    )

    const buildFormButtons = useCallback( (cancelEditButton: ReactElement) => (isSubmitting: boolean) =>
            <FormButtons buttons={[<SpinnableSubmitButton label={t('general.form-update')} showSpinner={isSubmitting} />, cancelEditButton]} fullWidth={true}/>
        , [t])

    const renderGeneralForm = useCallback((executive: Executive, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode => <Form executive={executive}buildFormButtons={buildFormButtons(cancelEditButton)}
                                                                                                                                              onSubmit={onGeneralSubmit(onSucceeded)} />, [onGeneralSubmit, buildFormButtons])
    const AssistantOptIn = useCallback((userId: string) => <AssistantOptInSwitch
            optIn={find(propEq( 'userId', userId), assistantSettings)?.optIn}
            userBusinessId={userId}
            executiveId={executiveId}
            readonly={!hasWriteAccess}
        />, [executiveId, assistantSettings, hasWriteAccess])

    const AssistantDivertComp = useCallback((userId: string) => <AssistantDivert
        initialAssistant={find(propEq( 'userId', userId), assistantSettings)}
        userBusinessId={userId}
        executiveId={executiveId}
        readonly={!hasWriteAccess}
    />, [executiveId, assistantSettings, hasWriteAccess])

    const infoLabelValues = useCallback(
        (executive: Executive): LabelValueType[] => [
            {
                label: t('executives.executive'),
                value: <Link
                    href={detailUrl.replace(':id', executive.userId || '')}>{`${executive.userLastName} ${executive.userFirstName}`}</Link>,
            },
            {
                label: t('executives.assistants'),
                value: <TableContainer component={Paper} sx={{marginTop: 1}}>
                    <Table sx={{ minWidth: 300 }} size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 700, minWidth: '30%', width: '30%' }}>{t('flows.table.name')}</TableCell>
                                <TableCell style={{ fontWeight: 700 }} align="left">
                                    {t('executives.divert')}
                                </TableCell>
                                <TableCell style={{ fontWeight: 700 }} align="right">
                                    {t('executives.opt-in')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {executive.assistants.map(assistant =>
                                <TableRow key={assistant.userId} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell scope="row">
                                        <UserLink uuid={assistant.userId} siteProductId={assistant.siteProductId} formattedUser={`${assistant.userLastName} ${assistant.userFirstName}`}/>
                                    </TableCell>
                                    <TableCell align="left">
                                        {AssistantDivertComp(assistant.userBusinessId)}
                                    </TableCell>
                                    <TableCell align="right">
                                        {AssistantOptIn(assistant.userBusinessId)}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>,
                type: "FULL_LINE"
            },
        ],
        [t, AssistantOptIn, AssistantDivertComp]
    )

    const renderDetails = useCallback(
        (executive: Executive): ReactNode => {
            return (
                <PaperStack>
                    {[<FormGroup key={1} label={''} fullWidth={true}>
                        <LabelValues labelValues={infoLabelValues(executive)} />
                    </FormGroup>]}
                </PaperStack>
            )
        },
        [infoLabelValues])

    return {
        title: t('executives.assistants'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderDetails,
        renderForm: renderGeneralForm,
        enabled: () => true,
        icon: () => <SupervisedUserCircleIcon/>
    }
}