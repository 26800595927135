import { useCallback, useEffect, useState } from 'react'

type actionCallback<T> = () => Promise<T | undefined>

export function useFetchData<T>(action: actionCallback<T>): { data?: T; isFetching: boolean; error: any; refetch: () => void } {
    const [error, setError] = useState<any>(null)
    const [isFetching, setFetching] = useState<boolean>(false)
    const [data, setData] = useState<T | undefined>()

    const loadData = useCallback(async () => {
        setFetching(true)
        try {
            const data = await action()
            setData(data)
        } catch (e) {
            setError(e)
        }
        setFetching(false)
    }, [setFetching, setData, setError, action])

    useEffect(() => {
        loadData()
    }, [loadData])

    return { data, isFetching, error, refetch: loadData }
}
