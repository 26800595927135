import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../../common/components/TitledBody'
import {Day, HolidayForm, HolidayPeriod} from '../../app/types'
import {api} from '../../common/api'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import {useHistory} from 'react-router'
import Form from './Form'
import {transformHolidayFormToSchedule} from '../util/scheduleHelpers'
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";
import CancelButton from "../../common/form/CancelButton";
import {Grid} from "@mui/material";

type DayKey = keyof typeof Day
export const days = Object.keys(Day).filter(element => {
    return isNaN(Number(element))
}) as DayKey[]

export const emptyPeriod: HolidayPeriod = {
    eventName: '',
    from: null,
    to: null,
    allDay: true,
    startTime: null,
    endTime: null,
}

const initialHolidays: HolidayForm = {
    uuid: '',
    name: '',
    type: 'HOLIDAY',
    holidayPeriods: [emptyPeriod],
}

export const AddHolidays = () => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const history = useHistory()
    const {buildFormButtons} = useFormSubmissionButtons(true)

    const onSubmit = useCallback(
        async (formData: HolidayForm) => {
            if (!site) return
            await api.post(`/api/site/${site.productId}/schedule`, transformHolidayFormToSchedule(formData))
            history.push('/holidays')
        },
        [site, history],
    )

    return (
        <TitledBody title={t('schedules.holidays.add.title')}>
            <Grid container>
                <Grid item xs={12} lg={10}>
                    <Form onSubmit={onSubmit} holidays={initialHolidays} buildFormButtons={buildFormButtons(<CancelButton/>)} />
                </Grid>
            </Grid>
        </TitledBody>
    )
}
