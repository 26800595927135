import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import {PhoneBookConfig, PhoneBookEntry, ServerPage} from '../app/types'
import {useSelector} from 'react-redux'
import {inEnterpriseViewSelector, isEnterpriseAdminSelector, selectedSiteSelector} from '../authentication/redux'
import {api, buildPaginatedUrl} from '../common/api'
import ErrorAlert from '../common/components/ErrorAlert'
import {AddButton} from '../common/components/AddButton'
import {SearchField} from '../common/components/SearchField'
import {Sort, useInfiniteScroll} from '../common/components/table/useInfiniteScroll'
import {ColumnDefinition, InfiniteScrollTable} from '../common/components/table/Table'
import TableRowActionsMenu from '../common/components/table/TableRowActionsMenu'
import {sorterHeader} from '../common/components/table/SorterHeader'
import DeletionModal from '../common/components/DeletionModal'
import usePhoneBookForm from './usePhoneBookForm'
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    ListItemIcon,
    ListItemText,
    Menu
} from '@mui/material'
import CancelButton from '../common/form/CancelButton'
import FileDropZone from '../common/components/upload/FileDropZone'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ClearIcon from '@mui/icons-material/Clear'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {useOktaAuth} from "@okta/okta-react";
import {useAccessHelpers, useUrlHelpers} from "../common/util/hooks";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import usePhoneBookConfigForm from "./usePhoneBookConfigForm";

const MAX_CSV_FILE_SIZE = 5000 * 1024

export const PhoneBook = () => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const isEnterpriseAdmin = useSelector(isEnterpriseAdminSelector)
    const inEnterpriseView = useSelector(inEnterpriseViewSelector)
    const [entryToDelete, setEntryToDelete] = useState<PhoneBookEntry | null>(null)
    const [entryToUpdate, setEntryToUpdate] = useState<PhoneBookEntry | null>(null)
    const [openImportCsvDialog, setOpenImportCsvDialog] = useState<boolean>(false)
    const [openClearConfirmation, setOpenClearConfirmation] = useState<boolean>(false)
    const [clearExistingValuesOnUpload, setClearExistingValuesOnUpload] = useState<boolean>(false)
    const [phoneBookConfig, setPhoneBookConfig] = useState<PhoneBookConfig | null>(null)
    const [showConfigForm, setShowConfigForm] = useState<boolean>(false)
    const {createUrl} = useUrlHelpers();
    const {hasWriteAccess, onlyWithWriteAccess} = useAccessHelpers()

    const phoneBookConfigUrl = useMemo(() => createUrl('phonebook/config'), [createUrl])

    useEffect(() => {
        const getConfig = async () => {
            setPhoneBookConfig((await api.get(phoneBookConfigUrl))?.data)
        }
        getConfig()
    }, [phoneBookConfigUrl])

    const fetchEntries = useCallback(
        async (pageParam = 0, sortParam: Sort, searchTermParam: string | null): Promise<ServerPage<PhoneBookEntry>> => {
            const { data } = await api.get(buildPaginatedUrl(createUrl('phonebook'), pageParam, sortParam, searchTermParam))
            return data
        },
        [createUrl]
    )

    const { sort, setSort, setSearchTerm, rows, error, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteScroll<PhoneBookEntry>({
        queryKey: `phonebook-${site?.productId}`,
        initialSort: { field: 'name', order: 'asc' },
        fetchFunction: fetchEntries,
    })

    const onSubmit = useCallback(
        async (formData: PhoneBookEntry) => {
            const url = createUrl(`phonebook${entryToUpdate?.uuid ? `/${entryToUpdate.uuid}` : ''}`)
            if (entryToUpdate?.uuid) {
                await api.put(url, formData)
            } else {
                await api.post(url, formData)
            }

            setEntryToUpdate(null)

            await refetch()
        },
        [refetch, setEntryToUpdate, entryToUpdate, createUrl]
    )

    const onConfigSubmit = useCallback(
        async (formData: PhoneBookConfig) => {
            setPhoneBookConfig(formData)
            await api.put(phoneBookConfigUrl, formData)
            setShowConfigForm(false)
        },
        [phoneBookConfigUrl]
    )

    const { handleSubmit, form: Form, submitButton: SubmitButton, reset, setValue } = usePhoneBookForm({ entry: entryToUpdate, onSubmit })

    const { handleSubmit: handleConfigSubmit, form: ConfigForm, submitButton: ConfigSubmitButton, setValue: setConfigValue } = usePhoneBookConfigForm({ phoneBookConfig: phoneBookConfig, onSubmit: onConfigSubmit, showEnableInternalEntepriseField: !inEnterpriseView })

    const rowActions = useMemo(
        () => [
            {
                key: 'edit',
                text: t('general.edit'),
                onClick: async (record: PhoneBookEntry) => {
                    reset()
                    setEntryToUpdate(record)
                    setValue('name', record.name)
                    setValue('number', record.number)
                    return false
                },
                dividerBeneath: true,
                needsWriteAccess: true
            },
            {
                key: 'delete',
                text: t('general.delete'),
                onClick: (record: PhoneBookEntry) => {
                    setEntryToDelete(record)
                },
                needsWriteAccess: true
            },
        ],
        [t, setValue, reset]
    )

    // @ts-ignore
    const columns: ColumnDefinition<PhoneBookEntry>[] = useMemo(
        () => [
            {
                title: t('phonebook.table.name'),
                key: 'name',
                sorter: true,
                render: (text: string) => <>{text}</>,
            },
            {
                title: t('phonebook.table.number'),
                key: 'number',
                sorter: true,
                render: (text: string) => <>{text}</>,
            },
            ...(!(inEnterpriseView && !isEnterpriseAdmin) ?  [{
                title: '',
                key: 'uuid',
                sorter: false,
                render: (text: string, record: PhoneBookEntry) => <TableRowActionsMenu record={record} menuItems={rowActions} readOnly={!hasWriteAccess}/>,
                }] : []
            ),
        ],
        [t, rowActions, inEnterpriseView, isEnterpriseAdmin, hasWriteAccess]
    )

    const onSearch = useCallback(async (searchTerm: string) => setSearchTerm(searchTerm), [setSearchTerm])

    const sorter = useCallback(col => sorterHeader(col, sort, setSort), [sort, setSort])

    const getRowKey = useCallback(entry => entry.uuid, [])

    const getDeleteUrl = useCallback(i => i ? createUrl(`phonebook/${i.uuid}`) : null, [createUrl])

    const oktaAuth = useOktaAuth();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const buttonPanel = useMemo(
        () => (
            <Grid container item justifyContent={'space-between'} flexDirection={'row'} spacing={2} flexWrap={'nowrap'}>
                {!(inEnterpriseView && !isEnterpriseAdmin) &&
                    onlyWithWriteAccess(<Grid item>
                        <AddButton
                            onClick={() => {
                                reset()
                                setEntryToUpdate({ name: '', number: '', uuid: '' })
                                return null
                            }}
                            label={`${t('phonebook.create-new')}`}
                        />
                    </Grid>)
                }
                <Grid item xs={'auto'} pr={1}>
                    <IconButton color="secondary" onClick={handleClick}>
                        <SettingsIcon/>
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {!(inEnterpriseView && !isEnterpriseAdmin) &&
                            <>
                                <MenuItem onClick={() => {
                                    setConfigValue("externalPhoneBookName", phoneBookConfig?.externalPhoneBookName || '')
                                    setConfigValue("internalPhoneBookName", phoneBookConfig?.internalPhoneBookName || '')
                                    setConfigValue("internalEnterprisePhoneBookEnabled", phoneBookConfig?.internalEnterprisePhoneBookEnabled || false)
                                    setShowConfigForm(true)}
                                } disabled={!hasWriteAccess}>
                                    <ListItemIcon>
                                        <MenuBookIcon/>
                                    </ListItemIcon>
                                    <ListItemText>{t("phonebook.configure")}</ListItemText>
                                </MenuItem>
                                <Divider/>
                            </>
                        }
                        {!(inEnterpriseView && !isEnterpriseAdmin) && <MenuItem disabled={!hasWriteAccess} onClick={() => setOpenImportCsvDialog(true)}>
                            <ListItemIcon>
                                <FileDownloadIcon/>
                            </ListItemIcon>
                            <ListItemText>{t("phonebook.import-csv-title")}</ListItemText>
                        </MenuItem>}
                        <MenuItem onClick={async () => {
                            const url = createUrl( 'phonebook/download')
                            fetch(url, {
                                method: 'GET',
                                headers: {
                                    ...(oktaAuth?.authState?.isAuthenticated? {"authorization" : `Bearer ${oktaAuth?.authState?.accessToken?.accessToken}`} : {})
                                },
                            })
                                .then(response => response.blob())
                                .then(blob => {
                                    // Create blob link to download
                                    const url = window.URL.createObjectURL(new Blob([blob]))
                                    const link = document.createElement('a')
                                    link.href = url
                                    link.setAttribute('download', 'pb_' + (site? site.productId : '') + '.csv')

                                    document.body.appendChild(link)
                                    link.click()
                                    link.parentNode?.removeChild(link)
                                })
                        }}>
                            <ListItemIcon>
                                <FileUploadIcon/>
                            </ListItemIcon>
                            <ListItemText>{t("phonebook.export-csv-title")}</ListItemText>
                        </MenuItem>
                        {!(inEnterpriseView && !isEnterpriseAdmin) &&
                            <>  <Divider/>
                                <MenuItem disabled={!hasWriteAccess} onClick={() => setOpenClearConfirmation(true)}>
                                    <ListItemIcon>
                                        <ClearIcon/>
                                    </ListItemIcon>
                                    <ListItemText>{t("phonebook.clear")}</ListItemText>
                                </MenuItem>
                            </>}
                    </Menu>
                </Grid>
            </Grid>
        ),
        [t, reset, site, createUrl,
            oktaAuth?.authState?.accessToken?.accessToken, oktaAuth?.authState?.isAuthenticated,
            inEnterpriseView, isEnterpriseAdmin, anchorEl, handleClose, phoneBookConfig?.externalPhoneBookName,
            phoneBookConfig?.internalPhoneBookName, phoneBookConfig?.internalEnterprisePhoneBookEnabled, setConfigValue, hasWriteAccess, onlyWithWriteAccess
        ]
    )

    const onCsvFileUploaded = useCallback(file => refetch(), [refetch])

    const entryForm = useMemo(
        () => (
            <form className={'ant-form ant-form-vertical'} onSubmit={handleSubmit} noValidate={true}>
                <Dialog disablePortal open={!!entryToUpdate} onClose={() => setEntryToUpdate(null)}>
                    <DialogTitle>{t(entryToUpdate?.uuid ? 'phonebook.update-dialog-title' : 'phonebook.create-new')}</DialogTitle>
                    <DialogContent>
                        <Form />
                    </DialogContent>
                    <DialogActions>
                        <SubmitButton />
                        <CancelButton onClick={() => setEntryToUpdate(null)} />
                    </DialogActions>
                </Dialog>
            </form>
        ),
        [Form, SubmitButton, entryToUpdate, handleSubmit, t]
    )

    const configForm = useMemo(
        () => (
            <form className={'ant-form ant-form-vertical'} onSubmit={handleConfigSubmit} noValidate={true}>
                <Dialog disablePortal open={showConfigForm} onClose={() => setShowConfigForm(false)}>
                    <DialogTitle>{t('phonebook.config.title')}</DialogTitle>
                    <DialogContent>
                        <ConfigForm />
                    </DialogContent>
                    <DialogActions>
                        <ConfigSubmitButton />
                        <CancelButton onClick={() => setShowConfigForm(false)} />
                    </DialogActions>
                </Dialog>
            </form>
        ),
        [ConfigForm, ConfigSubmitButton, handleConfigSubmit, t, showConfigForm]
    )

    const clearPhonebook = useCallback(async () => {
        await api.put(createUrl('phonebook/clear'))
        setOpenClearConfirmation(false)
        await refetch()
    }, [refetch, createUrl])

    const importCSVForm = useMemo(
        () => (
            <Dialog open={openImportCsvDialog} onClose={() => setOpenImportCsvDialog(false)}>
                <DialogTitle>
                    {t('phonebook.import-csv-title')}
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenImportCsvDialog(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500],
                        }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {
                        <Grid container>
                            <Grid item>
                                <FormControlLabel
                                    sx={{ padding: 2 }}
                                    control={
                                        <Checkbox
                                            disabled={false}
                                            checked={clearExistingValuesOnUpload}
                                            onChange={event => {
                                                setClearExistingValuesOnUpload(event.target.checked)
                                            }}
                                        />
                                    }
                                    key={'clearExisting'}
                                    label={t('phonebook.clear-existing-entries')}
                                />
                            </Grid>
                            <Grid item>
                                <FileDropZone
                                    uploadUrl={createUrl(`phonebook/csv?clearExisting=${clearExistingValuesOnUpload}`)}
                                    onFileUploaded={onCsvFileUploaded}
                                    acceptMime=".csv"
                                    maxSizeInBytes={MAX_CSV_FILE_SIZE}
                                    dropzoneTitle={t('phonebook.import-csv-dropzone-title')}
                                    showSuccess={true}
                                />
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <CancelButton title={t('general.close')} onClick={() => setOpenImportCsvDialog(false)} />
                </DialogActions>
            </Dialog>
        ),
        [t, onCsvFileUploaded, openImportCsvDialog, clearExistingValuesOnUpload, createUrl]
    )

    return (
        <>
            <TitledBody title={t('phonebook.title')} buttonsPanel={buttonPanel}>
                <SearchField onSearch={onSearch} />
                <ErrorAlert showAlert={!!error} />
                <InfiniteScrollTable<PhoneBookEntry>
                    hasNextPage={hasNextPage}
                    data={rows}
                    fetchNextPage={fetchNextPage}
                    isFetching={isFetching}
                    getRowKey={getRowKey}
                    sorter={sorter}
                    columns={columns}
                    tableId={'phonebookTable'}
                />
                <DeletionModal<PhoneBookEntry | null> itemToDelete={entryToDelete} deleteUrl={getDeleteUrl} onClose={() => setEntryToDelete(null)} onSuccess={refetch} />

                <DeletionModal<string | null>
                    title={t('phonebook.clear')}
                    text={() => t('phonebook.clear-confirmation')}
                    itemToDelete={openClearConfirmation ? 'dummy' : null}
                    deleteAction={clearPhonebook}
                    onClose={() => setOpenClearConfirmation(false)}
                    onSuccess={refetch}
                />
                {entryForm}
                {importCSVForm}
                {configForm}
            </TitledBody>
        </>
    )
}
