import React from 'react'
import {TitledBody} from '../common/components/TitledBody'
import {useTranslation} from 'react-i18next'
import {Container, Grid, Paper} from '@mui/material'
import {LicenceStats} from './LicenceStats'
import {CpeStats} from './CpeStats'
import {addUrl as addIvrUrl} from '../ivrs'
import {useHistory} from 'react-router'
import {useFlowStats} from './FlowStats'
import {useIvrStats} from './IvrStats'
import {MainNumber} from './MainNumber'
import {UserOptions} from "./UserOptions";
import {addUrlHuntGroup, addUrlQueue} from "../flows";

export const Group = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const { stats: ivrStats } = useIvrStats({
        onClick: () => {
            history.push(addIvrUrl)
            return null
        },
    })

    const { stats: huntGroupStats } = useFlowStats({
        onClick: () => {
            history.push(addUrlHuntGroup)
        },
        type: "HUNT_GROUP"
    })

    const { stats: queueStats } = useFlowStats({
        onClick: () => {
            history.push(addUrlQueue)
        },
        type: "QUEUE"
    })

    return (
        <TitledBody title={t('site.title')}>
            <Container sx={{ mt: 2, mb: 2 }}>
                <Grid container spacing={4}  sx={{ mb: 4 }}>
                    <Grid item xs={12} >
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <MainNumber />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={4} md={6}>
                        <LicenceStats />
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                        <UserOptions />
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                        <CpeStats />
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                        {huntGroupStats}
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                        {queueStats}
                    </Grid>
                    <Grid item xs={12} lg={4} md={6}>
                        {ivrStats}
                    </Grid>
                </Grid>
            </Container>
        </TitledBody>
    )
}
