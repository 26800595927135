import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import {AnswerGroup, AnswerGroupAgent} from '../app/types'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import {RouteDefinition} from '../app/App'
import {AddButton} from '../common/components/AddButton'
import {SearchField} from '../common/components/SearchField'
import {AddQueue} from './addQueue'
import {AddDialog} from './AddDialog'
import {AddHuntGroup} from './addHuntGroup'
import {useHistory} from 'react-router'
import {ColumnDefinition} from '../common/components/table/Table'
import {Link} from '@mui/material'
import {formatPhoneNumber} from '../common/util/formatters'
import TableRowActionsMenu from '../common/components/table/TableRowActionsMenu'
import DeletionModal from '../common/components/DeletionModal'
import {DetailAnswerGroup} from './detail'
import {useFlowTable} from './useFlowTable'
import PinCodeModal from '../users/PinCodeModal'
import {api} from '../common/api'
import {useAccessHelpers} from "../common/util/hooks";

export const detailUrl = '/flows/:id'
export const addUrlQueue = '/flows/new-queue'
export const addUrlHuntGroup = '/flows/new-huntgroup'

export const routes: RouteDefinition[] = [
    {
        href: addUrlQueue,
        component: AddQueue,
        id: 'flow-add-queue',
        parentId: 'flow-overview',
        translationKey: 'flows.add-queue.title',
    },
    {
        href: addUrlHuntGroup,
        component: AddHuntGroup,
        id: 'flow-add-huntgroup',
        parentId: 'flow-overview',
        translationKey: 'flows.add-huntgroup.title',
    },
    {
        href: detailUrl,
        component: DetailAnswerGroup,
        id: 'flow-detail',
        parentId: 'flow-overview',
    },
].map(routeDefinition => ({ ...routeDefinition, menuId: 'flow-overview' }))

export const Flows = () => {
    const history = useHistory()
    const site = useSelector(selectedSiteSelector)
    const { t } = useTranslation()
    const [showAddDialog, setShowAddDialog] = useState<boolean>(false)
    const [answerGroupToDelete, setAnswerGroupToDelete] = useState<AnswerGroup | null>(null)
    const [answerGroupToUpdatePinCode, setAnswerGroupToUpdatePinCode] = useState<AnswerGroup | null>(null)
    const {hasWriteAccess, onlyWithWriteAccess} = useAccessHelpers()

    const rowActions = useMemo(
        () => [
            {
                key: 'edit',
                text: t('general.edit'),
                onClick: (record: AnswerGroup) => {
                    history.push(detailUrl.replace(':id', record.uuid) + '?edit=true#0')
                },
                needsWriteAccess: true
            },
            {
                key: 'pincode',
                text: t('users.update-voicemail-pin-code'),
                onClick: (record: AnswerGroup) => {
                    setAnswerGroupToUpdatePinCode(record)
                },
                dividerBeneath: true,
                needsWriteAccess: true
            },
            {
                key: 'delete',
                text: t('general.delete'),
                onClick: (record: AnswerGroup) => {
                    setAnswerGroupToDelete(record)
                },
                needsWriteAccess: true
            },
        ],
        [t, history]
    )

    const columns: ColumnDefinition<AnswerGroup>[] = useMemo(
        () => [
            {
                title: t('flows.table.name'),
                key: 'name',
                sorter: true,
                render: (text: string, record: AnswerGroup) => <Link href={detailUrl.replace(':id', record.uuid)}>{record.name}</Link>,
            },
            {
                title: t('users.table.extension'),
                key: 'extension',
                sorter: true,
                render: (text: string) => <>{text}</>,
            },
            {
                title: t('flows.table.number'),
                key: 'number',
                sorter: true,
                defaultSortOrder: 'ascend' as 'ascend',
                render: (text: string) => <>{formatPhoneNumber(text)}</>,
            },
            {
                title: t('flows.table.type'),
                key: 'answerGroupType',
                sorter: false,
                /*render: (text: string) => <>{text === 'QUEUE' ? <RadioButtonChecked fontSize='small' color='secondary' /> :
          <RadioButtonUncheckedIcon fontSize='small' color='secondary' />}</>,*/
                render: (text: string) => <>{t(`flows.type.${text}`)}</>,
            },
            /*{
        title: t('flows.table.voicemail'),
        key: 'overflowDestination',
        sorter: false,
        render: (text: string) => <>{text === 'VOICEMAIL' ? <RadioButtonChecked fontSize='small' color='secondary' /> :
          <RadioButtonUncheckedIcon fontSize='small' color='secondary' />}</>,
      },*/
            {
                title: t('flows.table.number-of-agents'),
                key: 'userAgents',
                sorter: false,
                render: (agents: AnswerGroupAgent[]) => <>{agents ? agents.length : 0}</>,
            },
            {
                title: t('flows.table.overflow'),
                key: 'overflowDestination',
                sorter: false,
                render: (text: string) => <>{t(`flows.edit.overflow-destination-names.${text}`)}</>,
            },
            {
                title: '',
                key: 'uuid',
                sorter: false,
                render: (text: string, record: AnswerGroup) => <TableRowActionsMenu record={record} menuItems={rowActions} readOnly={!hasWriteAccess} />,
            },
        ],
        [t, rowActions, hasWriteAccess]
    )

    const getDeleteUrl = useCallback(a => (site && a ? `/api/site/${site.productId}/answergroup/${a.uuid}` : null), [site])

    const addButton = useMemo(
        () => (
            onlyWithWriteAccess(<AddButton
                onClick={() => {
                    setShowAddDialog(true)
                }}
                label={`${t('flows.create-new')}`}
            />)
        ),
        [t, setShowAddDialog, onlyWithWriteAccess]
    )

    const { table, onSearch, refetch } = useFlowTable({
        columns: columns,
        queryKey: `answerGroups-${site?.productId}`,
        initialSortField: 'name',
    })

    const onUpdatePinCode = useCallback(
        async (newPinCode: string) => {
            if (!site || !answerGroupToUpdatePinCode) return
            await api.put(`/api/site/${site.productId}/answergroup/${answerGroupToUpdatePinCode.uuid}/update-voicemail-pincode`, { pinCode: newPinCode })
            setAnswerGroupToUpdatePinCode(null)
        },
        [answerGroupToUpdatePinCode, site]
    )

    return (
        <>
            <TitledBody title={t('flows.title')} buttonsPanel={addButton}>
                <SearchField onSearch={onSearch} />

                {showAddDialog && <AddDialog handleClose={() => setShowAddDialog(false)} />}
                {table}
                <DeletionModal<AnswerGroup | null> itemToDelete={answerGroupToDelete} deleteUrl={getDeleteUrl} onClose={() => setAnswerGroupToDelete(null)} onSuccess={refetch} />

                <PinCodeModal
                    title={t('users.update-voicemail-pin-code')}
                    text={''}
                    onSubmit={onUpdatePinCode}
                    onClose={() => setAnswerGroupToUpdatePinCode(null)}
                    buttonLabel={t('general.form-update')}
                    open={!!answerGroupToUpdatePinCode}
                />
            </TitledBody>
        </>
    )
}
