import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import React, {useCallback, useMemo} from "react";
import {FAC, ServerPage} from "../../app/types";
import {Sort, useInfiniteScroll} from "../../common/components/table/useInfiniteScroll";
import {api} from "../../common/api";
import {ColumnDefinition, InfiniteScrollTable} from "../../common/components/table/Table";
import {sorterHeader} from "../../common/components/table/SorterHeader";
import ErrorAlert from "../../common/components/ErrorAlert";
import {TitledBody} from "../../common/components/TitledBody";
import {Grid} from "@mui/material";

export const FACList = () => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)

    const fetch = useCallback(
        async (pageParam = 0, sortParam: Sort, searchTermParam: string | null): Promise<ServerPage<FAC>> => {
            if (!site) return { content: [], totalPages: 0, number: 0, totalElements: 0 }
            const { data } = await api.get(`/api/site/${site.productId}/fac`)
            return data
        },
        [site]
    )

    const { sort, setSort, rows, error, fetchNextPage, hasNextPage, isFetching } = useInfiniteScroll<FAC>({
        queryKey: `facs-${site?.productId}`,
        initialSort: { field: 'name', order: 'asc' },
        fetchFunction: fetch,
    })


    const columns: ColumnDefinition<FAC>[] = useMemo(
        () => [
            {
                title: t('facs.table.name'),
                key: 'name',
                sorter: false,
                render: (text: string) => <>{text}</>,
            },
            {
                title: t('facs.table.code'),
                key: 'mainCode',
                sorter: false,
                render: (text: string) => <>{text}</>,
            }
        ],
        [t]
    )

    const getRowKey = useCallback(fac => fac.name, [])
    const sorter = useCallback(col => sorterHeader(col, sort, setSort), [sort, setSort])
    return (
        <TitledBody title={t('facs.title')} >
            <ErrorAlert showAlert={!!error} />
            <Grid item lg={6}>
                <InfiniteScrollTable<FAC>
                    hasNextPage={hasNextPage}
                    data={rows}
                    fetchNextPage={fetchNextPage}
                    isFetching={isFetching}
                    getRowKey={getRowKey}
                    columns={columns}
                    tableId={'facsTable'}
                    sorter={sorter}
                />
            </Grid>
        </TitledBody>
    )
}