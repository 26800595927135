import React, {ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import FormGroup from '../../common/form/FormGroup'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import {useForm} from 'react-hook-form'
import FormCheckbox from "../../common/form/FormCheckbox";
import {EmergencyCallNotification} from "../../app/types";
import {yupResolver} from "@hookform/resolvers/yup";
import {useFormSubmission} from "../../common/servercall/useFormSubmission";
import ErrorAlert from "../../common/components/ErrorAlert";
import FormContainer from "../../common/form/FormContainer";
import * as yup from "yup";
import {emailRegex} from "../../users/UserForm";
import TextInput from "../../common/form/TextInput";

type Props = {
    emergencyCallNotification: EmergencyCallNotification
    onSubmit: (formData: EmergencyCallNotification) => void
    readonly?: boolean
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

const schema = () =>
    yup
        .object({
            notificationEmail: yup
                .string()
                .when(['notificationEnabled'], {
                    is: (notificationEnabled: boolean) => notificationEnabled,
                    then: schema => yup.string().trim().max(70, 'too-long').required('required-m').matches(
                        emailRegex,
                        'invalid-email'
                    ).nullable(),
                    otherwise: schema => yup.string().trim().max(70, 'too-long').matches(
                        emailRegex,
                        'invalid-email'
                    ).nullable(),
                }),
        })
        .required()

export const EmergencyCallNotificationForm = ({ emergencyCallNotification, onSubmit, buildFormButtons}: Props) => {
    const { t } = useTranslation()

    const { control, handleSubmit, setError, setValue, getFieldState } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(schema()),
        defaultValues: emergencyCallNotification
    })

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<EmergencyCallNotification>(onSubmit, setError)

    return (
        <>
            <ErrorAlert showAlert={!!serverError} />
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={''} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('general.enabled')}
                                name={'notificationEnabled'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                        <FormFieldBox>
                            <TextInput
                                readonly={false}
                                label={t('users.edit.email')}
                                name={'notificationEmail'}
                                control={control}
                                required={true}
                            />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                {buildFormButtons(isSubmitting)}
            </FormContainer>
        </>
    )
}
