import React, {CSSProperties} from 'react'
import {Dialog, DialogContent, DialogContentText, DialogTitle} from '@mui/material'
import {useTranslation} from "react-i18next";
import {useCSVReader} from "react-papaparse";
import {ParseResult} from "papaparse";

export default ImportFromCsvModal

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const GREY_DIM = '#686868';

const styles = {
    zone: {
        alignItems: 'center',
        border: `2px dashed ${GREY}`,
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        padding: 20,
    } as CSSProperties,
    file: {
        background: 'linear-gradient(to bottom, #EEE, #DDD)',
        borderRadius: 20,
        display: 'flex',
        height: 120,
        width: 120,
        position: 'relative',
        zIndex: 10,
        flexDirection: 'column',
        justifyContent: 'center',
    } as CSSProperties,
    info: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
        paddingRight: 10,
    } as CSSProperties,
    size: {
        backgroundColor: GREY_LIGHT,
        borderRadius: 3,
        marginBottom: '0.5em',
        justifyContent: 'center',
        display: 'flex',
    } as CSSProperties,
    name: {
        backgroundColor: GREY_LIGHT,
        borderRadius: 3,
        fontSize: 12,
        marginBottom: '0.5em',
    } as CSSProperties,
    progressBar: {
        bottom: 14,
        position: 'absolute',
        width: '100%',
        paddingLeft: 10,
        paddingRight: 10,
    } as CSSProperties,
    zoneHover: {
        borderColor: GREY_DIM,
    } as CSSProperties,
    default: {
        borderColor: GREY,
    } as CSSProperties,
    remove: {
        height: 23,
        position: 'absolute',
        right: 6,
        top: 6,
        width: 23,
    } as CSSProperties,
};


function ImportFromCsvModal({
                                onUploadAccepted,
                                open,
                                onClose
                            }: {
    onUploadAccepted: (results: any) => void
    open: boolean
    onClose: () => void
}) {

    const {t} = useTranslation()
    const { CSVReader } = useCSVReader();

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{t('schedules.import-from-csv')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <CSVReader
                        config={{header:true, encoding: 'UTF-8F', skipEmptyLines: true}}
                        onUploadAccepted={(results: ParseResult<any>) => {
                            if (results.errors == null || results.errors.length === 0) {
                                try {
                                    onUploadAccepted(results.data)
                                } catch(e) {
                                    console.error(e)
                                }
                                onClose()
                            } else {
                                console.error(results.errors)
                                onClose()
                            }
                        }}
                        onDragOver={(event: DragEvent) => {}}
                        onDragLeave={(event: DragEvent) => {}}
                    >
                        {({
                              getRootProps,
                          }: any) => (
                            <>
                                <div
                                    {...getRootProps()}
                                    style={Object.assign(
                                        {},
                                        styles.zone,
                                    )}
                                >
                                    {t('schedules.drop-csv')}
                                </div>
                            </>
                        )}
                    </CSVReader>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}
