import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import CancelButton from '../common/form/CancelButton'
import SpinnableSubmitButton from '../common/form/SpinnableSubmitButton'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { addUrlHuntGroup, addUrlQueue } from './index'

type Props = {
    handleClose: () => void
}

export const AddDialog = ({ handleClose }: Props) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [type, setType] = useState<string>('huntgroup')

    const onSubmit = useCallback(async () => {
        history.push(type === 'queue' ? addUrlQueue : addUrlHuntGroup)
        handleClose()
    }, [handleClose, history, type])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setType((event.target as HTMLInputElement).value)
    }

    return (
        <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
            <form onSubmit={onSubmit}>
                <DialogTitle>{t('flows.add-dialog.title')}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText sx={{ mb: 1 }}>{t('flows.add-dialog.text')}</DialogContentText>
                    <FormControl component='fieldset'>
                        <RadioGroup aria-label='gender' name='controlled-radio-buttons-group' value={type}
                                    onChange={handleChange}>
                            <FormControlLabel value='huntgroup' control={<Radio />}
                                              label={<>{t('flows.add-dialog.huntgroup-option')}</>} />
                            <FormControlLabel value='queue' control={<Radio />}
                                              label={<>{t('flows.add-dialog.queue-option')}</>} />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <SpinnableSubmitButton label={t('flows.add-dialog.submit-button')} showSpinner={false} />
                    <CancelButton onClick={handleClose} />
                </DialogActions>
            </form>
        </Dialog>
    )
}
