import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from '@mui/material'
import {useTranslation} from 'react-i18next'
import CancelButton from '../common/form/CancelButton'
import SpinnableSubmitButton from '../common/form/SpinnableSubmitButton'
import React, {useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import ErrorAlert from '../common/components/ErrorAlert'
import {api} from '../common/api'
import {useTableWithAdjustableOrder} from "../common/components/table/useTableWithAdjustableOrder";
import {BasicUser} from "../app/types";
import {formatUser} from "../common/util/formatters";
import {getAutocompleteUsers} from "../common/util/helpers";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useFormSubmission} from "../common/servercall/useFormSubmission";
import * as yup from "yup";

type Props = {
    userId: string | null
    handleClose: () => void
    onSuccess: () => void
}

type UsersForm = {
    users: BasicUser[]
}

const schema = () =>
    yup
        .object({
            users: yup.array().nullable(true),
        })
        .required()

export const CloneCpeConfigToUserDialog = ({ userId, handleClose, onSuccess }: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)

    const { control, handleSubmit, setError } = useForm<UsersForm>({
        mode: 'onSubmit',
        resolver: yupResolver(schema()),
        defaultValues: {users: []}
    })

    const onSubmit = useCallback(
        async (usersForm: UsersForm) => {
            if (!site || !userId) return
            await api.put(`/api/site/${site.productId}/user/clone-settings`, { from: userId, to: usersForm.users.map(it => it.uuid) })
            onSuccess()
            handleClose()
        },
        [site, userId, handleClose, onSuccess]
    )

    const { submit, isSubmitting, serverError } = useFormSubmission<UsersForm>(onSubmit, setError)

    const userOptionParams = useMemo(() => {
        return getAutocompleteUsers(
            t('flows.edit.no-users-found'),
            [userId || ''],
            false,
            site
        )
    }, [userId, site, t])

    const { table: UsersTable, selector: UsersSelector } = useTableWithAdjustableOrder<BasicUser>('users', userOptionParams, control, formatUser, user => user.uuid, t('users.edit.user-select-button'),true, t('users.table.name'),false)

    return (
            <Dialog open={!!userId} fullWidth={true} >
                <form onSubmit={handleSubmit(submit)} noValidate>
                    <DialogTitle>{t('users.cpe-clone-config-to-dialog.title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{t('users.cpe-clone-config-to-dialog.text')}</DialogContentText>
                        <ErrorAlert showAlert={!!serverError}/>

                        <Grid container spacing={2} mt={2}>
                            <Grid item xs={12}>
                                {UsersSelector}
                            </Grid>
                            <Grid item xs={12}>
                                <UsersTable/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <SpinnableSubmitButton label={t('users.cpe-clone-config-to-dialog.clone-button')}
                                               disabled={false} showSpinner={isSubmitting}/>
                        <CancelButton onClick={handleClose}/>
                    </DialogActions>
                </form>
            </Dialog>
    )
}
