import React from 'react'
import {useTranslation} from 'react-i18next'
import {Control, useFieldArray} from 'react-hook-form'
import {Grid, IconButton, Typography} from '@mui/material'
import TextInput, {MaskProps} from '../../common/form/TextInput'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

type Props = {
    day: string
    control: Control<any>
}

const timeMaskProps: MaskProps = {
    mask: '#0:00',
    definitions: {
        '#': /[0-9]/,
    },
}

export const DayForm = ({ day, control }: Props) => {
    const { t } = useTranslation()

    const { fields,remove,insert,replace } = useFieldArray({
        control,
        name: `days.${day}.entries`,
    })

    return (
        <>
            {fields.map((item, index) => (
                <Grid container item spacing={2} key={item.id}>
                    <OpeningHourBox width={2} align={'center'}>
                        <Typography component='h6' variant='subtitle1' color='secondary'>
                            {index === 0 ? t(`general.days.${day}`) : ''}
                        </Typography>
                    </OpeningHourBox>
                    <OpeningHourBox>
                        <TextInput label={t('schedules.opening-hours.startTime')}
                                   name={`days.${day}.entries.${index}.startTime`} control={control}
                                   maskProps={timeMaskProps} />
                    </OpeningHourBox>
                    <OpeningHourBox>
                        <TextInput label={t('schedules.opening-hours.endTime')}
                                   name={`days.${day}.entries.${index}.endTime`} control={control}
                                   maskProps={timeMaskProps} />
                    </OpeningHourBox>
                    <OpeningHourBox width={4}>
                        <IconButton color={'secondary'} component='span'
                                    onClick={() => insert(index + 1,[{ startTime: '', endTime: '' }])}>
                            <AddIcon />
                        </IconButton>
                        <IconButton aria-label='delete' component='span' onClick={() => {fields.length === 1 ? replace([{ startTime: '', endTime: '' }]) : remove(index)}}>
                            <DeleteIcon fontSize='inherit' />
                        </IconButton>
                    </OpeningHourBox>
                </Grid>
            ))}
        </>
    )
}

function OpeningHourBox({ children, align, width }: { children: any; align?: string; width?: number }) {
    return (
        <Grid container={!!align} item xs={width || 3} sm={width || 3} md={width || 3} lg={width || 3}
              alignItems={align}>
            {children}
        </Grid>
    )
}
