import React, {ReactNode, useCallback, useEffect, useState} from 'react'

import {Box, Grid, IconButton, Stack, Typography} from '@mui/material'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../../authentication/redux'
import {api} from "../../../common/api";
import {ExecutiveAssistantSetting} from "../../../app/types";
import {AssistantDivertForm} from "./AssistantDivertForm";
import {ModeEdit} from "@mui/icons-material";
import {Spinner} from "../../../common/components/Spinner";

type Props = {
    userBusinessId: string
    initialAssistant: ExecutiveAssistantSetting | undefined
    executiveId: string
    readonly: boolean
}

export const AssistantDivert = ({ executiveId, userBusinessId, initialAssistant, readonly }: Props) => {
    const site = useSelector(selectedSiteSelector)
    const [editingMode, setEditingMode] = useState<boolean>(false)
    const [assistant, setAssistant] = useState<ExecutiveAssistantSetting | undefined>(initialAssistant)
    const [fetching, setFetching] = useState<boolean>(false)

    useEffect(() => setAssistant(initialAssistant), [initialAssistant])

    const refetch = useCallback(
        async () => {
            if (!site) return ''
            setFetching(true)
            try {
                const { data } = await api.get(`/api/site/${site.productId}/executive/${executiveId}/assistants?assistantIds=${userBusinessId}`)
                setAssistant(data[0])
                setFetching(false)
            } catch (e) {
                setFetching(false)
            }
        },
        [site, executiveId, userBusinessId],
    )

    const nonEditing = useCallback((): ReactNode => {
            return (
                <Stack direction={'row'}>
                    <Box alignSelf={'center'} sx={{ paddingRight: 1 }}>
                        {fetching? <Spinner size={16} /> : <Typography variant={'body1'} component={'span'}>
                            {assistant?.enableDivert? assistant.divertNumber : ''}
                        </Typography>
                        }
                    </Box>
                    <Box alignSelf={'center'}>
                        <IconButton size={'small'} color='secondary' aria-label='Change divert'
                                    component='span'
                                    disabled={readonly}
                                    onClick={() => {
                                        setEditingMode(true)
                                    }}>
                            <ModeEdit />
                        </IconButton>
                    </Box>
                </Stack>
            )
        },
        [setEditingMode, assistant, fetching, readonly],
    )

    const renderDivert = useCallback(
        (): ReactNode => {
            return (
                <Grid container direction='row'
                      justifyContent='start'
                      alignItems='center' spacing={0}>
                    < Grid item xs>
                        {editingMode ? <AssistantDivertForm executiveId={executiveId} userBusinessId={userBusinessId} onSuccess={async () => {
                                setEditingMode(false)
                                await refetch()
                            }} onCancel={() => {
                                setEditingMode(false)
                            }} form={{ divertNumber: assistant?.divertNumber || '', enableDivert: assistant?.enableDivert || false }} />
                            : nonEditing()}
                    </Grid>
                </Grid>
            )
        },
        [editingMode, setEditingMode, nonEditing, refetch, executiveId, userBusinessId, assistant],
    )

    return <>
        <>{renderDivert()}</>
    </>
}
