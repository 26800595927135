import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import {useFetchData} from '../common/servercall/useFetchData'
import {IvrForm} from '../app/types'
import {useParams} from 'react-router'
import {api} from '../common/api'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import {Page} from '../app/navigation/redux'
import {Grid} from '@mui/material'
import {createGraphElements} from './graph/IvrGraphHelpers'
import {Elements} from 'react-flow-renderer'
import {useGeneralSection} from "./sections/general";
import {SectionedForm} from "../common/components/SectionedForm";
import {useMenuSection} from "./sections/menu";
import {useAccessHelpers} from "../common/util/hooks";

export const DetailIvr = () => {
    const { t } = useTranslation()
    const { id }: { id: string } = useParams()
    const site = useSelector(selectedSiteSelector)
    const [graphElements, setGraphElements] = useState<Elements>([])
    const {hasWriteAccess} = useAccessHelpers()

    const fetchIvr = useCallback(
        async (ivrId: string): Promise<IvrForm | undefined> => {
            if (!site) return
            const { data } = await api.get<IvrForm>(`/api/site/${site.productId}/ivr/${ivrId}`)
            return data
        },
        [site]
    )

    const { data, isFetching, error, refetch } = useFetchData<IvrForm>(useCallback(() => fetchIvr(id), [fetchIvr, id]))

    useEffect(() => {
        setGraphElements(createGraphElements(true, data?.menuConfig?.subMenus, {}))
    }, [data])

    const pageInfo: Page | null = useMemo(
        () =>
            data
                ? {
                      label: `${data.name}`,
                      key: 'ivr-detail',
                  }
                : null,
        [data]
    )

    const generalSection = useGeneralSection(id, isFetching, error, refetch, data);
    const menuSection = useMenuSection(id, isFetching, error, refetch, graphElements, data)
    const formSections = useMemo(() => [
        generalSection, menuSection
    ], [generalSection, menuSection])

    return (
        <TitledBody title={t('ivrs.detail.title')} page={pageInfo}>
            <Grid container>
                <Grid item xs={12} lg={10}>
                    <SectionedForm formSections={formSections} readOnly={!hasWriteAccess} />
                </Grid>
            </Grid>
        </TitledBody>
    )
}
