import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from '@mui/material'
import {useTranslation} from 'react-i18next'
import CancelButton from '../common/form/CancelButton'
import React, {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import {api} from '../common/api'
import {Spinner} from '../common/components/Spinner'
import ErrorAlert from '../common/components/ErrorAlert'
import {useOktaAuth} from "@okta/okta-react";
import {useAccessHelpers} from "../common/util/hooks";

type Props = {
    handleClose: () => void
}


type ActivationCode = {
    code: string | null
    state: string | null
    expiryTime: string | null
    cpeUuid: string
}

export const MppDialog = ({ handleClose }: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const oktaAuth = useOktaAuth();

    const [emptyActivationCodes, setEmptyActivationCodes] = useState<ActivationCode[]>([])
    const [fetching, setFetching] = useState<boolean>(false)
    const [fetchError, setFetchError] = useState<boolean>(false)
    const {onlyWithWriteAccess} = useAccessHelpers()

    const fetchEmptyActivationCodes = useCallback(async () => {
        setFetchError(false)
        if (!site) return

        setFetching(true)
        try {
            const { data } = await api.get<ActivationCode[]>(`/api/site/${site.productId}/cpe/empty-activation-codes`)

            setEmptyActivationCodes(data)
        } catch (e) {
            setFetchError(true)
        }

        setFetching(false)
    }, [site])


    const generateActivationCodes = useCallback(async () => {
        if (!site) return
        setFetching(true)

        try {
            await api.put(`/api/site/${site.productId}/cpe/activation-code/generate`,
                {cpeUuids : emptyActivationCodes.map(code => code.cpeUuid)}).catch(console.error)
        } catch (e) {
            setFetchError(true)
            setFetching(false)
            return
        }

        await fetchEmptyActivationCodes()
    }, [site, emptyActivationCodes, fetchEmptyActivationCodes])

    const downloadActivationCodes = useCallback(async () => {
        if (!site) return

        try {
            const url = `/api/site/${site.productId}/cpe/activation-codes/download`
            fetch(url, {
                method: 'GET',
                headers: {
                    ...(oktaAuth?.authState?.isAuthenticated? {"authorization" : `Bearer ${oktaAuth?.authState?.accessToken?.accessToken}`} : {})
                },
            })
                .then(response => response.blob())
                .then(blob => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([blob]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'mpp_' + site.productId + '.xlsx')

                    document.body.appendChild(link)
                    link.click()
                    link.parentNode?.removeChild(link)
                })
        } catch (e) {
            return
        }
    }, [site, oktaAuth?.authState?.accessToken?.accessToken, oktaAuth?.authState?.isAuthenticated])

    useEffect(() => {
        fetchEmptyActivationCodes()
        setFetchError(false)
    }, [fetchEmptyActivationCodes])

    return (
        <Dialog open={true} onClose={handleClose} fullWidth={true}>
            <DialogTitle>
                {t('cpes.mpp.configure')}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'space-between'}>
                    {fetching && (
                        <Grid item xs={12} alignSelf={'center'}>
                            <Spinner size={36} />
                        </Grid>
                    )}
                    {!fetching && fetchError && (
                        <Grid item xs={12} alignSelf={'center'} width={'100%'}>
                            <ErrorAlert showAlert={true} />
                        </Grid>
                    )}
                    {!fetching && !!emptyActivationCodes.length && (
                        <>
                            <Grid item>
                                <DialogContentText>{t('cpes.mpp.empty-activation-codes', {"empty" : emptyActivationCodes.length})}</DialogContentText>
                            </Grid>
                            {onlyWithWriteAccess(<Grid item mt={1}>
                                <Button size={'small'} onClick={generateActivationCodes} variant="contained" title={t('cpes.mmp.generate-activation-codes')}>
                                    {t('cpes.mpp.generate-activation-codes')}
                                </Button>
                            </Grid>)}
                        </>
                    )}
                    {!fetching && !fetchError && !emptyActivationCodes.length &&
                        <Grid item>
                            <DialogContentText>{t('cpes.mpp.no-empty-activation-codes')}</DialogContentText>
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent={'space-between'}>
                    <Button onClick={downloadActivationCodes} color="secondary">
                        {t('cpes.mpp.download-activation-codes')}
                    </Button>
                    <CancelButton title={t('general.close')} onClick={handleClose} />
                </Grid>

            </DialogActions>
        </Dialog>
    )
}
