import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../../common/components/TitledBody'
import {Schedule, ServerPage} from '../../app/types'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import {api, buildPaginatedUrl} from '../../common/api'
import ErrorAlert from '../../common/components/ErrorAlert'
import {RouteDefinition} from '../../app/App'
import {SearchField} from '../../common/components/SearchField'
import {Sort, useInfiniteScroll} from '../../common/components/table/useInfiniteScroll'
import {useHistory} from 'react-router'
import {ColumnDefinition, InfiniteScrollTable} from '../../common/components/table/Table'
import {Link} from '@mui/material'
import TableRowActionsMenu from '../../common/components/table/TableRowActionsMenu'
import {sorterHeader} from '../../common/components/table/SorterHeader'
import DeletionModal from '../../common/components/DeletionModal'
import {DetailSchedule} from '../detail'
import {AddHolidays} from './addHolidays'
import {AddButton} from "../../common/components/AddButton";
import {useAccessHelpers} from "../../common/util/hooks";

export const detailUrlHoliday = '/holidays/:id'
export const addUrlHoliday = '/holidays/new'


export const addUrl = '/:type/new'
export const detailUrl = '/:type/:id'

export const routes: RouteDefinition[] = [
    {
        href: addUrlHoliday,
        component: AddHolidays,
        id: 'schedule-add-holiday',
        parentId: 'holiday-overview',
        translationKey: 'schedules.holidays.add.title',
    },
    {
        href: detailUrlHoliday,
        component: DetailSchedule,
        id: 'schedule-detail-holiday',
        parentId: 'holiday-overview',
    },
].map(routeDefinition => ({ ...routeDefinition, menuId: 'holiday-overview' }))

export const Holidays = () => {
    return (<ScheduleList type={'holidays'} />)
}

export const ScheduleList = ({ type }: { type: string }) => {
    const history = useHistory()
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const [scheduleToDelete, setScheduleToDelete] = useState<Schedule | null>(null)
    const {hasWriteAccess, onlyWithWriteAccess} = useAccessHelpers()

    const fetch = useCallback(
        async (pageParam = 0, sortParam: Sort, searchTermParam: string | null): Promise<ServerPage<Schedule>> => {
            if (!site) return { content: [], totalPages: 0, number: 0, totalElements: 0 }
            const { data } = await api.get(buildPaginatedUrl(`/api/site/${site.productId}/${type}`, pageParam, sortParam, searchTermParam))
            return data
        },
        [site, type]
    )

    const { sort, setSort, setSearchTerm, rows, error, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteScroll<Schedule>({
        queryKey: `schedules-${type}-${site?.productId}`,
        initialSort: { field: 'name', order: 'asc' },
        fetchFunction: fetch,
    })

    const rowActions = useMemo(
        () => [
            {
                key: 'edit',
                text: t('general.edit'),
                onClick: (record: Schedule) => {
                    history.push(detailUrl.replace(':id', record.uuid).replace(':type', type) + '?edit=true#0')
                },
                dividerBeneath: true,
                needsWriteAccess: true
            },
            {
                key: 'delete',
                text: t('general.delete'),
                onClick: (record: Schedule) => {
                    setScheduleToDelete(record)
                },
                needsWriteAccess: true
            },
        ],
        [t, history, type]
    )

    const columns: ColumnDefinition<Schedule>[] = useMemo(
        () => [
            {
                title: t('schedules.table.name'),
                key: 'name',
                sorter: true,
                render: (text: string, record: Schedule) => <Link href={detailUrl.replace(':id', record.uuid).replace(':type', type)}>{record.name}</Link>,
            },
            {
                title: '',
                key: 'uuid',
                sorter: false,
                render: (text: string, record: Schedule) => <TableRowActionsMenu record={record} menuItems={rowActions} readOnly={!hasWriteAccess}/>,
                style: { width: '25%' },
            },
        ],
        [t, rowActions, type, hasWriteAccess]
    )

    const onSearch = useCallback(async (searchTerm: string) => setSearchTerm(searchTerm), [setSearchTerm])

    const sorter = useCallback(col => sorterHeader(col, sort, setSort), [sort, setSort])

    const getRowKey = useCallback(schedule => schedule.uuid, [])

    const getDeleteUrl = useCallback(i => (site && i ? `/api/site/${site.productId}/schedule/${i.uuid}` : null), [site])

    const addButton = useMemo(
        () => (
            onlyWithWriteAccess(<AddButton
                onClick={() => {
                    history.push(addUrl.replace(':type', type))
                    return null
                }}
                label={`${t(`schedules.${type}.create-new`)}`}
            />)
        ),
        [history, t, type, onlyWithWriteAccess]
    )

    return (
        <TitledBody title={t(`schedules.${type}.title`)} buttonsPanel={addButton}>
            <SearchField onSearch={onSearch} />
            <ErrorAlert showAlert={!!error} />
            <InfiniteScrollTable<Schedule>
                hasNextPage={hasNextPage}
                data={rows}
                fetchNextPage={fetchNextPage}
                isFetching={isFetching}
                getRowKey={getRowKey}
                sorter={sorter}
                columns={columns}
                tableId={`${type}Table`}
            />
            <DeletionModal<Schedule | null> itemToDelete={scheduleToDelete} deleteUrl={getDeleteUrl} onClose={() => setScheduleToDelete(null)} onSuccess={refetch} />
        </TitledBody>
    )
}
