import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import React, {useCallback, useMemo, useState} from "react";
import {CallPickupGroup, ServerPage} from "../../app/types";
import {Sort, useInfiniteScroll} from "../../common/components/table/useInfiniteScroll";
import {api, buildPaginatedUrl} from "../../common/api";
import {ColumnDefinition, InfiniteScrollTable} from "../../common/components/table/Table";
import {Link} from "@mui/material";
import TableRowActionsMenu from "../../common/components/table/TableRowActionsMenu";
import {sorterHeader} from "../../common/components/table/SorterHeader";
import {SearchField} from "../../common/components/SearchField";
import ErrorAlert from "../../common/components/ErrorAlert";
import DeletionModal from "../../common/components/DeletionModal";
import {RouteDefinition} from "../../app/App";
import {AddCallPickupGroup} from "./addCallPickupGroup";
import {DetailCallPickupGroup} from "./detail";
import {TitledBody} from "../../common/components/TitledBody";
import {AddButton} from "../../common/components/AddButton";
import {useAccessHelpers} from "../../common/util/hooks";

export const detailCallPickupGroupUrl = '/services/pickupgroup/:id'
export const addCallPickupGroupUrl = '/services/pickupgroup/new'

export const routes: RouteDefinition[] = [
    {
        href: addCallPickupGroupUrl,
        component: AddCallPickupGroup,
        id: 'call-pickup-group-add',
        parentId: 'call-pickup-group-overview',
        translationKey: 'call-pickup-groups.add.title',
    },
    {
        href: detailCallPickupGroupUrl,
        component: DetailCallPickupGroup,
        id: 'call-pickup-group-detail',
        parentId: 'call-pickup-group-overview',
    }
].map(routeDefinition => ({ ...routeDefinition, menuId: 'call-pickup-group-overview' }))

export const CallPickupGroupList = () => {
    const history = useHistory()
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const [callPickupGroupToDelete, setCallPickupGroupToDelete] = useState<CallPickupGroup | null>(null)
    const {hasWriteAccess, onlyWithWriteAccess} = useAccessHelpers()

    const fetch = useCallback(
        async (pageParam = 0, sortParam: Sort, searchTermParam: string | null): Promise<ServerPage<CallPickupGroup>> => {
            if (!site) return { content: [], totalPages: 0, number: 0, totalElements: 0 }
            const { data } = await api.get(buildPaginatedUrl(`/api/site/${site.productId}/callpickupgroups`, pageParam, sortParam, searchTermParam))
            return data
        },
        [site]
    )

    const { sort, setSort, setSearchTerm, rows, error, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteScroll<CallPickupGroup>({
        queryKey: `callPickupGroups-${site?.productId}`,
        initialSort: { field: 'name', order: 'asc' },
        fetchFunction: fetch,
    })

    const rowActions = useMemo(
        () => [
            {
                key: 'edit',
                text: t('general.edit'),
                onClick: (record: CallPickupGroup) => {
                    history.push(detailCallPickupGroupUrl.replace(':id', record.uuid) + '?edit=true#0')
                },
                dividerBeneath: true,
                needsWriteAccess: true
            },
            {
                key: 'delete',
                text: t('general.delete'),
                onClick: (record: CallPickupGroup) => {
                    setCallPickupGroupToDelete(record)
                },
                needsWriteAccess: true
            },
        ],
        [t, history]
    )

    const columns: ColumnDefinition<CallPickupGroup>[] = useMemo(
        () => [
            {
                title: t('call-pickup-groups.table.name'),
                key: 'name',
                sorter: true,
                render: (text: string, record: CallPickupGroup) => <Link href={detailCallPickupGroupUrl.replace(':id', record.uuid)}>{record.name}</Link>,
            },
            {
                title: '',
                key: 'uuid',
                sorter: false,
                render: (text: string, record: CallPickupGroup) => <TableRowActionsMenu record={record} menuItems={rowActions}  readOnly={!hasWriteAccess}/>,
                style: { width: '25%' },
            },
        ],
        [t, rowActions, hasWriteAccess]
    )

    const onSearch = useCallback(async (searchTerm: string) => setSearchTerm(searchTerm), [setSearchTerm])

    const sorter = useCallback(col => sorterHeader(col, sort, setSort), [sort, setSort])

    const getRowKey = useCallback(callPickupGroup => callPickupGroup.uuid, [])

    const getDeleteUrl = useCallback(i => (site && i ? `/api/site/${site.productId}/callpickupgroup/${i.uuid}` : null), [site])

    return (
        <TitledBody title={t('call-pickup-groups.title')} buttonsPanel={
            onlyWithWriteAccess(<AddButton
                onClick={() => {
                    history.push(addCallPickupGroupUrl)
                }}
                label={`${t('call-pickup-groups.create-new')}`}
            />)}>
            <SearchField onSearch={onSearch} />
            <ErrorAlert showAlert={!!error} />
            <InfiniteScrollTable<CallPickupGroup>
                hasNextPage={hasNextPage}
                data={rows}
                fetchNextPage={fetchNextPage}
                isFetching={isFetching}
                getRowKey={getRowKey}
                sorter={sorter}
                columns={columns}
                tableId={'callPickupGroupsTable'}
            />
            <DeletionModal<CallPickupGroup | null> itemToDelete={callPickupGroupToDelete} deleteUrl={getDeleteUrl} onClose={() => setCallPickupGroupToDelete(null)} onSuccess={refetch} />
        </TitledBody>
    )
}