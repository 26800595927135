import React, { KeyboardEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import { IvrMenuConfigContext } from './IvrMenuConfigForm'
import CancelButton from '../../common/form/CancelButton'

function DeletionModal({
                           open,
                           onSuccess,
                           onClose,
                       }: { open: boolean; onSuccess: (subMenuName: string) => void; onClose: () => void }) {
    const { t } = useTranslation()
    const [value, setValue] = useState<string>('')
    const { menuConfig } = React.useContext(IvrMenuConfigContext)
    const [error, setError] = useState<string>('')

    useEffect(() => setValue(''), [open])

    const handleOk = async () => {
        if (!value) {
            setError(t('ivrs.sub-menu-editor.create-submenu-dialog.error-empty'))
        } else if (menuConfig?.subMenus && !!menuConfig?.subMenus[value]) {
            setError(t('general.form-error.ivr.submenu-id-already-exists'))
        } else {
            onSuccess && onSuccess(value)
        }
    }

    const keyPress = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleOk()
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('ivrs.sub-menu-editor.create-submenu-dialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('ivrs.sub-menu-editor.create-submenu-dialog.text')}</DialogContentText>
                <TextField
                    onKeyDown={keyPress}
                    error={!!error}
                    autoFocus
                    margin='dense'
                    id='name'
                    label={t('ivrs.sub-menu-editor.create-submenu-dialog.name')}
                    fullWidth
                    variant='standard'
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    helperText={error}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleOk}>
                    {t('general.ok')}
                </Button>
                <CancelButton onClick={onClose} />
            </DialogActions>
        </Dialog>
    )
}

export default DeletionModal
