import React, {ReactElement, ReactNode, useCallback, useMemo, useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Grid, Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import {WithErrorAndFetching} from "../servercall/WithErrorAndFetching";
import {withStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import CancelButton from "../form/CancelButton";
import {useHistory, useLocation} from "react-router";

const StyledAccordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(Accordion);

const StyledAccordionSummary = withStyles({
    root: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(AccordionSummary);

const StyledAccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(AccordionDetails);

export type FormSection<T> = {
    title: string
    data?: T
    isFetching: boolean
    error: any
    refetch: () => void
    renderDetails: (data: T) => ReactNode
    renderForm: (data: T, cancelEditButton: ReactElement, onSucceeded: () => void) => ReactNode
    enabled: () => boolean
    icon: () => ReactElement
}

type Props = {
    formSections: FormSection<any>[]
    activeTab?: number
    readOnly?: boolean
}

export const SectionedForm = ({ activeTab, formSections, readOnly }: Props) => {
    const location = useLocation();
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const [expanded, setExpanded] = useState<number>(location.hash? parseInt(location.hash.substring(1)) : activeTab !== undefined ? activeTab :  -1);
    const [editTab, setEditTab] = useState<number>(query.get('edit') === 'true' ? expanded : -1);
    const {t} = useTranslation()
    const history = useHistory()

    const handleChange = (panelNr: number) => (event: Object, newExpanded: boolean) => {
        const expandedTab = newExpanded ? panelNr : -1
        setExpanded(expandedTab);
        history.replace({ pathname: location.pathname, search: `#${expandedTab}` });
    };

    const onCancelEditing = useCallback(() => {
        setEditTab(-1);
    }, [setEditTab])

    const CancelEditButton = useMemo(() => <CancelButton onClick={onCancelEditing} /> , [onCancelEditing])

    const onEditSucceeded = useCallback(() => {onCancelEditing()} , [onCancelEditing])

    return <div>
        {formSections.map(
            (formSection, index) =>
                <StyledAccordion key={index} expanded={expanded === index} disabled={!formSection.enabled() || (editTab !== -1 && editTab !== index)} onChange={handleChange(index)}>
                    <StyledAccordionSummary id={`panel${index}`}>
                        <Grid container justifyContent={'space-between'} flexWrap={'nowrap'} alignItems={'center'}>
                            <Grid container item md={4} alignItems={'center'} spacing={2}><Grid item>{formSection.icon()}</Grid><Grid item><Typography variant={'subtitle1'}>{formSection.title}</Typography></Grid></Grid>
                            {formSection.enabled() &&
                                <Grid item>
                                    {editTab === index?
                                        <Link href={`#${index}`} onClick={(event) => {event.stopPropagation(); onCancelEditing(); }}
                                              onFocus={(event) => event.stopPropagation()}>
                                            {t('general.cancel')}
                                        </Link> : editTab === -1 && !readOnly ?
                                            <Link href={`#${index}`} onClick={(event) => {event.stopPropagation(); setExpanded(index); setEditTab(index) }}
                                                  onFocus={(event) => event.stopPropagation()}>
                                                {t('general.edit')}
                                            </Link> : <></>
                                    }
                                </Grid>
                            }
                        </Grid>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                        <WithErrorAndFetching isFetching={formSection.isFetching} error={formSection.error} data={formSection.data} render={data => editTab === index? formSection.renderForm(data, CancelEditButton, onEditSucceeded) : formSection.renderDetails(data)}/>
                    </StyledAccordionDetails>
                </StyledAccordion>
        )}
    </div>
}