import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'rebass'
import { Alert, AlertColor, AlertTitle } from '@mui/material'

function ErrorAlert({
                        showAlert,
                        errorKey,
                        body,
                        severity,
                    }: { showAlert: boolean; errorKey?: string; body?: ReactElement; severity?: AlertColor }) {
    const { t } = useTranslation()

    return (
        <div id={'server-error-box'}>
            {showAlert ? (
                <Flex mb={2}>
                    <Box width={1}>
                        <Alert severity={severity || 'error'}>
                            <AlertTitle>{severity === 'warning' ? t('general.warning') : t('general.error')}</AlertTitle>
                            {body ? body : t(errorKey ?? 'general.unknown-server-error')}
                        </Alert>
                    </Box>
                </Flex>
            ) : (
                <></>
            )}
        </div>
    )
}

export default ErrorAlert
