import i18n from 'i18next'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18nCountries from 'i18n-iso-countries'
import EN_COUNTRIES from 'i18n-iso-countries/langs/en.json'
import NL_COUNTRIES from 'i18n-iso-countries/langs/nl.json'
import FR_COUNTRIES from 'i18n-iso-countries/langs/fr.json'
import NL from '../labels/nl.json'
import EN from '../labels/en.json'
import FR from '../labels/fr.json'

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(
        {
            debug: false,
            resources: {
                nl: { translation: NL },
                en: { translation: EN },
                fr: { translation: FR },
            },
            fallbackLng: 'nl',

            interpolation: {
                escapeValue: false,
            },
        },
        (err, t) => {
            if (err) console.log('something went wrong loading', err)
        },
    )

i18nCountries.registerLocale({ ...EN_COUNTRIES, locale: 'en-gb' })
i18nCountries.registerLocale({ ...EN_COUNTRIES, locale: 'en-us' })
i18nCountries.registerLocale(EN_COUNTRIES)
i18nCountries.registerLocale(NL_COUNTRIES)
i18nCountries.registerLocale(FR_COUNTRIES)

i18next.languages = ['nl', 'fr', 'en']

export default i18n
