import {useTranslation} from "react-i18next";
import React, {ReactElement, ReactNode, useCallback} from "react";
import {FormSection} from "../../common/components/SectionedForm";
import {VoicemailSettings} from "../../app/types";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import {api} from "../../common/api";
import {useFetchData} from "../../common/servercall/useFetchData";
import {emailRegexWithoutEmpty, VoicemailSettingsForm} from "./VoicemailSettingsForm";
import PaperStack from "../../common/form/PaperStack";
import FormGroup from "../../common/form/FormGroup";
import {LabelValues, LabelValueType} from "../../common/components/LabelValues";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";


export const useVoicemailSection = (userId: string, enabled: boolean) : FormSection<VoicemailSettings> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const {buildFormButtons} = useFormSubmissionButtons(false)

    const fetchVoicemailSettings = useCallback(
        async (userId: string): Promise<VoicemailSettings | undefined> => {
            if (!site || !enabled) return
            const { data } = await api.get<VoicemailSettings>(`/api/site/${site.productId}/user/${userId}/voicemail-settings`)
            return data
        },
        [site, enabled],
    )
    const { data, isFetching, error, refetch } = useFetchData<VoicemailSettings>(useCallback(() => fetchVoicemailSettings(userId), [fetchVoicemailSettings, userId]))

    const onSubmit = useCallback((onSucceeded: () => void) =>
        async (formData: VoicemailSettings) => {
                if (!site) return
                await api.put(`/api/site/${site.productId}/user/${userId}/voicemail-settings`, fixVoicemailSettingsBeforeSending(formData, data))
                await refetch()
                onSucceeded()
            }, [site, userId, data, refetch],)

    const renderForm = useCallback((settings: VoicemailSettings, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode =>
        <VoicemailSettingsForm settings={settings} onSubmit={onSubmit(onSucceeded)} buildFormButtons={buildFormButtons(cancelEditButton)}/>, [onSubmit, buildFormButtons])

    const generalLabelValues = useCallback(
        (value: VoicemailSettings): LabelValueType[] => {
            return value.active?  [
                {
                    label: t('users.voicemail-settings.send-calls-to-voicemail'),
                    value: <>
                        {[value.alwaysRedirectToVoiceMail ? 'settings-always' : null, value.busyRedirectToVoiceMail? 'settings-when-busy' : null, value.noAnswerRedirectToVoiceMail? 'settings-when-no-answer' : null]
                            .filter(it => !!it)
                            .map(it => t(`users.voicemail-settings.${it}`))
                            .join(", ")
                        }</>
                },
                {
                    label: t('users.voicemail-settings.when-message-arrives'),
                    value: <>{`${t(`users.voicemail-settings.processing.${value.processing}`)}`}</>
                },
                {
                    label: t('users.voicemail-settings.email-notification'),
                    value: <>{value.notifyEmail? value.notifyEmailAddress : t('general.no')}</>
                },
                {
                    label: t('users.voicemail-settings.carbon-copy'),
                    value: <>{value.sendCarbonCopyVoiceMessage? value.carbonCopyEmailAddress : t('general.no')}</>
                },
                {
                    label: t('users.voicemail-settings.transfer-on-zero'),
                    value: <>{value.transferPhoneNumber? t('general.yes') : t('general.no')}</>
                }
            ] : [
                ...(!value.active ? [{label: t('users.edit.voicemail-enabled'), value: <>{t('general.no')}</>}] : []),
            ]}, [t]
    )

    const renderVoicemail = useCallback(
        (value: VoicemailSettings): ReactNode => {
            return (
                <PaperStack>
                    <FormGroup key={1} label={t('general.general')} fullWidth={true}>
                        <LabelValues labelValues={generalLabelValues(value)} />
                    </FormGroup>
                    <div></div>
                </PaperStack>
            )
        },
        [t, generalLabelValues]
    )

    return {
        title: t('users.voicemail-settings.title'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderVoicemail,
        renderForm: renderForm,
        enabled: () => enabled,
        icon: () => <VoicemailIcon/>
    }
}

const fixVoicemailSettingsBeforeSending = (newSettings: VoicemailSettings, oldSettings?: VoicemailSettings, ) : VoicemailSettings => {
    if (newSettings.active) {
        return newSettings
    }

    return {
        ...newSettings,
        deliveryEmailAddress: !isValid( newSettings.deliveryEmailAddress, newSettings.processing === 'DELIVER_TO_EMAIL_ADDRESS_ONLY', emailRegexWithoutEmpty) ?
            oldSettings?.deliveryEmailAddress || null : newSettings.deliveryEmailAddress,
        processing: !isValid( newSettings.deliveryEmailAddress, newSettings.processing === 'DELIVER_TO_EMAIL_ADDRESS_ONLY', emailRegexWithoutEmpty) ?
            oldSettings?.processing || 'UNIFIED_VOICE_AND_EMAIL_MESSAGING' : newSettings.processing,
        carbonCopyEmailAddress: !isValid( newSettings.carbonCopyEmailAddress, newSettings.sendCarbonCopyVoiceMessage, emailRegexWithoutEmpty) ?
            oldSettings?.carbonCopyEmailAddress || null : newSettings.carbonCopyEmailAddress,
        sendCarbonCopyVoiceMessage: !isValid( newSettings.carbonCopyEmailAddress, newSettings.sendCarbonCopyVoiceMessage, emailRegexWithoutEmpty) ?
            oldSettings?.sendCarbonCopyVoiceMessage || false : newSettings.sendCarbonCopyVoiceMessage,
        notifyEmailAddress: !isValid( newSettings.notifyEmailAddress, newSettings.notifyEmail, emailRegexWithoutEmpty) ?
            oldSettings?.notifyEmailAddress || null : newSettings.notifyEmailAddress,
        notifyEmail: !isValid( newSettings.notifyEmailAddress, newSettings.notifyEmail, emailRegexWithoutEmpty) ?
            oldSettings?.notifyEmail || false : newSettings.notifyEmail,
        transferPhoneNumber : !isValid( newSettings.transferPhoneNumber, newSettings.transferOnZeroToPhoneNumber, new RegExp('^\\+?[0-9]*$')) ?
            oldSettings?.transferPhoneNumber || null : newSettings.transferPhoneNumber,
        transferOnZeroToPhoneNumber: !isValid( newSettings.transferPhoneNumber, newSettings.transferOnZeroToPhoneNumber, new RegExp('^\\+?[0-9]*$')) ?
            oldSettings?.transferOnZeroToPhoneNumber || false : newSettings.transferOnZeroToPhoneNumber
    }
}

const isValid = (newValue: string | null, optionEnabled: boolean, regex: RegExp): boolean =>  {
    if (optionEnabled && !newValue) {
        return false;
    } else if (!!newValue && !regex.test(newValue)) {
        return false;
    }

    return true
}