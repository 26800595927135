import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'rebass'
import { api } from '../api'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import ErrorAlert from './ErrorAlert'
import axios from 'axios'

function ConfirmationModal<itemClass>({
    title,
    text,
    action,
    actionUrl,
    actionAfterWarningUrl,
    item,
    onSuccess,
    onClose,
    buttonLabel,
}: {
    title?: string
    text?: (item: any) => string
    action?: (item: itemClass) => void
    actionUrl?: (item: itemClass) => string | null
    actionAfterWarningUrl?: (item: itemClass) => string | null
    onSuccess?: () => void
    onClose: () => void
    item?: itemClass
    buttonLabel?: string
}) {
    const [error, setError] = useState<string | null>(null)
    const [warning, setWarning] = useState<string | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const { t } = useTranslation()

    useEffect(() => {
        setError(null)
    }, [item])

    const handleOk = async () => {
        try {
            setLoading(true)
            if (warning && actionAfterWarningUrl && item) {
                const url = actionAfterWarningUrl(item)
                url && (await api.delete(url))
            } else if (action && item) {
                await action(item)
            } else if (actionUrl && item) {
                const url = actionUrl(item)
                url && (await api.delete(url))
            }
            setError(null)
            setWarning(null)
            onSuccess && onSuccess()
            onClose()
        } catch (err) {
            setError(null)
            setWarning(null)
            if (axios.isAxiosError(err)) {
                const data = err?.response?.data?.errors as [any]
                err.response?.status === 400 && !!data
                    ? data[0].key.startsWith('warning-')
                        ? setWarning(`general.form-error.${data[0].key}`)
                        : setError(`general.form-error.${data[0].key}`)
                    : setError('general.unknown-server-error')
            } else {
                setError('general.unknown-server-error')
            }
        }
        setLoading(false)
    }

    return (
        <Dialog open={item !== null} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title || t('general.delete')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error && (
                        <Flex mb={2}>
                            <Box width={1}>
                                <ErrorAlert errorKey={error ?? undefined} showAlert={true} />
                            </Box>
                        </Flex>
                    )}
                    {warning && (
                        <Flex mb={2}>
                            <Box width={1}>
                                <ErrorAlert errorKey={warning ?? undefined} showAlert={true} severity={'warning'} />
                            </Box>
                        </Flex>
                    )}
                    {!warning && (text && item ? text(item) : t('general.delete-confirmation'))}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose}>
                    {t('general.cancel')}
                </Button>
                <Button variant="contained" onClick={handleOk} color="error" endIcon={loading && <CircularProgress color="primary" size="1rem" />}>
                    {buttonLabel ? buttonLabel : t('general.delete-after-confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationModal
