import {
    Days,
    HolidayForm,
    HolidayPeriod,
    OpeningHourEntries,
    OpeningHoursForm,
    Schedule,
    ScheduleEvent,
    ScheduleTimeSlot,
} from '../../app/types'
import {format, formatISO, parseISO} from 'date-fns'
import {days} from '../openinghours/addOpeningHours'
import {emptyPeriod} from '../holidays/addHolidays'
import {prop, sortBy} from 'ramda'
import {jsonToCSV} from "react-papaparse";

export const transformOpeningHoursFormToSchedule = (openingHoursForm: OpeningHoursForm | null): Schedule | null => {
    if (!openingHoursForm) {
        return null
    }
    const { days, ...openingHours } = openingHoursForm
    return {
        ...openingHours,
        configJson: {
            eventList: Object.keys(openingHoursForm.days).flatMap(day =>
                openingHoursForm.days[day].entries
                    .filter(entry => !!entry.startTime)
                    .map((entry, index) => ({
                        name: `${day}_${index}`,
                        startDate: formatISO(Date.now(), { representation: 'date' }),
                        endDate: formatISO(Date.now(), { representation: 'date' }),
                        startTime: {
                            hour: Number(entry.startTime.split(':')[0]),
                            minute: Number(entry.startTime.split(':')[1]),
                        },
                        endTime: {
                            hour: Number(entry.endTime.split(':')[0]),
                            minute: Number(entry.endTime.split(':')[1]),
                        },
                        allDayEvent: false,
                        scheduleDay: day,
                    })),
            ),
        },
    }
}

export const transformHolidayFormToSchedule = (holidayForm: HolidayForm | null): Schedule | null => {
    if (!holidayForm) {
        return null
    }
    const { holidayPeriods, ...holidays } = holidayForm

    return {
        ...holidays,
        configJson: {
            eventList: holidayForm.holidayPeriods.map((entry, index) => {
                const transformedStartDate = entry.from ? formatISO(entry.from, { representation: 'date' }) : ''
                return {
                    name: entry.eventName,
                    startDate: transformedStartDate,
                    endDate: entry.to ? formatISO(entry.to, { representation: 'date' }) : transformedStartDate,
                    allDayEvent: entry.allDay,
                    startTime:
                        !entry.allDay && entry.startTime
                            ? {
                                hour: Number(entry.startTime.split(':')[0]),
                                minute: Number(entry.startTime.split(':')[1]),
                            }
                            : null,
                    endTime:
                        !entry.allDay && entry.endTime
                            ? {
                                hour: Number(entry.endTime.split(':')[0]),
                                minute: Number(entry.endTime.split(':')[1]),
                            }
                            : null,
                    scheduleDay: null,
                }
            }),
        },
    }
}

export const transformScheduleToOpeningHoursForm = (schedule: Schedule): OpeningHoursForm => {
    const { configJson, eventConfig, ...scheduleWOConfig } = schedule
    return {
        ...scheduleWOConfig,
        days: transformDays(schedule.eventConfig?.eventList)
    }
}

export const transformDays = (eventList?: ScheduleEvent[]): Days => (
    {
        [days[0]]: deriveOpeningHourEntriesForDay(days[0], eventList),
        [days[1]]: deriveOpeningHourEntriesForDay(days[1], eventList),
        [days[2]]: deriveOpeningHourEntriesForDay(days[2], eventList),
        [days[3]]: deriveOpeningHourEntriesForDay(days[3], eventList),
        [days[4]]: deriveOpeningHourEntriesForDay(days[4], eventList),
        [days[5]]: deriveOpeningHourEntriesForDay(days[5], eventList),
        [days[6]]: deriveOpeningHourEntriesForDay(days[6], eventList),
    }
)

const deriveOpeningHourEntriesForDay = (day: string, eventList ?: ScheduleEvent[]): OpeningHourEntries => {
    const entries = sortOpeningHoursEvents(eventList?.filter(event => event.scheduleDay === day))?.map(event => ({
        startTime: formatTimeSlot(event.startTime),
        endTime: formatTimeSlot(event.endTime),
    }))
    return {
        entries: !!entries && entries?.length > 0 ? entries : [{ startTime: '', endTime: '' }],
    }
}

export const transformScheduleToHolidaysForm = (schedule: Schedule): HolidayForm => {
    const { configJson, eventConfig, ...scheduleWOConfig } = schedule
    return {
        ...scheduleWOConfig,
        holidayPeriods: transformHolidays(schedule.eventConfig?.eventList)
    }
}

export const transformHolidays = (eventList?: ScheduleEvent[]): HolidayPeriod[] =>
    eventList?.length
        ? sortHolidayEvents(eventList).map(event => ({
            eventName: event.name,
            from: parseISO(event.startDate),
            to: event.endDate ? parseISO(event.endDate) : null,
            startTime: formatTimeSlot(event.startTime),
            endTime: formatTimeSlot(event.endTime),
            allDay: event.allDayEvent,
        }))
        : [emptyPeriod]


export const formatTimeSlot = (scheduleTimeSlot: ScheduleTimeSlot | null): string => {
    if (!scheduleTimeSlot) return ''
    return String(scheduleTimeSlot.hour).padStart(2, '0') + ':' + String(scheduleTimeSlot.minute).padStart(2, '0')
}

export const formatTimes = (scheduleEvent: ScheduleEvent): string => {
    return formatTimeSlot(scheduleEvent.startTime) + ' - ' + formatTimeSlot(scheduleEvent.endTime)
}

export const formatDate = (date: Date | null): string => {
    if (!date) return ''
    return format(date, 'dd/MM/yyyy')
}

export const formatIsoDate = (isoDate: string | null): string => {
    if (!isoDate) return ''
    return formatDate(parseISO(isoDate))
}

export const formatIsoPeriod = (isoDate1: string | null, isoDate2: string | null): string => {
    if (!isoDate1) return ''
    return formatIsoDate(isoDate1) + (!isoDate2 || isoDate1 === isoDate2 ? '' : ' - ' + formatIsoDate(isoDate2))
}

export const sortOpeningHoursEvents = (scheduleEvents?: ScheduleEvent[]): ScheduleEvent[] => {
    return scheduleEvents?.sort((a, b) => (a.startTime?.hour || 0) * 60 + (a.startTime?.minute || 0) - ((b.startTime?.hour || 0) * 60 + (b.startTime?.minute || 0))) || []
}

export const sortHolidayEvents = (scheduleEvents?: ScheduleEvent[]): ScheduleEvent[] => {
    return scheduleEvents ? sortBy(prop('startDate'), scheduleEvents) : []
}

const toCsvOptions = {
    quotes: true, //or array of booleans
    delimiter: ";",
    header: true,
    newline: "\r\n",
}

export const exportScheduleEventsToCsv = (schedule: Schedule, toCsv: typeof jsonToCSV): string => {
    const toTransform = schedule.eventConfig?.eventList?.map(
        it => {
            const csvRow = {
                ...it,
                startTimeHour: it.startTime?.hour,
                startTimeMinute: it.startTime?.minute,
                endTimeHour: it.endTime?.hour,
                endTimeMinute: it.endTime?.minute,
            }
            const { startTime, endTime, ...csv} = csvRow
            return csv
        })
    // @ts-ignore
    return toCsv(toTransform, toCsvOptions);
}

export const toFile = (content: string, fileName: string) =>  {
    const blob = new Blob([content], { type: 'text/csv;charset=utf-8,' })
    const objUrl = URL.createObjectURL(blob)
    var link = document.createElement("a");
    link.setAttribute("href", objUrl);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link)
}