import React, {ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import {PhoneBookConfig} from '../app/types'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import TextInput from '../common/form/TextInput'
import FormRow from '../common/form/FormRow'
import FormFieldBox from '../common/form/FormFieldBox'
import SpinnableSubmitButton from '../common/form/SpinnableSubmitButton'
import {useFormSubmission} from '../common/servercall/useFormSubmission'
import ErrorAlert from '../common/components/ErrorAlert'
import {UseFormSetValue} from 'react-hook-form/dist/types/form'
import FormCheckbox from "../common/form/FormCheckbox";

const schema = yup
    .object()
    .shape({
        internalPhoneBookName: yup
            .string()
            .trim()
            .max(50, 'too-long')
            .required('required-m')
            .matches(/^[^\\/]*$/, 'invalid-entry-name'),
        externalPhoneBookName: yup
            .string()
            .trim()
            .max(50, 'too-long')
            .required('required-m')
            .matches(/^[^\\/]*$/, 'invalid-entry-name'),
    })
    .required()

type Props = {
    phoneBookConfig: PhoneBookConfig | null
    onSubmit: (formData: PhoneBookConfig) => void
    showEnableInternalEntepriseField: boolean
}

export type ReturnProps = {
    handleSubmit: () => void
    form: () => ReactElement
    submitButton: () => ReactElement
    setValue: UseFormSetValue<PhoneBookConfig>
}

const usePhoneBookConfigForm = ({ phoneBookConfig, onSubmit, showEnableInternalEntepriseField }: Props): ReturnProps => {
    const { t } = useTranslation()

    const { control, handleSubmit, setError, setValue } = useForm<PhoneBookConfig>({
        resolver: yupResolver(schema),
        defaultValues: phoneBookConfig
            ? {
                  ...phoneBookConfig,
              }
            : {},
    })

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<PhoneBookConfig>(onSubmit, setError)

    return {
        setValue: setValue,
        handleSubmit: handleSubmit(submit, onError),
        form: () =>
            phoneBookConfig ? (
                <>
                    <ErrorAlert errorKey={typeof serverError === 'string' ? serverError : undefined} showAlert={!!serverError} />
                    <FormRow>
                        <FormFieldBox fullWidth={true}>
                            <TextInput autofocus={true} label={t('phonebook.config.external-phonebook-name')} name={'externalPhoneBookName'} control={control} required={true} />
                        </FormFieldBox>
                        <FormFieldBox fullWidth={true}>
                            <TextInput label={t('phonebook.config.internal-phonebook-name')} name={'internalPhoneBookName'} control={control} required={true} />
                        </FormFieldBox>
                        {showEnableInternalEntepriseField ?
                            <FormFieldBox fullWidth={true}>
                                <FormCheckbox label={t('phonebook.config.enable-internal-enterprise-phonebook')} name={'internalEnterprisePhoneBookEnabled'} control={control} setValue={setValue} />
                            </FormFieldBox> : <></>
                        }
                    </FormRow>
                </>
            ) : (
                <div />
            ),
        submitButton: () => (phoneBookConfig ? <SpinnableSubmitButton label={t('general.form-update')} showSpinner={isSubmitting} /> : <></>),
    }
}

export default usePhoneBookConfigForm
