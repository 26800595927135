import {ExecutiveScreeningAlert} from "../../../app/types";
import {useTranslation} from "react-i18next";
import React, {ReactElement, useMemo} from "react";
import FormGroup from "../../../common/form/FormGroup";
import ErrorAlert from "../../../common/components/ErrorAlert";
import FormContainer from "../../../common/form/FormContainer";
import {useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useFormSubmission} from "../../../common/servercall/useFormSubmission";
import * as yup from "yup";
import FormFieldBox from "../../../common/form/FormFieldBox";
import FormCheckbox from "../../../common/form/FormCheckbox";
import FormRow from "../../../common/form/FormRow";
import TextInput from "../../../common/form/TextInput";

const schema = () => yup.object({
    rolloverForwardNumber: yup
        .string()
        .when(['rolloverAction'], {
            is: (rolloverAction: string) => rolloverAction === 'FORWARD',
            then: schema => yup.string().trim().transform((curr) => {
                return curr ? curr.replaceAll("-", "").replaceAll(" ", "") : null
            }).max(22, 'too-long').matches(/^[0-9]*$/, {
                message: 'invalid-phone-number',
                excludeEmptyString: true,
            }).required('required-m').nullable(),
            otherwise: schema => yup.string().trim().nullable(),
        }),
    rolloverWaitTimeSeconds: yup.number().required('required-f').typeError('invalid-rollover-wait-time').max(7200, 'invalid-rollover-wait-time')
})

type Props = {
    screeningAlert: ExecutiveScreeningAlert
    onSubmit: (formData: ExecutiveScreeningAlert) => void
    readonly?: boolean
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

export const ScreenAlertForm = ({ screeningAlert, onSubmit, readonly, buildFormButtons }: Props) => {
    const { t } = useTranslation()

    const { control, handleSubmit, setError, setValue } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(schema()),
        defaultValues: screeningAlert
    })

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<ExecutiveScreeningAlert>(onSubmit, setError)

    const alertingModeOptions = useMemo(
        () =>
            ['SIMULTANEOUS', 'SEQUENTIAL'].map(option => ({
                value: `${option}`,
                label: `${t(`executives.screening-alerting.alerting-types.${option}`)}`,
            })),
        [t]
    )

    const rolloverTypes = useMemo(
        () =>
            ['VOICE_MESSAGING', 'FORWARD', 'NO_ANSWER_PROCESSING'].map(option => ({
                value: `${option}`,
                label: `${t(`executives.screening-alerting.rollover-types.${option}`)}`,
            })),
        [t]
    )

    const ringTimeOptions = useMemo(
        () =>
            [6, 12, 18, 24, 30, 42, 60, 120].map(seconds => ({
                value: `${seconds}`,
                label: `${seconds} ${t('general.seconds-abbrev')}`,
            })),
        [t]
    )

    const callPushRecallRingTimeOptions = useMemo(
        () =>
            [12, 18, 24, 30, 42, 60, 120].map(seconds => ({
                value: `${seconds}`,
                label: `${seconds} ${t('general.seconds-abbrev')}`,
            })),
        [t]
    )

    const alertingMode = useWatch({
        control,
        name: 'alertingMode',
    })

    const rolloverAction = useWatch({
        control,
        name: 'rolloverAction',
    })

    return <>
        <ErrorAlert showAlert={!!serverError} />
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={''} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox mdSize={4}>
                            <TextInput
                                readonly={readonly}
                                label={t('executives.screening-alerting.alerting-mode')}
                                name={'alertingMode'}
                                control={control}
                                required={true}
                                options={alertingModeOptions}
                                isRadio={true}
                            />
                        </FormFieldBox>
                        {alertingMode === 'SEQUENTIAL' && (
                                <FormFieldBox mdSize={4}>
                                    <TextInput
                                        readonly={readonly}
                                        label={t('executives.screening-alerting.seconds-between-assistants-ring-time')}
                                        name={'ringTimePerAssistant'}
                                        control={control}
                                        required={true}
                                        options={ringTimeOptions}
                                    />
                                </FormFieldBox>
                        )}
                    </FormRow>
                    <FormRow>
                        <FormFieldBox mdSize={4}>
                            <FormCheckbox
                                label={t('executives.screening-alerting.enable-rollover')}
                                name={'enableRollover'}
                                control={control}
                                setValue={setValue}
                            />
                        </FormFieldBox>
                        <FormFieldBox mdSize={4}>
                            <TextInput
                                readonly={readonly}
                                label={t('executives.screening-alerting.rollover-wait-time-seconds')}
                                name={'rolloverWaitTimeSeconds'}
                                control={control}
                                required={true}
                            />
                        </FormFieldBox>

                    </FormRow>
                    <FormRow>
                        <FormFieldBox mdSize={4}>
                            <TextInput
                                readonly={readonly}
                                label={t('executives.screening-alerting.rollover')}
                                name={'rolloverAction'}
                                control={control}
                                required={true}
                                options={rolloverTypes}
                                isRadio={true}
                            />
                        </FormFieldBox>
                        {rolloverAction === 'FORWARD' && <FormFieldBox  mdSize={4}>
                            <TextInput readonly={false} label={t('executives.screening-alerting.forward-to-number')} name={'rolloverForwardNumber'} control={control} required={true} />
                        </FormFieldBox>}
                    </FormRow>
                    <FormRow>
                        <FormFieldBox mdSize={4}>
                            <TextInput
                                readonly={readonly}
                                label={t('executives.screening-alerting.call-push-recall-time')}
                                name={'callPushRecallRingTime'}
                                control={control}
                                required={true}
                                options={callPushRecallRingTimeOptions}
                            />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                {buildFormButtons(isSubmitting)}
            </FormContainer>
        </>
}
