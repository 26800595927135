import React, {useCallback, useMemo} from 'react'
import {Control, FieldValues, Path, useController} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import AutocompleteWithAsyncFetch, {AutoCompleteOptions} from './AutocompleteWithAsyncFetch'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Input,
    InputLabel,
    Radio,
    RadioGroup,
    Select,
    TextField,
    TextFieldProps
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import {SelectChangeEvent} from '@mui/material/Select/SelectInput'
import {IMaskInput} from 'react-imask'
import {DatePicker} from '@mui/x-date-pickers'

export type MaskProps = {
    mask: string
    prepare?: (input: string) => string
    definitions: any
}

type Props<T extends FieldValues> = {
    label: string
    name: Path<T>
    fieldKey?: string
    control: Control<T>
    help?: string
    required?: boolean
    options?: Option[]
    autoComplete?: boolean
    isNumber?: boolean
    autofocus?: boolean
    autoCompleteOptions?: AutoCompleteOptions<any>
    readonly?: boolean
    isRadio?: boolean
    isCheckbox?: boolean
    onChange?: (event: SelectChangeEvent, child: React.ReactNode) => void
    maskProps?: MaskProps
    isDate?: boolean,
    hideLabel?: boolean
}

export type Option = {
    value: any
    label: string
    disabled?: boolean
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
}

const textMaskCustom = (mask: string, definitions: any, prepare?: (input: string) => string) => {
    return React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props

        return (
            <IMaskInput
                {...other}
                mask={mask}
                definitions={definitions}
                prepare={prepare}
                // @ts-ignore
                inputRef={ref}
                onAccept={(value: any) => {
                    onChange({ target: { name: props.name, value } })
                }}
            />
        )
    })
}

export default function TextInput<T extends FieldValues>({
                                         control,
                                         label,
                                         name,
                                         fieldKey,
                                         help,
                                         required,
                                         options,
                                         isNumber,
                                         autofocus,
                                         autoCompleteOptions,
                                         readonly,
                                         isRadio,
                                         isCheckbox,
                                         onChange,
                                         maskProps,
                                         isDate,
                                         hideLabel
                                     }: Props<T>) {
    const {
        field: { ref, ...inputProps },
        fieldState: { error, invalid },
    } = useController<T>({
        name,
        control,
    })
    const { t } = useTranslation()

    const errorObject = error as any

    const onChangeCheckbox = useCallback(
        index => (event: React.ChangeEvent<HTMLInputElement>) => {
            const valueCopy: (string | null)[] = [...(inputProps.value || [])]

            valueCopy[index] = event.target.checked ? event.target.value : null

            // @ts-ignore
            inputProps.onChange(valueCopy)
        },
        [inputProps],
    )

    const isChecked = useCallback(
        (value): boolean => {
            if (!inputProps.value) return false
            const values: (string | null)[] = inputProps.value
            return values.includes(value)
        },
        [inputProps],
    )

    const maskedInput = useMemo(() => maskProps && textMaskCustom(maskProps.mask, maskProps.definitions, maskProps.prepare), [maskProps])

    return (
        <>
            {isDate ? (
                <DatePicker
                    ignoreInvalidInputs={false}
                    mask='__/__/____'
                    inputFormat='dd/MM/yyyy'
                    {...inputProps}
                    onChange={(inputDate, kbInputValue) => { if(inputDate) inputProps.onChange(inputDate) }}
                    label={label}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                        <TextField
                            helperText={invalid ? t(`general.form-error.${errorObject?.message}`, { label: label }) : help}
                            name={name}
                            required={required}
                            InputProps={{ error: invalid }}
                            InputLabelProps={{ error: invalid }}
                            FormHelperTextProps={{ error: invalid }}
                            variant={'standard'}
                            fullWidth={true}
                            {...params}
                        />
                    )}
                />
            ) : autoCompleteOptions ? (
                <AutocompleteWithAsyncFetch
                    innerRef={ref}
                    onChange={inputProps.onChange}
                    label={label}
                    required={required}
                    readonly={readonly}
                    name={name}
                    invalid={invalid}
                    help={help}
                    autoCompleteOptions={autoCompleteOptions}
                    errorMessage={errorObject?.message}
                />
            ) : (
                <FormControl variant={'standard'} fullWidth={true} component='fieldset'>
                    {options && (isRadio || isCheckbox) ? (
                        <FormLabel error={invalid} required={required} htmlFor={name}>
                            {!hideLabel && label}
                        </FormLabel>
                    ) : (
                        <InputLabel error={invalid} required={required} htmlFor={name}>
                            {!hideLabel && label}
                        </InputLabel>
                    )}
                    {options ? (
                        isCheckbox ? (
                            <FormGroup id={name} ref={ref}>
                                {options.map((option, index) => (
                                    <FormControlLabel
                                        control={<Checkbox size='small' onChange={onChangeCheckbox(index)}
                                                           checked={isChecked(option.value)} name={name}
                                                           value={option.value} />}
                                        key={option.value}
                                        label={option.label}
                                    />
                                ))}
                            </FormGroup>
                        ) : isRadio ? (
                            <RadioGroup key={fieldKey} id={name} {...inputProps} ref={ref}>
                                {options.map(option => (
                                    <FormControlLabel control={<Radio size='small' />} key={option.value}
                                                      value={option.value} label={option.label} />
                                ))}
                            </RadioGroup>
                        ) : (
                            <Select
                                key={fieldKey}
                                readOnly={readonly}
                                disabled={readonly}
                                error={invalid}
                                id={name}
                                label={label}
                                {...inputProps}
                                onChange={(e, child) => {
                                    // @ts-ignore
                                    inputProps.onChange(e)
                                    onChange && onChange(e, child)
                                }}
                                ref={ref}
                                autoFocus={autofocus}
                            >
                                {options.map(option => (
                                    <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        )
                    ) : (
                        <Input
                            key={fieldKey}
                            readOnly={readonly}
                            disabled={readonly}
                            id={name}
                            error={invalid}
                            required={required}
                            type={isNumber ? 'number' : ''}
                            {...inputProps}
                            ref={ref}
                            autoFocus={autofocus}
                            inputComponent={!!maskedInput ? (maskedInput as any) : null}
                        />
                    )}
                    <FormHelperText
                        error={invalid}>{invalid ? t(`general.form-error.${errorObject?.message}`, { label: label }) : help}</FormHelperText>
                </FormControl>
            )}
        </>
    )
}
