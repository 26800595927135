import {useSelector} from 'react-redux'
import {maxIvrsSelector, selectedSiteSelector} from '../authentication/redux'
import React, {ReactElement, useMemo} from 'react'
import {Ivr} from '../app/types'
import {ColumnDefinition} from '../common/components/table/Table'
import {useTranslation} from 'react-i18next'
import {formatPhoneNumber, formatTotalStat} from '../common/util/formatters'
import {Avatar, Card, CardContent, CardHeader, CardMedia, Link, Typography} from '@mui/material'
import {detailUrl} from '../ivrs'
import {useIvrTable} from '../ivrs/useIvrTable'
import {AddButton} from '../common/components/AddButton'
import {Mediation} from "@mui/icons-material";
import {useAccessHelpers} from "../common/util/hooks";

export const useIvrStats = ({ onClick }: { onClick: () => void }): { stats: ReactElement; totalIvrs: number } => {
    const site = useSelector(selectedSiteSelector)
    const { t } = useTranslation()
    const maxIvrs = useSelector(maxIvrsSelector)
    const {onlyWithWriteAccess} = useAccessHelpers()

    const columns: ColumnDefinition<Ivr>[] = useMemo(
        () => [
            {
                title: t('site.cards.ivrs.table.name'),
                key: 'name',
                sorter: true,
                render: (text: string, record: Ivr) => <Link href={detailUrl.replace(':id', record.uuid)}>{record.name}</Link>,
            },
            {
                title: t('site.cards.ivrs.table.number'),
                key: 'number',
                sorter: true,
                render: (text: string) => <>{formatPhoneNumber(text)}</>,
            },
        ],
        [t]
    )

    const { table, totalElements } = useIvrTable({
        columns: columns,
        queryKey: `ivrs-${site?.productId}-stats`,
        initialSortField: 'name',
        maxHeight: '18vh'
    })

    return {
        stats: (
            <Card sx={{ height: '100%' }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: theme => theme.palette.primary.main, color: theme => theme.palette.secondary.main }} variant="rounded">
                            <Mediation />
                        </Avatar>
                    }
                    title={<Link href={'/ivrs'}>{t('navigation.ivrs')}</Link>}
                    subheader={
                        <Typography color={maxIvrs !== -1 && totalElements > maxIvrs ? 'error.main' : 'text.secondary'} sx={{ fontSize: 14 }}>
                            {`# ${formatTotalStat(totalElements, maxIvrs)}`}
                        </Typography>
                    }
                    action={onlyWithWriteAccess(<AddButton small={true} onClick={onClick} label={t('ivrs.create-new')} />)}
                />
                <CardMedia></CardMedia>
                <CardContent>{table}</CardContent>
            </Card>
        ),
        totalIvrs: totalElements,
    }
}
