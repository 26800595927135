import React, {ReactElement, ReactNode, useCallback} from 'react'
import {FormSection} from "../../../common/components/SectionedForm";
import {api} from "../../../common/api";
import FormButtons from "../../../common/form/FormButtons";
import SpinnableSubmitButton from "../../../common/form/SpinnableSubmitButton";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../../authentication/redux";
import {useTranslation} from "react-i18next";
import PaperStack from "../../../common/form/PaperStack";
import FormGroup from "../../../common/form/FormGroup";
import {LabelValues, LabelValueType} from "../../../common/components/LabelValues";
import FilterListIcon from "@mui/icons-material/FilterList";
import {ExecutiveFiltering} from "../../../app/types";
import {useFetchData} from "../../../common/servercall/useFetchData";
import {FiltersForm} from "./filtersForm";
import {join} from "ramda";

export const useFilterSection = (executiveId: string) : FormSection<ExecutiveFiltering> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)

    const fetchFiltering = useCallback(
        async (userId: string): Promise<ExecutiveFiltering | undefined> => {
            if (!site) return
            const { data: settings } = await api.get<ExecutiveFiltering>(`/api/site/${site.productId}/executive/${executiveId}/filtering`)
            return settings
        },
        [site, executiveId]
    )
    const { data, isFetching, error, refetch } = useFetchData<ExecutiveFiltering>(useCallback(() => fetchFiltering(executiveId), [fetchFiltering, executiveId]))


    const onSubmit = useCallback((onSucceeded: () => void) =>
            async (formData: ExecutiveFiltering) => {
                if (!site) return
                await api.put(`/api/site/${site.productId}/executive/${executiveId}/filtering`,
                    {
                        ...formData,
                        filterMode: formData.simpleFilterType === 'EXCLUDE_SPECIFIC_NUMBERS' ? 'ADVANCED' : 'SIMPLE',
                        simpleFilterType: formData.simpleFilterType === 'EXCLUDE_SPECIFIC_NUMBERS'? 'ALL_CALLS' : formData.simpleFilterType,
                        excludedNumbers: formData.excludedNumbers.filter(nr => !!nr && nr.length > 0  )
                    })
                await refetch()
                onSucceeded();
            },
        [site, executiveId, refetch],
    )

    const buildFormButtons = useCallback( (cancelEditButton: ReactElement) => (isSubmitting: boolean) =>
            <FormButtons buttons={[<SpinnableSubmitButton label={t('general.form-update')} showSpinner={isSubmitting} />, cancelEditButton]} fullWidth={true}/>
        , [t])

    const renderForm = useCallback((filtering: ExecutiveFiltering, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode => <FiltersForm filtering={filtering} buildFormButtons={buildFormButtons(cancelEditButton)} onSubmit={onSubmit(onSucceeded)} />, [onSubmit, buildFormButtons])

    const labelValues = useCallback(
        (value: ExecutiveFiltering): LabelValueType[] => [
            {
                label: t('general.enabled'),
                value: <>{value.enabled? t('general.yes') : t('general.no')}</>,
            },
            {
                label: t('executives.filtering.filtered-calls'),
                value: value.filterMode === 'SIMPLE'?
                        <>{t(`executives.filtering.simple-filter.${value.simpleFilterType}`)}</> :
                        <>{`${t('executives.filtering.simple-filter.EXCLUDE_SPECIFIC_NUMBERS')}: ${join(', ', value.excludedNumbers)}`}</>
            },
        ],
        [t]
    )

    const renderFiltering = useCallback(
        (filtering: ExecutiveFiltering): ReactNode => {
            return (
                <PaperStack>
                    {[<FormGroup key={1} label={''} fullWidth={true}>
                        <LabelValues labelValues={labelValues(filtering)} />
                    </FormGroup>]}
                </PaperStack>
            )
        },
        [labelValues])

    return {
        title: t('executives.section-filtering'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderFiltering,
        renderForm: renderForm,
        enabled: () => true,
        icon: () => <FilterListIcon/>
    }
}