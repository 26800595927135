import React, {useEffect, useState} from 'react'

import {Alert, AlertTitle, Snackbar, Switch} from '@mui/material'
import {PhoneUser} from '../app/types'
import {api} from '../common/api'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import {useTranslation} from 'react-i18next'
import axios from 'axios'

type Props = {
    user?: PhoneUser | null
    refetch: () => void
    readonly?: boolean
}

export const FmuSwitchButton = ({ user, readonly }: Props) => {
    const [checked, setChecked] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [success, setSuccess] = useState<boolean>(false)
    const { t } = useTranslation()

    const site = useSelector(selectedSiteSelector)

    useEffect(() => {
        setChecked(user ? user.fmuMode === 'ON' : false)
    }, [user])

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
        if (site && user) {
            try {
                await api.put(`/api/site/${site.productId}/user/${user.uuid}/toggle-fmu`, { enabled: event.target.checked })
                setSuccess(true)
            } catch (err) {
                setChecked(!event.target.checked)
                setSuccess(false)
                if (axios.isAxiosError(err)) {
                    const data = err?.response?.data as [any]
                    if (err.response?.status === 400 && !!data) {
                        data.forEach(serverError => setError(`general.form-error.${serverError.key}`))
                    } else {
                        setError('general.unknown-server-error')
                    }
                } else {
                    setError('general.unknown-server-error')
                }
                return
            }
        }
    }

    return (
        <>
            <Switch size="small" onChange={handleChange} checked={checked} disabled={readonly || !user || !user.fmuMsisdn || !user.number} />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={!!error} autoHideDuration={5000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
                    <AlertTitle>{t('general.error')}</AlertTitle>
                    {error && t(error)}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={success} autoHideDuration={5000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    <AlertTitle>{t('general.succeeded')}</AlertTitle>
                    {t(checked ? 'users.fmu-switch-on-success' : 'users.fmu-switch-off-success')}
                </Alert>
            </Snackbar>
        </>
    )
}
