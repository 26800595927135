import {UserCallControl} from "../../app/types";
import {useTranslation} from "react-i18next";
import React, {ReactElement} from "react";
import FormGroup from "../../common/form/FormGroup";
import ErrorAlert from "../../common/components/ErrorAlert";
import FormContainer from "../../common/form/FormContainer";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useFormSubmission} from "../../common/servercall/useFormSubmission";
import * as yup from "yup";
import {array, object} from "yup";
import FormFieldBox from "../../common/form/FormFieldBox";
import FormCheckbox from "../../common/form/FormCheckbox";
import FormRow from "../../common/form/FormRow";
import {Box, Paper, Table, TableContainer} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {CallControlNumberForm} from "./callControlNumberForm";

const schema = () => yup.object().shape(
    {
        phoneNumberAnywhereList: array().of(
            object().shape({
                    number: yup.string().trim().max(22, 'too-long').required('required-m').matches(/^\+?[0-9]*$/, {
                        message: 'invalid-phone-number',
                        excludeEmptyString: true,
                    }).nullable()
                })
            )
    }
)

type Props = {
    callControl: UserCallControl
    onSubmit: (formData: UserCallControl) => void
    readonly?: boolean
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

export const CallControlForm = ({ callControl, onSubmit, readonly, buildFormButtons }: Props) => {
    const { t } = useTranslation()

    const { control, handleSubmit, setError, setValue, getFieldState } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(schema()),
        defaultValues: callControl
    })

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<UserCallControl>(onSubmit, setError)

    return <>
        <ErrorAlert showAlert={!!serverError} />
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={t('users.edit.queue-availability.title')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <TableContainer component={Paper} sx={{ mt: 1 }}>
                                <Table sx={{ minWidth: 300 }} size={'small'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 700 }}>{t('flows.table.name')}</TableCell>
                                            <TableCell style={{ fontWeight: 700 }} align="right">
                                                {t('flows.detail.available')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {callControl.callCenterAvailabilities &&
                                            callControl.callCenterAvailabilities.map((queue, index) => (
                                                <TableRow key={queue.callCenterId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="row">
                                                        {queue.name}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Box alignSelf={'center'} sx={{ marginLeft: 1 }}>
                                                            <FormCheckbox
                                                                label={''}
                                                                name={`callCenterAvailabilities[${index}].available`}
                                                                control={control}
                                                                setValue={setValue}
                                                                getFieldState={getFieldState}
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                <FormGroup label={t('users.edit.office-anywhere.title')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('users.edit.office-anywhere.alert-all-locations')}
                                name={'alertAllLocations'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox>
                            <CallControlNumberForm control={control} getFieldState={getFieldState} setValue={setValue}/>
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                {buildFormButtons(isSubmitting)}
            </FormContainer>
        </>
}
