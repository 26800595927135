import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import React, {useCallback, useMemo, useState} from "react";
import {Paging, ServerPage} from "../../app/types";
import {Sort, useInfiniteScroll} from "../../common/components/table/useInfiniteScroll";
import {api, buildPaginatedUrl} from "../../common/api";
import {ColumnDefinition, InfiniteScrollTable} from "../../common/components/table/Table";
import {Link} from "@mui/material";
import TableRowActionsMenu from "../../common/components/table/TableRowActionsMenu";
import {sorterHeader} from "../../common/components/table/SorterHeader";
import {SearchField} from "../../common/components/SearchField";
import ErrorAlert from "../../common/components/ErrorAlert";
import DeletionModal from "../../common/components/DeletionModal";
import {RouteDefinition} from "../../app/App";
import {AddPaging} from "./addPaging";
import {DetailPaging} from "./pagingDetail";
import {TitledBody} from "../../common/components/TitledBody";
import {AddButton} from "../../common/components/AddButton";
import {useAccessHelpers} from "../../common/util/hooks";

export const detailPagingUrl = '/services/paging/:id'
export const addPagingUrl = '/services/paging/new'

export const routes: RouteDefinition[] = [
    {
        href: addPagingUrl,
        component: AddPaging,
        id: 'paging-add',
        parentId: 'paging-overview',
        translationKey: 'pagings.add.title',
    },
    {
        href: detailPagingUrl,
        component: DetailPaging,
        id: 'paging-detail',
        parentId: 'paging-overview',
    },
].map(routeDefinition => ({ ...routeDefinition, menuId: 'paging-overview' }))

export const PagingList = () => {
    const history = useHistory()
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const [pagingToDelete, setPagingToDelete] = useState<Paging | null>(null)
    const {hasWriteAccess, onlyWithWriteAccess} = useAccessHelpers()

    const fetch = useCallback(
        async (pageParam = 0, sortParam: Sort, searchTermParam: string | null): Promise<ServerPage<Paging>> => {
            if (!site) return { content: [], totalPages: 0, number: 0, totalElements: 0 }
            const { data } = await api.get(buildPaginatedUrl(`/api/site/${site.productId}/pagings`, pageParam, sortParam, searchTermParam))
            return data
        },
        [site]
    )

    const { sort, setSort, setSearchTerm, rows, error, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteScroll<Paging>({
        queryKey: `pagings-${site?.productId}`,
        initialSort: { field: 'name', order: 'asc' },
        fetchFunction: fetch,
    })

    const rowActions = useMemo(
        () => [
            {
                key: 'edit',
                text: t('general.edit'),
                onClick: (record: Paging) => {
                    history.push(detailPagingUrl.replace(':id', record.uuid) + '?edit=true#0')
                },
                dividerBeneath: true,
                needsWriteAccess: true
            },
            {
                key: 'delete',
                text: t('general.delete'),
                onClick: (record: Paging) => {
                    setPagingToDelete(record)
                },
                needsWriteAccess: true
            },
        ],
        [t, history]
    )

    const columns: ColumnDefinition<Paging>[] = useMemo(
        () => [
            {
                title: t('pagings.table.name'),
                key: 'name',
                sorter: true,
                render: (text: string, record: Paging) => <Link href={detailPagingUrl.replace(':id', record.uuid)}>{record.name}</Link>,
            },
            {
                title: t('users.table.extension'),
                key: 'extension',
                sorter: true,
                render: (text: string) => <>{text}</>,
            },
            {
                title: '',
                key: 'uuid',
                sorter: false,
                render: (text: string, record: Paging) => <TableRowActionsMenu record={record} menuItems={rowActions} readOnly={!hasWriteAccess}/>,
                style: { width: '25%' },
            },
        ],
        [t, rowActions, hasWriteAccess]
    )

    const onSearch = useCallback(async (searchTerm: string) => setSearchTerm(searchTerm), [setSearchTerm])

    const sorter = useCallback(col => sorterHeader(col, sort, setSort), [sort, setSort])

    const getRowKey = useCallback(paging => paging.uuid, [])

    const getDeleteUrl = useCallback(i => (site && i ? `/api/site/${site.productId}/paging/${i.uuid}` : null), [site])
    return (
        <TitledBody
            title={t('pagings.title')}
            buttonsPanel={
                onlyWithWriteAccess(<AddButton onClick={() => {history.push(addPagingUrl)}} label={`${t('pagings.create-new')}`}/>)
            }
        >
            <SearchField onSearch={onSearch} />
            <ErrorAlert showAlert={!!error} />
            <InfiniteScrollTable<Paging>
                hasNextPage={hasNextPage}
                data={rows}
                fetchNextPage={fetchNextPage}
                isFetching={isFetching}
                getRowKey={getRowKey}
                sorter={sorter}
                columns={columns}
                tableId={'pagingsTable'}
            />
            <DeletionModal<Paging | null> itemToDelete={pagingToDelete} deleteUrl={getDeleteUrl} onClose={() => setPagingToDelete(null)} onSuccess={refetch} />
        </TitledBody>
    )
}