import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../../common/components/TitledBody'
import {Executive, ExecutiveWithAssistantsForm} from '../../app/types'
import {api} from '../../common/api'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import {useHistory} from 'react-router'
import Form from './Form'
import FormButtons from "../../common/form/FormButtons";
import SpinnableSubmitButton from "../../common/form/SpinnableSubmitButton";
import CancelButton from "../../common/form/CancelButton";
import {detailExecutiveUrl} from "./index";

const initialExecutive: Executive = {
    id: null,
    userId: null,
    userFirstName: null,
    userLastName: null,
    assistants: []
}

export const AddExecutive = () => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const history = useHistory()

    const onSubmit = useCallback(
        async (formData: ExecutiveWithAssistantsForm) => {
            if (!site) return
            const {data : createdExecutive} = await api.post(`/api/site/${site.productId}/executive`, {
                executiveUserId: formData.executiveUser?.uuid,
                assistantUserIds: formData.assistantUsers.map(assistant => assistant.uuid)
            })
            history.push(detailExecutiveUrl.replace(':id', createdExecutive.id ))
        },
        [site, history],
    )

    const buildFormButtons = useCallback((isSubmitting) =>
            <FormButtons buttons={[<SpinnableSubmitButton label={t('general.form-create')} showSpinner={isSubmitting} />, <CancelButton />]} fullWidth={true} />
        , [t])

    return (
        <TitledBody title={t('executives.add.title')}>
            <Form onSubmit={onSubmit} executive={initialExecutive} buildFormButtons={buildFormButtons} />
        </TitledBody>
    )
}
