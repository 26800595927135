import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from '@mui/material'
import {useTranslation} from 'react-i18next'
import CancelButton from '../common/form/CancelButton'
import React, {useCallback, useState} from 'react'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import {api} from '../common/api'
import {Spinner} from '../common/components/Spinner'
import ErrorAlert from '../common/components/ErrorAlert'
import * as yup from 'yup'
import {CPE} from "../app/types";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useFormSubmission} from "../common/servercall/useFormSubmission";
import TextInput, {MaskProps} from "../common/form/TextInput";
import Link from "@mui/material/Link";
import {formatCPEModel} from "../common/util/formatters";
import {detailUrl} from "./index";
import SpinnableSubmitButton from "../common/form/SpinnableSubmitButton";

type Props = {
    handleClose: () => void
}

type CPEForm = {
    mac: string
}

const macProps: MaskProps = {
    mask: '##:##:##:##:##:##',
    prepare: function (str : string) {
        return str.toUpperCase();
    },
    definitions: {
        '#': /[0-9a-fA-F]/,
    },
}

export const SearchByMacDialog = ({ handleClose }: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)

    const [cpe, setCpe] = useState<CPE | null>(null)

    const onSubmit = useCallback(
        async (cpeForm: CPEForm) => {
            if (!site) return

            const { data } = await api.get<CPE>(`/api/site/${site.productId}/cpe/mac/${cpeForm.mac.replaceAll(":", "")}`)
            setCpe(data)

        },
        [site],
    )

    const {control, handleSubmit, setError} = useForm<CPEForm>({
        resolver: yupResolver(yup.object()),
        defaultValues: {
            mac: ""
        },
    })

    const {submit, isSubmitting, serverError, onError} = useFormSubmission<CPEForm>(onSubmit, setError)

    return (
        <Dialog open={true} onClose={handleClose} fullWidth={true}>
            <DialogTitle>
                {t('cpes.search-by-mac')}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'space-between'}>
                    <Grid item xs={12}>
                        <form className={'ant-form ant-form-vertical'} onSubmit={handleSubmit(submit, onError)} noValidate={true}>
                            <Grid container spacing={2} alignItems={'center'}>
                                <Grid item alignItems={'center'}>
                                    <TextInput readonly={false} label={t('cpes.detail.mac-address')} name={'mac'}
                                               control={control} required={true} maskProps={macProps}/>
                                </Grid>
                                <Grid item alignItems={'center'}>
                                    <SpinnableSubmitButton label={t('general.search')} showSpinner={isSubmitting}/>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>

                    <Grid item xs={12} alignSelf={'center'} width={'100%'} mt={2}>
                        {isSubmitting && (<Spinner size={36} />)}
                        {!isSubmitting && (serverError?.response?.status === 404) && (<>{t('general.no-data')}</>)}
                        {!isSubmitting && serverError && serverError?.response?.status !== 404 && (<ErrorAlert showAlert={true} />)}
                        {!isSubmitting && !!cpe && (
                            <DialogContentText>{t('cpes.found-cpe')}: <Link href={detailUrl.replace(':id', cpe.uuid)}>{formatCPEModel(cpe.model)}</Link></DialogContentText>
                        )}
                        {!isSubmitting && !serverError && !cpe && <DialogContentText>{t('general.no-data')}</DialogContentText>}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent={'flex-end'}>
                    <CancelButton title={t('general.close')} onClick={handleClose} />
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
