import React, {ReactElement, ReactNode, useCallback} from 'react'
import {PhoneUser, PhoneUserForm} from "../../app/types";
import {FormSection} from "../../common/components/SectionedForm";
import {api} from "../../common/api";
import {allowedTools, UserForm} from "../UserForm";
import {defaultCallRecording} from "../detail";
import {useSelector} from "react-redux";
import {callRecordingEnabledSelector, fmuEnabledSelector, selectedSiteSelector} from "../../authentication/redux";
import {useTranslation} from "react-i18next";
import PaperStack from "../../common/form/PaperStack";
import FormGroup from "../../common/form/FormGroup";
import {LabelValues, LabelValueType} from "../../common/components/LabelValues";
import {Link, List, ListItem} from "@mui/material";
import {formatCPE, formatPhoneNumber, formatUser} from "../../common/util/formatters";
import {FmuSwitchButton} from "../FmuSwitchButton";
import {find, prop, propEq, reject} from "ramda";
import {UserLink} from "../UserLink";
import PersonIcon from "@mui/icons-material/Person";
import {detailUrl} from "../../cpes";
import {SelectiveCallForwardDetail} from "../../common/components/SelectiveCallForwardDetail";
import {usePhoneNumbersToForwardTo} from "../../common/servercall/usePhoneNumbersToForwardTo";
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";
import {useAccessHelpers} from "../../common/util/hooks";

export const useGeneralSection = (userId: string, isFetching: boolean, error: any, refetch: () => void, data?: PhoneUser) : FormSection<PhoneUser> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const fmuEnabled = useSelector(fmuEnabledSelector)
    const callRecordingEnabled = useSelector(callRecordingEnabledSelector)
    const {phoneNumbersToForwardTo} = usePhoneNumbersToForwardTo(site)
    const {buildFormButtons} = useFormSubmissionButtons(false)
    const {hasWriteAccess} = useAccessHelpers()

    const onGeneralSubmit = useCallback((onSucceeded: () => void) =>
            async (formData: PhoneUserForm) => {
                if (!site) return
                await api.put(`/api/site/${site.productId}/user/${userId}`, {
                    postalCode: '',
                    ...formData,
                    nonCpeDevices: formData.gbtfProfile === 'INTERNAL'? [] : formData.nonCpeDevices?.filter(d => d),
                    busyLampUserIds: formData.busyLampUsers?.map(user => user.uuid).join(','),
                    permittedMonitorUserIds: formData.permittedMonitorUsers?.map(user => user.uuid).join(','),
                    fmuMsisdn: formData.gbtfProfile === 'INTERNAL' ? null : formData.fmuMsisdn,
                    welcomeMessageFileName: formData.gbtfProfile === 'INTERNAL' ? null : formData.welcomeMessageFileName,
                    callRecordingUserSettings: formData.gbtfProfile === 'INTERNAL' ? defaultCallRecording : formData.callRecordingUserSettings,
                    prioExternalCalls: formData.gbtfProfile === 'INTERNAL' ? false : formData.prioExternalCalls,
                    alternateNumbers: formData.alternateNumbers.map(nr => nr.number),
                })
                await refetch()
                onSucceeded();
            },
        [site, userId, refetch],
    )

    const transformUserToFormObject = useCallback((user: PhoneUser): PhoneUserForm => {
        return {
            ...user,
            cpeId: find(propEq('deviceType', 'CPE'), user?.devices ? user.devices : [])?.cpeId,
            nonCpeDevices: allowedTools.map(it => (user.devices.some(device => device.deviceType === it.value) ? it.value : null)),
        }
    }, [])

    const renderGeneralForm = useCallback((user: PhoneUser, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode => <UserForm user={transformUserToFormObject(user)} buildFormButtons={buildFormButtons(cancelEditButton)}
                                                                                                                                             onSubmit={onGeneralSubmit(onSucceeded)} />, [onGeneralSubmit, transformUserToFormObject, buildFormButtons])
    const infoLabelValues = useCallback(
        (value: PhoneUser): LabelValueType[] => [
            { label: t('users.edit.lastName'), value: <>{value.lastName}</> },
            { label: t('users.edit.firstName'), value: <>{value.firstName}</> },
            { label: t('users.edit.email'), value: <>{value.webexEmail}</> },
            { label: t('users.edit.language'), value: <>{t(`users.language.${value.language}`)}</> },
            { label: t('users.edit.license'), value: <>{t(`users.license.${value.gbtfProfile.toLowerCase()}`)}</> },
        ],
        [t]
    )

    const numbersLabelValues = useCallback(
        (value: PhoneUser): LabelValueType[] => [
            {
                label: t('users.edit.number'),
                value: <>{value.number ? formatPhoneNumber(value.number) : t('general.none')}</>,
            },
            {
                label: t('users.edit.alternate-numbers'),
                value: <>{value.alternateNumbers.length > 0 ?
                    value.alternateNumbers.map(alternateNumber => formatPhoneNumber(alternateNumber.number)).join(", ")
                    : t('general.none')}</>,
            },
            {
                label: t('users.edit.outgoing-number'),
                value: <>{value.outgoingNumber ? formatPhoneNumber(value.outgoingNumber) : value.number ? formatPhoneNumber(value.number) : t('general.none')}</>,
            },
            { label: t('users.edit.extension'), value: <>{value.extension}</> },
            ...(fmuEnabled
                ? [
                    {
                        label: t('users.edit.fmu-msisdn'),
                        value: <>{value.fmuMsisdn ? formatPhoneNumber(value.fmuMsisdn) : t('general.none')}</>,
                    },
                ]
                : []),
        ],
        [t, fmuEnabled]
    )

    const servicesLabelValues = useCallback(
        (value: PhoneUser): LabelValueType[] => [
            {
                label: t('users.edit.ncos'),
                value: <>{t(`users.ncos-names.${value.ncos}`)}</>,
            },
            ...(data && data.gbtfProfile !== 'INTERNAL' ? [{
                label: t('flows.edit.welcome-message'),
                value: <>{value.welcomeMessageFileName || t('general.none')}</>,
            }] : []),
            ...(data && data.gbtfProfile !== 'INTERNAL' ? [{
                label: t('users.edit.prio-external-calls'),
                value: <>{value.prioExternalCalls ? t('general.yes') : t('general.no')}</>,
            }] : []),
            ...(fmuEnabled && data && data.fmuMsisdn && value.gbtfProfile !== 'INTERNAL'
                ? [
                    {
                        label: t('users.edit.fmu-business-mode'),
                        value: <FmuSwitchButton readonly={!hasWriteAccess} user={data} refetch={refetch} />,
                    },
                ]
                : []),
            ...(data && data.gbtfProfile !== 'INTERNAL' ? [{
                label: t('users.edit.optimized-for-teams'),
                value: <>{value.optimizedForTeams ? t('general.yes') : t('general.no')}</>,
            }] : []),
            ...(data && data.callStatus ? [{
                label: t('users.call-status.label'),
                value: <>{t(`users.call-status.${data.callStatus}`)}</>,
            }] : []),
        ],
        [t, fmuEnabled, data, refetch, hasWriteAccess]
    )

    const privacyLabelValues = useCallback(
        (value: PhoneUser): LabelValueType[] => [
            {
                label: t('users.edit.hide-from-phonebook'),
                value: <>{value.hideFromPhoneBook ? t('general.yes') : t('general.no')}</>,
            },
            {
                label: t('users.edit.hide-phone-status'),
                value: <>{value.hidePhoneStatus ? t('general.yes') : t('general.no')}</>,
            },
            // @ts-ignore
            ...(value.hidePhoneStatus ?
                [{
                    type: value.permittedMonitorUsers?.length > 0? 'FULL_LINE' : 'NORMAL',
                    label: t('users.edit.monitors'),
                    value: value.permittedMonitorUsers?.length > 0 ?
                        <List dense={true}>
                            {value.permittedMonitorUsers.map(it => (
                                <ListItem key={it.uuid}>
                                    <UserLink uuid={it.uuid} siteProductId={it.siteProductId} formattedUser={formatUser(it)}/>
                                </ListItem>
                            ))}
                       </List> : <>{t('general.none')}</>
                }]
                : []
            ),
        ],
        [t]
    )

    const cpeLabelValues = useCallback(
        (value: PhoneUser): LabelValueType[] => {
            const cpeDevice = find(propEq('deviceType', 'CPE'), value.devices)
            const nonCpeDevices = reject(propEq('deviceType', 'CPE'), value.devices)

            return [
                {
                    label: t('users.edit.cpe'),
                    value: <>{!!cpeDevice?.cpeId ? <Link href={detailUrl.replace(":id", cpeDevice.cpeId)}>
                        {formatCPE(cpeDevice)}
                    </Link> : t('general.none')}</>,
                },
                {
                    label: t('users.edit.allowed-tools'),
                    value: (
                        <>
                            {nonCpeDevices && nonCpeDevices.length
                                ? // @ts-ignore
                                nonCpeDevices.map(device => t(prop('label', find(propEq('value', device.deviceType), allowedTools)))).join(', ')
                                : t('general.none')}
                        </>
                    ),
                },
            ]
        },
        [t]
    )

    const callRecordingLabelValues = useCallback(
        (value: PhoneUser): LabelValueType[] => [
            {
                label: t('call-recording.mode'),
                value: <>{t(`call-recording.modes.${value.callRecordingUserSettings?.callRecordingMode}`)}</>,
            },
            {
                label: t('call-recording.play-announcement'),
                value: <>{value.callRecordingUserSettings?.playAnnouncement ? t('general.yes') : t('general.no')}</>,
            },
            {
                label: t('call-recording.record-voice-messaging'),
                value: <>{value.callRecordingUserSettings?.recordVoiceMessaging ? t('general.yes') : t('general.no')}</>,
            },
        ],
        [t]
    )

    const busyLampUsers = useCallback(
        (value: PhoneUser): LabelValueType[] => [
            {
                label: t('navigation.users'),
                value: (
                    value.busyLampUsers.length > 0 ? <List dense={false}>
                        {value.busyLampUsers.map(it => (
                            <ListItem key={it.uuid}>
                                <UserLink uuid={it.uuid} siteProductId={it.siteProductId} formattedUser={formatUser(it)}/>
                            </ListItem>
                        ))}
                    </List> : <>{t('general.none')}</>
                ),
                type: 'FULL_LINE',
            },
            {
                label: t('users.edit.blf-hide-caller-id'),
                value: <>{value.blfHideCallerId? t('general.yes') : t('general.no')}</>,
                subSectionId: 'multi-call-window'
            },
            {
                label: t('users.edit.blf-time-before-pickup-notification'),
                value: <>{`${value.blfNotificationDelayTime} ${t('general.seconds-abbrev')}`}</>,
                subSectionId: 'multi-call-window'
            },
        ],
        [t]
    )

    const renderGeneralDetails = useCallback(
        (user: PhoneUser): ReactNode => {
            return (
                <PaperStack>
                    <FormGroup key={1} label={t('users.edit.section.info')} fullWidth={true}>
                        <LabelValues labelValues={infoLabelValues(user)} />
                    </FormGroup>
                    <FormGroup key={2} label={t('users.edit.section.numbers')} fullWidth={true}>
                        <LabelValues labelValues={numbersLabelValues(user)} />
                    </FormGroup>
                    <FormGroup key={3} label={t('users.edit.section.privacy')} fullWidth={true}>
                        <LabelValues labelValues={privacyLabelValues(user)} />
                    </FormGroup>
                    <FormGroup key={3} label={t('users.edit.section.services')} fullWidth={true}>
                        <LabelValues labelValues={servicesLabelValues(user)} />
                    </FormGroup>
                    <FormGroup key={4} label={t('users.edit.section.cpe')} fullWidth={true}>
                        <LabelValues labelValues={cpeLabelValues(user)} />
                    </FormGroup>
                    <SelectiveCallForwardDetail key={6} selectiveCallForward={user.selectiveCallForward || null} nightServiceMode={null} fullWidth={true} phoneNumbersToForwardTo={phoneNumbersToForwardTo} />
                    {callRecordingEnabled ? <FormGroup key={7} label={t('call-recording.section-header')} fullWidth={true}>
                        <LabelValues labelValues={callRecordingLabelValues(user)}/>
                    </FormGroup> : <></>}
                    <FormGroup key={8} label={t('users.edit.section.busy-lamp-field')} fullWidth={true}>
                        <LabelValues labelValues={busyLampUsers(user)} subSections={[{id: 'multi-call-window', label: t('users.edit.blf-in-multi-call-window')}]} />
                    </FormGroup>
                </PaperStack>
            )
        },
        [callRecordingEnabled, privacyLabelValues, callRecordingLabelValues, infoLabelValues, numbersLabelValues, servicesLabelValues, cpeLabelValues, busyLampUsers, t, phoneNumbersToForwardTo]
    )

    return {
        title: t('general.general'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderGeneralDetails,
        renderForm: renderGeneralForm,
        enabled: () => true,
        icon: () => <PersonIcon/>
    }
}