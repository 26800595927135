import React from 'react'
import { Grid } from '@mui/material'

type Props = {
    children: any
    spacing?: number
}

export default function FormRow({ children, spacing }: Props) {
    return (
        <Grid container item spacing={spacing || 3} >
            {children}
        </Grid>
    )
}
