import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { compose, prop } from 'ramda'
import { RootState } from '../../../app/store'

export type Page = {
    label: string
    key: string
}

type State = {
    page: Page | null
}

const initialState: State = {
    page: null,
}

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setPage(state, action: PayloadAction<Page | null>) {
            state.page = action.payload
        },
    },
})

export const navigationSelector: (state: RootState) => State = prop('navigation')

export const currentPageSelector: (state: RootState) => null | Page = compose(prop('page'), navigationSelector)

export const { setPage } = navigationSlice.actions
export const reducer = navigationSlice.reducer
