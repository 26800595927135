import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import {PhoneUser, ServerPage} from '../app/types'
import {useSelector} from 'react-redux'
import {
    callRecordingEnabledSelector,
    fmuEnabledSelector,
    hotDeskEnabledSelector,
    selectedSiteSelector
} from '../authentication/redux'
import {api, buildPaginatedUrl} from '../common/api'
import {find, propEq} from 'ramda'
import DeletionModal from '../common/components/DeletionModal'
import ErrorAlert from '../common/components/ErrorAlert'
import TableRowActionsMenu from '../common/components/table/TableRowActionsMenu'
import {AddUser} from './add'
import {useHistory} from 'react-router'
import {RouteDefinition} from '../app/App'
import {AddButton} from '../common/components/AddButton'
import {SearchField} from '../common/components/SearchField'
import {DetailUser} from './detail'
import {formatCPE, formatPhoneNumber} from '../common/util/formatters'
import {sorterHeader} from '../common/components/table/SorterHeader'
import {ColumnDefinition, InfiniteScrollTable} from '../common/components/table/Table'
import {Sort, useInfiniteScroll} from '../common/components/table/useInfiniteScroll'
import {Grid, Link, ListItemIcon, ListItemText, Menu} from '@mui/material'
import {detailUrl as cpeDetailUrl} from '../cpes/index'
import PinCodeModal from './PinCodeModal'
import CheckIcon from "@mui/icons-material/Check";
import {isConfigurableModel} from "../cpes/util/CPEHelpers";
import PersonIcon from "@mui/icons-material/Person";
import {useAccessHelpers, useUrlHelpers} from "../common/util/hooks";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuItem from "@mui/material/MenuItem";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {useOktaAuth} from "@okta/okta-react";

export const detailUrl = '/users/:id'
export const addUrl = '/users/new'

export const routes: RouteDefinition[] = [
    {
        href: addUrl,
        component: AddUser,
        id: 'user-add',
        parentId: 'user-overview',
        translationKey: 'users.add.title',
    },
    {
        href: detailUrl,
        component: DetailUser,
        id: 'user-detail',
        parentId: 'user-overview',
    },
].map(routeDefinition => ({ ...routeDefinition, menuId: 'user-overview' }))

export const Users = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const fmuEnabled = useSelector(fmuEnabledSelector)
    const hotDeskEnabled = useSelector(hotDeskEnabledSelector)
    const callRecordingEnabled = useSelector(callRecordingEnabledSelector)
    const site = useSelector(selectedSiteSelector)
    const [userToDelete, setUserToDelete] = useState<PhoneUser | null>(null)
    const [userToUpdatePinCode, setUserToUpdatePinCode] = useState<PhoneUser | null>(null)
    const {hasWriteAccess, onlyWithWriteAccess} = useAccessHelpers()
    const {createUrl} = useUrlHelpers();

    const fetchUsers = useCallback(
        async (pageParam = 0, sortParam: Sort, searchTermParam: string | null): Promise<ServerPage<PhoneUser>> => {
            if (!site) return { content: [], totalPages: 0, number: 0, totalElements: 0 }
            const { data } = await api.get(buildPaginatedUrl(`/api/site/${site.productId}/users`, pageParam, sortParam, searchTermParam))
            return data
        },
        [site]
    )

    const { sort, setSort, setSearchTerm, rows, error, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteScroll<PhoneUser>({
        queryKey: `users-${site?.productId}`,
        initialSort: { field: 'extension', order: 'asc' },
        fetchFunction: fetchUsers,
    })

    const rowActions = useMemo(
        () => [
            {
                key: hasWriteAccess? 'edit' : 'details',
                text: t('general.general'),
                onClick: (record: PhoneUser) => {
                    history.push(detailUrl.replace(':id', record.uuid) + (hasWriteAccess ? '?edit=true#0' : '?#0'))
                },
                icon: () => <PersonIcon/>
            },
            {
                key: 'edit-incoming-calls-settings',
                text: t('users.sections.incoming-calls'),
                onClick: (record: PhoneUser) => {
                    history.push(detailUrl.replace(':id', record.uuid) + (hasWriteAccess ?  '?edit=true#1' : '?#1'))
                },
                disabled: (record: PhoneUser) => record.gbtfProfile === 'INTERNAL',
            },
            {
                key: 'edit-outgoing-calls-settings',
                text: t('users.sections.outgoing-calls'),
                onClick: (record: PhoneUser) => {
                    history.push(detailUrl.replace(':id', record.uuid) + (hasWriteAccess ?  '?edit=true#2' : '?#2') )
                },
                disabled: (record: PhoneUser) => record.gbtfProfile === 'INTERNAL'
            },
            {
                key: 'edit-voicemail-settings',
                text: t('users.voicemail-settings.title'),
                onClick: (record: PhoneUser) => {
                    history.push(detailUrl.replace(':id', record.uuid) + (hasWriteAccess ?  '?edit=true#3' : '?#3'))
                },
                disabled: (record: PhoneUser) => record.gbtfProfile === 'INTERNAL'
            },
            {
                key: 'edit-call-control-settings',
                text: t('users.sections.call-control'),
                onClick: (record: PhoneUser) => {
                    history.push(detailUrl.replace(':id', record.uuid) + (hasWriteAccess ?  '?edit=true#4' : '?#4') )
                },
                disabled: (record: PhoneUser) => record.gbtfProfile === 'INTERNAL'
            },
            {
                key: 'edit-cpe-config',
                text: t('users.sections.cpe-settings'),
                onClick: (record: PhoneUser) => {
                    history.push(detailUrl.replace(':id', record.uuid) + (hasWriteAccess ?  '?edit=true#5' : '?#5') )
                },
                disabled: (record: PhoneUser) => !isConfigurableModel(find(propEq('deviceType', 'CPE'), record?.devices || [])?.model)
            },
            {
                key: 'hot-desk',
                text: t('users.sections.hot-desk'),
                onClick: (record: PhoneUser) => {
                    history.push(detailUrl.replace(':id', record.uuid) + (hasWriteAccess ? '?edit=true#6' : '?#6'))
                },
                disabled: (record: PhoneUser) => !hotDeskEnabled || !record.eligibleForHotDesking,
            },
            {
                key: 'pincode',
                text: t('users.update-voicemail-pin-code'),
                onClick: (record: PhoneUser) => {
                    setUserToUpdatePinCode(record)
                },
                dividerAbove: true,
                needsWriteAccess: true
            },
            {
                key: 'delete',
                text: t('general.delete'),
                onClick: (record: PhoneUser) => {
                    setUserToDelete(record)
                },
                dividerAbove: true,
                needsWriteAccess: true
            },
        ],
        [t, history, hasWriteAccess, hotDeskEnabled]
    )

    // @ts-ignore
    const columns: ColumnDefinition<PhoneUser>[] = useMemo(
        () => [
            {
                title: t('users.table.name'),
                key: 'lastName',
                sorter: true,
                render: (text: string, record: PhoneUser) => <Link href={detailUrl.replace(':id', record.uuid)}>{`${record.lastName} ${record.firstName}`}</Link>,
            },
            {
                title: t('users.table.extension'),
                key: 'extension',
                sorter: true,
                render: (text: string) => <>{text}</>,
            },
            {
                title: t('users.table.number'),
                key: 'number',
                sorter: true,
                defaultSortOrder: 'ascend' as 'ascend',
                render: (text: string) => <>{formatPhoneNumber(text)}</>,
            },
            ...(fmuEnabled ? [{
                title: t('users.table.fmu-msisdn'),
                key: 'fmuMsisdn',
                sorter: true,
                defaultSortOrder: 'ascend' as 'ascend',
                render: (text: string) => <>{formatPhoneNumber(text)}</>,
            }]: []),
            ...(callRecordingEnabled ? [{
                title: t('site.cards.user-options.types.call_recording'),
                key: 'callRecordingUserSettings',
                sorter: false,
                render: (text: object, record: PhoneUser) => <>{record?.callRecordingUserSettings?.callRecordingMode === 'NEVER' ? '' :  <CheckIcon color={'success'}/>}</>,
            }]: []),
            {
                title: t('users.table.e-mail'),
                key: 'webexEmail',
                sorter: true,
                render: (text: string) => <>{text}</>,
            },
            {
                title: t('users.table.license'),
                key: 'gbtfProfile',
                sorter: false,
                render: (text: string) => <>{text ? t(`users.license.${text.toLowerCase()}`) : ''}</>,
            },
            {
                title: t('users.table.cpe'),
                key: 'devices',
                sorter: false,
                render: (text: string, record: PhoneUser) => {
                    const cpeDevice = find(propEq('deviceType', 'CPE'), record.devices)
                    return cpeDevice?.cpeId ? <Link href={cpeDetailUrl.replace(':id', cpeDevice.cpeId)}>{formatCPE(cpeDevice)}</Link> : <></>
                },
            },
            {
                title: '',
                key: 'uuid',
                sorter: false,
                render: (text: string, record: PhoneUser) => <TableRowActionsMenu record={record} menuItems={rowActions} readOnly={!hasWriteAccess} />,
            },
        ],
        [t, rowActions, fmuEnabled, callRecordingEnabled, hasWriteAccess]
    )

    const sorter = useCallback(col => sorterHeader(col, sort, setSort), [sort, setSort])

    const getRowKey = useCallback(user => user.uuid, [])

    const onSearch = useCallback(async (searchTerm: string) => setSearchTerm(searchTerm), [setSearchTerm])

    const getDeleteUrl = useCallback(u => (site && u ? `/api/site/${site.productId}/user/${u.uuid}?forceWithoutWarning=false` : null), [site])

    const getDeleteAfterWarningUrl = useCallback(u => (site && u ? `/api/site/${site.productId}/user/${u.uuid}?forceWithoutWarning=true` : null), [site])

    const addButton = useMemo(
        () => (
            onlyWithWriteAccess(
                <Grid item>
                    <AddButton
                        onClick={() => {
                            history.push(addUrl)
                            return null
                        }}
                        label={`${t('users.create-new')}`}
                    />
                </Grid>)
            ),
        [history, t, onlyWithWriteAccess]
    )

    const onUpdatePinCode = useCallback(
        async (newPinCode: string) => {
            if (!site || !userToUpdatePinCode) return
            await api.put(`/api/site/${site.productId}/user/${userToUpdatePinCode.uuid}/update-voicemail-pincode`, { pinCode: newPinCode })
            setUserToUpdatePinCode(null)
        },
        [userToUpdatePinCode, site]
    )

    const oktaAuth = useOktaAuth();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const buttonPanel = useMemo(
        () => (
            <Grid container item justifyContent={'space-between'} flexDirection={'row'} spacing={2} flexWrap={'nowrap'}>
                {addButton}
                <Grid item xs={'auto'} pr={1}>
                    <IconButton color="secondary" onClick={handleClick}>
                        <SettingsIcon/>
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={async () => {
                            const url = createUrl( 'user/download')
                            fetch(url, {
                                method: 'GET',
                                headers: {
                                    ...(oktaAuth?.authState?.isAuthenticated? {"authorization" : `Bearer ${oktaAuth?.authState?.accessToken?.accessToken}`} : {})
                                },
                            })
                                .then(response => response.blob())
                                .then(blob => {
                                    // Create blob link to download
                                    const url = window.URL.createObjectURL(new Blob([blob]))
                                    const link = document.createElement('a')
                                    link.href = url
                                    link.setAttribute('download', 'u_' + (site? site.productId : '') + '.csv')

                                    document.body.appendChild(link)
                                    link.click()
                                    link.parentNode?.removeChild(link)
                                })
                        }}>
                            <ListItemIcon>
                                <FileUploadIcon/>
                            </ListItemIcon>
                            <ListItemText>{t("phonebook.export-csv-title")}</ListItemText>
                        </MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        ),
        [t,site, addButton, createUrl,
            oktaAuth?.authState?.accessToken?.accessToken, oktaAuth?.authState?.isAuthenticated,
            anchorEl, handleClose
        ]
    )

    return (
        <>
            <TitledBody title={t('users.title')} buttonsPanel={buttonPanel}>
                <SearchField onSearch={onSearch} />
                <ErrorAlert showAlert={!!error} />
                <InfiniteScrollTable<PhoneUser>
                    hasNextPage={hasNextPage}
                    data={rows}
                    fetchNextPage={fetchNextPage}
                    isFetching={isFetching}
                    getRowKey={getRowKey}
                    sorter={sorter}
                    columns={columns}
                    tableId={'userstable'}
                />
                <DeletionModal<PhoneUser | null>
                    itemToDelete={userToDelete}
                    deleteUrl={getDeleteUrl}
                    deleteAfterWarningUrl={getDeleteAfterWarningUrl}
                    onClose={() => setUserToDelete(null)}
                    onSuccess={refetch}
                />
                <PinCodeModal
                    title={t('users.update-voicemail-pin-code')}
                    text={''}
                    onSubmit={onUpdatePinCode}
                    onClose={() => setUserToUpdatePinCode(null)}
                    buttonLabel={t('general.form-update')}
                    open={!!userToUpdatePinCode}
                />
            </TitledBody>
        </>
    )
}
