import { configureStore } from '@reduxjs/toolkit'
import { reducer as authentication } from '../authentication/redux'
import { reducer as navigation } from './navigation/redux'

export const store = configureStore({
    reducer: {
        authentication,
        navigation,
    },
})

export type RootState = ReturnType<typeof store.getState>
