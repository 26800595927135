import {CallForwarding, OutOfOffice, SequentialRing, UserIncomingCalls} from "../../app/types";
import {FormSection} from "../../common/components/SectionedForm";
import {TFunction, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import React, {ReactElement, ReactNode, useCallback} from "react";
import {api} from "../../common/api";
import {useFetchData} from "../../common/servercall/useFetchData";
import {LabelValues, LabelValueType} from "../../common/components/LabelValues";
import {List, ListItem} from "@mui/material";
import PaperStack from "../../common/form/PaperStack";
import FormGroup from "../../common/form/FormGroup";
import {formatIsoDate} from "../../schedules/util/scheduleHelpers";
import {IncomingCallsForm} from "./incomingCallsForm";
import {addHours, format, isToday, startOfDay} from "date-fns";
import {PhoneCallback} from "@mui/icons-material";
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";

const activeNotActive = (active: boolean, t: TFunction) : string => t(`users.edit.${!active? 'not-' : ''}active`)

const calculateUntil = (outOfOffice: OutOfOffice): string | null => {
    const expDate = outOfOffice.expirationTimeAsDate;
    if (!expDate) {
        return null
    }
    let calcDate = expDate;
    if (['LUNCH', 'MEETING'].includes(outOfOffice.presence)) {
        if (isToday(expDate)) {
            calcDate = addHours(new Date(), 1)
        }
    } else {
        calcDate = startOfDay(expDate)
    }
    return  format(calcDate, "yyyy-MM-dd'T'HH:mm:ss")
}

export const useIncomingCallsSection = (userId: string, enabled: boolean) : FormSection<UserIncomingCalls> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const {buildFormButtons} = useFormSubmissionButtons(false)

    const fetchUserIncomingCalls = useCallback(
        async (userId: string): Promise<UserIncomingCalls | undefined> => {
            if (!site || !enabled) return
            const { data } = await api.get<UserIncomingCalls>(`/api/site/${site.productId}/user/${userId}/incoming-calls-settings`)
            const expTime = data?.outOfOffice?.expirationTime
            return {...data, outOfOffice: {...data.outOfOffice, expirationTimeAsDate: expTime ? new Date(expTime) : null }}
        },
        [site, enabled]
    )
    const { data, isFetching, error, refetch } = useFetchData<UserIncomingCalls>(useCallback(() => fetchUserIncomingCalls(userId), [fetchUserIncomingCalls, userId]))

    const onSubmit = useCallback((onSucceeded: () => void) =>
            async (formData: UserIncomingCalls) => {
                if (!site) return
                await api.put(`/api/site/${site.productId}/user/${userId}/incoming-calls-settings`, {
                    ...formData,
                    outOfOffice: {
                        ...formData.outOfOffice,
                        expirationTime: calculateUntil(formData.outOfOffice),
                        attendantNumber: formData.outOfOffice.enableTransferToAttendant ?  formData.outOfOffice.attendantNumber : null
                    },
                    callForwarding : {
                        ...formData.callForwarding,
                        forwardingAlwaysNumber: formData.callForwarding.forwardingAlwaysActive ?  formData.callForwarding.forwardingAlwaysNumber : null,
                        forwardingBusyNumber: formData.callForwarding.forwardingBusyActive ?  formData.callForwarding.forwardingBusyNumber : null,
                        forwardingNoAnswerNumber: formData.callForwarding.forwardingNoAnswerActive ?  formData.callForwarding.forwardingNoAnswerNumber : null,
                        forwardingNotReachableNumber: formData.callForwarding.forwardingNotReachableActive ?  formData.callForwarding.forwardingNotReachableNumber : null,
                    },
                    sequentialRingNumbers : {
                        ...formData.sequentialRingNumbers,
                        criteriaActivations: formData.sequentialRingNumbers.criteriaActivations.map(
                            it => ({...it, activated: formData.sequentialRingNumbers.activeCriteria.includes(it.name) })
                        )
                    }
                })
                await refetch()
                onSucceeded();
            },
        [site, userId, refetch],
    )

    const generalLabelValues = useCallback(
        (value: UserIncomingCalls): LabelValueType[] => {
            return [
                { label: t('users.edit.do-not-disturb'), value: <>{activeNotActive(value.doNotDisturbEnabled, t)}</> },
                { label: t('users.edit.call-waiting'), value: <>{activeNotActive(value.callWaitingEnabled, t)}</> },
                { label: t('users.edit.anonymous-call-rejection'), value: <>{activeNotActive(value.anonymousCallRejectionEnabled, t)}</> },
            ]}, [t]
    )

    const outOfOfficeLabelValues = useCallback(
        (value: OutOfOffice): LabelValueType[] => {
            return [
                {
                    label: t('users.edit.out-of-office.availability'),
                    value: <>{`${t(`users.edit.out-of-office.availability-types.${value.presence}`)} ${value.expirationTime ? `${t("users.edit.out-of-office.until")} ${formatIsoDate(value.expirationTime)}` : ''}`}</>
                },
                {
                    label: t('users.edit.out-of-office.transfer'),
                    value: <>{value.enableTransferToAttendant ? value.attendantNumber : t('users.edit.not-active')}</>
                },
                {
                    label: t('users.edit.out-of-office.ring-splash'),
                    value: <>{activeNotActive(value.enableRingSplash, t)}</>
                },
            ]}, [t]
    )

    const callForwardingLabelValues = useCallback(
        (callForwarding: CallForwarding): LabelValueType[] => {
            return [
                { label: t('users.edit.call-forwarding.always'), value: <>{callForwarding.forwardingAlwaysActive? callForwarding.forwardingAlwaysNumber : t('users.edit.not-active') }</> },
                { label: t('users.edit.call-forwarding.no-answer'), value: <>{callForwarding.forwardingNoAnswerActive? `${callForwarding.forwardingNoAnswerNumber} (${callForwarding.forwardingNoAnswerNumberOfRings} ${t('users.edit.call-forwarding.rings')})` : t('users.edit.not-active') }</> },
                { label: t('users.edit.call-forwarding.busy'), value: <>{callForwarding.forwardingBusyActive? callForwarding.forwardingBusyNumber : t('users.edit.not-active') }</> },
                { label: t('users.edit.call-forwarding.not-reachable'), value: <>{callForwarding.forwardingNotReachableActive? callForwarding.forwardingNotReachableNumber : t('users.edit.not-active') }</> }
            ]}, [t]
    )

    const sequentialRingLabelValues = useCallback(
        (sequentialRing: SequentialRing): LabelValueType[] => {
            return [
                {
                    label: t('users.edit.sequential-ring.base-location'),
                    value: <>{sequentialRing.ringBaseLocationFirst? `${sequentialRing.baseLocationNumberOfRings} ${t('users.edit.sequential-ring.rings')} ${sequentialRing.continueIfBaseLocationIsBusy ? `(${t('users.edit.sequential-ring.continue-if-busy')})` : ''}` : t('users.edit.sequential-ring.base-location-not-active') }</>
                },
                {
                    label: t('users.edit.sequential-ring.caller-may-cancel'),
                    value: <>{sequentialRing.callerMayStopSearch? t('general.yes'): t('general.no')}</>
                },
                {
                    label: t('users.edit.sequential-ring.when-to-ring'),
                    value: <>{sequentialRing.criteriaActivations.filter(act => act.activated).map(act => act.name).join(", ")}</>
                },
                {
                    label: t('users.edit.sequential-ring.ring-numbers'),
                    value: (
                        <List dense={false}>
                            {sequentialRing.locations.map(it => (
                                <ListItem key={it.phoneNumber}>
                                    <>{`${it.phoneNumber} (${it.numberOfRings} ${t('users.edit.sequential-ring.rings')}${it.answerConfirmationRequired ? `, ${t('users.edit.sequential-ring.answer-confirmation-required')}` : '' })`}</>
                                </ListItem>
                            ))}
                        </List>
                    ),
                    type: 'FULL_LINE',
                },

            ]}, [t]
    )

    const renderIncomingCallsDetails = useCallback(
        (value: UserIncomingCalls): ReactNode => {
            return (
                <PaperStack>
                    <FormGroup key={1} label={t('general.general')} fullWidth={true}>
                        <LabelValues labelValues={generalLabelValues(value)} />
                    </FormGroup>
                    {!!value.outOfOffice.presence ?
                        <FormGroup key={1} label={t('users.edit.out-of-office.title')} fullWidth={true}>
                            <LabelValues labelValues={outOfOfficeLabelValues(value.outOfOffice)} />
                        </FormGroup> : <></>
                    }
                    <FormGroup key={2} label={t('users.edit.call-forwarding.title')} fullWidth={true}>
                        <LabelValues labelValues={callForwardingLabelValues(value.callForwarding)} />
                    </FormGroup>
                    <FormGroup key={3} label={t('users.edit.sequential-ring.title')} fullWidth={true}>
                        <LabelValues labelValues={sequentialRingLabelValues(value.sequentialRingNumbers)} />
                    </FormGroup>
                </PaperStack>
            )
        },
        [callForwardingLabelValues,  t, generalLabelValues, outOfOfficeLabelValues, sequentialRingLabelValues ]
    )

    const renderForm = useCallback((incomingCalls: UserIncomingCalls, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode =>
            <IncomingCallsForm incomingCalls={incomingCalls} buildFormButtons={buildFormButtons(cancelEditButton)} onSubmit={onSubmit(onSucceeded)} />,
        [onSubmit, buildFormButtons])

    return {
        title: t('users.sections.incoming-calls'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderIncomingCallsDetails,
        renderForm: renderForm,
        enabled: () => enabled,
        icon: () => <PhoneCallback />
    }
}