import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../../common/components/TitledBody'
import {CallPickupGroup} from '../../app/types'
import {api} from '../../common/api'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import {useHistory} from 'react-router'
import Form from './Form'
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";
import CancelButton from "../../common/form/CancelButton";
import {Grid} from "@mui/material";

const initialCallPickupGroup: CallPickupGroup = {
    uuid: '',
    name: '',
    users: [],
}

export const AddCallPickupGroup = () => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const history = useHistory()
    const {buildFormButtons} = useFormSubmissionButtons(true)

    const onSubmit = useCallback(
        async (formData: CallPickupGroup) => {
            if (!site) return
            await api.post(`/api/site/${site.productId}/callpickupgroup`, {
                ...formData,
                userIds: formData.users?.map(user => user.uuid).join(','),
            })
            history.push('/call-pickup-groups')
        },
        [site, history],
    )

    return (
        <TitledBody title={t('call-pickup-groups.add.title')}>
            <Grid container>
                <Grid item xs={12} lg={9}>
                    <Form onSubmit={onSubmit} callPickupGroup={initialCallPickupGroup} buildFormButtons={buildFormButtons(<CancelButton/>)}/>
                </Grid>
            </Grid>
        </TitledBody>
    )
}
