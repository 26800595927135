import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PhoneNumber, ServerPage } from '../app/types'
import { useSelector } from 'react-redux'
import {fmuEnabledSelector, selectedSiteSelector} from '../authentication/redux'
import { api, buildPaginatedUrl } from '../common/api'
import ErrorAlert from '../common/components/ErrorAlert'
import { SearchField } from '../common/components/SearchField'
import { sorterHeader } from '../common/components/table/SorterHeader'
import { ColumnDefinition, InfiniteScrollTable } from '../common/components/table/Table'
import { Sort, useInfiniteScroll } from '../common/components/table/useInfiniteScroll'
import { detailUrl as userDetailUrl } from '../users/index'
import { detailUrl as flowDetailUrl } from '../flows/index'
import { detailUrl as ivrDetailUrl } from '../ivrs/index'
import { detailUrl as cpeDetailUrl } from '../cpes/index'
import { Link } from '@mui/material'
import { formatPhoneNumber } from '../common/util/formatters'

type Props = {
    type: string
}
export const NumbersTable = ({ type }: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const fmuEnabled = useSelector(fmuEnabledSelector)

    const fetchNumbers = useCallback(
        async (pageParam = 0, sortParam: Sort, searchTermParam: string | null): Promise<ServerPage<PhoneNumber>> => {
            if (!site) return { content: [], totalPages: 0, number: 0, totalElements: 0 }
            const { data } = await api.get(buildPaginatedUrl(`/api/site/${site.productId}/numbers/` + type, pageParam, sortParam, searchTermParam, type === 'all' ? 10000 : 20))
            return data
        },
        [site, type]
    )

    const { sort, setSort, setSearchTerm, rows, error, fetchNextPage, hasNextPage, isFetching } = useInfiniteScroll<PhoneNumber>({
        queryKey: `numbers-${type}-${site?.productId}`,
        initialSort: { field: 'number', order: 'desc' },
        fetchFunction: fetchNumbers,
    })

    // @ts-ignore
    const columns: ColumnDefinition<PhoneNumber>[] = useMemo(
        () => [
            {
                title: t('numbers.table.number'),
                key: 'number',
                sorter: true,
                render: (text: string, record: PhoneNumber) =>
                    record.type ? (
                        <Link
                            href={
                                record.type === 'user'
                                    ? userDetailUrl.replace(':id', record.uuid)
                                    : record.type === 'ivr'
                                    ? ivrDetailUrl.replace(':id', record.uuid)
                                    : record.type === 'alternate_number_user'
                                    ? userDetailUrl.replace(':id', record.uuid)
                                    : record.type === 'alternate_number_ivr'
                                    ? ivrDetailUrl.replace(':id', record.uuid)
                                    : flowDetailUrl.replace(':id', record.uuid)
                            }>
                            {formatPhoneNumber(text)}
                        </Link>
                    ) : (
                        <>{formatPhoneNumber(text)}</>
                    ),
            },
            ...(fmuEnabled ? [{
                title: t('users.table.fmu-msisdn'),
                key: 'fmuMsisdn',
                sorter: true,
                render: (text: string) => <>{formatPhoneNumber(text)}</>,
            }] : []),
            {
                title: t('users.table.extension'),
                key: 'extension',
                sorter: true,
                render: (text: string, record: PhoneNumber) =>
                    record.type ? (
                        <Link
                            href={
                                record.type === 'user'
                                    ? userDetailUrl.replace(':id', record.uuid)
                                    : record.type === 'ivr'
                                    ? ivrDetailUrl.replace(':id', record.uuid)
                                    : record.type === 'hot_desk'
                                    ? cpeDetailUrl.replace(':id', record.uuid)
                                    : flowDetailUrl.replace(':id', record.uuid)
                            }>
                            {text}
                        </Link>
                    ) : (
                        <>{text}</>
                    ),
            },
            {
                title: t('numbers.table.type'),
                key: 'type',
                sorter: false,
                render: (text: string) => <>{text ? t(`numbers.types.${text}`) : ''}</>,
            },
            {
                title: t('numbers.table.assigned-to'),
                key: 'assignedTo',
                sorter: false,
                render: (text: string) => <>{text}</>,
            },
        ],
        [t, fmuEnabled]
    )

    const sorter = useCallback(col => sorterHeader(col, sort, setSort), [sort, setSort])

    const getRowKey = useCallback(cpe => cpe.uuid + '+' + cpe.number, [])

    const onSearch = useCallback(async (searchTerm: string) => setSearchTerm(searchTerm), [setSearchTerm])

    return (
        <>
            <SearchField key={`search-numbers-${type}`} onSearch={onSearch} />
            <ErrorAlert key={`search-numbers-errpor-${type}`} showAlert={!!error} />
            <InfiniteScrollTable<PhoneNumber>
                maxHeight={'55vh'}
                hasNextPage={hasNextPage}
                data={rows}
                fetchNextPage={fetchNextPage}
                isFetching={isFetching}
                getRowKey={getRowKey}
                sorter={sorter}
                columns={columns}
                tableId={'numbers-' + type}
            />
        </>
    )
}
