import React, {ReactElement, ReactNode, useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Elements} from 'react-flow-renderer'

import {IvrMenuConfig, IvrOperator, IvrSubMenu, IvrSubMenuFormFriendly} from '../../app/types'
import ErrorAlert from '../../common/components/ErrorAlert'
import {createGraphElements} from '../graph/IvrGraphHelpers'
import IvrGraph from '../graph/IvrGraph'
import PaperStack from '../../common/form/PaperStack'
import FormGroup from '../../common/form/FormGroup'
import {Box, Flex} from 'rebass'
import SlidingPane from 'react-sliding-pane'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import CloseIcon from '@mui/icons-material/Close'
import {IvrSubMenuForm} from './IvrSubMenuForm'
import produce from 'immer'
import {
    Alert,
    Button,
    FormLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Slide,
    Stack,
    Typography
} from '@mui/material'
import {
    addTransferToOperatorAction,
    findRoots,
    generateNewSubMenusFromSubmenuDefinition,
    hasTransferToOperatorOnZeroKey,
    transformSubMenuFormToSubMenu,
    transformSubMenuToForm,
    validate,
} from '../util/IvrHelpers'
import DeleteIcon from '@mui/icons-material/Delete'
import {useFormSubmission} from '../../common/servercall/useFormSubmission'
import {TransitionProps} from '@mui/material/transitions'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {ErrorOption} from 'react-hook-form/dist/types/errors'
import OperatorForm, {OperatorFormType} from './OperatorForm'
import {ModeEdit} from '@mui/icons-material'
import ConfirmationModal from '../../common/components/ConfirmationModal'
import FormButtons from "../../common/form/FormButtons";
import SpinnableSubmitButton from "../../common/form/SpinnableSubmitButton";

type Props = {
    initialMenuConfig: IvrMenuConfig
    operator: IvrOperator
    onOperatorUpdate: (operator: IvrOperator) => void
    onSubmit: (formData: IvrMenuConfig) => void
    CancelEditButton: ReactElement
}

type ContextProps = {
    menuConfig?: IvrMenuConfig
    setMenuConfig: (ivrMenuConfig: IvrMenuConfig) => void
}

export const IvrMenuConfigContext = React.createContext<ContextProps>({
    setMenuConfig: ivrMenuConfig => {},
})

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

const IvrMenuConfigFormScreen = ({ initialMenuConfig, onSubmit, operator, onOperatorUpdate, CancelEditButton }: Props) => {
    const { t } = useTranslation()
    const [operatorEditingMode, setOperatorEditingMode] = useState<boolean>(false)
    const [menuConfig, setMenuConfig] = useState<IvrMenuConfig>(initialMenuConfig)
    const value = { menuConfig, setMenuConfig }
    const [graphElements, setGraphElements] = useState<Elements>([])
    const [selectedIvrSubMenu, setSelectedIvrSubMenu] = useState<IvrSubMenu | null>()
    const [selectedSubMenuForm, setSelectedSubMenuForm] = useState<IvrSubMenuFormFriendly | null>()
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] } | null>(null)
    const [showAddTransferToOperatorActionModal, setShowAddTransferToOperatorActionModal] = useState<boolean>(false)

    useEffect(() => {
        setGraphElements([])
        setGraphElements(createGraphElements(false, menuConfig?.subMenus, validate(menuConfig, t, operator)))
    }, [menuConfig, t, operator])

    useEffect(() => {
        setSelectedSubMenuForm(transformSubMenuToForm(selectedIvrSubMenu || null))
    }, [selectedIvrSubMenu])

    const onSubMenuUpdate = (ivrSubMenuForm: IvrSubMenuFormFriendly) => {
        const ivrSubMenu = transformSubMenuFormToSubMenu(ivrSubMenuForm)
        const newSubMenus = generateNewSubMenusFromSubmenuDefinition(ivrSubMenuForm, menuConfig)
        setSelectedIvrSubMenu(null)
        //use Immer to avoid complex spreading
        setMenuConfig((prevConfig: IvrMenuConfig) =>
            produce(prevConfig, (draftState: IvrMenuConfig) => {
                selectedIvrSubMenu?.id && delete draftState.subMenus[selectedIvrSubMenu.id]
                draftState.subMenus[ivrSubMenu.id] = ivrSubMenu
                newSubMenus.forEach(newSubMenu => (draftState.subMenus[newSubMenu.id] = newSubMenu))
                Object.values(draftState.subMenus).forEach(subMenu => {
                    Object.values(subMenu.keyActions).forEach(keyAction => {
                        if (keyAction.gbtfIvrSubMenuAction === 'MENU' && keyAction.config === selectedIvrSubMenu?.id) {
                            subMenu.keyActions[keyAction.menuKey].config = ivrSubMenu.id
                        }
                    })
                })
            })
        )
    }

    //use Immer to avoid complex spreading
    const onSubMenuDelete = useCallback(() => {
        setMenuConfig((prevConfig: IvrMenuConfig) =>
            produce(prevConfig, (draftState: IvrMenuConfig) => {
                selectedIvrSubMenu?.id && delete draftState.subMenus[selectedIvrSubMenu.id]
                //delete all parent keyActions
                Object.values(draftState.subMenus).forEach(subMenu => {
                    Object.values(subMenu.keyActions).forEach(keyAction => {
                        if (keyAction.gbtfIvrSubMenuAction === 'MENU' && keyAction.config === selectedIvrSubMenu?.id) {
                            delete subMenu.keyActions[keyAction.menuKey]
                        }
                    })
                })
            })
        )
        setSelectedIvrSubMenu(null)
    }, [selectedIvrSubMenu])

    const IvrGraphMemo = useMemo(
        () => () => {
            return <IvrGraph elements={graphElements} onNodeClick={node => node.ivrSubMenu && setSelectedIvrSubMenu(node.ivrSubMenu)} />
        },
        [graphElements]
    )

    const setError = useCallback(
        (context: string, error: ErrorOption) => {
            const validationErrors = { [context.split('###')[0]]: [t(`general.form-error.${error.message}`)] }
            error?.message && setGraphElements(createGraphElements(false, menuConfig?.subMenus, validationErrors))
            setValidationErrors(validationErrors)
        },
        [menuConfig?.subMenus, t]
    )

    const { submit, isSubmitting, serverError } = useFormSubmission<IvrMenuConfig>(onSubmit, setError)

    const doSubmit = useCallback(() => {
        const errors = validate(menuConfig, t, operator)
        if (Object.keys(errors).length) {
            setValidationErrors(errors)
        } else {
            //use Immer to avoid complex spreading
            const configWithCorrectRoots = produce(menuConfig, (draftState: IvrMenuConfig) => {
                Object.values(draftState.subMenus).forEach(it => (it.businessHoursRoot = false))
                draftState.subMenus[findRoots(draftState)[0].id].businessHoursRoot = true
            })
            submit(configWithCorrectRoots)
        }
    }, [menuConfig, submit, t, operator])

    const onChangeOperator = useCallback(
        (operatorForm: OperatorFormType) => {
            onOperatorUpdate(operatorForm.operator)
            setOperatorEditingMode(false)
            if (!hasTransferToOperatorOnZeroKey(findRoots(menuConfig)[0])) {
                setShowAddTransferToOperatorActionModal(true)
            }
        },
        [menuConfig, onOperatorUpdate, setOperatorEditingMode, setShowAddTransferToOperatorActionModal]
    )

    const addOperatorAction = useCallback(() => {
        let root = findRoots(menuConfig)[0]
        setMenuConfig({
            subMenus: { ...menuConfig.subMenus, [root.id]: addTransferToOperatorAction(root) },
        })
    }, [menuConfig, setMenuConfig])

    const ValidationErrorDialog = useMemo(
        () => () =>
            (
                <Dialog open={!!validationErrors && Object.keys(validationErrors).length > 0} TransitionComponent={Transition} keepMounted onClose={() => setValidationErrors(null)}>
                    <DialogTitle>{t('ivrs.edit.validation-error-dialog.title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Alert variant="outlined" severity="error">
                                {t('ivrs.edit.validation-error-dialog.text')}
                                <List dense={true}>
                                    {validationErrors &&
                                        Object.keys(validationErrors).map(errorKey => (
                                            <ListItem key={errorKey}>
                                                <ListItemText primary={errorKey} secondary={validationErrors[errorKey][0]} />
                                            </ListItem>
                                        ))}
                                </List>
                            </Alert>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setValidationErrors(null)}>
                            {t('general.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            ),
        [validationErrors, t]
    )

    const nonOperatorEditing = useCallback(
        (operator: IvrOperator): ReactNode => {
            return (
                <Stack direction={'row'}>
                    <Box alignSelf={'center'} sx={{ paddingRight: 1 }}>
                        <Typography variant={'body1'} component={'span'}>
                            {operator.user ? `${operator.user.lastName}  ${operator.user.firstName}` : operator.answerGroup? operator.answerGroup.name : t('general.none')}
                        </Typography>
                    </Box>
                    <Box alignSelf={'center'}>
                        <IconButton
                            size={'small'}
                            color="secondary"
                            aria-label="Change main number"
                            component="span"
                            onClick={() => {
                                setOperatorEditingMode(true)
                            }}>
                            <ModeEdit />
                        </IconButton>
                    </Box>
                </Stack>
            )
        },
        [setOperatorEditingMode, t]
    )

    return (
        // @ts-ignore
        <IvrMenuConfigContext.Provider value={value}>
            <ErrorAlert showAlert={!!serverError} />
            {/*<Grid container spacing={3} sx={{ mb: 3 }}>
        <FormButtons fullWidth={true} buttons={[<AddButton onClick={() => {
          setSelectedIvrSubMenu({ id: '', businessHoursRoot: false, announcementFileName: '', keyActions: {} })
        }} label={t('ivrs.sub-menu-editor.create-sub-menu')} />]} />
      </Grid>*/}
            <PaperStack>
                <FormGroup fullWidth={true} key={1} label={''}>
                    <Grid item container direction="row" justifyContent="start" alignItems="center" spacing={0}>
                        <Grid item xs={12} md={3} lg={2} xl={2} sx={{ paddingRight: 1 }}>
                            <FormLabel>{`${t('ivrs.edit.operator')}:`}</FormLabel>
                        </Grid>
                        <Grid item xs>
                            <OperatorForm
                                open={operatorEditingMode}
                                onSuccess={onChangeOperator}
                                onCancel={() => {setOperatorEditingMode(false)}}
                                operatorForm={{ operator: operator }}
                            />
                            {nonOperatorEditing(operator)}
                        </Grid>
                    </Grid>
                </FormGroup>
                <FormGroup fullWidth={true} key={2} label={''}>
                    <Box mt={4} style={{ height: 450, width: '100%', backgroundColor: 'white' }}>
                        <IvrGraphMemo />
                    </Box>
                </FormGroup>
                <FormButtons fullWidth={true} buttons={[<SpinnableSubmitButton showSpinner={isSubmitting} label={t('general.form-update')} onClick={doSubmit} />, CancelEditButton]} />
            </PaperStack>
            <SlidingPane
                overlayClassName={'slide-pane__custom-overlay'}
                closeIcon={<CloseIcon fontSize={'large'} />}
                isOpen={!!selectedIvrSubMenu}
                subtitle={
                    <Flex justifyContent={'space-between'} mr={4}>
                        <Box>
                            <Typography variant="h6" align="left">
                                {selectedIvrSubMenu?.id ? t('ivrs.sub-menu-editor.title-edit') : t('ivrs.sub-menu-editor.title-create')}
                            </Typography>
                        </Box>
                        <Box>
                            {selectedSubMenuForm?.id && (
                                <IconButton aria-controls="demo-positioned-menu" size={'small'} onClick={onSubMenuDelete}>
                                    <DeleteIcon fontSize={'medium'} />
                                </IconButton>
                            )}
                        </Box>
                    </Flex>
                }
                width="700px"
                onRequestClose={() => {
                    setSelectedIvrSubMenu(null)
                }}>
                {!!selectedSubMenuForm && (
                    <IvrSubMenuForm
                        ivrSubMenu={selectedSubMenuForm}
                        onSubmit={onSubMenuUpdate}
                        onCancel={() => {
                            setSelectedIvrSubMenu(null)
                        }}
                    />
                )}
            </SlidingPane>
            <ValidationErrorDialog />
            <ConfirmationModal<IvrOperator | null>
                title={t('ivrs.edit.transfer-to-operator-modal.title')}
                text={item => t('ivrs.edit.transfer-to-operator-modal.text')}
                buttonLabel={t('ivrs.edit.transfer-to-operator-modal.button')}
                item={showAddTransferToOperatorActionModal ? operator : null}
                action={addOperatorAction}
                onClose={() => setShowAddTransferToOperatorActionModal(false)}
                onSuccess={() => setShowAddTransferToOperatorActionModal(false)}
            />
        </IvrMenuConfigContext.Provider>
    )
}

export default IvrMenuConfigFormScreen
