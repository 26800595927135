import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../../common/components/TitledBody'
import {Paging} from '../../app/types'
import {api} from '../../common/api'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import {useHistory} from 'react-router'
import PagingForm from './PagingForm'
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";
import CancelButton from "../../common/form/CancelButton";
import {Grid} from "@mui/material";

const initialPaging: Paging = {
    uuid: '',
    name: '',
    extension: '',
    originatorUsers: [],
    targetUsers: [],
}

export const AddPaging = () => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const history = useHistory()
    const {buildFormButtons} = useFormSubmissionButtons(true)

    const onSubmit = useCallback(
        async (formData: Paging) => {
            if (!site) return
            await api.post(`/api/site/${site.productId}/paging`, {
                ...formData,
                originatorUserIds: formData.originatorUsers?.map(user => user.uuid).join(','),
                targetUserIds: formData.targetUsers?.map(user => user.uuid).join(','),
            })
            history.push('/pagings')
        },
        [site, history]
    )

    return (
        <TitledBody title={t('pagings.add.title')}>
            <Grid container>
                <Grid item xs={12} lg={9}>
                    <PagingForm onSubmit={onSubmit} paging={initialPaging} buildFormButtons={buildFormButtons(<CancelButton/>)} />
                </Grid>
            </Grid>
        </TitledBody>
    )
}
