import React from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import {NumbersTable} from './NumbersTable'

export const Numbers = () => {
    const { t } = useTranslation()

    return (
        <>
            <TitledBody title={t('numbers.title')}>
                <NumbersTable key={`numbers-table-flows`} type={'all'} />
            </TitledBody>
        </>
    )
}
