import {AppBar, Grid, IconButton} from '@mui/material'
import {Box} from 'rebass'
import React, {ReactElement, useCallback, useEffect, useState} from 'react'
import {useInterval} from 'react-interval-hook'
import {api} from '../common/api'
import {Site} from './types'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'

export const useSiteStatus = ({
                                  site,
                                  refetchState,
                              }: { site: Site | null, refetchState: number }): { statusBar: () => ReactElement, warning: boolean } => {
    const [warning, setWarning] = useState<boolean>(false)
    const { t } = useTranslation()

    const pollFunction = useCallback(async () => {
        if (!site) return
        try {
            const { data } = await api.get(`/api/site/${site.productId}/status?dummy=` + refetchState)
            setWarning(data.nrOfQueueElements > 0 && data.ageOfOldestQueueElement > 10)
        } catch (e) {
            console.log(e)
        }
    }, [site, refetchState])

    const { start, stop } = useInterval(
        pollFunction,
        35000,
        {
            autoStart: false,
            immediate: true,
            selfCorrecting: false,
            onFinish: () => {
            },
        },
    )

    useEffect(() => {
        stop()
        start()
    }, [refetchState, site, start, stop])


    return {
        statusBar: () => warning ? <AppBar position='fixed' sx={{ p: 0, bgcolor: 'warning.main' }}>
            <Grid container justifyContent='center' spacing={0} sx={{ flexGrow: 1 }}>
                <Grid item xs={3} md={6} alignSelf={'center'}>
                    <Box display='flex' justifyContent='flex-end'>
                        <IconButton aria-label={'test'}>
                            <HourglassEmptyIcon fontSize={'small'} color={'secondary'} />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={9} md={6} alignSelf={'center'}>
                    <Typography variant='body1'>
                        {t('general.message-pending-changes')}
                    </Typography>
                </Grid>
            </Grid>
        </AppBar> : <> </>,
        warning: warning,
    }
}