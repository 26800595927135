import {SequentialRingLocation, UserIncomingCalls} from "../../app/types";
import {useTranslation} from "react-i18next";
import React, {ReactElement, useMemo} from "react";
import FormGroup from "../../common/form/FormGroup";
import ErrorAlert from "../../common/components/ErrorAlert";
import FormContainer from "../../common/form/FormContainer";
import {Control, useFieldArray, useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useFormSubmission} from "../../common/servercall/useFormSubmission";
import * as yup from "yup";
import {object} from "yup";
import FormFieldBox from "../../common/form/FormFieldBox";
import FormCheckbox from "../../common/form/FormCheckbox";
import FormRow from "../../common/form/FormRow";
import TextInput, {Option} from "../../common/form/TextInput";
import {Checkbox, FormControlLabel, Grid, IconButton, Paper} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {localeMap} from "../../schedules/holidays/Form";
import {startOfToday, startOfTomorrow} from "date-fns";

const schema = () =>
    yup.object({
        outOfOffice: yup.object({
            expirationTimeAsDate: yup.lazy(value => {
                return yup.object().when('presence', presence => {
                    if (['MEETING', 'LUNCH'].includes(presence)) {
                        return yup
                            .date()
                            .nullable()
                            .typeError('invalid-date')
                            .transform((curr: any, orig: any) => (orig === '' ? null : curr))
                            .min(startOfToday(), 'invalid-date')
                    } else {
                        return yup
                            .date()
                            .nullable()
                            .typeError('invalid-date')
                            .transform((curr: any, orig: any) => (orig === '' ? null : curr))
                            .min(startOfTomorrow(), 'invalid-date')
                    }
                })
            }),
            attendantNumber: yup
                .string()
                .when(['enableTransferToAttendant'], {
                    is: (enableTransferToAttendant: boolean) => enableTransferToAttendant,
                    then: schema => yup.string().trim().transform((curr) => {
                        return curr ? curr.replaceAll("-", "").replaceAll(" ", "") : null
                    }).max(30, 'too-long').matches(/^[0-9]*$/, {
                        message: 'invalid-phone-number',
                        excludeEmptyString: true,
                    }).required('required-m').nullable(),
                    otherwise: schema => yup.string().trim().nullable(),
                })
        }),
        callForwarding: yup.object({
            forwardingAlwaysNumber: yup
                .string()
                .when(['forwardingAlwaysActive'], {
                    is: (forwardingAlwaysActive: boolean) => forwardingAlwaysActive,
                    then: schema => yup.string().trim().transform((curr) => {
                        return curr ? curr.replaceAll("-", "").replaceAll(" ", "") : null
                    }).max(22, 'too-long').matches(/^[0-9]*$/, {
                        message: 'invalid-phone-number',
                        excludeEmptyString: true,
                    }).required('required-m').nullable(),
                    otherwise: schema => yup.string().trim().nullable(),
                }),
            forwardingBusyNumber: yup
                .string()
                .when(['forwardingBusyActive'], {
                    is: (forwardingBusyActive: boolean) => forwardingBusyActive,
                    then: schema => yup.string().trim().transform((curr) => {
                        return curr ? curr.replaceAll("-", "").replaceAll(" ", "") : null
                    }).max(22, 'too-long').matches(/^[0-9]*$/, {
                        message: 'invalid-phone-number',
                        excludeEmptyString: true,
                    }).required('required-m').nullable(),
                    otherwise: schema => yup.string().trim().nullable(),
                }),
            forwardingNoAnswerNumber: yup
                .string()
                .when(['forwardingNoAnswerActive'], {
                    is: (forwardingNoAnswerActive: boolean) => forwardingNoAnswerActive,
                    then: schema => yup.string().trim().transform((curr) => {
                        return curr ? curr.replaceAll("-", "").replaceAll(" ", "") : null
                    }).max(22, 'too-long').matches(/^[0-9]*$/, {
                        message: 'invalid-phone-number',
                        excludeEmptyString: true,
                    }).required('required-m').nullable(),
                    otherwise: schema => yup.string().trim().nullable(),
                }),
            forwardingNotReachableNumber: yup
                .string()
                .when(['forwardingNotReachableActive'], {
                    is: (forwardingNotReachableActive: boolean) => forwardingNotReachableActive,
                    then: schema => yup.string().trim().transform((curr) => {
                        return curr ? curr.replaceAll("-", "").replaceAll(" ", "") : null
                    }).max(22, 'too-long').matches(/^[0-9]*$/, {
                        message: 'invalid-phone-number',
                        excludeEmptyString: true,
                    }).required('required-m').nullable(),
                    otherwise: schema => yup.string().trim().nullable(),
                })
        }),
        sequentialRingNumbers: yup.object({
            locations:yup
                .array<SequentialRingLocation>()
                .of(
                    object().shape({
                        phoneNumber: yup.string().trim().transform((curr) => {
                            return curr ? curr.replaceAll("-", "").replaceAll(" ", "") : null
                        }).max(22, 'too-long').matches(/^[0-9]*$/, {
                            message: 'invalid-phone-number',
                            excludeEmptyString: true,
                        }).nullable(),
                    })
                )
        })
    })

type Props = {
    incomingCalls: UserIncomingCalls
    onSubmit: (formData: UserIncomingCalls) => void
    readonly?: boolean
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

export const IncomingCallsForm = ({ incomingCalls, onSubmit, readonly, buildFormButtons }: Props) => {
    const { t, i18n } = useTranslation()

    const { control, handleSubmit, setError, setValue, getFieldState, getValues } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(schema()),
        defaultValues: {
            ...incomingCalls,
            sequentialRingNumbers : {
                ...incomingCalls.sequentialRingNumbers,
                locations: incomingCalls.sequentialRingNumbers.locations.length ? incomingCalls.sequentialRingNumbers.locations : [
                    {phoneNumber: '', numberOfRings: 3, answerConfirmationRequired: true}
                ],
                activeCriteria: incomingCalls.sequentialRingNumbers.criteriaActivations.filter(it => it.activated).map(it => it.name)
            }
        },
    })

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<UserIncomingCalls>(onSubmit, setError)

    const availabilityOptions = useMemo(
        () =>
            ['NONE', "BUSINESS_TRIP", "GONE_FOR_THE_DAY", "LUNCH", "MEETING", "OUT_OF_OFFICE", "TEMPORARILY_OUT",
                "TRAINING", "UNAVAILABLE", "VACATION"].map(av => ({
                value: av,
                label: t(`users.edit.out-of-office.availability-types.${av}`),
            })),
        [t]
    )

    const nrOfRingsOptions = useMemo(
        () =>
            [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(nr => ({
                value: nr,
                label: nr.toString(),
            })),
        []
    )

    const criteriaOptions = useMemo(
        () =>
            incomingCalls.sequentialRingNumbers.criteriaActivations.map(criteria => ({
                value: criteria.name,
                label: criteria.name,
            })),
        [incomingCalls]
    )

    const enableTransfer = useWatch({
        control,
        name: 'outOfOffice.enableTransferToAttendant',
    })

    const presence = useWatch({
        control,
        name: 'outOfOffice.presence',
    })

    const forwardingAlwaysActive = useWatch({
        control,
        name: 'callForwarding.forwardingAlwaysActive',
    })

    const forwardingNoAnswerActive = useWatch({
        control,
        name: 'callForwarding.forwardingNoAnswerActive',
    })

    const forwardingBusyActive = useWatch({
        control,
        name: 'callForwarding.forwardingBusyActive',
    })

    const forwardingNotReachableActive = useWatch({
        control,
        name: 'callForwarding.forwardingNotReachableActive',
    })

    const ringBaseLocationFirst = useWatch({
        control,
        name: 'sequentialRingNumbers.ringBaseLocationFirst',
    })

    const { fields: locationFields, remove, append, replace, update } = useFieldArray({
        control,
        name: `sequentialRingNumbers.locations`,
    })

    // @ts-ignore
    const dateFnsLocale = useMemo(() => localeMap[i18n.language], [i18n.language])

    return <>
        <ErrorAlert showAlert={!!serverError} />
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLocale}>
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={t('general.general')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('users.edit.do-not-disturb')}
                                name={'doNotDisturbEnabled'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('users.edit.call-waiting')}
                                name={'callWaitingEnabled'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('users.edit.anonymous-call-rejection')}
                                name={'anonymousCallRejectionEnabled'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                {incomingCalls.outOfOffice.presence && <FormGroup label={t('users.edit.out-of-office.title')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput readonly={false} label={t('users.edit.out-of-office.availability')} name={'outOfOffice.presence'} control={control} required={true} options={availabilityOptions} />
                        </FormFieldBox>
                        {presence !== 'NONE' &&
                            <FormFieldBox>
                                <TextInput required={false} label={t('users.edit.out-of-office.until')}
                                           name={`outOfOffice.expirationTimeAsDate`} control={control} isDate={true} />
                            </FormFieldBox>
                        }
                    </FormRow>
                    <FormRow>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('users.edit.out-of-office.enable-transfer')}
                                name={'outOfOffice.enableTransferToAttendant'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                        {enableTransfer && <FormFieldBox>
                            <TextInput readonly={false} label={t('users.edit.out-of-office.transfer')} name={'outOfOffice.attendantNumber'} control={control} required={true} />
                        </FormFieldBox>}
                    </FormRow>
                    <FormRow>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('users.edit.out-of-office.ring-splash')}
                                name={'outOfOffice.enableRingSplash'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>}

                <FormGroup label={t('users.edit.call-forwarding.title')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox mdSize={4}>
                            <FormCheckbox
                                label={t('users.edit.call-forwarding.always')}
                                name={'callForwarding.forwardingAlwaysActive'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                        {forwardingAlwaysActive && <FormFieldBox  mdSize={4}>
                            <TextInput readonly={false} label={t('numbers.table.number')} name={'callForwarding.forwardingAlwaysNumber'} control={control} required={true} />
                        </FormFieldBox>}
                    </FormRow>
                    <FormRow>
                        <FormFieldBox  mdSize={4}>
                            <FormCheckbox
                                label={t('users.edit.call-forwarding.no-answer')}
                                name={'callForwarding.forwardingNoAnswerActive'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                        {forwardingNoAnswerActive && <FormFieldBox  mdSize={4}>
                            <TextInput readonly={false} label={t('numbers.table.number')} name={'callForwarding.forwardingNoAnswerNumber'} control={control} required={true} />
                        </FormFieldBox>}
                        {forwardingNoAnswerActive && <FormFieldBox  mdSize={4}>
                            <TextInput readonly={false} label={t('users.edit.call-forwarding.number-of-rings')} name={'callForwarding.forwardingNoAnswerNumberOfRings'} control={control} required={true} options={nrOfRingsOptions} />
                        </FormFieldBox>}
                    </FormRow>
                    <FormRow>
                        <FormFieldBox  mdSize={4}>
                            <FormCheckbox
                                label={t('users.edit.call-forwarding.busy')}
                                name={'callForwarding.forwardingBusyActive'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                        {forwardingBusyActive && <FormFieldBox  mdSize={4}>
                            <TextInput readonly={false} label={t('numbers.table.number')} name={'callForwarding.forwardingBusyNumber'} control={control} required={true} />
                        </FormFieldBox>}
                    </FormRow>
                    <FormRow>
                        <FormFieldBox  mdSize={4}>
                            <FormCheckbox
                                label={t('users.edit.call-forwarding.not-reachable')}
                                name={'callForwarding.forwardingNotReachableActive'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                        {forwardingNotReachableActive && <FormFieldBox  mdSize={4}>
                            <TextInput readonly={false} label={t('numbers.table.number')} name={'callForwarding.forwardingNotReachableNumber'} control={control} required={true} />
                        </FormFieldBox>}
                    </FormRow>
                </FormGroup>
                <FormGroup label={t('users.edit.sequential-ring.title')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox  mdSize={4}>
                            <FormCheckbox
                                label={t('users.edit.sequential-ring.base-location')}
                                name={'sequentialRingNumbers.ringBaseLocationFirst'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                        {ringBaseLocationFirst && <FormFieldBox  mdSize={4}>
                            <TextInput readonly={false} label={t('users.edit.call-forwarding.number-of-rings')} name={'sequentialRingNumbers.baseLocationNumberOfRings'} control={control} required={true} options={nrOfRingsOptions} />
                        </FormFieldBox>}
                        {ringBaseLocationFirst && <FormFieldBox  mdSize={4}>
                            <FormCheckbox
                                label={t('users.edit.sequential-ring.continue-if-busy')}
                                name={'sequentialRingNumbers.continueIfBaseLocationIsBusy'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>}
                    </FormRow>
                    <FormRow>
                        <FormFieldBox >
                            <FormCheckbox
                                label={t('users.edit.sequential-ring.caller-may-cancel')}
                                name={'sequentialRingNumbers.callerMayStopSearch'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox >
                            <TextInput
                                readonly={false}
                                label={t('users.edit.sequential-ring.when-to-ring')}
                                name={'sequentialRingNumbers.activeCriteria'}
                                control={control}
                                required={false}
                                options={criteriaOptions}
                                isCheckbox={true}
                            />
                        </FormFieldBox>
                    </FormRow>
                    <Typography variant={'subtitle1'} mt={4}>
                        <strong>{t('users.edit.sequential-ring.ring-numbers')}</strong>
                    </Typography>
                    {locationFields.map((item, index) => (
                        <Grid container item spacing={3} key={index}>
                            <Grid item md={10}>
                                <LocationForm index={index} control={control} nrOfRingOptions={nrOfRingsOptions} getValues={getValues} update={update}  />
                            </Grid>
                            <Grid item container md={2} alignItems={'center'} justifyContent={'center'} flexWrap={'nowrap'}>
                                {index === locationFields.length - 1 && locationFields.length < 5 && (
                                    <IconButton
                                        color={'secondary'}
                                        component='div'
                                        onClick={() =>
                                            append({
                                                phoneNumber: '',
                                                numberOfRings: 3,
                                                answerConfirmationRequired: true
                                            })
                                        }
                                    >
                                        <AddIcon />
                                    </IconButton>
                                )}
                                {locationFields.length > 1 && (
                                    <IconButton
                                        aria-label='delete'
                                        component='div'
                                        onClick={() =>
                                            locationFields.length === 1
                                                ? replace({
                                                    phoneNumber: '',
                                                    numberOfRings: 3,
                                                    answerConfirmationRequired: true
                                                })
                                                : remove(index)
                                        }
                                    >
                                        <DeleteIcon fontSize='inherit' />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                    ))}
                </FormGroup>
                {buildFormButtons(isSubmitting)}
            </FormContainer>
        </LocalizationProvider>
        </>
}

const LocationForm = ({
                       index,
                       control,
                       nrOfRingOptions,
                       update,
                       getValues
                   }: {
    index: number
    control: Control<any>
    nrOfRingOptions: Option[]
    update: (index: number, period: any) => void
    getValues: (payload?: string | string[]) => Object
}) => {
    const { t } = useTranslation()

    const confirmationRequired = useWatch({
        name: `sequentialRingNumbers.locations[${index}].answerConfirmationRequired`,
        control: control,
    })

    const ConfirmationRequiredBox: ReactElement = useMemo(
        () => (
            <Checkbox
                checked={confirmationRequired}
                onChange={event => {
                    update(index, {
                        ...getValues(`sequentialRingNumbers.locations[${index}]`),
                        answerConfirmationRequired: event.target.checked,
                    })
                }}
            />
        ),
        [confirmationRequired, index, update, getValues],
    )

    return (
        <Paper sx={{ p: 4 }}>
            <Grid container spacing={4}>
                <RingBox width={6}>
                    <TextInput required={true} label={t('numbers.table.number')}
                               name={`sequentialRingNumbers.locations[${index}].phoneNumber`} control={control} />
                </RingBox>
                <RingBox width={6}>
                    <TextInput readonly={false} label={t('users.edit.call-forwarding.number-of-rings')} name={`sequentialRingNumbers.locations[${index}].numberOfRings`} control={control} required={true} options={nrOfRingOptions} />
                </RingBox>
                <RingBox width={6} align={'end'} justifyContent={'start'}>
                    <FormControlLabel control={ConfirmationRequiredBox} label={t(`users.edit.sequential-ring.answer-confirmation-required`) as string} />
                </RingBox>
            </Grid>
        </Paper>
    )
}

function RingBox({
                        children,
                        align,
                        width,
                        justifyContent = 'center',
                    }: { children: any; align?: string; width?: number; justifyContent?: string }) {
    return (
        <Grid container={!!align} item xs={12} sm={6} md={width || 3} lg={width || 3} alignItems={align}
              justifyContent={justifyContent}>
            {children}
        </Grid>
    )
}