import React, {ReactElement, ReactNode, useCallback, useMemo} from 'react'
import {FormSection} from "../common/components/SectionedForm";
import {api} from "../common/api";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../authentication/redux";
import {useTranslation} from "react-i18next";
import PaperStack from "../common/form/PaperStack";
import FormGroup from "../common/form/FormGroup";
import {LabelValues, LabelValueType} from "../common/components/LabelValues";
import {Day, HolidayForm, OpeningHoursForm, Schedule, ScheduleEvent} from "../app/types";
import {Fab, Grid} from "@mui/material";
import {useFormSubmissionButtons} from "../common/form/useFormSubmissmionButtons";
import {
    exportScheduleEventsToCsv,
    formatIsoPeriod,
    formatTimes,
    sortHolidayEvents,
    sortOpeningHoursEvents,
    toFile,
    transformHolidayFormToSchedule,
    transformOpeningHoursFormToSchedule,
    transformScheduleToHolidaysForm,
    transformScheduleToOpeningHoursForm
} from "./util/scheduleHelpers";
import {any} from "ramda";
import PublishIcon from "@mui/icons-material/Publish";
import {default as OHForm} from './openinghours/Form'
import {default as HFORM} from './holidays/Form'
import {usePapaParse} from "react-papaparse";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import ScheduleIcon from "@mui/icons-material/Schedule";


export const useGeneralSection = (scheduleId: string, isFetching: boolean, error: any, refetch: () => void, data?: Schedule) : FormSection<Schedule> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const {buildFormButtons} = useFormSubmissionButtons(false)
    const { jsonToCSV } = usePapaParse();

    const isHoliday = useMemo(() => data && data.type === 'HOLIDAY', [data])
    const translationKey = useMemo(() => (isHoliday ? 'schedules.holidays' : 'schedules.opening-hours'), [isHoliday])

    const onSubmitOpeningHours = useCallback((onSucceeded: () => void) =>
        async (formData: OpeningHoursForm) => {
            if (!site) return
            await api.put(`/api/site/${site.productId}/schedule/${scheduleId}`, transformOpeningHoursFormToSchedule(formData))
            await refetch()
            onSucceeded();
        },
        [site, refetch, scheduleId],
    )

    const onSubmitOpeningHolidays = useCallback((onSucceeded: () => void) =>
        async (formData: HolidayForm) => {
            if (!site) return
            await api.put(`/api/site/${site.productId}/schedule/${scheduleId}`, transformHolidayFormToSchedule(formData))
            await refetch()
            onSucceeded();
        },
        [site, refetch, scheduleId],
    )

    const renderGeneralForm = useCallback(
        (schedule: Schedule, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode =>
            isHoliday ? <HFORM holidays={transformScheduleToHolidaysForm(schedule)} onSubmit={onSubmitOpeningHolidays(onSucceeded)} buildFormButtons={buildFormButtons(cancelEditButton)} />
                : <OHForm openingHours={transformScheduleToOpeningHoursForm(schedule)} onSubmit={onSubmitOpeningHours(onSucceeded)} buildFormButtons={buildFormButtons(cancelEditButton)} />
        , [isHoliday, onSubmitOpeningHolidays, onSubmitOpeningHours, buildFormButtons])

    const infoLabelValues = useCallback((value: Schedule): LabelValueType[] => [{
        label: t(`${translationKey}.edit.name`),
        value: <>{value.name}</>,
    }], [t, translationKey])

    const timeValues = useCallback(
        (value: Schedule): LabelValueType[] =>
            Object.keys(Day)
                .filter(element => {
                    return isNaN(Number(element))
                })
                .filter(day => any((ev: ScheduleEvent) => ev.scheduleDay === day, value.eventConfig?.eventList || []))
                .map((day, index) => ({
                    label: t(`general.days.${day}`),
                    value: (
                        <>
                            {sortOpeningHoursEvents(value.eventConfig?.eventList?.filter(ev => ev.scheduleDay === day))
                                ?.map(ev => formatTimes(ev))
                                .join(', ')}
                        </>
                    ),
                    type: 'FULL_LINE',
                })),
        [t],
    )

    const holidayValues = useCallback(
        (value: Schedule): LabelValueType[] =>
            sortHolidayEvents(value.eventConfig?.eventList).map(event => ({
                label: event.name,
                value: <>{`${formatIsoPeriod(event.startDate, event.endDate)} ${!event.allDayEvent ? `(${formatTimes(event)})` : ''}`}</>,
                type: 'FULL_LINE',
            })),
        [],
    )

    const exportButtonsPanel = useCallback(
        (schedule: Schedule) => (
            <Grid container spacing={2} >
                <Grid item pt={1}>
                    <Fab
                        title={t('schedules.export-to-csv')}
                        onClick={() => toFile(exportScheduleEventsToCsv(schedule, jsonToCSV), "schedule_" + schedule.name + ".csv")}
                        color='primary'
                        size={'small'}
                    >
                        <PublishIcon  />
                    </Fab>
                </Grid>
            </Grid>
        ),
        [t, jsonToCSV],
    )

    const renderDetails = useCallback(
        (schedule: Schedule): ReactNode => {
            return (
                <PaperStack>
                    <FormGroup fullWidth={true} key={1} label={t(`${translationKey}.edit.section.info`)}>
                        <LabelValues labelValues={infoLabelValues(schedule)} />
                    </FormGroup>
                    {isHoliday ? (
                        <FormGroup fullWidth={true} key={2} label={t(`${translationKey}.edit.section.days`)} buttonsPanel={exportButtonsPanel(schedule)}>
                            <LabelValues labelValues={holidayValues(schedule)} />
                        </FormGroup>
                    ) : (
                        <FormGroup fullWidth={true} key={2} label={t('schedules.opening-hours.edit.section.times')} buttonsPanel={exportButtonsPanel(schedule)}>
                            <LabelValues labelValues={timeValues(schedule)} />
                        </FormGroup>
                    )}
                </PaperStack>
            )
        },
        [infoLabelValues, t, isHoliday, translationKey, timeValues, holidayValues, exportButtonsPanel],
    )

    return {
        title: t('general.general'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderDetails,
        renderForm: renderGeneralForm,
        enabled: () => true,
        icon: () => isHoliday?  <BeachAccessIcon/> : <ScheduleIcon/>
    }
}