import React, {ReactElement, useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {FormProvider, useForm, useWatch} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {object} from 'yup'
import TextInput from '../../common/form/TextInput'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import {useFormSubmission} from '../../common/servercall/useFormSubmission'
import ErrorAlert from '../../common/components/ErrorAlert'
import FormGroup from '../../common/form/FormGroup'
import FormContainer from '../../common/form/FormContainer'
import {HolidayForm, HolidayPeriod} from '../../app/types'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import nlLocale from 'date-fns/locale/nl-BE'
import frLocale from 'date-fns/locale/fr'
import enLocale from 'date-fns/locale/en-GB'

import {HolidayPeriodForm} from './HolidayPeriodForm'
import {uniq} from 'ramda'
import {LocalizationProvider} from '@mui/x-date-pickers'
import ImportFromCsvModal from "../util/ImportFromCsvModal";
import {transformHolidays} from "../util/scheduleHelpers";
import {Fab, Grid} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";

declare module 'yup' {
    interface ArraySchema<T> {
        unique(message: string, mapper?: (value: T, index?: number, list?: T[]) => T[]): ArraySchema<T>
    }
}

yup.addMethod(yup.array, 'unique', function(message, mapper = (a: any) => a) {
    return this.test('unique', message, function(list) {
        if (!list) return true
        const set = new Set(list.map(mapper))
        const isUnique = list.length === set.size
        if (isUnique) {
            return true
        }
        const uniqueList = uniq(list.map(mapper))
        const idx = list.findIndex((l, i) => mapper(l) !== uniqueList[i])
        return this.createError({
            path: `holidayPeriods.[${idx}].eventName`,
            message: message,
        })
    })
})

const schema = yup
    .object()
    .shape({
        name: yup.string().trim().max(30, 'too-long').required('required-m').matches(/^[^\\/]*$/, 'invalid-schedule-name'),
        holidayPeriods: yup
            .array<HolidayPeriod>()
            .of(
                object().shape({
                    eventName: yup.string().trim().max(30, 'too-long').required('required-m'),
                    from: yup
                        .date()
                        .nullable()
                        .typeError('invalid-date')
                        .transform((curr, orig) => (orig === '' ? null : curr))
                        .required('required-m')
                        .min(new Date(2020, 1, 1), 'invalid-date'),
                    to: yup.object().when('from', from => {
                            if (!!from && from instanceof Date && !isNaN(from.valueOf())) {
                                return yup
                                    .date()
                                    .nullable()
                                    .typeError('invalid-date')
                                    .transform((curr: any, orig: any) => (orig === '' ? null : curr))
                                    .min(from, 'holiday-to-date-lower-than-from-date')
                            } else {
                                return yup
                                    .date()
                                    .nullable()
                                    .typeError('invalid-date')
                                    .transform((curr: any, orig: any) => (orig === '' ? null : curr))
                                    .min(new Date(2020, 1, 1), 'invalid-date')
                            }
                        })
                   ,
                    startTime: yup.lazy(value => {
                        return yup.object().when('allDay', allDay => {
                            return !allDay
                                ? yup
                                    .string()
                                    .required('required-m')
                                    .matches(/^$|^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, {
                                        message: 'invalid-time',
                                        excludeEmptyString: true,
                                    })
                                    .nullable()
                                : yup.string().nullable()
                        })
                    }),
                    // @ts-ignore
                    endTime: yup.object().when(['startTime', 'allDay', 'from', 'to'], (startTime, allDay, from, to) => {
                        if (allDay) {
                            return yup.string().nullable()
                        } else if (!!startTime && !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(startTime)) {
                            return yup.string().nullable()
                        } else if (!!startTime) {
                            return yup
                                .string()
                                .required('required-m')
                                .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, {
                                    message: 'invalid-time',
                                    excludeEmptyString: true,
                                })
                                .when('startTime', (startTime2, schema) => {
                                    return schema.test({
                                        test: (endTime2: string) => (from == null) || (to != null && from.getTime() !== to.getTime()) || endTime2 > startTime2,
                                        message: 'closing-time-lower-than-opening-time',
                                    })
                                })
                                .nullable(true)
                        } else {
                            return yup.string().nullable()
                        }
                    })
                }),
            ) // @ts-ignore
            .unique('duplicate-holiday-period-name', e => e.eventName),
    })
    .required()

type Props = {
    holidays: HolidayForm
    onSubmit: (formData: HolidayForm) => void
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

export const localeMap = {
    nl: nlLocale,
    fr: frLocale,
    en: enLocale,
}

const HolidaysFormScreen = ({ holidays, onSubmit, buildFormButtons }: Props) => {
    const { t, i18n } = useTranslation()
    const [csvModalOpen, setCsvModalOpen] = useState<boolean>(false)

    const { control, handleSubmit, setError, getValues, clearErrors, reset } = useForm<HolidayForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            ...holidays,
        },
    })

    const name = useWatch({
        name: 'name',
        control: control,
    })

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<HolidayForm>(onSubmit, setError)

    // @ts-ignore
    const dateFnsLocale = useMemo(() => localeMap[i18n.language], [i18n.language])

    const holidaysButtonsPanel = useMemo(
        () => (
            <Grid container spacing={2} >
                <Grid item pt={1}>
                    <Fab
                        title={t('schedules.import-from-csv')}
                        onClick={() => {
                            setCsvModalOpen(true)}
                        }
                        color='primary'
                        size={'small'}
                    >
                        <GetAppIcon  />
                    </Fab>
                </Grid>
            </Grid>
        ),
        [t],
    )

    const importCsvData = useCallback((csvData: any) => {
        const events = csvData?.map( (it:object) => ({...it}))
        const holidayPeriods = transformHolidays(
            events.map((event: any) =>
                ({...event, allDayEvent: ['TRUE', "true"].includes(event.allDayEvent), startTime: {hour: event.startTimeHour, minute: event.startTimeMinute}, endTime: {hour: event.endTimeHour, minute: event.endTimeMinute}})
            )
        )
        reset({name: name, type: 'HOLIDAY', holidayPeriods: holidayPeriods})
    }, [name, reset])

    return (
        // @ts-ignore
        <FormProvider clearErrors={clearErrors}>
            <ErrorAlert showAlert={!!serverError} />
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup fullWidth={true} label={t('schedules.holidays.edit.section.info')} >
                    <FormRow>
                        <FormFieldBox>
                            <TextInput autofocus={true} label={t('schedules.holidays.edit.name')} name={'name'}
                                       control={control} required={true} />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                <FormGroup fullWidth={true} label={t('schedules.holidays.edit.section.days')} buttonsPanel={holidaysButtonsPanel}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLocale}>
                        <HolidayPeriodForm getValues={getValues} control={control} />
                    </LocalizationProvider>
                </FormGroup>
                {buildFormButtons(isSubmitting)}
            </FormContainer>
            <ImportFromCsvModal open={csvModalOpen} onUploadAccepted={importCsvData} onClose={() => setCsvModalOpen(false)}></ImportFromCsvModal>
        </FormProvider>
    )
}

export default HolidaysFormScreen
