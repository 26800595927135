import {BasicUser, CPE, Device, PhoneNumber, PhoneUser} from '../../app/types'
import {TFunction} from "react-i18next";
import {find} from "ramda";

export const formatMacAddress = (macAddress?: string | null) => {
    return macAddress
        ? macAddress.slice(0, 2) + ':' + macAddress.slice(2, 4) + ':' + macAddress.slice(4, 6) + ':' + macAddress.slice(6, 8) + ':' + macAddress.slice(8, 10) + ':' + macAddress.slice(10, 12)
        : ''
}

export const formatIpei = (ipei?: string | null) => {
    return ipei
        ? ipei
        : ''
}

export const formatCPEModel = (cpeModel?: string | null) => {
    return cpeModel
        ? cpeModel.substr(10).replaceAll('-', ' ')
        : ''
}

export const formatCPEIdentifier = (cpe?: CPE | Device) => {
    return cpe ? cpe.identifier : ''
}

export const formatMPPActivationCode = (activationCode?: string | null) => {
    return activationCode ? activationCode.slice(0, 4) + '-' + activationCode.slice(4, 8) + '-' + activationCode.slice(8, 12) + '-' + activationCode.slice(12, 16) : ''
}

export const formatCPE = (cpe?: CPE | Device) => {
    return cpe ? `${cpe.name ? cpe.name : formatCPEModel(cpe.model)} (${formatCPEIdentifier(cpe)})` : ''
}

export const formatPhoneNumber = (number?: string | null) => {
    return number ? (number.startsWith('+32') ? addSeparatorsToPhoneNumber(`0${number.slice(3)}`) : addSeparatorsToPhoneNumber(number)) : ''
}
export const formatUser = (user?: BasicUser) => {
    return user ? `${formatUserNames(user.lastName, user.firstName)} ${isPhoneUser(user) ? `(${user.extension})`: ''}` : ''
}
export const formatUserInDropdown = (user?: BasicUser) => {
    return user ? `${isPhoneUser(user) ? `${user.extension}`: ''} (${formatUserNames(user.lastName, user.firstName)})` : ''
}

export const formatUserNames = (lastName: string, firstName: string) => {
    return `${lastName} ${firstName}`
}

function isPhoneUser(user: any): user is PhoneUser {
    return typeof user !== 'string' && 'extension' in user
}

const shortZonePattern = /^(02|03|04|09).*$/

const addSeparatorsToPhoneNumber = (localNumber: string) => {
    if (isMobileNumber(localNumber)) {
        return localNumber.slice(0, 4) + '/' + localNumber.slice(4, 6) + '.' + localNumber.slice(6, 8) + '.' + localNumber.slice(8, 10)
    }
    if (shortZonePattern.test(localNumber)) {
        return localNumber.slice(0, 2) + '/' + localNumber.slice(2, 5) + '.' + localNumber.slice(5, 7) + '.' + localNumber.slice(7, 9)
    }

    return localNumber.slice(0, 3) + '/' + localNumber.slice(3, 5) + '.' + localNumber.slice(5, 7) + '.' + localNumber.slice(7, 9)
}

const isMobileNumber = (number: string) => number.length === 10

const belgianPhoneNumberPattern = /^\+32[0-9]{8,9}$|^0[0-9]{8,9}$/

export const isBelgianPhoneNumber = (number: string | null): boolean => !!number && belgianPhoneNumberPattern.test(number)

export const formatTotalStat = (stat: number, total: number) : string =>  `${stat} ${total === -1 ? '' : ` / ${total}`}`

export const formatNumberToForwardTo = (t: TFunction, extension: string | null, phoneNumbersToForwardTo: PhoneNumber[]) => {
    if (!extension) return t('general.none')

    if (isBelgianPhoneNumber(extension)) return formatPhoneNumber(extension)

    const nr = find((nr: PhoneNumber) => nr.extension === extension)(phoneNumbersToForwardTo)
    return nr? `${nr.extension} (${nr.assignedTo})` : extension
}

