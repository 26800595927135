import {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useFetchData} from '../common/servercall/useFetchData'
import {Schedule} from '../app/types'
import {useParams} from 'react-router'
import {api} from '../common/api'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import {Page} from '../app/navigation/redux'
import {Grid} from "@mui/material";
import {SectionedForm} from "../common/components/SectionedForm";
import {useGeneralSection} from "./general";
import {TitledBody} from "../common/components/TitledBody";
import {useAccessHelpers} from "../common/util/hooks";

export const DetailSchedule = () => {
    const { t } = useTranslation()
    const { id }: { id: string } = useParams()
    const site = useSelector(selectedSiteSelector)
    const {hasWriteAccess} = useAccessHelpers()

    const fetchSchedule = useCallback(
        async (scheduleId: string): Promise<Schedule | undefined> => {
            if (!site) return
            const { data } = await api.get<Schedule>(`/api/site/${site.productId}/schedule/${scheduleId}`)
            return data
        },
        [site],
    )

    const {
        data,
        isFetching,
        error,
        refetch
    } = useFetchData<Schedule>(useCallback(() => fetchSchedule(id), [fetchSchedule, id]))

    const isHoliday = useMemo(() => data && data.type === 'HOLIDAY', [data])

    const translationKey = useMemo(() => (isHoliday ? 'schedules.holidays' : 'schedules.opening-hours'), [isHoliday])

    const pageInfo: Page | null = useMemo(
        () =>
            data
                ? {
                    label: `${data.name}`,
                    key: `${translationKey}.title`,
                }
                : null,
        [data, translationKey],
    )

    const generalSection = useGeneralSection(id, isFetching, error, refetch, data);
    const formSections = useMemo(() => [
        generalSection
    ], [generalSection])

    return <TitledBody title={t(`${translationKey}.title`)} page={pageInfo}>
            <Grid container>
                <Grid item xs={12} lg={10}>
                    <SectionedForm formSections={formSections} activeTab={0} readOnly={!hasWriteAccess}/>
                </Grid>
            </Grid>
        </TitledBody>

}
