import {ExecutiveFiltering} from "../../../app/types";
import {useTranslation} from "react-i18next";
import React, {ReactElement, useMemo} from "react";
import FormGroup from "../../../common/form/FormGroup";
import ErrorAlert from "../../../common/components/ErrorAlert";
import FormContainer from "../../../common/form/FormContainer";
import {useForm, useFormState, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useFormSubmission} from "../../../common/servercall/useFormSubmission";
import * as yup from "yup";
import FormFieldBox from "../../../common/form/FormFieldBox";
import FormCheckbox from "../../../common/form/FormCheckbox";
import FormRow from "../../../common/form/FormRow";
import TextInput from "../../../common/form/TextInput";
import {Box, FormHelperText} from "@mui/material";
import {ErrorMessage} from "@hookform/error-message";

const schema = () => yup.object(
    {
        excludedNumbers: yup.array().when(['simpleFilterType'], {
            is: (filterType: string) => filterType === 'EXCLUDE_SPECIFIC_NUMBERS',
            then: schema => yup.array()
                .of(
                    yup.string().trim().max(22, 'too-long').matches(/^\+?[0-9]*$/, {
                        message: 'invalid-phone-number',
                        excludeEmptyString: true,
                    }).nullable(true)
                ),
            otherwise: schema => yup.array().nullable(true),
        }),
    }
)

type Props = {
    filtering: ExecutiveFiltering
    onSubmit: (formData: ExecutiveFiltering) => void
    readonly?: boolean
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

export const FiltersForm = ({ filtering, onSubmit, readonly, buildFormButtons }: Props) => {
    const { t } = useTranslation()

    const { control, handleSubmit, setError, setValue, getFieldState } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(schema()),
        defaultValues: {
            ...filtering,
            simpleFilterType: filtering.filterMode === 'ADVANCED' ? 'EXCLUDE_SPECIFIC_NUMBERS' : filtering.simpleFilterType
        }
    })

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<ExecutiveFiltering>(onSubmit, setError)

    const filterModeOptions = useMemo(
        () =>
            ['ALL_CALLS', 'ALL_INTERNAL_CALLS', 'ALL_EXTERNAL_CALLS', 'EXCLUDE_SPECIFIC_NUMBERS'].map(option => ({
                value: `${option}`,
                label: `${t(`executives.filtering.simple-filter.${option}`)}`,
            })),
        [t]
    )

    const filterType = useWatch({
        control,
        name: 'simpleFilterType',
    })

    const { errors } = useFormState({
        control,
    })

    const errorMessages = errors['excludedNumbers']
    const isInvalid = !!(errors && errorMessages && errorMessages.type==='server')

    return <>
        <ErrorAlert showAlert={!!serverError} />
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={''} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('general.enabled')}
                                name={'enabled'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput
                                readonly={readonly}
                                label={t('executives.filtering.filtered-calls')}
                                name={'simpleFilterType'}
                                control={control}
                                required={true}
                                options={filterModeOptions}
                                isRadio={true}
                            />
                        </FormFieldBox>
                    </FormRow>
                    {filterType === 'EXCLUDE_SPECIFIC_NUMBERS' && (
                        <>
                        <FormRow spacing={2}>
                            {[0,1,2,3,4,5,6,7,8,9,10,11].map(index =>
                                <FormFieldBox mdSize={3}>
                                    <TextInput
                                        readonly={readonly}
                                        label={''}
                                        name={`excludedNumbers.${index}`}
                                        control={control}
                                        required={false}
                                    />
                                </FormFieldBox>)
                            }
                        </FormRow>
                        {isInvalid && (
                            <Box mt={2}>
                                <ErrorMessage
                                    errors={errors}
                                    // @ts-ignore
                                    name={'excludedNumbers'}
                                    render={({ message }) => <FormHelperText
                                        error={true}>{t(`general.form-error.${message}`)}</FormHelperText>}
                                />
                            </Box>
                        )}
                        </>
                    )}
                </FormGroup>
                {buildFormButtons(isSubmitting)}
            </FormContainer>
        </>
}
