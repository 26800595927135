import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import FormGroup from '../form/FormGroup'
import {PhoneNumber, SelectiveCallForward} from '../../app/types'
import {LabelValues, LabelValueType} from "./LabelValues";
import {Box} from "@mui/material";
import {formatNumberToForwardTo} from "../util/formatters";

type Props = {
    selectiveCallForward: SelectiveCallForward | null
    nightServiceMode: string | null
    fullWidth?: boolean
    phoneNumbersToForwardTo: PhoneNumber[]
}

export const SelectiveCallForwardDetail = ({ selectiveCallForward, nightServiceMode, fullWidth, phoneNumbersToForwardTo }: Props) => {
    const { t } = useTranslation()

    const formatNumber = useCallback((extension?: string | null) => formatNumberToForwardTo(t, extension || null, phoneNumbersToForwardTo), [phoneNumbersToForwardTo, t])

    const nightServiceModeLabelValue = useCallback((): LabelValueType[] => !nightServiceMode ?
        [] :
        [
            {
                label: t('flows.edit.night-service'),
                value: <>{t(`flows.edit.night-service-modes.${nightServiceMode}`)}</>,
                type: 'FULL_LINE'
            }
        ], [nightServiceMode, t])

    const selectiveForwardHolidays = useCallback((value: SelectiveCallForward | null): LabelValueType[] => {
        return [
            {
                label: t('selective-call-forward.holiday-schedule'),
                value: <>{value?.holidaySchedule?.name || t('general.none')}</>
            },
            {
                label: t('selective-call-forward.holiday-number'),
                value: <>{formatNumber(value?.holidayPhoneNumber)}</>
            }
        ];
    }, [t, formatNumber])

    const selectiveForwardOpeningHours = useCallback(
        (value: SelectiveCallForward | null): LabelValueType[] => [
            {
                label: t('selective-call-forward.openinghours-schedule'),
                value: <>{value?.outsideOpeningHoursSchedule?.name || t('general.none')}</>,
            },
            {
                label: t('selective-call-forward.openinghours-number'),
                value: <>{formatNumber(value?.outsideOpeningHoursPhoneNumber)}</>,
            },
        ],
        [t, formatNumber]
    )

    return (
        <FormGroup label={t('selective-call-forward.section-header')} fullWidth={fullWidth || false}>
            {nightServiceMode ? <LabelValues labelValues={nightServiceModeLabelValue()} /> : <></>}
            <LabelValues labelValues={selectiveForwardHolidays(selectiveCallForward)} />
            <Box flexWrap={"wrap"} width="100%"></Box>
            <LabelValues labelValues={selectiveForwardOpeningHours(selectiveCallForward)} />
        </FormGroup>
    )
}
