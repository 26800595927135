import {useSelector} from 'react-redux'
import {configSelector} from '../authentication/redux'
import React, {ReactNode, useCallback, useMemo} from 'react'
import {UserOption} from '../app/types'
import {WithErrorAndFetching} from '../common/servercall/WithErrorAndFetching'
import {ColumnDefinition, InfiniteScrollTable} from '../common/components/table/Table'
import {sorterHeader} from '../common/components/table/SorterHeader'
import {useTranslation} from 'react-i18next'
import {Avatar, Card, CardContent, CardHeader, CardMedia} from '@mui/material'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const UserOptions = () => {
    const { t } = useTranslation()
    const config = useSelector(configSelector);

    const columns: ColumnDefinition<UserOption>[] = useMemo(
        () => [
            {
                title: t('site.cards.user-options.table.name'),
                key: 'type',
                sorter: false,
                render: (text: string) => <>{t(`site.cards.user-options.types.${text.toLowerCase()}`)}</>,
            },
            {
                title: t('site.cards.user-options.table.number'),
                key: 'nrOfLicenses',
                sorter: false,
                render: (text: string, record: UserOption) => <>{`${record.usedLicenses} / ${record.nrOfLicenses}`}</>,
            },
        ],
        [t]
    )

    const sorter = useCallback(col => sorterHeader(col, null, () => {}), [])

    const renderUserOptions = useCallback(
        (options: UserOption[]): ReactNode => (
            <Card sx={{ height: '100%' }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: theme => theme.palette.primary.main, color: theme => theme.palette.secondary.main }} variant="rounded">
                            <ManageAccountsIcon />
                        </Avatar>
                    }
                    title={t('site.cards.user-options.title')}
                    action={<></>}
                />
                <CardMedia></CardMedia>
                <CardContent>
                    <InfiniteScrollTable<UserOption>
                        data={options}
                        hasNextPage={false}
                        fetchNextPage={() => {}}
                        getRowKey={row => row.type}
                        sorter={sorter}
                        columns={columns}
                        tableId={'licenceInfo'}
                        maxHeight={'18vh'}
                    />
                </CardContent>
            </Card>
        ),
        [columns, sorter, t]
    )

    return <WithErrorAndFetching<UserOption[]> isFetching={config == null} error={null} data={config?.siteOptions} render={renderUserOptions} />
}
