import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'

type Props = {
    label?: string
    children: any
    fullWidth?: boolean
    buttonsPanel?: any
}

export default function FormGroup({ label, children, fullWidth, buttonsPanel }: Props) {
    return (
        <Grid item xs={12} lg={fullWidth ? 12 : 9}>
            <Paper
                elevation={2}
                sx={{
                    p: { xs: 2, lg: 3 },
                    backgroundColor: theme => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
                }}
            >
                {(label || buttonsPanel) &&
                    <Grid container item justifyContent={'space-between'} flexDirection={'row'} spacing={1}
                          flexWrap={'nowrap'}>
                        {label && <Grid item xs={'auto'} pr={1}>
                            <Typography variant='h6' align='left' gutterBottom>
                                {label}
                            </Typography>
                        </Grid>}
                        {buttonsPanel ? <Grid item>{buttonsPanel}</Grid> : <></>}
                    </Grid>}
                <Grid container rowSpacing={4} p={1}>
                    {children}
                </Grid>
            </Paper>
        </Grid>
    )
}
