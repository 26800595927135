import React, {ReactNode, useCallback, useEffect, useMemo} from 'react'
import {Flex} from 'rebass'
import Box from '@mui/material/Box'
import NavigationMenu from './navigation/menu'
import {Bcs} from './Breadcrumbs'
import {
    AppBar,
    Button,
    Container,
    CssBaseline,
    Fab,
    Grid,
    IconButton,
    Link,
    List,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@mui/material'
import {styled} from '@mui/material/styles'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Divider from '@mui/material/Divider'
import MenuIcon from '@mui/icons-material/Menu'
import MuiDrawer from '@mui/material/Drawer'
import {useTranslation} from 'react-i18next'
import {
    announcementDismissed,
    announcementDismissedSelector,
    announcementSelector,
    clearSite,
    inEnterpriseViewSelector,
    loadingFailureSelector,
    loadingSelector,
    meSelector,
    refetchStateTriggerSelector,
    selectedSiteSelector,
} from '../authentication/redux'
import {useDispatch, useSelector} from 'react-redux'
import {ThunkDispatch} from '@reduxjs/toolkit'
import {RootState} from './store'
import {useSiteStatus} from './SiteStatus'
import AnnouncementIcon from '@mui/icons-material/Announcement';
import CloseIcon from '@mui/icons-material/Close';
import logo from './assets/logo_negative.png';
import {matchPath, useHistory, useLocation} from "react-router";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import {Loading, RouteDefinition, RouteDefinitions} from "./App";
import {ErrorPage} from "./ErrorPage";
import {useOktaAuth} from "@okta/okta-react";
import ErrorAlert from "../common/components/ErrorAlert";
import {useOktaSession} from "../common/servercall/useOktaSession";

const materialUiBanner = require('material-ui-banner');

const {StaticBanner} = materialUiBanner

type Props = {
    children: ReactNode
}
export const AppContainer = ({ children }: Props) => {
    const [open, setOpen] = React.useState(true)
    const dispatch = useDispatch<ThunkDispatch<RootState, any, any>>()
    const history = useHistory()
    const site = useSelector(selectedSiteSelector)
    const loadingFailure = useSelector(loadingFailureSelector)
    const isLoading = useSelector(loadingSelector)
    const me = useSelector(meSelector)
    const refetchState = useSelector(refetchStateTriggerSelector)
    const inEnterpriseView = useSelector(inEnterpriseViewSelector)
    const { pathname } = useLocation()
    const oktaAuth = useOktaAuth();
    const { logout } = useOktaSession()

    const { t, i18n } = useTranslation()
    const { statusBar: StatusBar, warning } = useSiteStatus({ site, refetchState })
    const portalAnnouncement = useSelector(announcementSelector)
    const announcementClosed = useSelector(announcementDismissedSelector)

    useEffect(() => {
        if (!!portalAnnouncement && !warning && !announcementClosed) {
            StaticBanner.show({
                icon: <AnnouncementIcon/>,
                dismissButtonProps: {color: 'secondary', size: 'medium', startIcon: <CloseIcon/>},
                dismissButtonLabel: t('general.close-announcement'),
                label: <span dangerouslySetInnerHTML={{__html:i18n.language === 'nl'? portalAnnouncement.messageNl : i18n.language === 'fr' ? portalAnnouncement.messageFr : portalAnnouncement.messageEn}}></span>,
                paperProps: {
                    elevation: 4,
                    square: false,
                    sx: { paddingLeft: '0px', paddingRight: '0px', zIndex: 2000 }
                },
                onClose: () => {dispatch(announcementDismissed())},
                appBar: true,
                cardProps: {
                    raised: true,
                    sx: { paddingLeft: '0px', paddingRight: '0px', zIndex: 2000  }
                }
            });
        }

    }, [portalAnnouncement, t, i18n.language, warning, announcementClosed, dispatch])

    const toggleDrawer = () => {
        setOpen(!open)
    }

    const goToEnterprise = useCallback(async () => {
        await dispatch(clearSite())
        history.push("/")
    }, [dispatch, history])

    // @ts-ignore
    const onPhone = useMediaQuery(theme => theme.breakpoints.down('md'))

    const drawerWidth: number = 240

    const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
            }),
        },
    }))

    const NavigationMenuMemo = useMemo(() => <NavigationMenu inEnterpriseView={inEnterpriseView}/> , [inEnterpriseView])

    const currentNavigationPath = useCallback(() : RouteDefinition[] => {
        let path = [RouteDefinitions.find(def => matchPath(pathname, def.href))]
            .filter(def => !!def) as RouteDefinition[]
        if (path) {
            path = RouteDefinitions
                .reduce((previous, current) => (previous[0]?.parentId === current.id ? [current, ...previous] : previous), path)
        }
        return path
    } , [pathname])

    const navigationDisallowed = useCallback(() => {
        const path = currentNavigationPath()
        if (!path || path.length === 0) return false

        if (!!site){
            return path[0]?.hideInSiteMode
        } else {
            return !path[0]?.showInEnterpriseMode
        }
    } , [site, currentNavigationPath])

    return (loadingFailure === '401' ?
            <Container maxWidth="sm" sx={{ marginTop: 8 }}>
                <ErrorAlert showAlert={true} body={<>Authorization error</>} />
            </Container>
            :
            <Flex>
                <CssBaseline/>
                <Drawer variant='permanent' open={open && !onPhone}>
                    <AppBar
                        color='primary'
                        position='static'
                        sx={{
                            flexGrow: 1,
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: '100vh',
                            maxHeight: '100vh',
                        }}
                    >
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: open ? 'flex-end' : 'flex-start',
                                px: [1],
                            }}
                        >
                            {open && !onPhone ? (
                                <IconButton onClick={toggleDrawer} sx={{color: 'inherit'}}>
                                    <ChevronLeftIcon/>
                                </IconButton>
                            ) : !onPhone ? (
                                <IconButton sx={{color: 'inherit'}} onClick={toggleDrawer}>
                                    <MenuIcon/>
                                </IconButton>
                            ) : (
                                <></>
                            )}
                        </Toolbar>
                        <Divider/>
                        {<>
                            <Grid container pt={2} pb={2}
                                  flexDirection={'column'}
                                  justifyContent={'center'}
                                  alignItems={'center'}>
                                {inEnterpriseView ? <>
                                    <Typography variant="h5">{open && !onPhone ? 'Voice Cloud' : 'VC'}</Typography>
                                </> :
                                <>
                                    {!!site && <>
                                        <Grid item xs zeroMinWidth px={1}>
                                            <Typography variant="h5" align={'center'} style={{overflowWrap: 'break-word', whiteSpace: 'wrap'}} >{open && !onPhone ? site.name : ''}</Typography>
                                        </Grid>
                                        <Grid item xs zeroMinWidth>
                                            <Typography variant="subtitle1"  >{open && !onPhone && !!me ? t(`general.license.${site?.license?.toLowerCase()}`) : ''}</Typography>
                                        </Grid>
                                    </>
                                    }
                                    <Grid item pt={2}>
                                        {open && !onPhone ?
                                            <Button onClick={goToEnterprise} startIcon={<LocationCityIcon/>}
                                                    variant={'outlined'}
                                                    color={'secondary'}
                                                    size={'medium'}>{t('navigation.enterprise-view')}</Button>
                                            : <Fab title={t('navigation.enterprise-view')} onClick={goToEnterprise}
                                                   color='primary'
                                                   size={'small'}>
                                                <LocationCityIcon/>
                                            </Fab>
                                        }
                                    </Grid>
                                </>}
                            </Grid>
                            <Divider/>
                        </>}
                        <List>
                            {NavigationMenuMemo}
                        </List>
                        <Grid flexDirection={'column'}
                              sx={{flexGrow: 1, alignItems: 'center'}}
                              justifyContent={open && !onPhone ? 'space-around' : 'end'} container>
                            <img alt="Telenet Business" src={logo} style={{
                                marginBottom: 4,
                                width: open && !onPhone ? '7rem' : '2.5rem',
                                height: 'auto'
                            }}></img>
                        </Grid>
                    </AppBar>
                </Drawer>
                <Box
                    component='main'
                    id={'gbtf-main'}
                    sx={{
                        backgroundColor: theme => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <StatusBar/>
                    {!warning && !announcementClosed && <StaticBanner/>}
                    <Container maxWidth={'xl'} sx={{mt: 2, mb: 2}}>
                        <Flex alignItems={'center'} justifyContent={'space-between'}>
                            <Box mt={warning ? 5 : 0}>
                                {loadingFailure || navigationDisallowed() ? <></> : <Bcs/>}
                            </Box>
                            {oktaAuth?.authState?.isAuthenticated &&
                                <Box mt={warning ? 5 : 0}>
                                    <Link
                                        color="inherit"
                                        component="button"
                                        variant="body2"
                                        onClick={() => logout()}
                                    >
                                        {t('general.sign-out')}
                                    </Link>
                                </Box>
                            }
                        </Flex>
                        <Grid container spacing={3} sx={{mt: 1, mb: 3}}>
                            <Grid item xs={12}>
                                {isLoading ? <Loading/> : (loadingFailure || navigationDisallowed()) ? (
                                    <ErrorPage status={loadingFailure || ''}/>
                                ) : children
                                }
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Flex>
    )
}
