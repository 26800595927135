import React from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

type Props = {
    onClick?: () => void
    title?: string
}

export default function CancelButton({ onClick, title }: Props) {
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <Button title={title ?? t('general.cancel')} color='secondary'
                onClick={() => (onClick ? onClick() : history.goBack())}>
            {title ?? t('general.cancel')}
        </Button>
    )
}
