import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import {Announcement, ServerPage} from '../app/types'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import {api, buildPaginatedUrl} from '../common/api'
import DeletionModal from '../common/components/DeletionModal'
import ErrorAlert from '../common/components/ErrorAlert'
import TableRowActionsMenu from '../common/components/table/TableRowActionsMenu'
import {SearchField} from '../common/components/SearchField'
import {sorterHeader} from '../common/components/table/SorterHeader'
import {ColumnDefinition, InfiniteScrollTable} from '../common/components/table/Table'
import {Sort, useInfiniteScroll} from '../common/components/table/useInfiniteScroll'
import FileDropZone from '../common/components/upload/FileDropZone'
import {Grid} from '@mui/material'
import {useOktaAuth} from "@okta/okta-react";
import AudioPlayer from "../common/components/AudioPlayer";
import {useAccessHelpers} from "../common/util/hooks";

//max 5 mb
const MAX_WAV_FILE_SIZE = 5000 * 1024

export const Announcements = () => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const [announcementToDelete, setAnnouncementToDelete] = useState<Announcement | null>(null)
    const oktaAuth = useOktaAuth();
    const {hasWriteAccess, onlyWithWriteAccess} = useAccessHelpers()

    const fetchAnnouncements = useCallback(
        async (pageParam = 0, sortParam: Sort, searchTermParam: string | null): Promise<ServerPage<Announcement>> => {
            if (!site) return { content: [], totalPages: 0, number: 0, totalElements: 0 }
            const { data } = await api.get(buildPaginatedUrl(`/api/site/${site.productId}/announcements`, pageParam, sortParam, searchTermParam, 1000))
            return data
        },
        [site]
    )

    const { sort, setSort, setSearchTerm, rows, error, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteScroll<Announcement>({
        queryKey: `announcements-${site?.productId}`,
        initialSort: { field: 'name', order: 'asc' },
        fetchFunction: fetchAnnouncements,
    })

    const getDownloadUrl = useCallback(announcement => (site && announcement ? `/api/site/${site.productId}/announcement/download?filename=${encodeURIComponent(announcement.name)}` : ''), [site])

    const rowActions = useMemo(
        () => [
            {
                key: 'download',
                text: t('general.download'),
                onClick: async (record: Announcement) => {
                    const url = getDownloadUrl(record)
                    if (!url) return
                    fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/pdf',
                            ...(oktaAuth?.authState?.isAuthenticated? {"authorization" : `Bearer ${oktaAuth?.authState?.accessToken?.accessToken}`} : {})
                        },
                    })
                        .then(response => response.blob())
                        .then(blob => {
                            // Create blob link to download
                            const url = window.URL.createObjectURL(new Blob([blob]))
                            const link = document.createElement('a')
                            link.href = url
                            link.setAttribute('download', record.name + '.wav')

                            document.body.appendChild(link)
                            link.click()
                            link.parentNode?.removeChild(link)
                        })
                },
            },
            {
                key: 'delete',
                text: t('general.delete'),
                onClick: (record: Announcement) => {
                    setAnnouncementToDelete(record)
                },
                dividerAbove: true,
                needsWriteAccess: true
            },
        ],
        [t, getDownloadUrl, oktaAuth?.authState?.accessToken?.accessToken, oktaAuth?.authState?.isAuthenticated]
    )

    const columns: ColumnDefinition<Announcement>[] = useMemo(
        () => [
            {
                title: t('announcements.table.name'),
                key: 'name',
                sorter: true,
                render: (text: string) => <>{text}</>,
            },
            {
                title: t('announcements.table.size'),
                key: 'fileSize',
                sorter: true,
                defaultSortOrder: 'ascend' as 'ascend',
                render: (text: number) => <>{`${text} KB`}</>,
            },
            {
                title: '',
                key: 'fileSize',
                sorter: false,
                render: (text: number, record) => (
                    <Grid container alignItems={'center'} justifyContent={'flex-end'} xl={10} md={12}>
                        <AudioPlayer wavUrl={getDownloadUrl(record)} />
                    </Grid>
                ),
            },
            {
                title: '',
                key: 'mediaType',
                sorter: false,
                render: (text: string, record: Announcement) => <TableRowActionsMenu record={record} menuItems={rowActions} readOnly={!hasWriteAccess}/>,
            },
        ],
        [t, rowActions, getDownloadUrl, hasWriteAccess]
    )

    const sorter = useCallback(col => sorterHeader(col, sort, setSort), [sort, setSort])

    const getRowKey = useCallback(announcement => announcement.name, [])

    const onSearch = useCallback(async (searchTerm: string) => setSearchTerm(searchTerm), [setSearchTerm])

    const getDeleteUrl = useCallback(announcement => (site && announcement ? `/api/site/${site.productId}/announcement?filename=${encodeURIComponent(announcement.name)}` : null), [site])

    const onFileUploaded = useCallback(file => refetch(), [refetch])
    return (
        <>
            <TitledBody title={t('announcements.title')}>
                {site && onlyWithWriteAccess(
                    <FileDropZone
                        uploadUrl={`/api/site/${site.productId}/announcement`}
                        onFileUploaded={onFileUploaded}
                        acceptMime={['audio/wav', 'audio/mpeg'
                            //, 'audio/m4a', 'audio/mp4', 'audio/x-m4a'
                            ]}
                        maxSizeInBytes={MAX_WAV_FILE_SIZE}
                        dropzoneTitle={t('announcements.dropzone-title')}
                    />
                )}
                <SearchField onSearch={onSearch} />
                <ErrorAlert showAlert={!!error} />
                <InfiniteScrollTable<Announcement>
                    hasNextPage={hasNextPage}
                    data={rows}
                    fetchNextPage={fetchNextPage}
                    isFetching={isFetching}
                    getRowKey={getRowKey}
                    sorter={sorter}
                    columns={columns}
                    tableId={'announcementstable'}
                />
                <DeletionModal<Announcement | null> itemToDelete={announcementToDelete} deleteUrl={getDeleteUrl} onClose={() => setAnnouncementToDelete(null)} onSuccess={refetch} />
            </TitledBody>
        </>
    )
}
