import React, {ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import FormGroup from '../../common/form/FormGroup'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import {useForm} from 'react-hook-form'
import FormCheckbox from "../../common/form/FormCheckbox";
import {NightService} from "../../app/types";
import {yupResolver} from "@hookform/resolvers/yup";
import {useFormSubmission} from "../../common/servercall/useFormSubmission";
import ErrorAlert from "../../common/components/ErrorAlert";
import FormContainer from "../../common/form/FormContainer";
import * as yup from "yup";
import TextInput from "../../common/form/TextInput";

type Props = {
    nightService: NightService
    onSubmit: (formData: NightService) => void
    readonly?: boolean
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

const schema = () =>
    yup
        .object({
            forwardNumber: yup
                .string()
                .when(['enabled'], {
                    is: (enabled: boolean) => enabled,
                    then: schema => yup.string().trim().max(22, 'too-long').required('required-m').matches(/^\+?[0-9]*$/, {
                            message: 'invalid-phone-number',
                            excludeEmptyString: true,
                        }).nullable(),
                    otherwise: schema => yup.string().trim().max(22, 'too-long').matches(/^\+?[0-9]*$/, {
                        message: 'invalid-phone-number',
                        excludeEmptyString: true,
                    }).nullable(),
                }),
        })
        .required()

export const NightServiceForm = ({ nightService, onSubmit, buildFormButtons}: Props) => {
    const { t } = useTranslation()

    const { control, handleSubmit, setError, setValue, getFieldState } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(schema()),
        defaultValues: nightService
    })

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<NightService>(onSubmit, setError)

    return (
        <>
            <ErrorAlert showAlert={!!serverError} />
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={''} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('general.enabled')}
                                name={'enabled'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                        <FormFieldBox>
                            <TextInput
                                readonly={false}
                                label={t('executives.screening-alerting.forward-to-number')}
                                name={'forwardNumber'}
                                control={control}
                                required={true}
                            />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                {buildFormButtons(isSubmitting)}
            </FormContainer>
        </>
    )
}
