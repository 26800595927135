import React, {ReactElement, useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useForm, useWatch} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {array, object} from 'yup'
import TextInput from '../../common/form/TextInput'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import {useFormSubmission} from '../../common/servercall/useFormSubmission'
import ErrorAlert from '../../common/components/ErrorAlert'
import FormGroup from '../../common/form/FormGroup'
import FormContainer from '../../common/form/FormContainer'
import {Day, OpeningHoursForm} from '../../app/types'
import {DayForm} from './DayForm'
import {Fab, Grid} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import ImportFromCsvModal from "../util/ImportFromCsvModal";
import {transformDays} from "../util/scheduleHelpers";

const daySchema = yup
    .object()
    .shape({
        entries: array().of(
            object().shape({
                startTime: yup
                    .string()
                    .matches(/^$|^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, {
                        message: 'invalid-time',
                        excludeEmptyString: true,
                    })
                    .nullable(true),
                endTime: yup.lazy(value => {
                    return yup.object().when('startTime', startTime => {
                        if (!!startTime && !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(startTime)) {
                            return yup.string().nullable()
                        } else if (!!startTime) {
                            return yup
                                .string()
                                .required('required-m')
                                .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, {
                                    message: 'invalid-time',
                                    excludeEmptyString: true,
                                })
                                .when('startTime', (startTime2, schema) => {
                                    return schema.test({
                                        test: (endTime2: string) => endTime2 > startTime2,
                                        message: 'closing-time-lower-than-opening-time',
                                    })
                                })
                                .nullable(true)
                        } else {
                            return yup.string().nullable()
                        }
                    })
                }),
            }),
        ),
    })
    .required()

const schema = yup
    .object()
    .shape({
        name: yup.string().trim().max(30, 'too-long').required('required-m').matches(/^[^\\/]*$/, 'invalid-schedule-name'),
        days: object().shape({
            MONDAY: daySchema,
            TUESDAY: daySchema,
            WEDNESDAY: daySchema,
            THURSDAY: daySchema,
            FRIDAY: daySchema,
            SATURDAY: daySchema,
            SUNDAY: daySchema,
        }),
    })
    .required()

type Props = {
    openingHours: OpeningHoursForm
    onSubmit: (formData: OpeningHoursForm) => void
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

const OpeningHoursFormScreen = ({ openingHours, onSubmit, buildFormButtons }: Props) => {
    const { t } = useTranslation()
    const [csvModalOpen, setCsvModalOpen] = useState<boolean>(false)

    const { control, handleSubmit, setError, reset } = useForm<OpeningHoursForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            ...openingHours,
        },
    })

    const name = useWatch({
        name: 'name',
        control: control,
    })

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<OpeningHoursForm>(onSubmit, setError)

    const openingHoursButtonsPanel = useMemo(
        () => (
            <Grid container spacing={2} >
                <Grid item pt={1}>
                    <Fab
                        title={t('schedules.import-from-csv')}
                        onClick={() => {
                            setCsvModalOpen(true)}
                        }
                        color='primary'
                        size={'small'}
                    >
                        <GetAppIcon  />
                    </Fab>
                </Grid>
            </Grid>
        ),
        [t],
    )

    const importCsvData = useCallback((csvData: any) => {
        const events = csvData?.map( (it:object) => ({...it}))
        const days = transformDays(
            events.map((event: any) =>
                ({...event, startTime: {hour: event.startTimeHour, minute: event.startTimeMinute}, endTime: {hour: event.endTimeHour, minute: event.endTimeMinute}})
            )
        )
        reset({name: name, type: 'TIME', days: days})
    }, [name, reset])

    return (
        <>
            <ErrorAlert errorKey={typeof serverError === 'string' ? serverError : undefined} showAlert={!!serverError} />
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={t('schedules.opening-hours.edit.section.info')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput autofocus={true} label={t('schedules.opening-hours.edit.name')} name={'name'}
                                       control={control} required={true} />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                <FormGroup label={t('schedules.opening-hours.edit.section.times')} buttonsPanel={openingHoursButtonsPanel} fullWidth={true}>
                    {Object.keys(Day)
                        .filter(element => {
                            return isNaN(Number(element))
                        })
                        .map((field, index) => (
                            <DayForm key={index} day={field} control={control} />
                        ))}
                </FormGroup>
                {buildFormButtons(isSubmitting)}
            </FormContainer>
            <ImportFromCsvModal open={csvModalOpen} onUploadAccepted={importCsvData} onClose={() => setCsvModalOpen(false)}></ImportFromCsvModal>
        </>
    )
}

export default OpeningHoursFormScreen
