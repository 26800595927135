import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import {useFetchData} from '../common/servercall/useFetchData'
import {CallRecordingUserSettings, PhoneUser} from '../app/types'
import {useParams} from 'react-router'
import {api} from '../common/api'
import {useSelector} from 'react-redux'
import {hotDeskEnabledSelector, selectedSiteSelector} from '../authentication/redux'
import {Page} from '../app/navigation/redux'
import {SectionedForm} from "../common/components/SectionedForm";
import {useGeneralSection} from "./sections/general";
import {useIncomingCallsSection} from "./sections/incomingCalls";
import {useVoicemailSection} from "./sections/voicemail";
import {Grid} from "@mui/material";
import {useOutgoingCallsSection} from "./sections/outgoingCalls";
import {useCallControlSection} from "./sections/callControl";
import {useHotDeskSection} from "./sections/hotdesk";
import {useCpeSection} from "./sections/cpe";
import {find, propEq} from "ramda";
import {isConfigurableModel} from "../cpes/util/CPEHelpers";
import {useAccessHelpers} from "../common/util/hooks";

export const defaultCallRecording : CallRecordingUserSettings = {
    callRecordingMode: 'NEVER',
    playAnnouncement: false,
    recordVoiceMessaging: false
}

export const DetailUser = () => {
    const { t } = useTranslation()
    const { id }: { id: string } = useParams()
    const site = useSelector(selectedSiteSelector)
    const hotDeskEnabled = useSelector(hotDeskEnabledSelector)
    const {hasWriteAccess} = useAccessHelpers()

    const fetchUser = useCallback(
        async (userId: string): Promise<PhoneUser | undefined> => {
            if (!site) return
            const { data } = await api.get<PhoneUser>(`/api/site/${site.productId}/user/${userId}`)
            return data
        },
        [site]
    )
    const { data, isFetching, error, refetch } = useFetchData<PhoneUser>(useCallback(() => fetchUser(id), [fetchUser, id]))

    const pageInfo: Page | null = useMemo(
        () =>
            data
                ? {
                      label: `${data.lastName} ${data.firstName}`,
                      key: 'user-update',
                  }
                : null,
        [data]
    )

    const cpeModel = useMemo(() =>  find(propEq('deviceType', 'CPE'), data?.devices || [])?.model, [data])
    const generalSection = useGeneralSection(id, isFetching, error, refetch, data);
    const incomingCallsSection = useIncomingCallsSection(id, !!data && data.gbtfProfile !== 'INTERNAL');
    const outGoingCallsSection = useOutgoingCallsSection(id, !!data && data.gbtfProfile !== 'INTERNAL');
    const voicemailSection = useVoicemailSection(id, !!data && data.gbtfProfile !== 'INTERNAL')
    const callControlSection = useCallControlSection(id, !!data && data.gbtfProfile !== 'INTERNAL')
    const cpeSection = useCpeSection(id, cpeModel, isConfigurableModel(cpeModel), data)
    const hotDeskSection = useHotDeskSection(id, hotDeskEnabled && !!data && (data.eligibleForHotDesking || false), data)
    const formSections = useMemo(() => [
        generalSection, incomingCallsSection, outGoingCallsSection, voicemailSection, callControlSection, cpeSection, hotDeskSection
    ], [generalSection, incomingCallsSection, voicemailSection, outGoingCallsSection, callControlSection, cpeSection, hotDeskSection])

    return (
        <TitledBody title={t('users.detail.title')} page={pageInfo}>
            <Grid container>
                <Grid item xs={12} lg={10}>
                    <SectionedForm formSections={formSections} readOnly={!hasWriteAccess}/>
                </Grid>
            </Grid>
        </TitledBody>
    )
}
