import {detailUrl as userDetailUrl} from "./index";
import {Link} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";
import {selectedSiteSelector, sitesSelector} from "../authentication/redux";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import {any} from "ramda";
import {Site} from "../app/types";
import ConfirmationModal from "../common/components/ConfirmationModal";
import {useTranslation} from "react-i18next";

type Props = {
    uuid: string
    siteProductId: string
    formattedUser: string
}

export const UserLink = ({uuid, siteProductId, formattedUser}: Props) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const history = useHistory()
    const {t} = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const availableSites = useSelector(sitesSelector)

    const goToSite = useCallback(async () => {
        setOpenDialog(false)
        history.push(`/switch-site?to-site=${siteProductId}&from-site=${site?.productId}&to-url=${userDetailUrl.replace(':id', uuid)}&from-url=${history.location.pathname}`)
    }, [uuid, siteProductId, history, site?.productId]);

    const sameSite = useMemo(() => siteProductId === site?.productId, [siteProductId, site])

    const hasAccessToSite = useMemo(() => availableSites && any((s: Site | null) => !!s && s.productId === siteProductId)(availableSites),
        [siteProductId, availableSites])

    const onClick = useCallback(() => {
        if (sameSite) {
            return true;
        } else {
            setOpenDialog(true);
            return false
        }
    }, [sameSite, setOpenDialog])

    const href = useMemo(() => {
        return sameSite ? userDetailUrl.replace(':id', uuid) : '#'
    }, [sameSite, uuid])

    return <>
        <ConfirmationModal<boolean | null>
            title={t('users.link.switch-site.title')}
            text={item => t('users.link.switch-site.are-you-sure')}
            buttonLabel={t('general.ok')}
            item={openDialog ? true : null}
            action={() => {
            }}
            onClose={() => setOpenDialog(false)}
            onSuccess={() => goToSite()}
        />
        {hasAccessToSite ?
            <Link onClick={onClick} href={href}>
                {formattedUser}
            </Link> :
            <span>{formattedUser}</span>
        }
    </>
}