import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import {CPE, ServerPage} from '../app/types'
import {useSelector} from 'react-redux'
import {hotDeskEnabledSelector, selectedSiteSelector} from '../authentication/redux'
import {api, buildPaginatedUrl} from '../common/api'
import ErrorAlert from '../common/components/ErrorAlert'
import TableRowActionsMenu from '../common/components/table/TableRowActionsMenu'
import {SearchField} from '../common/components/SearchField'
import {sorterHeader} from '../common/components/table/SorterHeader'
import {ColumnDefinition, InfiniteScrollTable} from '../common/components/table/Table'
import {Sort, useInfiniteScroll} from '../common/components/table/useInfiniteScroll'
import {formatCPEIdentifier, formatCPEModel} from '../common/util/formatters'
import {detailUrl as userDetailUrl} from '../users/index'
import {IconButton, Link, Menu} from '@mui/material'
import {RouteDefinition} from '../app/App'
import {DetailCpe} from './detail'
import {ContactPhone} from '@mui/icons-material'
import {AttachUserToCpeDialog} from './AttachUserToCpeDialog'
import {ActivationCodeDialog} from './ActivationCodeDialog'
import {EditConfigCPE} from "./editConfig";
import {MppDialog} from "./MppDialog";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from '@mui/icons-material/Settings';
import {SearchByMacDialog} from "./SearchByMacDialog";
import {HotDeskDialog} from "./HotDeskDialog";
import CheckIcon from '@mui/icons-material/Check';
import {useAccessHelpers} from "../common/util/hooks";

export const detailUrl = '/cpes/:id'
export const editConfigUrl = '/cpes/:id/configure'

export const routes: RouteDefinition[] = [
    {
        href: editConfigUrl,
        component: EditConfigCPE,
        id: 'cpe-config',
        parentId: 'cpe-overview',
    },
    {
        href: detailUrl,
        component: DetailCpe,
        id: 'cpe-detail',
        parentId: 'cpe-overview',
    },

].map(routeDefinition => ({ ...routeDefinition, menuId: 'cpe-overview' }))

export const Cpes = () => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const hotDeskEnabled = useSelector(hotDeskEnabledSelector)
    const [cpeToChangeUser, setCpeToChangeUser] = useState<CPE | null>(null)
    const [activationCodeCpe, setActivationCodeCpe] = useState<CPE | null>(null)
    const [hotDeskCpe, setHotDeskCpe] = useState<CPE | null>(null)
    const [showConfigMpp, setShowConfigMpp] = useState<boolean>(false)
    const [showSearchByMac, setShowSearchByMac] = useState<boolean>(false)
    const {hasWriteAccess} = useAccessHelpers()

    const fetchCpes = useCallback(
        async (pageParam = 0, sortParam: Sort, searchTermParam: string | null): Promise<ServerPage<CPE>> => {
            if (!site) return { content: [], totalPages: 0, number: 0, totalElements: 0 }
            const { data } = await api.get(buildPaginatedUrl(`/api/site/${site.productId}/cpes`, pageParam, sortParam, searchTermParam))
            return data
        },
        [site]
    )

    const { sort, setSort, setSearchTerm, rows, error, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteScroll<CPE>({
        queryKey: `cpes-${site?.productId}`,
        initialSort: { field: 'identifier', order: 'asc' },
        fetchFunction: fetchCpes,
    })

    const rowActions = useMemo(
        () => [
            {
                key: 'edit',
                text: t('cpes.table.menu.attach-user'),
                icon: (props: object) => <ContactPhone {...props} />,
                onClick: setCpeToChangeUser,
                needsWriteAccess: true
            },
            ...(hotDeskEnabled ? [{
                key: 'hot-desk',
                text: t('cpes.table.menu.as-hot-desk-phone'),
                disabled: (cpe: CPE) => cpe.model !== 'Voicecloud-Cisco-MPP',
                onClick: setHotDeskCpe,
                needsWriteAccess: true
            }] : []),
            {
                key: 'activationcode',
                text: t('cpes.table.menu.activation-code'),
                disabled: (cpe: CPE) => !cpe.eligibleForActivationCode,
                onClick: setActivationCodeCpe,
                dividerAbove: true,
                needsWriteAccess: true
            },

        ],
        [t, hotDeskEnabled]
    )

    // @ts-ignore
    const columns: ColumnDefinition<CPE>[] = useMemo(
        () => {
            return [
                {
                    title: t('cpes.table.identifier'),
                    key: 'identifier',
                    sorter: true,
                    defaultSortOrder: 'ascend' as 'ascend',
                    render: (text: string, record) => <Link
                        href={detailUrl.replace(':id', record.uuid)}>{formatCPEIdentifier(record)}</Link>,
                },
                {
                    title: t('cpes.table.name'),
                    key: 'name',
                    sorter: true,
                    render: (text: string) => <>{text}</>,
                },
                {
                    title: t('cpes.table.type'),
                    key: 'model',
                    sorter: true,
                    render: (text: string) => <>{formatCPEModel(text)}</>,
                },
                {
                    title: t('users.table.extension'),
                    key: 'extension',
                    sorter: true,
                    render: (text: string) => <>{text}</>,
                },
                {
                    title: t('cpes.table.user'),
                    key: 'userLastName',
                    sorter: true,
                    defaultSortOrder: 'ascend' as 'ascend',
                    render: (text: string, record) => <>{record.userUuid ? <Link
                        href={userDetailUrl.replace(':id', record.userUuid)}>{`${record.userFirstName} ${record.userLastName}`}</Link> : ''}</>,
                },
                ...(hotDeskEnabled ? [{
                    title: t('cpes.hot-desk-dialog.title'),
                    key: 'hotDeskExtension',
                    sorter: true,
                    render: (text: string, record: CPE) => <>{!!text ? <CheckIcon color={'success'}/> : ''}</>,
                }] : []),
                {
                    title: '',
                    key: 'uuid',
                    sorter: false,
                    render: (text: string, record: CPE) => <TableRowActionsMenu record={record}
                                                                                menuItems={rowActions} readOnly={!hasWriteAccess}/>,
                },
            ];
        },
        [t, rowActions, hotDeskEnabled, hasWriteAccess]
    )

    const sorter = useCallback(col => sorterHeader(col, sort, setSort), [sort, setSort])

    const getRowKey = useCallback(cpe => cpe.uuid, [])

    const onSearch = useCallback(async (searchTerm: string) => setSearchTerm(searchTerm), [setSearchTerm])

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const buttonsPanel = useMemo(() =>
        <div>
            <IconButton color="secondary" onClick={handleClick}>
                <SettingsIcon/>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {setShowConfigMpp(true); handleClose();}}>{t("cpes.mpp.configure")}</MenuItem>
                <MenuItem onClick={() => {setShowSearchByMac(true); handleClose();}}>{t("cpes.search-by-mac")}</MenuItem>
            </Menu>
        </div>, [t, setShowConfigMpp, handleClose, anchorEl])

    return (
        <>
            <TitledBody title={t('cpes.title')} buttonsPanel={buttonsPanel}>
                <SearchField onSearch={onSearch} />
                <ErrorAlert showAlert={!!error} />
                <InfiniteScrollTable<CPE>
                    hasNextPage={hasNextPage}
                    data={rows}
                    fetchNextPage={fetchNextPage}
                    tableId={'cpetable'}
                    isFetching={isFetching}
                    getRowKey={getRowKey}
                    sorter={sorter}
                    columns={columns}
                />
                {cpeToChangeUser && <AttachUserToCpeDialog onSuccess={refetch} cpe={cpeToChangeUser} handleClose={() => setCpeToChangeUser(null)} />}
                <ActivationCodeDialog cpe={activationCodeCpe} handleClose={() => setActivationCodeCpe(null)} />
                {hotDeskCpe && <HotDeskDialog onSuccess={refetch} cpe={hotDeskCpe} handleClose={() => setHotDeskCpe(null)} /> }
                {showConfigMpp && <MppDialog handleClose={() => setShowConfigMpp(false)}/>}
                {showSearchByMac && <SearchByMacDialog handleClose={() => setShowSearchByMac(false)}/>}
            </TitledBody>
        </>
    )
}
