import { useSelector } from 'react-redux'
import { selectedSiteSelector } from '../authentication/redux'
import React, { ReactNode, useCallback, useMemo } from 'react'
import { LicenceInfo } from '../app/types'
import { api } from '../common/api'
import { useFetchData } from '../common/servercall/useFetchData'
import { WithErrorAndFetching } from '../common/servercall/WithErrorAndFetching'
import { ColumnDefinition, InfiniteScrollTable } from '../common/components/table/Table'
import { sorterHeader } from '../common/components/table/SorterHeader'
import { useTranslation } from 'react-i18next'
import { Avatar, Card, CardContent, CardHeader, CardMedia } from '@mui/material'
import { CardMembership } from '@mui/icons-material'
import { sum } from 'ramda'

export const LicenceStats = () => {
    const site = useSelector(selectedSiteSelector)
    const { t } = useTranslation()

    const fetchLicenceInfo = useCallback(async (): Promise<LicenceInfo[]> => {
        if (!site) return []
        const { data } = await api.get(`/api/site/${site.productId}/stats/licences`)
        return data
    }, [site])

    const { data: licences, isFetching: fetchingLicenses, error: licenceError } = useFetchData<LicenceInfo[]>(useCallback(() => fetchLicenceInfo(), [fetchLicenceInfo]))

    const columns: ColumnDefinition<LicenceInfo>[] = useMemo(
        () => [
            {
                title: t('site.cards.licenses.table.license'),
                key: 'name',
                sorter: false,
                render: (text: string, record: LicenceInfo) => <>{t(`users.license.${text.toLowerCase()}`)}</>,
            },
            {
                title: t('site.cards.licenses.table.number'),
                key: 'total',
                sorter: false,
                render: (text: number, record) => <>{`${record.taken} / ${record.total}`}</>,
            },
        ],
        [t]
    )

    const sorter = useCallback(col => sorterHeader(col, null, () => {}), [])

    const renderLicenceInfo = useCallback(
        (licences: LicenceInfo[]): ReactNode => (
            <Card sx={{ height: '100%' }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: theme => theme.palette.primary.main, color: theme => theme.palette.secondary.main }} variant="rounded">
                            <CardMembership />
                        </Avatar>
                    }
                    title={t('site.cards.licenses.title')}
                    subheader={<>{`# ${sum(licences.map(licence => licence.taken))} / ${sum(licences.map(licence => licence.total))}`}</>}
                    action={<></>}
                />
                <CardMedia></CardMedia>
                <CardContent>
                    <InfiniteScrollTable<LicenceInfo>
                        data={licences}
                        hasNextPage={false}
                        fetchNextPage={() => {}}
                        getRowKey={row => row.name}
                        sorter={sorter}
                        columns={columns}
                        tableId={'licenceInfo'}
                        maxHeight={'18vh'}
                    />
                </CardContent>
            </Card>
        ),
        [columns, sorter, t]
    )

    return <WithErrorAndFetching<LicenceInfo[]> isFetching={fetchingLicenses} error={licenceError} data={licences} render={renderLicenceInfo} />
}
