import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {compose, find, prop, propEq} from 'ramda'
import {ExtensionValidationRule, OktaClaims, PortalAnnouncement, PortalConfig, Site, User} from '../../app/types'
import {RootState} from '../../app/store'
import {api} from '../../common/api'
import i18n from 'i18next'
import OktaAuth from "@okta/okta-auth-js";

export const fetchMe = createAsyncThunk<User, OktaAuth | null, { rejectValue: null | string }>('authentication/FETCH_ME', async (oktaAuth, { rejectWithValue }) => {
    try {
        if (!!oktaAuth) {
            let oktaClaims = null
            try {
                oktaClaims  = await oktaAuth.getUser<OktaClaims>();
            } catch (e: any) {
                return rejectWithValue('unauthorized')
            }

            oktaClaims.language && i18n.changeLanguage(oktaClaims.language)
            const { data: user } = await api.get('/api/me')
            if (user && !user.customerProductId) {
                return rejectWithValue("Product not found")
            }
            return {
                ...user,
                language: oktaClaims.language,
                username: oktaClaims.preferred_username
            }
        } else {
            const { data: user } = await api.get('/api/me')
            if (user && !user.customerProductId) {
                return rejectWithValue("Product not found")
            }
            user.language && i18n.changeLanguage(user.language)
            return user
        }
    } catch (err: any) {
        try {
            if (!!err.response) {
                return rejectWithValue(err.response.status.toString())
            } else {
                return rejectWithValue('unknown')
            }
        } catch (e: any) {
            console.error(e)
        }

    }
})

export const fetchConfig = createAsyncThunk<PortalConfig, string, { state: RootState; rejectValue: null | string }>(
    'authentication/FETCH_CONFIG',
    async (siteProductId, { getState, rejectWithValue }) => {
        try {
            const { data: config } = await api.get('/api/site/' + siteProductId + '/config', {
                headers: {},
            })
            return config
        } catch (err: any) {
            return rejectWithValue(err.response.status)
        }
    }
)

export const selectSite = createAsyncThunk<Site | null, string | null, { state: RootState; rejectValue: null  }>('authentication/SELECT_SITE', async (siteProductId, { getState, dispatch, rejectWithValue }) => {
    const me = meSelector(getState())
    if (me && me.sites) {
        const sessionStorageSiteProductIdKey = `${me.username}:selectedSite`
        const siteProductIdFromSessionStorage = sessionStorage.getItem(sessionStorageSiteProductIdKey)
        if (!siteProductId) {
            siteProductId = siteProductIdFromSessionStorage
        }
        if (!!siteProductId) {
            const foundSite = find(propEq('productId', siteProductId), me.sites) || null
            if (foundSite) {
                sessionStorage.setItem(sessionStorageSiteProductIdKey, foundSite.productId)
                await dispatch(fetchConfig(foundSite.productId))
            } else {
                if (!!siteProductIdFromSessionStorage) {
                    sessionStorage.removeItem(`${me.username}:selectedSite`)
                }
                if (me.sites.length > 0) {
                    await dispatch(fetchConfig(me.sites[0].productId))
                    return null
                } else {
                    return rejectWithValue(null)
                }
            }
            return foundSite
        } else if (me.sites.length > 0) {
            await dispatch(fetchConfig(me.sites[0].productId))
            return null
        }
        return null
    } else {
        return null
    }
})

export const clearSite = createAsyncThunk<void, undefined, { state: RootState }>('authentication/CLEAR_SITE', async (arg, {getState, dispatch }) => {
    const me = meSelector(getState())
    if (me) {
        sessionStorage.removeItem(`${me.username}:selectedSite`)
    }
})

type State = {
    me: null | User
    loading: boolean
    loadingFailureStatus: undefined | null | string
    selectedSite: null | Site
    previousSelectedSite: null | Site
    config: null | PortalConfig
    refetchStateTrigger: number
    announcementDismissed: boolean
}
const initialState: State = {
    me: null,
    loading: true,
    loadingFailureStatus: null,
    selectedSite: null,
    previousSelectedSite: null,
    config: null,
    refetchStateTrigger: 0,
    announcementDismissed: false
}

const authSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        clearSelectedSite(state) {
            state.previousSelectedSite = state.selectedSite
            state.selectedSite = null
        },
        announcementDismissed(state) {
            state.announcementDismissed = true
        },
        triggerRefetchSiteState(state) {
            state.refetchStateTrigger = state.refetchStateTrigger + 1
        },
        clear(state) {
            state = initialState
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchMe.fulfilled, (state, action) => {
                state.me = action.payload
                state.loadingFailureStatus = null
            })
            .addCase(fetchMe.pending, (state, action) => {
                state.me = null
                state.loading = true
                state.loadingFailureStatus = null
            })
            .addCase(fetchMe.rejected, (state, action) => {
                state.me = null
                state.loading = false
                state.loadingFailureStatus = action.payload
            })
            .addCase(selectSite.pending, (state, action) => {
                state.loadingFailureStatus = null
                state.loading = true
            })
            .addCase(selectSite.fulfilled, (state, action) => {
                state.loading = false
                state.previousSelectedSite = state.selectedSite
                state.selectedSite = action.payload
                state.loadingFailureStatus = null
            })
            .addCase(selectSite.rejected, (state, action) => {
                state.loading = false
                state.previousSelectedSite = state.selectedSite
                state.selectedSite = null
                state.loadingFailureStatus = "Site not found"
            })
            .addCase(fetchConfig.fulfilled, (state, action) => {
                state.config = action.payload
            })
            .addCase(clearSite.fulfilled, (state, action) => {
                state.loading = false
                state.selectedSite = null
                state.previousSelectedSite = null
                state.loadingFailureStatus = null
            })
    },
})

export const { clearSelectedSite, triggerRefetchSiteState, announcementDismissed , clear} = authSlice.actions

export const authenticationSelector: (state: RootState) => State = prop('authentication')
export const announcementDismissedSelector: (state: RootState) => boolean = compose(prop('announcementDismissed'), authenticationSelector)
export const meSelector: (state: RootState) => null | User = compose(prop('me'), authenticationSelector)
export const loadingSelector: (state: RootState) => boolean = compose(prop('loading'), authenticationSelector)
export const loadingFailureSelector: (state: RootState) => string | null | undefined = compose(prop('loadingFailureStatus'), authenticationSelector)
export const selectedSiteSelector: (state: RootState) => null | Site = compose(prop('selectedSite'), authenticationSelector)
export const inEnterpriseViewSelector: (state: RootState) => boolean = compose(propEq('selectedSite', null), authenticationSelector)
export const previousSelectedSiteSelector: (state: RootState) => null | Site = compose(prop('previousSelectedSite'), authenticationSelector)
export const refetchStateTriggerSelector: (state: RootState) => number = compose(prop('refetchStateTrigger'), authenticationSelector)

export const sitesSelector: (state: RootState) => null | Site[] = (state: RootState) => {
    const me = meSelector(state)
    return !!me ? me.sites : []
}
export const configSelector: (state: RootState) => null | PortalConfig = compose(prop('config'), authenticationSelector)
export const extensionValidationRulesSelector: (state: RootState) => ExtensionValidationRule[] = (state: RootState) => {
    const config = configSelector(state)
    return config?.extensionValidationRules || []
}
export const allowedUserNcosSelector: (state: RootState) => string = (state: RootState) => {
    const config = configSelector(state)
    return config?.allowedUserNcos || ''
}
export const defaultNcosSelector: (state: RootState) => string = (state: RootState) => {
    const config = configSelector(state)
    return config?.defaultNcos || ''
}

export const announcementSelector: (state: RootState) => PortalAnnouncement | null = (state: RootState) => {
    const config = configSelector(state)
    return config?.announcement || null
}

export const fmuEnabledSelector: (state: RootState) => boolean = (state: RootState) => {
    const config = configSelector(state)
    return config?.fmuEnabled || false
}

export const callRecordingEnabledSelector: (state: RootState) => boolean = (state: RootState) => {
    const config = configSelector(state)
    return config?.callRecordingEnabled || false
}

export const executiveAssistantEnabledSelector: (state: RootState) => boolean = (state: RootState) => {
    const config = configSelector(state)
    return config?.executiveAssistantEnabled || false
}

export const hotDeskEnabledSelector: (state: RootState) => boolean = (state: RootState) => {
    const config = configSelector(state)
    return config?.hotDeskEnabled || false
}

export const pagingEnabledSelector: (state: RootState) => boolean = (state: RootState) => {
    const config = configSelector(state)
    return config?.pagingEnabled || false
}

export const callPickupEnabledSelector: (state: RootState) => boolean = (state: RootState) => {
    const config = configSelector(state)
    return config?.callPickupEnabled || false
}

export const maxIvrsSelector: (state: RootState) => number = (state: RootState) => {
    const config = configSelector(state)
    return config?.maxNrOfIvrs || 0
}

export const maxCallQueueSelector: (state: RootState) => number = (state: RootState) => {
    const config = configSelector(state)
    return config?.maxNrOfCallQueues || 0
}

export const hasWriteAccessSelector = (state: RootState) => {
    const me = meSelector(state)
    return me?.privileges.includes('ENTITY_UPDATE')
}

export const isEnterpriseAdminSelector = (state: RootState) => {
    const me = meSelector(state)
    return !!me?.enterpriseAdmin
}

export const reducer = authSlice.reducer
