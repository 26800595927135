import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../../common/components/TitledBody'
import {useFetchData} from '../../common/servercall/useFetchData'
import {CallPickupGroup} from '../../app/types'
import {useParams} from 'react-router'
import {api} from '../../common/api'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import {Page} from '../../app/navigation/redux'
import {Grid} from '@mui/material'
import {SectionedForm} from "../../common/components/SectionedForm";
import {useGeneralSection} from "./general";
import {useAccessHelpers} from "../../common/util/hooks";

export const DetailCallPickupGroup = () => {
    const { t } = useTranslation()
    const { id }: { id: string } = useParams()
    const site = useSelector(selectedSiteSelector)
    const {hasWriteAccess} = useAccessHelpers()

    const fetch = useCallback(
        async (callPickupId: string): Promise<CallPickupGroup | undefined> => {
            if (!site) return
            const { data } = await api.get<CallPickupGroup>(`/api/site/${site.productId}/callpickupgroup/${callPickupId}`)
            return data
        },
        [site],
    )

    const {
        data,
        isFetching,
        error,
        refetch
    } = useFetchData<CallPickupGroup>(useCallback(() => fetch(id), [fetch, id]))

    const pageInfo: Page | null = useMemo(
        () =>
            data
                ? {
                    label: `${data.name}`,
                    key: 'flow-detail',
                }
                : null,
        [data],
    )

    const generalSection = useGeneralSection(id, isFetching, error, refetch, data);
    const formSections = useMemo(() => [
        generalSection
    ], [generalSection])

    return (
        <TitledBody title={t('call-pickup-groups.detail.title')} page={pageInfo}>
            <Grid container>
                <Grid item xs={12} lg={10}>
                    <SectionedForm formSections={formSections} activeTab={0} readOnly={!hasWriteAccess}/>
                </Grid>
            </Grid>
        </TitledBody>
    )
}
