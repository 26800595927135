import React, {useCallback, useMemo, useState} from 'react'
import {TFunction, useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {matchPath, useLocation} from 'react-router'
import {Group} from '../../group'
import {Users} from '../../users'
import {Holidays} from '../../schedules/holidays'
import {RouteDefinition, RouteDefinitions} from '../App'
import {Collapse, List, ListItem, ListItemIcon, ListItemText} from '@mui/material'
import AppsIcon from '@mui/icons-material/Apps'
import DialpadIcon from '@mui/icons-material/Dialpad'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CallSplitIcon from '@mui/icons-material/CallSplit'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import ScheduleIcon from '@mui/icons-material/Schedule'
import PersonIcon from '@mui/icons-material/Person';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import {Cpes} from '../../cpes'
import {Numbers} from '../../numbers'
import {Flows} from '../../flows'
import {Ivrs} from '../../ivrs'
import {PhoneBook} from '../../phonebook'
import {Announcements} from '../../announcements'
import {Fax, LibraryMusic, Mediation} from '@mui/icons-material'
import {EnterpriseSites} from "../../enterprisesites";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GroupIcon from '@mui/icons-material/Group';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import CampaignIcon from '@mui/icons-material/Campaign';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import {FACList} from "../../services/facs/FACList";
import {PagingList} from "../../services/pagings";
import {CallPickupGroupList} from "../../services/callpickupgroups";
import {DetailEmergencyCalls} from "../../services/emergencycalls/detail";
import {ExecutiveList} from "../../services/executiveassistants";
import {callPickupEnabledSelector, pagingEnabledSelector} from "../../authentication/redux";
import {useSelector} from "react-redux";
import BedTimeIcon from "@mui/icons-material/Bedtime";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import {DetailNightService} from "../../services/nightservice/detail";
import {OpeningHours} from "../../schedules/openinghours";

export const NavigationDefinition: RouteDefinition[] = [
    {
        translationKey: 'navigation.enterprise-sites',
        href: '/sites',
        icon: (props: any) => <LocationOnIcon {...props} />,
        component: EnterpriseSites,
        id: 'groups-overview',
        showInEnterpriseMode : true,
        hideInSiteMode: true
    },
    {
        translationKey: 'navigation.site',
        href: '/site',
        icon: (props: any) => <LocationOnIcon {...props} />,
        component: Group,
        id: 'group-overview',
        showInEnterpriseMode : false,
    },
    {
        translationKey: 'navigation.users',
        href: '/users',
        icon: (props: any) => <PersonIcon {...props} />,
        component: Users,
        id: 'user-overview',
        showInEnterpriseMode : false,
    },
    {
        translationKey: 'navigation.cpe',
        href: '/cpe',
        icon: (props: any) => <Fax {...props} />,
        component: Cpes,
        id: 'cpe-overview',
        showInEnterpriseMode : false,
    },
    {
        translationKey: 'navigation.numbers',
        href: '/numbers',
        icon: (props: any) => <DialpadIcon {...props} />,
        component: Numbers,
        id: 'number-overview',
        showInEnterpriseMode : false,
    },
    {
        translationKey: 'navigation.flows',
        href: '/flows',
        icon: (props: any) => <CallSplitIcon {...props} />,
        component: Flows,
        id: 'flow-overview',
        showInEnterpriseMode : false,
    },
    {
        translationKey: 'navigation.ivrs',
        href: '/ivrs',
        icon: (props: any) => <Mediation {...props} />,
        component: Ivrs,
        id: 'ivr-overview',
        showInEnterpriseMode : false,
    },
    {
        translationKey: 'navigation.services',
        href: '/services',
        icon: (props: any) => <AppsIcon {...props} />,
        component: () => null,
        id: 'service-overview',
        showInEnterpriseMode : false,
    },
    {
        translationKey: 'navigation.call-pickup-groups',
        href: '/call-pickup-groups',
        icon: (props: any) => <GroupIcon {...props} />,
        component : CallPickupGroupList,
        id: 'call-pickup-group-overview',
        menuParentId: 'service-overview',
        showInEnterpriseMode : false,
        hidden: (pagingEnabled, callPickupEnabled) => !callPickupEnabled
    },
    {
        translationKey: 'navigation.executive-assistants',
        href: '/executive-assistant',
        icon: (props: any) => <GroupWorkIcon {...props} />,
        component : ExecutiveList,
        id: 'executive-assistant-overview',
        menuParentId: 'service-overview',
        showInEnterpriseMode : false,
    },
    {
        translationKey: 'navigation.pagings',
        href: '/pagings',
        icon: (props: any) => <CampaignIcon {...props} />,
        component : PagingList,
        id: 'paging-overview',
        menuParentId: 'service-overview',
        showInEnterpriseMode : false,
        hidden: (pagingEnabled, callPickupEnabled) => !pagingEnabled
    },
    {
        translationKey: 'navigation.facs',
        href: '/facs',
        icon: (props: any) => <AccessibilityNewIcon {...props} />,
        component : FACList,
        id: 'fac-overview',
        menuParentId: 'service-overview',
        showInEnterpriseMode : false,
    },
    {
        translationKey: 'navigation.emergency-call-notification',
        href: '/emergency-call-notification',
        icon: (props: any) => <ContactEmergencyIcon {...props} />,
        component : DetailEmergencyCalls,
        id: 'emergency-call-notification',
        menuParentId: 'service-overview',
        showInEnterpriseMode : false,
    },
    {
        translationKey: 'navigation.phonebook',
        href: '/phonebook',
        icon: (props: any) => <MenuBookIcon {...props} />,
        component: PhoneBook,
        id: 'phonebook-overview',
        showInEnterpriseMode : true,
        hideInSiteMode: false
    },
    {
        translationKey: 'navigation.schedules',
        href: '/schedules',
        icon: (props: any) => <PendingActionsIcon {...props} />,
        component: () => null,
        id: 'schedule-overview',
        showInEnterpriseMode : false,
    },
    {
        translationKey: 'navigation.holidays',
        href: '/holidays',
        icon: (props: any) => <BeachAccessIcon {...props} />,
        component : Holidays,
        id: 'holiday-overview',
        menuParentId: 'schedule-overview',
        showInEnterpriseMode : false,
    },
    {
        translationKey: 'navigation.opening-hours',
        href: '/opening-hours',
        icon: (props: any) => <ScheduleIcon {...props} />,
        component : OpeningHours,
        id: 'opening-hours-overview',
        menuParentId: 'schedule-overview',
        showInEnterpriseMode : false,
    },
    {
        translationKey: 'navigation.night-service',
        href: '/night-service',
        icon: (props: any) => <BedTimeIcon {...props} />,
        component : DetailNightService,
        id: 'night-service',
        menuParentId: 'schedule-overview',
        showInEnterpriseMode : false,
    },
    {
        translationKey: 'navigation.announcements',
        href: '/announcements',
        icon: (props: any) => <LibraryMusic {...props} />,
        component: Announcements,
        id: 'announcement-overview',
        showInEnterpriseMode : false,
    },
]

export const routeDefinitions = (inEnterpriseView: boolean) : RouteDefinition[] =>
    NavigationDefinition.filter(definition => (inEnterpriseView && definition.showInEnterpriseMode)
        || (!inEnterpriseView && !definition.hideInSiteMode)
    )

const SingleLevelItem = ({definition, t, activeDefinition} : {definition: RouteDefinition, t: TFunction, activeDefinition: RouteDefinition | undefined}) => {
    return <ListItem title={definition.translationKey && t(definition.translationKey)}
                     key={`menu-${definition.id}`} selected={definition.id === activeDefinition?.id} button
                     component={Link} to={definition.href}>
        <ListItemIcon sx={{ color: 'inherit' }}>
            <definition.icon />
        </ListItemIcon>
        <ListItemText primary={definition.translationKey && t(definition.translationKey)} />
    </ListItem>
}

const MultiLevelItem = ({definition, children, t, activeDefinition} : {definition: RouteDefinition, children: RouteDefinition[], t: TFunction, activeDefinition: RouteDefinition | undefined}) => {

    const isChildActive = useCallback(() => activeDefinition?.menuParentId === definition.id, [activeDefinition, definition.id])

    const [open, setOpen] = useState<boolean>(isChildActive());

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const expandIcon = useMemo(() => open ? <ExpandLessIcon /> : <ExpandMoreIcon /> , [open])

    const subList = useMemo(() => <Collapse in={open} timeout="auto"  unmountOnExit={false}>
        <List component="div" style={{backgroundColor: '#F8D975' }} >
            {children.map((child) => <SingleLevelItem definition={child} t={t} activeDefinition={activeDefinition} key={child.translationKey}/> )}
        </List>
    </Collapse> , [open, activeDefinition, children, t])

    return<><ListItem title={definition.translationKey && t(definition.translationKey)}
                     key={`menu-${definition.id}`} selected={false} button onClick={handleClick}>
            <ListItemIcon sx={{ color: 'inherit' }}>
                <definition.icon />
            </ListItemIcon>
            <ListItemText primary={definition.translationKey && t(definition.translationKey)} />
            {expandIcon}
        </ListItem>
        {subList}
        </>
}

const MenuListItem = ({definition, t, activeDefinition} : {definition: RouteDefinition, t: TFunction, activeDefinition: RouteDefinition | undefined}) =>
{
    const pagingEnabled = useSelector(pagingEnabledSelector)
    const callPickupEnabled = useSelector(callPickupEnabledSelector)

    const findChildren = useCallback(() =>
            NavigationDefinition
                .filter(def => def.menuParentId === definition.id)
                .filter(definition => !definition.hidden || !definition.hidden(pagingEnabled, callPickupEnabled))
        , [definition, pagingEnabled, callPickupEnabled] )

    return findChildren().length > 0 ?
        <MultiLevelItem activeDefinition={activeDefinition} t={t} definition={definition} children={findChildren()} />
        : <SingleLevelItem definition={definition} t={t} activeDefinition={activeDefinition}/>
}

const NavigationMenu = ({inEnterpriseView} : {inEnterpriseView: boolean}) => {
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const pagingEnabled = useSelector(pagingEnabledSelector)
    const callPickupEnabled = useSelector(callPickupEnabledSelector)

    let activeDefinition = RouteDefinitions.find(def => matchPath(pathname, def.href))

    if (activeDefinition?.menuId) {
        activeDefinition = RouteDefinitions.find(def => def.id === activeDefinition?.menuId)
    }

    const NavDefs = useMemo(() =>
        routeDefinitions(inEnterpriseView)
        .filter(definition => !definition.menuParentId)
        .filter(definition => !definition.hidden || !definition.hidden(pagingEnabled, callPickupEnabled))
        .map(definition => <MenuListItem t={t} definition={definition} activeDefinition={activeDefinition} key={definition?.id}/>
    ) , [inEnterpriseView, activeDefinition, t, pagingEnabled, callPickupEnabled])

    return (
        <>
            {NavDefs}
        </>
    )
}

export default NavigationMenu
