import React from 'react'
import { useTranslation } from 'react-i18next'
import { debounce, IconButton, TextField } from '@mui/material'
import { Box, Flex } from 'rebass'
import CancelIcon from '@mui/icons-material/Cancel'

type Props = {
    onSearch: (searchTerm: string) => void
}

export const SearchField = ({ onSearch }: Props) => {
    const { t } = useTranslation()

    const [searchTerm, setSearchTerm] = React.useState<string | null>(null)

    const debouncedFetchData = debounce(async value => {
        await onSearch(value)
    }, 400)

    React.useEffect(() => {
        searchTerm !== null && debouncedFetchData(searchTerm)
    }, [searchTerm, debouncedFetchData])

    return (
        <Flex mb={3} justifyContent='space-between' alignItems='center' flexWrap='wrap'>
            <Box width={[1, 1 / 2, 1 / 2]} mt={2}>
                <TextField
                    type='text'
                    size='small'
                    id='outlined-basic'
                    label={`${t('general.search')}...`}
                    variant='outlined'
                    value={searchTerm || ''}
                    autoFocus={true}
                    InputProps={{
                        endAdornment: searchTerm && (
                            <IconButton size='small' onClick={() => setSearchTerm('')}>
                                <CancelIcon />
                            </IconButton>
                        ),
                    }}
                    onChange={e => {
                        setSearchTerm(e.target.value)
                    }}
                />
            </Box>
        </Flex>
    )
}
