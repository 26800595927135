import React, {useCallback, useEffect, useRef, useState} from 'react'
import ReactHowler from 'react-howler'
import {Grid, IconButton} from '@mui/material'
import {api} from '../api'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

const AudioPlayer = ({wavUrl}: { wavUrl: string, authorizationHeader?: string }) => {
    const [playing, setPlaying] = useState<boolean>(false)
    const [seek, setSeek] = useState<number>(0.0)
    const [duration, setDuration] = useState<number>(0.0)
    const [wavBlob, setWavBlob] = useState<string>()

    var player = useRef<ReactHowler>(null)
    const rafRef = useRef<number>()

    const clearRAF = useCallback(() => {
        if (rafRef.current) {
            cancelAnimationFrame(rafRef.current)
        }
    }, [rafRef])

    const renderSeekPos = useCallback(() => {
        if (player.current) {
            setSeek(player.current.seek())
        }

        rafRef.current = requestAnimationFrame(renderSeekPos)
    }, [player, rafRef, setSeek])

    useEffect(() => {
        if (playing) {
            renderSeekPos()
        } else {
            setSeek(0)
            clearRAF()
        }
    }, [playing, renderSeekPos, setSeek, clearRAF])


    const handleToggle = async () => {
        await getData()
        setPlaying(!playing)
    }

    const handleOnLoad = () => {
        if (player.current != null) {
            setDuration(player.current.duration())
        }
    }

    const handleOnPlay = () => {
        if (player.current != null) {
            setPlaying(true)
        }
    }

    const handleOnEnd = () => {
        setPlaying(false)
        clearRAF()
    }

    const handleStop = () => {
        if (player.current) {
            player.current.stop()
        }

        setPlaying(false)
    }

    const getData = async () => {
        const {data} = await api.get(wavUrl, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'audio/wav',
            },
        })
        const blob = new Blob([data], {type: 'audio/wav'})
        setWavBlob(URL.createObjectURL(blob))
    }

    return (
        <Grid container
              spacing={1}
              flexWrap={'nowrap'}
              justifyContent={'center'}
              flexDirection={'row'}
              alignItems={'center'}>

            {wavBlob && <ReactHowler
                preload={false}
                format={'wav'}
                src={[wavBlob]}
                playing={playing}
                onLoad={handleOnLoad}
                onPlay={handleOnPlay}
                onEnd={handleOnEnd}
                volume={1.0}
                ref={player}
                html5={false}
                xhr={{
                    method: 'POST',
                    withCredentials: true,
                }}
            />
            }
            <Grid item>
                <span style={{whiteSpace: 'nowrap'}}>
                    {(duration) ? seek.toFixed(0) : 0}
                    {' / '}
                    {(duration) ? duration.toFixed(0) : '0'}
                </span>
            </Grid>

            <Grid item>
                <span className='slider-container'>
                  <input style={{cursor: 'not-allowed'}}
                         disabled={!playing}
                         type='range'
                         min='0'
                         max={duration ? duration.toFixed(2) : 0}
                         step='.01'
                         value={seek}
                  />
                </span>
            </Grid>

            <Grid item alignItems={'center'} justifyContent={'center'} sx={{pt: 0, pl: 0, pb: 1}}>
                {!playing &&
                    <IconButton onClick={handleToggle} color={'secondary'} aria-label="Play" component="label">
                        <PlayArrowIcon/>
                    </IconButton>
                }

                {playing &&
                    <IconButton onClick={handleStop} color={'secondary'} aria-label="Play" component="label">
                        <StopIcon/>
                    </IconButton>
                }

            </Grid>
        </Grid>
    )
}

export default AudioPlayer
