import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

type Props = {
    isInfiniteScrollOn: boolean
    hasMore: boolean
    lengthData: number
    functionNext: any
    children: any
    scrollableTarget: string
}

export const InfiniteScrollWrapper = (props: Props) => {
    if (!props.isInfiniteScrollOn) {
        return <>{props.children}</>
    }

    return (
        <InfiniteScroll dataLength={props.lengthData} next={props.functionNext} hasMore={props.hasMore} loader={<></>}
                        scrollableTarget={props.scrollableTarget}>
            {props.children}
        </InfiniteScroll>
    )
}
