import React, {ReactElement, ReactNode, useCallback} from 'react'
import {FormSection} from "../../common/components/SectionedForm";
import {api} from "../../common/api";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import {useTranslation} from "react-i18next";
import PaperStack from "../../common/form/PaperStack";
import FormGroup from "../../common/form/FormGroup";
import {LabelValues, LabelValueType} from "../../common/components/LabelValues";
import {CallPickupGroup} from "../../app/types";
import {formatUser} from "../../common/util/formatters";
import {List, ListItem, ListItemText} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";
import Form from "./Form";

export const useGeneralSection = (callPickupGroupId: string, isFetching: boolean, error: any, refetch: () => void, data?: CallPickupGroup) : FormSection<CallPickupGroup> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const {buildFormButtons} = useFormSubmissionButtons(false)

    const onSubmit = useCallback((onSucceeded: () => void) =>
        async (formData: CallPickupGroup) => {
            if (!site) return
            await api.put(`/api/site/${site.productId}/callpickupgroup/${callPickupGroupId}`, {
                ...formData,
                userIds: formData.users?.map(user => user.uuid).join(','),
            })
            await refetch()
            onSucceeded();
        },
        [site, callPickupGroupId, refetch],
    )

    const renderGeneralForm = useCallback(
        (callPickupGroup: CallPickupGroup, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode =>
            <Form
                callPickupGroup={callPickupGroup}
                onSubmit={onSubmit(onSucceeded)}
                buildFormButtons={buildFormButtons(cancelEditButton)}
            />
        , [onSubmit, buildFormButtons])

    const infoLabelValues = useCallback(
        (value: CallPickupGroup): LabelValueType[] => [
            { label: t('call-pickup-groups.edit.name'), value: <>{value.name}</> },
        ],
        [t],
    )

    const users = useCallback(
        (value: CallPickupGroup): LabelValueType[] => [
            {
                label: t('call-pickup-groups.edit.section.users'),
                value: (
                    <List dense={false}>
                        {value.users.map(it => (
                            <ListItem key={it.uuid}>
                                <ListItemText primary={formatUser(it)}></ListItemText>
                            </ListItem>
                        ))}
                    </List>
                ),
                type: 'FULL_LINE',
            },
        ],
        [t],
    )

    const renderDetails = useCallback(
        (callPickupGroup: CallPickupGroup): ReactNode => {
            return (
                <PaperStack>
                    <FormGroup key={1} label={t('call-pickup-groups.edit.section.info')} fullWidth={true}>
                        <LabelValues labelValues={infoLabelValues(callPickupGroup)} />
                    </FormGroup>
                    <FormGroup key={2} label={t('call-pickup-groups.edit.section.users')} fullWidth={true}>
                        <LabelValues labelValues={users(callPickupGroup)} />
                    </FormGroup>
                </PaperStack>
            )
        },
        [infoLabelValues, users, t],
    )

    return {
        title: t('general.general'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderDetails,
        renderForm: renderGeneralForm,
        enabled: () => true,
        icon: () => <GroupIcon/>
    }
}