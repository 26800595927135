import TableCell from '@mui/material/TableCell'
import { TableSortLabel } from '@mui/material'
import React, { ReactElement } from 'react'
import { ColumnDefinition } from './Table'

export const sorterHeader = (col: ColumnDefinition<any>, sort: any, setSort: (sort: any) => void): ReactElement =>
    col.sorter ? (
        <TableCell style={{ fontWeight: 700, ...col.style }} key={`header-${col.key? col.key.toString(): col.title}`}
                   sortDirection={sort && sort.field === col.key ? sort.order : false}>
            <TableSortLabel
                active={sort && sort.field === col.key}
                direction={sort && sort.field === col.key ? sort.order : 'asc'}
                onClick={() =>
                    setSort({
                        field: col.key,
                        order: sort && sort.field === col.key && sort.order === 'asc' ? 'desc' : 'asc',
                    })
                }
            >
                {col.title}
            </TableSortLabel>
        </TableCell>
    ) : (
        <TableCell style={{ fontWeight: 700, ...col.style }} key={`header-${col.key? col.key.toString(): col.title}`}>
            {col.title}
        </TableCell>
    )
