import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {api} from '../../../common/api'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../../authentication/redux'
import {ErrorOption} from 'react-hook-form/dist/types/errors'
import {useFormSubmission} from '../../../common/servercall/useFormSubmission'
import {Box, Grid, IconButton, Stack} from '@mui/material'
import {Cancel, Check} from '@mui/icons-material'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {Spinner} from '../../../common/components/Spinner'
import TextInput from "../../../common/form/TextInput";
import FormCheckbox from "../../../common/form/FormCheckbox";

const schema = yup.object({
    divertNumber: yup
        .string()
        .when(['enableDivert'], {
            is: (enableDivert: boolean) => enableDivert,
            then: schema => yup.string().trim().max(22, 'too-long').required('required-m').matches(/^\+?[0-9]*$/, {
                message: 'invalid-phone-number',
                excludeEmptyString: true,
            }).nullable(),
            otherwise: schema =>
                yup.string().trim().max(22, 'too-long').matches(/^\+?[0-9]*$/, {
                    message: 'invalid-phone-number',
                    excludeEmptyString: true,
                }).nullable()
        }),
})

type DivertForm = {
    divertNumber: string
    enableDivert: boolean
}

type props = {
    form: DivertForm,
    executiveId: string
    userBusinessId: string
    onCancel: () => void
    onSuccess: () => void
}

export const AssistantDivertForm = ({ form, onSuccess, onCancel, executiveId, userBusinessId }: props) => {
    const site = useSelector(selectedSiteSelector)

    const { control, handleSubmit, setError, getFieldState, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: form,
    })

    const onSubmit = useCallback(
        async (formData: DivertForm) => {
            if (!site) return
            await api.put(`/api/site/${site.productId}/executive/${executiveId}/assistant/${userBusinessId}/divert`, formData)
            onSuccess()
        },
        [site, onSuccess, userBusinessId, executiveId],
    )

    const checkError = useCallback(
        (name: any, error: ErrorOption) => {
            setError(name, error)
        },
        [setError],
    )

    const { submit, isSubmitting } = useFormSubmission<DivertForm>(onSubmit, checkError)

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Stack direction={'row'}>
                <Grid item alignSelf={'left'} sx={{}}>
                    <FormCheckbox
                        label={''}
                        name={'enableDivert'}
                        control={control}
                        setValue={setValue}
                        getFieldState={getFieldState}
                    />
                </Grid>
                <Grid item alignSelf={'left'} sx={{ marginTop: -2 }}>
                    <TextInput readonly={false} label={' '} name={'divertNumber'} control={control} required={false}  />
                </Grid>
                {
                    isSubmitting ?
                        <Box alignSelf={'center'} sx={{ marginLeft: 2 }}>
                            <Spinner size={16} />
                        </Box> :
                        <>
                            <Box alignSelf={'center'} sx={{ marginLeft: 1 }}>
                                <IconButton type={'submit'} size={'small'} color='success'
                                            aria-label='Change main number'>
                                    <Check />
                                </IconButton>
                            </Box>
                            <Box alignSelf={'center'} sx={{ marginLeft: 1 }}>
                                <IconButton size={'small'} color='secondary' aria-label='Cancel' onClick={onCancel}>
                                    <Cancel />
                                </IconButton>
                            </Box>
                        </>
                }
            </Stack>
        </form>
    )
}