import React, { ReactNode, useCallback, useEffect } from 'react'
import { Page, setPage } from '../../app/navigation/redux'
import { useDispatch } from 'react-redux'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import Typography from '@mui/material/Typography'
import { Grid, Paper } from '@mui/material'

type Props = {
    title: string
    children: ReactNode
    page?: Page | null
    buttonsPanel?: any
}

export const TitledBody = ({ title, page, buttonsPanel, children }: Props) => {
    const dispatch = useDispatch<ThunkDispatch<RootState, any, any>>()

    const setPageOnRedux = useCallback(
        async (page: Page | null) => {
            dispatch(setPage(page))
        },
        [dispatch],
    )

    useEffect(() => {
        setPageOnRedux(page === undefined ? null : page)
    }, [page, setPageOnRedux])
    return (
        <>
            <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={1} width={1}>
                    <Grid container item justifyContent={'space-between'} flexDirection={'row'} spacing={1}
                          flexWrap={'nowrap'}>
                        <Grid item xs={'auto'} pr={1}>
                            <Typography component='h2' variant='h4' color='secondary' gutterBottom>
                                {title}
                            </Typography>
                        </Grid>
                        {buttonsPanel ? <Grid item>{buttonsPanel}</Grid> : <></>}
                    </Grid>
                    <Grid item justifyContent={'space-between'} flexDirection={'row'} width={1}>
                        {children}
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
