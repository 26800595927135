import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import IvrFormScreen from './IvrForm'
import {IvrForm, Language} from '../app/types'
import {api} from '../common/api'
import {useSelector} from 'react-redux'
import {defaultNcosSelector, selectedSiteSelector} from '../authentication/redux'
import {useHistory} from 'react-router'
import {detailUrl} from './index'
import {useFormSubmissionButtons} from "../common/form/useFormSubmissmionButtons";
import CancelButton from "../common/form/CancelButton";
import {Grid} from "@mui/material";

const initialIvr = (defaultNcos: string) : IvrForm => ({
    uuid: '',
    name: '',
    language: Language.nl,
    extension: '',
    number: '',
    menuConfig: {
        subMenus: {},
    },
    selectiveCallForward: {
        outsideOpeningHoursScheduleUuid: '',
        holidayPhoneNumber: '',
        holidayScheduleUuid: '',
        outsideOpeningHoursPhoneNumber: '',
    },
    transferToOperatorAfter: 10,
    operator: {
        type: 'NONE',
        user: null,
        answerGroup: null
    },
    operatorType: 'NONE',
    operatorAnswerGroupId: null,
    operatorUserId: null,
    ncos: defaultNcos,
    alternateNumbers: []
})

export const Add = () => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const history = useHistory()
    const defaultNcos = useSelector(defaultNcosSelector)
    const {buildFormButtons} = useFormSubmissionButtons(true)

    const onSubmit = useCallback(
        async (formData: IvrForm) => {
            if (!site) return
            const { data } = await api.post(`/api/site/${site.productId}/ivr`, { ...formData, alternateNumbers: formData.alternateNumbers.map(nr => nr.number)})
            //probably user wants to configure menu? -> forward to menu
            history.push(detailUrl.replace(':id', data.uuid) + '?edit=true#1')
        },
        [site, history]
    )

    return (
        <TitledBody title={t('ivrs.add.title')}>
            <Grid container>
                <Grid item xs={12} lg={10}>
                    <IvrFormScreen onSubmit={onSubmit} ivr={initialIvr(defaultNcos)} buildFormButtons={buildFormButtons(<CancelButton/>)}/>
                </Grid>
            </Grid>
        </TitledBody>
    )
}
