import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from './app/App'
import { Provider } from 'react-redux'
import { store } from './app/store'
import { Theme, ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { injectStore } from './common/api'

injectStore(store)

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {
    }
}

const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>((props, ref) => {
    const { href, ...other } = props
    return <RouterLink ref={ref} to={href} {...other} />
})

const mdTheme = createTheme({
    palette: {
        primary: {
            main: '#FFC421',
            contrastText: '#291D18',
            dark: '#FFB61B',
            light: '#FFE186',
        },
        secondary: {
            main: '#291D18',
            contrastText: '#ffffff',
            light: '#766F6B',
        },
        error: {
            main: '#de350b',
        },
        info: {
            main: '#2373e6',
        },
        warning: {
            main: '#ff9500',
            contrastText: '#291D18',
        },
        success: {
            main: '#00875a',
        },
        divider: '#BAB6B5',
        text: {
            primary: '#291D18',
            secondary: '#766F6B',
            disabled: '#BAB6B5',
        },
        background: {
            default: '#F7F6F6',
        }
    },
    typography: {
        fontFamily: 'Telenet, sans-serif',
        h1: {
            fontWeight: 700,
            color: 'secondary',
        },
        h2: {
            fontWeight: 700,
            color: 'secondary',
        },
        h3: {
            fontWeight: 700,
            color: 'secondary',
        },
        h4: {
            fontWeight: 700,
            color: 'secondary',
        },
        h5: {
            fontWeight: 700,
            color: 'secondary',
        },
        h6: {
            fontWeight: 700,
            color: 'secondary',
        },
    },
    components: {
        MuiLink: {
            defaultProps: {
                // @ts-ignore
                component: LinkBehavior,
                color: 'secondary',
            },
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        color: 'inherit',
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        fontWeight: 'bold',
                    },
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#291D18',
                        color: '#ffffff',
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: '#291D18',
                        color: '#ffffff',
                    },
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                color: 'secondary',
            },
        },
        MuiCheckbox: {
            defaultProps: {
                color: 'secondary',
            },
        },
    },
})

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={mdTheme}>
                <App />
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log)
