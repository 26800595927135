import React, {ReactElement, ReactNode, useCallback} from 'react'
import {FormSection} from "../../common/components/SectionedForm";
import {api} from "../../common/api";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import {useTranslation} from "react-i18next";
import PaperStack from "../../common/form/PaperStack";
import FormGroup from "../../common/form/FormGroup";
import {LabelValues, LabelValueType} from "../../common/components/LabelValues";
import {Paging} from "../../app/types";
import {formatUser} from "../../common/util/formatters";
import {List, ListItem, ListItemText} from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";
import PagingForm from "./PagingForm";

export const useGeneralSection = (pagingId: string, isFetching: boolean, error: any, refetch: () => void, data?: Paging) : FormSection<Paging> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const {buildFormButtons} = useFormSubmissionButtons(false)

    const onSubmit = useCallback((onSucceeded: () => void) =>
        async (formData: Paging) => {
            if (!site) return
            await api.put(`/api/site/${site.productId}/paging/${pagingId}`, {
                ...formData,
                originatorUserIds: formData.originatorUsers?.map(user => user.uuid).join(','),
                targetUserIds: formData.targetUsers?.map(user => user.uuid).join(','),
            })
            await refetch()
            onSucceeded();
        },
        [site, refetch, pagingId]
    )

    const renderGeneralForm = useCallback(
        (paging: Paging, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode =>
            <PagingForm paging={paging} onSubmit={onSubmit(onSucceeded)} buildFormButtons={buildFormButtons(cancelEditButton)} />
        , [onSubmit, buildFormButtons])

    const infoLabelValues = useCallback(
        (value: Paging): LabelValueType[] => [
            { label: t('pagings.edit.name'), value: <>{value.name}</> },
            { label: t('flows.edit.extension'), value: <>{value.extension || t('general.none')}</> },
        ],
        [t]
    )

    const originatorUsers = useCallback(
        (value: Paging): LabelValueType[] => [
            {
                label: '',
                value: (
                    <List dense={false}>
                        {value.originatorUsers.map(it => (
                            <ListItem key={it.uuid}>
                                <ListItemText primary={formatUser(it)}></ListItemText>
                            </ListItem>
                        ))}
                    </List>
                ),
                type: 'FULL_LINE',
            },
        ],
        []
    )

    const targetUsers = useCallback(
        (value: Paging): LabelValueType[] => [
            {
                label: '',
                value: (
                    <List dense={false}>
                        {value.targetUsers.map(it => (
                            <ListItem key={it.uuid}>
                                <ListItemText primary={formatUser(it)}></ListItemText>
                            </ListItem>
                        ))}
                    </List>
                ),
                type: 'FULL_LINE',
            },
        ],
        []
    )

    const renderDetails = useCallback(
        (paging: Paging): ReactNode => {
            return (
                <PaperStack>
                    <FormGroup key={1} label={t('pagings.edit.section.info')} fullWidth={true}>
                        <LabelValues labelValues={infoLabelValues(paging)} />
                    </FormGroup>
                    <FormGroup key={2} label={t('pagings.edit.section.originator-users')} fullWidth={true}>
                        <LabelValues labelValues={originatorUsers(paging)} />
                    </FormGroup>
                    <FormGroup key={2} label={t('pagings.edit.section.target-users')} fullWidth={true}>
                        <LabelValues labelValues={targetUsers(paging)} />
                    </FormGroup>
                </PaperStack>
            )
        },
        [infoLabelValues, t, originatorUsers, targetUsers]
    )

    return {
        title: t('general.general'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderDetails,
        renderForm: renderGeneralForm,
        enabled: () => true,
        icon: () => <CampaignIcon/>
    }
}