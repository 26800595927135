import React, {ReactElement, useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Control, useForm, useWatch} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import TextInput from '../../common/form/TextInput'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import SpinnableSubmitButton from '../../common/form/SpinnableSubmitButton'
import {useFormSubmission} from '../../common/servercall/useFormSubmission'
import ErrorAlert from '../../common/components/ErrorAlert'
import FormGroup from '../../common/form/FormGroup'
import CancelButton from '../../common/form/CancelButton'
import FormContainer from '../../common/form/FormContainer'
import FormButtons from '../../common/form/FormButtons'
import {cpeYealinkKeyTypes, toYealinkKeyConfigForm} from "../util/CPEHelpers";
import {CpeKey, CPEKeyTypeMetaData, CPESettings, CpeYealinkKeyConfigForm} from "../../app/types";
import {reject} from "ramda";
import {kemSchema, KeyFields, renderKeyHeaders} from "./CpeMPPConfigForm";

const schema = (typeOptions: CPEKeyTypeMetaData[]) =>
    yup
        .object()
        .shape({
            mainPhone: kemSchema(typeOptions),
        })
        .required()


type Props = {
    cpeSettings: CPESettings
    onSubmit: (cpeSettings: CPESettings) => void
    readonly?: boolean,
    nrOfButtons: number
    buildFormButtons: ((isSubmitting: boolean) => ReactElement) | undefined
}

type ConditionalInputProps = {
    control: Control<CpeYealinkKeyConfigForm>
    section: 'mainPhone',
    index: number
    typeOptions: CPEKeyTypeMetaData[]
}

const ConditionalInput = ({control, section, index, typeOptions}: ConditionalInputProps) => {
    const {t} = useTranslation()
    const keys = useWatch({
        name: `${section}.keys`,
        control,
    })

    const cpe = useMemo(() => keys?.[index], [index, keys])

    return typeOptions.find(t => t.value === cpe?.type)?.needsArg ?
        <TextInput hideLabel={true} label={t('cpes.detail.key-number')} name={`${section}.keys.${index}.value`} control={control}/> : <></>
}


const submitForm = (onSubmit: (cpeSettings: CPESettings) => void) => (formData: CpeYealinkKeyConfigForm) => onSubmit(
    {
        keys : [
            ...reject((cpeKey: CpeKey) => cpeKey.key === '1', formData.mainPhone.keys),
        ],
        features: []
    }
)

const CPEYealinkConfigFormScreen = ({cpeSettings, onSubmit, readonly, nrOfButtons, buildFormButtons}: Props) => {
    const {t} = useTranslation()

    const typeOptions = useMemo(() => cpeYealinkKeyTypes(t), [t])

    const {control, handleSubmit, setError, setValue} = useForm<CpeYealinkKeyConfigForm>({
        resolver: yupResolver(schema(typeOptions)),
        defaultValues: toYealinkKeyConfigForm(cpeSettings, nrOfButtons),
    })

    const {submit, isSubmitting, serverError, onError} = useFormSubmission<CpeYealinkKeyConfigForm>(submitForm(onSubmit), setError)

    const renderKemForm = useCallback((section: 'mainPhone', keys: number[]) => {
        const evenLength = keys.length % 2 === 0 ? keys.length : keys.length + 1
        return <>
            <FormRow>
                <FormFieldBox mdSize={6} lgSize={6}>
                    {renderKeyHeaders(t)}
                </FormFieldBox>
                <FormFieldBox mdSize={6} lgSize={6}>
                    {renderKeyHeaders(t)}
                </FormFieldBox>
            </FormRow>
            {Array.from({length: evenLength / 2}, (x, i) => i).map(index => {
                return <FormRow key={index}>
                    <FormFieldBox mdSize={6} lgSize={6}>
                        <KeyFields section={section} keyNumber={index} t={t} control={control}
                                   typeOptions={typeOptions}
                                   argInput={() => <ConditionalInput control={control} section={section} index={index} typeOptions={typeOptions} />}
                                   readOnly={false} setValue={setValue}
                        />
                    </FormFieldBox>
                    {(index * 2) < keys.length - 1? <FormFieldBox mdSize={6} lgSize={6}>
                        <KeyFields section={section} keyNumber={(evenLength / 2) + (index as number)} t={t} control={control}
                                   typeOptions={typeOptions}
                                   argInput={() => <ConditionalInput control={control} section={section} index={(evenLength / 2) + (index as number)} typeOptions={typeOptions} />}
                                   readOnly={false} setValue={setValue}
                        />
                    </FormFieldBox> : <></>
                    }
                </FormRow>
            })}
        </>
    }, [control, t, typeOptions, setValue])

    return (
        <>
            <ErrorAlert errorKey={typeof serverError === 'string' ? serverError : undefined} showAlert={!!serverError}/>
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={t('cpes.detail.main-phone')} fullWidth={true}>
                    {renderKemForm('mainPhone', Array.from({length: nrOfButtons}, (x, i) => i + 1))}
                </FormGroup>
                {readonly ? <></> : buildFormButtons? buildFormButtons(isSubmitting) : <FormButtons fullWidth={true}
                                                 buttons={[<SpinnableSubmitButton label={t('general.form-update')}
                                                                                  showSpinner={isSubmitting}/>,
                                                     <CancelButton/>]}/>}
            </FormContainer>
        </>
    )
}

export default CPEYealinkConfigFormScreen
