import React from 'react'
import {find} from 'ramda'
import {IconButton, ListItemIcon, ListItemText, Menu} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PinIcon from '@mui/icons-material/Pin'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import VoicemailIcon from '@mui/icons-material/Voicemail';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import {Fax, PhoneCallback, PhoneForwarded, SettingsPhone} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import FilterListIcon from "@mui/icons-material/FilterList";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import SettingsIcon from "@mui/icons-material/Settings";

export type ItemProp<itemClass> = {
    text: string
    icon?: (iconProps: object) => any
    key: string
    onClick: (record: itemClass) => void
    disabled?: (record: itemClass) => boolean
    hide?: (record: itemClass) => boolean
    dividerBeneath?: boolean
    dividerAbove?: boolean
    needsWriteAccess?: boolean
}

function TableRowActionsMenu<itemClass>({ record, menuItems, readOnly }: { record: itemClass; menuItems: ItemProp<itemClass>[], readOnly?: boolean }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const iconMap: { name: string; icon: React.ReactNode }[] = [
        { name: 'edit', icon: <EditIcon fontSize={'inherit'} /> },
        { name: 'delete', icon: <DeleteIcon fontSize={'inherit'} /> },
        { name: 'download', icon: <DownloadIcon fontSize={'inherit'} /> },
        { name: 'pincode', icon: <PinIcon fontSize={'inherit'} /> },
        { name: 'activationcode', icon: <VpnKeyIcon fontSize={'inherit'} /> },
        { name: 'hot-desk', icon: <DeviceUnknownIcon fontSize={'inherit'} /> },
        { name: 'edit-voicemail-settings', icon: <VoicemailIcon fontSize={'inherit'} /> },
        { name: 'edit-incoming-calls-settings', icon: <PhoneCallback fontSize={'inherit'} /> },
        { name: 'edit-outgoing-calls-settings', icon: <PhoneForwarded fontSize={'inherit'} /> },
        { name: 'edit-call-control-settings', icon: <SettingsPhone fontSize={'inherit'} /> },
        { name: 'edit-cpe-config', icon: <Fax fontSize={'inherit'} /> },
        { name: 'edit-executive-assistants', icon: <SupervisedUserCircleIcon fontSize={'inherit'} /> },
        { name: 'edit-executive-filtering', icon: <FilterListIcon fontSize={'inherit'} /> },
        { name: 'edit-executive-screening-alerting', icon: <RecentActorsIcon fontSize={'inherit'} /> },
        { name: 'settings', icon: <SettingsIcon fontSize={'inherit'} /> },

    ]

    return (
        <>
            <IconButton aria-controls="demo-positioned-menu" size={'small'} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                <MoreVertIcon fontSize={'inherit'} />
            </IconButton>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClick={handleClose}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                {menuItems.map(item => {
                    const Icon = (): any => (item.icon ? item.icon({ fontSize: 'inherit' }) : find(iconMapItem => iconMapItem.name === item.key, iconMap)?.icon || <></>)
                    return (
                        <>
                            {item.dividerAbove ? <Divider/> : <></> }
                            {
                                !(item.hide && item.hide(record)) ? <MenuItem
                                    key={`menuitem-${item.text}`}
                                    disabled={(item.disabled && item.disabled(record)) || (readOnly && item.needsWriteAccess)}
                                    onClick={e => {
                                        e.preventDefault()
                                        item.onClick(record)
                                        return true
                                    }}>
                                    <ListItemIcon>
                                        <Icon />
                                    </ListItemIcon>
                                    <ListItemText>{item.text}</ListItemText>
                                </MenuItem> : <></>
                            }
                            {item.dividerBeneath ? <Divider/> : <></> }
                        </>
                    )
                })}
            </Menu>
        </>
    )
}

export default TableRowActionsMenu
