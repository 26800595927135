import React, {ReactElement, ReactNode, useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Control, useForm, useWatch} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {array, object, string} from 'yup'
import TextInput, {Option} from '../../common/form/TextInput'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import SpinnableSubmitButton from '../../common/form/SpinnableSubmitButton'
import {useFormSubmission} from '../../common/servercall/useFormSubmission'
import ErrorAlert from '../../common/components/ErrorAlert'
import FormGroup from '../../common/form/FormGroup'
import CancelButton from '../../common/form/CancelButton'
import FormContainer from '../../common/form/FormContainer'
import FormButtons from '../../common/form/FormButtons'
import {cpeMppKeyTypes, removeBlfFields, toMppKeyConfigForm} from "../util/CPEHelpers";
import {
    CpeConfigResult,
    CpeKey,
    CPEKeyTypeMetaData,
    CpeMPPConfigForm,
    CPESettings,
    HotDeskBasicSettings,
    KeyConfigForm,
    PhoneUser
} from "../../app/types";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {reject} from "ramda";
import {TFunction} from "i18next";
import {formatUserInDropdown} from "../../common/util/formatters";
import FormCheckbox from "../../common/form/FormCheckbox";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import {UseFormSetValue} from "react-hook-form/dist/types/form";

export const kemSchema = (typeOptions: CPEKeyTypeMetaData[]) => yup.object().shape(
    {
        keys: array().of(
            object().shape({
                key: string().required('required-m').nullable(),
                type: string().nullable(),
                label: yup.lazy(value => {
                    return yup.string().when('type', type => {
                        if (!!type) {
                            let meta = typeOptions.find(t => t.value === type)
                            if (!meta?.needsNoLabel) {
                                return yup.string().nullable().required('required-m')
                            }
                        }
                        return yup.string().nullable()
                    })
                }),
                value: yup.lazy(value => {
                    return yup.string().when('type', type => {
                        let meta = typeOptions.find(t => t.value === type)
                        return meta?.validationRule() || yup.string().nullable()
                    })
                })
            }),
        ),
    }
)

const schema = (typeOptions: CPEKeyTypeMetaData[]) =>
    yup
        .object()
        .shape({
            mainPhone: kemSchema(typeOptions),
            kem1: kemSchema(typeOptions),
            kem2: kemSchema(typeOptions),
            kem3: kemSchema(typeOptions),
            hotDeskSettings: yup.object().shape({
                hotDeskAssociationLimitHours: yup.number().when(['hotDeskAssociationLimitEnabled', 'hotDeskingEnabled'], {
                    is: (hotDeskAssociationLimitEnabled: boolean, hotDeskingEnabled: boolean) => hotDeskAssociationLimitEnabled && hotDeskingEnabled,
                    then: yup.number().required('required-m').typeError('invalid-hot-desk-association-limit').min(1, 'invalid-hot-desk-association-limit').max(999, 'invalid-hot-desk-association-limit').nullable(true),
                    otherwise: yup.number().transform((_, val) => val === Number(val) ? val : 0)
                })
            }).nullable(true)
        })
        .required()


type Props = {
    cpeSettings: CPESettings
    onSubmit: (cpeConfigResult: CpeConfigResult) => void
    readonly?: boolean
    buildFormButtons: ((isSubmitting: boolean) => ReactElement) | undefined
    user?: PhoneUser
    hotDeskSettings?: HotDeskBasicSettings
}

type ConditionalInputProps = {
    control: Control<CpeMPPConfigForm>
    section: 'mainPhone' | 'kem1' | 'kem2' | 'kem3',
    index: number
    typeOptions: CPEKeyTypeMetaData[],
    blfUserOptions ?: Option[]
}
type KeyFieldInputProps<K extends keyof KeyConfigForm> = {
    section: K
    keyNumber: number
    t: TFunction
    control: Control<any>,
    typeOptions: CPEKeyTypeMetaData[]
    argInput: () => ReactElement
    readOnly: boolean
    setValue: UseFormSetValue<any>
}

const ConditionalInput = ({control, section, index, typeOptions, blfUserOptions}: ConditionalInputProps) => {
    const {t} = useTranslation()
    const keys = useWatch({
        name: `${section}.keys`,
        control,
    })

    const cpeKey = useMemo(() => keys?.[index], [index, keys])

    const keyTypeMeta = typeOptions.find(t => t.value === cpeKey?.type);

    return keyTypeMeta?.needsArg ?
        keyTypeMeta.value === 'MPP_BLF_USER' ?
            <TextInput hideLabel={true} label={t('cpes.detail.key-number')} name={`${section}.keys.${index}.value`} control={control} options={blfUserOptions}/>
            : <TextInput hideLabel={true} label={t('cpes.detail.key-number')} name={`${section}.keys.${index}.value`} control={control}/>
            : <></>
}

export const KeyFields =<K extends keyof KeyConfigForm> ({section, keyNumber, t, control, typeOptions, argInput, readOnly, setValue} : KeyFieldInputProps<K> ) => {
    const keys = useWatch({
        name: `${section}.keys`,
        control,
    })

    const cpeKey = useMemo(() => keys?.[keyNumber], [keyNumber, keys])
    const keyTypeMeta = typeOptions.find(t => t.value === cpeKey?.type);

    return <Grid container spacing={2} key={`${section as string}-${keyNumber}`}>
        <KeyBox width={1} align={'center'}>
            <Typography component='h6' variant='subtitle1' color='secondary'>
                {`#${keyNumber + 1}`}
            </Typography>
        </KeyBox>
        <KeyBox width={5}>
            <TextInput hideLabel={true} label={t('cpes.detail.key-type')}
                       readonly={(section === 'mainPhone' && (keyNumber === 0)) || readOnly}
                       options={typeOptions}
                       onChange={(event) => {
                           if (event.target.value === 'MPP_BLF_USER') {
                                setValue(`${section as string}.keys.${keyNumber}.label`, '')
                           } else if (keyTypeMeta?.value === 'MPP_BLF_USER') {
                               setValue(`${section as string}.keys.${keyNumber}.value`, '')
                           }
                       }}
                        // @ts-ignore
                       name={`${section as string}.keys.${keyNumber}.type`} control={control}
            />
        </KeyBox>
        <KeyBox width={3}>
                <TextInput hideLabel={true} label={t('cpes.detail.key-label')}
                           readonly={(section === 'mainPhone' && keyNumber === 0) || keyTypeMeta?.needsNoLabel || readOnly}
                            // @ts-ignore
                           name={`${section as string}.keys.${keyNumber}.label`} control={control}
                />
        </KeyBox>
        <KeyBox width={3}>
            {argInput()}
        </KeyBox>
    </Grid>
}

export const renderKeyHeaders = (t: TFunction): ReactNode =>
    <Grid mt={2} container spacing={2} key={'keyHeader'}>
        <KeyBox width={1} align={'center'}>
            <></>
        </KeyBox>
        <KeyBox width={5}>
            <Typography variant="h6" component='h6' color='secondary'>
                {t('cpes.detail.key-type')}
            </Typography>
        </KeyBox>
        <KeyBox width={3}>
            <Typography variant="h6" component='h6' color='secondary'>
                {t('cpes.detail.key-label')}
            </Typography>
        </KeyBox>
        <KeyBox width={3}>
            <Typography variant="h6" component='h6' color='secondary'>
                {t('cpes.detail.key-number')}
            </Typography>
        </KeyBox>
    </Grid>

const toCpeSettings = (formData: CpeMPPConfigForm): CPESettings => (
    {
        keys : [
            ...reject((cpeKey: CpeKey) => cpeKey.key === '1', formData.mainPhone.keys),
            ...formData.kem1.keys,
            ...formData.kem2.keys,
            ...formData.kem3.keys
        ],
        features: [
            {type: 'MPP_AUTOFILL_BLF', value: formData.autoFillBlf ? 'true' : 'false'}
        ]
    }
)

const hotDeskUserWouldBeDisassociated = (oldHotDeskSettings: HotDeskBasicSettings, newHotDeskSettings: HotDeskBasicSettings) => {
    const isEnabled = newHotDeskSettings.hotDeskingEnabled && !oldHotDeskSettings.hotDeskingEnabled
    const isDisabled = !newHotDeskSettings.hotDeskingEnabled && oldHotDeskSettings.hotDeskingEnabled
    const configChanged = newHotDeskSettings.hotDeskAssociationLimitEnabled !== oldHotDeskSettings.hotDeskAssociationLimitEnabled
        || newHotDeskSettings.hotDeskAssociationLimitHours !== oldHotDeskSettings.hotDeskAssociationLimitHours


    return isDisabled || (!isEnabled && configChanged)

}

const CPEMPPConfigFormScreen = ({user, cpeSettings, onSubmit, readonly, buildFormButtons, hotDeskSettings}: Props) => {
    const {t} = useTranslation()
    const [showAutoFillWarning, setShowAutoFillWarning] = React.useState<CpeConfigResult | null>(null)
    const [showHotDeskUpdateWarning, setShowHotDeskUpdateWarning] = React.useState<CpeConfigResult | null>(null)

    const typeOptions = useMemo(() => cpeMppKeyTypes(t).filter(it => !it.onlyReadOnly), [t])

    const {control, handleSubmit, setError, setValue, getFieldState} = useForm<CpeMPPConfigForm>({
        resolver: yupResolver(schema(typeOptions)),
        defaultValues: toMppKeyConfigForm(cpeSettings, hotDeskSettings),
    })

    const autoFillBlf = useWatch({
        control,
        name: 'autoFillBlf',
    })

    const hotDeskingEnabled = useWatch({
        control,
        name: 'hotDeskSettings.hotDeskingEnabled',
    })

    const hotDeskAssociationLimitEnabled = useWatch({
        control,
        name: 'hotDeskSettings.hotDeskAssociationLimitEnabled',
    })

    const doSubmit = useCallback((formData: CpeMPPConfigForm) => {
        const configResult = {
            cpeSettings: toCpeSettings(formData),
            hotDeskSettings: formData.hotDeskSettings
        }
        if (formData.autoFillBlf && !!cpeSettings.keys.find(k => k.type === 'MPP_BLF_USER')) {
            setShowAutoFillWarning(configResult)
        } else if (!!hotDeskSettings && hotDeskUserWouldBeDisassociated(hotDeskSettings, formData.hotDeskSettings)) {
            setShowHotDeskUpdateWarning(configResult)
        } else {
            onSubmit(configResult)
        }
    }, [onSubmit, cpeSettings.keys, hotDeskSettings])

    const filteredTypeOptions = useMemo(() => typeOptions.map(it =>  ({...it, disabled: autoFillBlf && it.value === 'MPP_BLF_USER'})), [autoFillBlf, typeOptions])
    const readOnlyTypeOptions = useMemo(() => cpeMppKeyTypes(t).filter(it => it.onlyReadOnly), [t])
    const blfUserOptions : Option[] | undefined = useMemo(() => user?.busyLampUsers.map(blu=> ({label: formatUserInDropdown(blu), value: blu.userBusinessId})), [user])
    const readOnlyKeys = useMemo(() => cpeSettings.keys.filter(k => !k?.editable).map(k => k.key), [cpeSettings])
    const {submit, isSubmitting, serverError, onError} = useFormSubmission<CpeMPPConfigForm>(doSubmit, setError)

    // @ts-ignore
    const renderKemForm = useCallback((section: 'mainPhone' | 'kem1' | 'kem2' | 'kem3', keys: number[]) =>
            <>
                <FormRow>
                    <FormFieldBox mdSize={6} lgSize={6}>
                        {renderKeyHeaders(t)}
                    </FormFieldBox>
                    <FormFieldBox mdSize={6} lgSize={6}>
                        {renderKeyHeaders(t)}
                    </FormFieldBox>
                </FormRow>
                {Array.from({length: keys.length / 2}, (x, i) => i).map(index =>
                    <FormRow key={index}>
                        <FormFieldBox mdSize={6} lgSize={6}>
                            <KeyFields section={section} keyNumber={index} t={t} control={control}
                                       typeOptions={readOnlyKeys.includes(`${index + 1}`) ? readOnlyTypeOptions : filteredTypeOptions}
                                       argInput={() => <ConditionalInput blfUserOptions={blfUserOptions} control={control} section={section} index={index} typeOptions={filteredTypeOptions} />}
                                       readOnly={readOnlyKeys.includes(`${index + 1}`)} setValue={setValue}
                            />
                        </FormFieldBox>
                        <FormFieldBox mdSize={6} lgSize={6}>
                            <KeyFields section={section} keyNumber={(keys.length / 2) + (index as number)} t={t} control={control}
                                       typeOptions={readOnlyKeys.includes(`${index + 1}`) ? readOnlyTypeOptions : filteredTypeOptions}
                                       argInput={() => <ConditionalInput blfUserOptions={blfUserOptions} control={control} section={section} index={(keys.length / 2) + (index as number)} typeOptions={filteredTypeOptions} />}
                                       readOnly={readOnlyKeys.includes(`${(keys.length / 2) + (index + 1)}`)} setValue={setValue}
                            />
                        </FormFieldBox>
                    </FormRow>)}
            </>, [t, control, filteredTypeOptions, readOnlyKeys, readOnlyTypeOptions, blfUserOptions, setValue])

    return (
        <>
            <ConfirmationModal<CpeConfigResult | null>
                title={t('cpes.mpp.blf-autofill-warning.title')}
                text={item => t('cpes.mpp.blf-autofill-warning.text')}
                buttonLabel={t('cpes.mpp.blf-autofill-warning.confirm-button')}
                item={showAutoFillWarning}
                action={() => onSubmit(removeBlfFields(showAutoFillWarning as CpeConfigResult))}
                onClose={() => setShowAutoFillWarning(null)}
                onSuccess={() => setShowAutoFillWarning(null)}
            />
            <ConfirmationModal<CpeConfigResult | null>
                title={t('cpes.mpp.hot-desk-disassociate-user-warning.title')}
                text={item => t('cpes.mpp.hot-desk-disassociate-user-warning.text')}
                buttonLabel={t('cpes.mpp.hot-desk-disassociate-user-warning.confirm-button')}
                item={showHotDeskUpdateWarning}
                action={() => onSubmit(showHotDeskUpdateWarning as CpeConfigResult)}
                onClose={() => setShowHotDeskUpdateWarning(null)}
                onSuccess={() => setShowHotDeskUpdateWarning(null)}
            />
            <ErrorAlert errorKey={typeof serverError === 'string' ? serverError : undefined} showAlert={!!serverError}/>
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={t('cpes.mpp.settings-title')} fullWidth={true}>
                    {hotDeskSettings &&
                        <>
                            <FormRow>
                                <FormFieldBox>
                                    <FormCheckbox
                                        label={t('users.edit.hot-desking')}
                                        name={'hotDeskSettings.hotDeskingEnabled'}
                                        control={control}
                                        setValue={setValue}
                                        getFieldState={getFieldState}
                                    />
                                </FormFieldBox>
                            </FormRow>
                            {hotDeskingEnabled &&
                                <FormRow>
                                    <FormFieldBox>
                                        <FormCheckbox
                                            label={t('users.edit.association-limit')}
                                            name={'hotDeskSettings.hotDeskAssociationLimitEnabled'}
                                            control={control}
                                            setValue={setValue}
                                            getFieldState={getFieldState}
                                        />
                                    </FormFieldBox>
                                    {hotDeskAssociationLimitEnabled &&
                                        <FormFieldBox>
                                            <TextInput readonly={readonly} label={t('users.edit.association-limit-hours')} name={'hotDeskSettings.hotDeskAssociationLimitHours'} control={control}/>
                                        </FormFieldBox>
                                    }
                                </FormRow>
                            }
                        </>
                    }

                    <FormRow>
                        <FormFieldBox mdSize={6} lgSize={6}>
                            <FormCheckbox
                                label={t(`cpes.config.MPP_AUTOFILL_BLF`)}
                                name={`autoFillBlf`}
                                control={control}
                                setValue={setValue}
                            />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                <FormGroup label={t('cpes.detail.main-phone')} fullWidth={true}>
                    {renderKemForm('mainPhone', [1,2,3,4,5,6,7,8,9,10])}
                </FormGroup>
                <FormGroup label={t('cpes.detail.kem', {index: 1})} fullWidth={true}>
                    {renderKemForm('kem1', Array.from({length: 28}, (x, i) => i + 1))}
                </FormGroup>
                <FormGroup label={t('cpes.detail.kem', {index: 2})} fullWidth={true}>
                    {renderKemForm('kem2', Array.from({length: 28}, (x, i) => i + 1))}
                </FormGroup>
                <FormGroup label={t('cpes.detail.kem', {index: 3})} fullWidth={true}>
                    {renderKemForm('kem3', Array.from({length: 28}, (x, i) => i + 1))}
                </FormGroup>

                {readonly ? <></> : buildFormButtons? buildFormButtons(isSubmitting)
                    : <FormButtons fullWidth={true} buttons={[<SpinnableSubmitButton label={t('general.form-update')} showSpinner={isSubmitting}/>, <CancelButton/>]}/>}
            </FormContainer>
        </>
    )
}

function KeyBox({children, align, width}: { children: any; align?: string; width?: number }) {
    return (
        <Grid container={!!align} item xs={width || 3} sm={width || 3} md={width || 3} lg={width || 3}
              alignItems={align} pl={2}>
            {children}
        </Grid>
    )
}


export default CPEMPPConfigFormScreen
