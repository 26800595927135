import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../../common/components/TitledBody'
import {SectionedForm} from "../../common/components/SectionedForm";
import {Grid} from "@mui/material";
import {useGeneralSection} from "./general";
import {useAccessHelpers} from "../../common/util/hooks";

export const DetailEmergencyCalls = () => {
    const { t } = useTranslation()
    const {hasWriteAccess} = useAccessHelpers()

    const generalSection = useGeneralSection();
    const formSections = useMemo(() => [
        generalSection
    ], [generalSection])

    return (
        <TitledBody title={t('navigation.emergency-call-notification')} >
            <Grid container>
                <Grid item xs={12} lg={10}>
                    <SectionedForm formSections={formSections} activeTab={0} readOnly={!hasWriteAccess}/>
                </Grid>
            </Grid>
        </TitledBody>
    )
}
