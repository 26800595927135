import React, {ReactElement, useCallback, useMemo, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {AlternateNumber, ExtensionValidationRule, IvrForm, Language} from '../app/types'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import TextInput from '../common/form/TextInput'
import FormRow from '../common/form/FormRow'
import FormFieldBox from '../common/form/FormFieldBox'
import {useFormSubmission} from '../common/servercall/useFormSubmission'
import ErrorAlert from '../common/components/ErrorAlert'
import FormGroup from '../common/form/FormGroup'
import {useSelector} from 'react-redux'
import {allowedUserNcosSelector, extensionValidationRulesSelector, selectedSiteSelector} from '../authentication/redux'
import {getAutocompleteAlternateNumbers, getAutocompleteSiteNumbers} from '../common/util/helpers'
import FormContainer from '../common/form/FormContainer'
import {ErrorOption} from 'react-hook-form/dist/types/errors'
import {Grid, IconButton, Link, Paper, Tooltip} from '@mui/material'
import {editMenuConfigUrl} from './index'
import {SelectiveCallForwardForm, selectiveForwardSchema} from '../common/components/SelectiveCallForwardForm'
import {sort, split} from "ramda";
import useTableInput, {moveItem, removeItem} from "../common/form/useTableInput";
import {ColumnDefinition} from "../common/components/table/Table";
import {formatPhoneNumber} from "../common/util/formatters";
import DeleteIcon from "@mui/icons-material/Delete";
import AutocompleteWithAsyncFetch from "../common/form/AutocompleteWithAsyncFetch";
import {usePhoneNumbersToForwardTo} from "../common/servercall/usePhoneNumbersToForwardTo";

const schema = (extensionValidationRules: ExtensionValidationRule[]) =>
    yup
        .object()
        .shape({
            name: yup.string().trim().max(30, 'too-long').required('required-m'),
            language: yup.string().required('required-f'),
            extension: yup.lazy(value => {
                let schema = yup
                    .string()
                    .trim()
                    .required('required-f')
                    .matches(/^$|^[0-9]{4}$/, {
                        message: 'invalid-extension',
                        excludeEmptyString: true,
                    })
                extensionValidationRules.forEach(rule => {
                    schema = schema.test({
                        name: 'test',
                        test: value => !value || !new RegExp(rule.regex).test(value),
                        message: rule.errorKey,
                    })
                })
                return schema.nullable()
            }),
            selectiveCallForward: selectiveForwardSchema,
            transferToOperatorAfter: yup
                .number()
                .required('required-m')
                .typeError('invalid-transfer-to-operator-after')
                .min(5, 'invalid-transfer-to-operator-after')
                .max(30, 'invalid-transfer-to-operator-after'),
            ncos: yup.string().required('required-f'),
            number: yup
                .string()
                .matches(/^$|^\+32[0-9]{8}$|^0[0-9]{8}$/, 'invalid-phone-number')
                .nullable(),
            alternateNumbers: yup.array().max(10, 'alternate-numbers-max-10').nullable(),
        })
        .required()

type Props = {
    ivr: IvrForm
    onSubmit: (formData: IvrForm) => void
    readonly?: boolean
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

const IvrFormScreen = ({ ivr, onSubmit, readonly, buildFormButtons }: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const allowedUserNcos = useSelector(allowedUserNcosSelector)
    const [configError, setConfigError] = useState<ErrorOption | null>(null)
    const extensionValidationRules = useSelector(extensionValidationRulesSelector)
    const {phoneNumbersToForwardTo}= usePhoneNumbersToForwardTo(site)

    const { control, handleSubmit, setError } = useForm<IvrForm>({
        resolver: yupResolver(schema(extensionValidationRules)),
        defaultValues: {
            ...ivr,
        },
    })

    const checkError = useCallback(
        (name: any, error: ErrorOption) => {
            if (name?.includes('###')) {
                setConfigError(error)
            }
            setError(name, error)
        },
        [setError, setConfigError]
    )

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<IvrForm>(onSubmit, checkError)

    const languageOptions = useMemo(
        () =>
            Object.values(Language).map(language => {
                return {
                    value: language,
                    label: t(`users.language.${language}`),
                }
            }),
        [t]
    )

    const numberOptionParams = useMemo(() => {
        return getAutocompleteSiteNumbers(t, false, site, ivr?.number, ivr.alternateNumbers.map(an => an.number))
    }, [ivr, site, t])

    const ncosOptions = useMemo(
        () =>
            sort(
                (a, b) =>
                    a.localeCompare(b, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    }),
                split(',', allowedUserNcos)
            ).map(ncos => ({ label: t(`users.ncos-names.${ncos}`), value: ncos })),
        [t, allowedUserNcos]
    )

    const alternateNumberColumns: (remove: removeItem, move: moveItem) => ColumnDefinition<AlternateNumber>[] = useCallback(
        (remove, move) => [
            {
                title: t('users.edit.alternate-number'),
                key: 'number',
                sorter: false,
                render: (text: string) => <>{formatPhoneNumber(text)}</>,
            },
            {
                title: '',
                key: 'uuid',
                sorter: false,
                render: (text: string, record: AlternateNumber, index) => (
                    <>
                        <Grid container>
                            <Tooltip placement={'right-end'} title={`${t('users.edit.busy-lamp-users.table.delete')}`}>
                                <IconButton
                                    size={'small'}
                                    aria-haspopup="true"
                                    onClick={() => {
                                        remove(index)
                                    }}>
                                    <DeleteIcon fontSize={'inherit'} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </>
                ),
            },
        ],
        [t]
    )

    const { table: AlternateNumbersTable, append: appendAlternateNumber, data: alternateNumbersData } = useTableInput('alternateNumbers', control, alternateNumberColumns)

    const alternateNumberOptionParams = useMemo(() => {
        return {...getAutocompleteAlternateNumbers(t, false,
                [...ivr.alternateNumbers.map(nr => nr.number), ...(ivr.number ? [ivr.number] : [])],
                alternateNumbersData.map(nr => nr.number), site), disableClearable: true}
    }, [ivr, site, t, alternateNumbersData])

    return (
        <>
            <ErrorAlert errorKey={typeof serverError === 'string' ? serverError : undefined} showAlert={!!serverError} />
            <ErrorAlert
                showAlert={!!configError}
                body={
                    <Trans i18nKey={'ivrs.edit.config-error'}>
                        You have a configuration error. Click <Link href={editMenuConfigUrl.replace(':id', ivr?.uuid)}>here</Link> to fix
                    </Trans>
                }
            />
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={t('ivrs.edit.section.info')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput readonly={readonly} autofocus={true} label={t('ivrs.edit.name')} name={'name'} control={control} required={true} />
                        </FormFieldBox>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('users.edit.language')} name={'language'} control={control} required={true} options={languageOptions} />
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('ivrs.edit.number')} name={'number'} control={control} required={false} autoCompleteOptions={numberOptionParams} />
                        </FormFieldBox>
                        <FormFieldBox>
                            <TextInput label={t('ivrs.edit.extension')} name={'extension'} control={control} required={true} />
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox mdSize={8} lgSize={6}>
                            <AutocompleteWithAsyncFetch
                                keepEmpty={true}
                                preventDefaultEnterBehavior={true}
                                help={t('users.edit.alternate-numbers-helper-text')}
                                label={t('users.edit.alternate-numbers')}
                                name={'test2'}
                                autoCompleteOptions={alternateNumberOptionParams}
                                invalid={false}
                                onChange={value => {
                                    value && appendAlternateNumber({number: value} )
                                }}
                            />
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox mdSize={12}>
                            <Paper elevation={2}>
                                <AlternateNumbersTable />
                            </Paper>
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput readonly={readonly} label={t('users.edit.ncos')} name={'ncos'} control={control} required={true} options={ncosOptions} />
                        </FormFieldBox>
                        <FormFieldBox>
                            <TextInput label={t('ivrs.edit.transfer-to-operator-after')} name={'transferToOperatorAfter'} control={control} required={true} />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>

                <SelectiveCallForwardForm control={control} selectiveCallForward={ivr.selectiveCallForward} phoneNumbersToForwardTo={phoneNumbersToForwardTo} fullWidth={true}/>

                {!readonly && buildFormButtons(isSubmitting)}
            </FormContainer>
        </>
    )
}

export default IvrFormScreen
