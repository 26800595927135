import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'
import { Box, Flex } from 'rebass'

type Props = {
    buttons: ReactElement[]
    fullWidth?: boolean
}

export default function FormButtons({ buttons, fullWidth }: Props) {
    return (
        <Grid item xs={12} lg={fullWidth ? 12 : 9}>
            <Flex justifyContent={'end'}>
                {buttons.map((button, index) => (
                    <Box key={`formButton-${index}`} mr={index !== buttons.length - 1 ? 3 : 0}>
                        {button}
                    </Box>
                ))}
            </Flex>
        </Grid>
    )
}
