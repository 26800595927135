import React, { ReactNode } from 'react'
import ErrorAlert from '../components/ErrorAlert'
import { Spinner } from '../components/Spinner'
import { Box, Flex } from 'rebass'

type Props<T> = {
    isFetching: boolean
    error: any
    data?: T
    render: (data: T) => ReactNode
}

export function WithErrorAndFetching<T>({ isFetching, error, render, data }: Props<T>) {
    return (
        <>
            {isFetching ? (
                <Flex justifyContent={'center'}>
                    <Box>
                        <Spinner size={'3rem'} />
                    </Box>
                </Flex>
            ) : error ? (
                <ErrorAlert showAlert={true} />
            ) : data ? (
                render(data)
            ) : (
                <></>
            )}
        </>
    )
}
