import {PhoneNumberAnywhere, UserCallControl} from "../../app/types";
import {FormSection} from "../../common/components/SectionedForm";
import {TFunction, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import React, {ReactElement, ReactNode, useCallback} from "react";
import {api} from "../../common/api";
import {useFetchData} from "../../common/servercall/useFetchData";
import {LabelValues, LabelValueType} from "../../common/components/LabelValues";
import PaperStack from "../../common/form/PaperStack";
import FormGroup from "../../common/form/FormGroup";
import {Check, SettingsPhone} from "@mui/icons-material";
import {Box, List, ListItem, ListItemText, Paper, Table, TableContainer} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {CallControlForm} from "./callControlForm";
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";

const activeNotActive = (active: boolean, t: TFunction) : string => t(`users.edit.${!active? 'not-' : ''}active`)

export const useCallControlSection = (userId: string, enabled: boolean) : FormSection<UserCallControl> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const {buildFormButtons} = useFormSubmissionButtons(false)

    const fetchUserCallControl = useCallback(
        async (userId: string): Promise<UserCallControl | undefined> => {
            if (!site || !enabled) return
            const { data } = await api.get<UserCallControl>(`/api/site/${site.productId}/user/${userId}/call-control-settings`)
            return data
        },
        [site, enabled]
    )
    const { data, isFetching, error, refetch } = useFetchData<UserCallControl>(useCallback(() => fetchUserCallControl(userId), [fetchUserCallControl, userId]))

    const onSubmit = useCallback((onSucceeded: () => void) =>
            async (formData: UserCallControl) => {
                if (!site) return
                await api.put(`/api/site/${site.productId}/user/${userId}/call-control-settings`, formData)
                await refetch()
                onSucceeded();
            },
        [site, userId, refetch],
    )

    const queueAvailibiltyLabelValues = useCallback(
        (value: UserCallControl): LabelValueType[] => {
            return [
                {
                    label: '',
                    value: (
                        <TableContainer component={Paper} sx={{ mt: 1 }}>
                            <Table sx={{ minWidth: 300 }} size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 700 }}>{t('flows.table.name')}</TableCell>
                                        <TableCell style={{ fontWeight: 700 }} align="right">
                                            {t('flows.detail.available')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {value.callCenterAvailabilities &&
                                        value.callCenterAvailabilities.map(it => (
                                            <TableRow key={it.callCenterId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {it.name}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Box alignSelf={'center'} sx={{ marginLeft: 1 }}>
                                                        {it.available ? <Check color={'success'}/> : <></>}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ),
                    type: 'NORMAL',
                },
            ]}, [t]
    )

    const officeAnywhereLabelValues = useCallback(
        (value: UserCallControl): LabelValueType[] => {
            return [
                {   label: t('users.edit.office-anywhere.alert-all-locations'),
                    value: <>{activeNotActive(value.alertAllLocations, t)}</>
                },
                {   label: t('users.edit.sequential-ring.ring-numbers'),
                    value: <List dense={true}>
                        {value.phoneNumberAnywhereList &&
                            value.phoneNumberAnywhereList.filter(it => it.active).map(anywhereNumber => {
                                return <ListItem key={anywhereNumber.number}>
                                    <ListItemText primary={anywhereNumber.number} secondary={constructList(anywhereNumber).map(it => t(it)).join(", ")}/>
                                </ListItem>
                            })}
                    </List>,
                    type: 'FULL_LINE',
                },
            ]}, [t]
    )

    const renderCallControlDetails = useCallback(
        (value: UserCallControl): ReactNode => {
            return (
                <PaperStack>
                    <FormGroup key={1} label={t('users.edit.queue-availability.title')} fullWidth={true}>
                        <LabelValues labelValues={queueAvailibiltyLabelValues(value)} />
                    </FormGroup>
                    <FormGroup key={1} label={t('users.edit.office-anywhere.title')} fullWidth={true}>
                        <LabelValues labelValues={officeAnywhereLabelValues(value)} />
                    </FormGroup>
                </PaperStack>
            )
        },
        [t, queueAvailibiltyLabelValues, officeAnywhereLabelValues]
    )

    const renderForm = useCallback((callControl: UserCallControl, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode =>
            <CallControlForm callControl={callControl} buildFormButtons={buildFormButtons(cancelEditButton)} onSubmit={onSubmit(onSucceeded)} />,
        [onSubmit, buildFormButtons])

    return {
        title: t('users.sections.call-control'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderCallControlDetails,
        renderForm: renderForm,
        enabled: () => enabled,
        icon: () => <SettingsPhone />
    }
}

const constructList = (numberAnywhere: PhoneNumberAnywhere) => {
    let resultList = []
    if (numberAnywhere.useDiversionInhibitor) {
        resultList.push('users.edit.office-anywhere.do-not-forward')
    }
    if (numberAnywhere.answerConfirmationRequired) {
        resultList.push('users.edit.office-anywhere.answer-confirmation')
    }
    if (numberAnywhere.callControl) {
        resultList.push('users.edit.office-anywhere.call-control')
    }
    return resultList
}