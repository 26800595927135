import axios from 'axios'
import { Sort } from './components/table/useInfiniteScroll'
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore'
import { triggerRefetchSiteState } from '../authentication/redux'

let store: EnhancedStore

export const injectStore = (_store: EnhancedStore) => {
    store = _store
}

export const api = axios.create({
    responseType: 'json',
    //, baseURL: '/edgeweb/VoiceCloudHomepage/b/',
})

api.interceptors.request.use(config => {
    const jwtToken = getCookie('jwt_token')
    if (jwtToken != null) {
        //console.log('jwt token found ' + jwtToken)
        //config.headers['edgeauthorization'] = jwtToken
    }
    config.headers['Content-Type'] = 'application/json; charset=utf-8'

    return config
})

api.interceptors.response.use(
    response => {
        if (response?.config?.method && ['put', 'post', 'delete'].includes(response?.config?.method)) {
            store.dispatch(triggerRefetchSiteState())
        }
        return response
    },
    (error) => {
        throw error
    },
)

export const buildPaginatedUrl = (url: string, pageParam = 0, sortParam: Sort, searchTermParam: string | null, pageSize?: number) => {
    return `${url}?page=${pageParam}${sortParam ? `&sort=${sortParam.field},${sortParam.order}` : ''}${searchTermParam ? `&searchTerm=${encodeURIComponent(searchTermParam)}` : ''}${
        pageSize ? `&size=${pageSize}` : ''
    }`
}

const getCookie = (key: string): string | null => {
    const cookie = document.cookie.split('; ').find(row => row.startsWith(`${key}=`))
    return cookie ? cookie.split('=')[1] : null
}
