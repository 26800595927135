import {
    IvrKeyAction,
    IvrKeyActionMap,
    IvrMenuConfig,
    IvrOperator,
    IvrSubMenu,
    IvrSubMenuFormFriendly,
    IvrSubMenuMap
} from '../../app/types'
import {any} from 'ramda'
import {TFunction} from 'react-i18next'

export const transformSubMenuToForm = (ivrSubmenu: IvrSubMenu | null): IvrSubMenuFormFriendly | null => {
    if (!ivrSubmenu) {
        return null
    }
    return {
        ...ivrSubmenu,
        keyActions: Object.values(ivrSubmenu.keyActions),
    }
}

export const transformSubMenuFormToSubMenu = (ivrSubmenu: IvrSubMenuFormFriendly): IvrSubMenu => {
    const keyActions = ivrSubmenu.keyActions.map(keyAction => {
        if (keyAction.gbtfIvrSubMenuAction && ['USER', 'ANSWER_GROUP', 'IVR'].includes(keyAction.gbtfIvrSubMenuAction) && keyAction.config instanceof Object) {
            return {
                ...keyAction,
                dependencyInfo: {
                    firstName: keyAction.config.firstName,
                    lastName: keyAction.config.lastName,
                    name: keyAction.config.name,
                    number: keyAction.config.number,
                    extension: keyAction.config.extension,
                    uuid: keyAction.config.uuid,
                },
                config: keyAction.config?.uuid,
            }
        } else {
            return keyAction
        }
    })
    return {
        ...ivrSubmenu,
        keyActions: keyActions.reduce(function (map: IvrKeyActionMap, keyAction) {
            map[keyAction.menuKey] = keyAction
            return map
        }, {}),
    }
}

export const generateNewSubMenusFromSubmenuDefinition = (ivrSubmenu: IvrSubMenuFormFriendly, currentIvrConfig: IvrMenuConfig): IvrSubMenu[] => {
    return ivrSubmenu.keyActions
        .filter(it => it.gbtfIvrSubMenuAction === 'MENU' && it.config && !currentIvrConfig.subMenus[it.config])
        .map(it => {
            return { id: it.config, businessHoursRoot: false, keyActions: {}, announcementFileName: '' }
        })
}

export const calculateFirstFreeKey = (currentKeyActions: IvrKeyAction[]): string => {
    const candidates = [...Array.from(Array(9).keys()).map(nr => (nr + 1).toString()), '0', '*', '#']
    currentKeyActions.forEach(keyAction => {
        const index = candidates.indexOf(keyAction.menuKey)
        if (index > -1) {
            candidates.splice(index, 1)
        }
    })
    return candidates[0]
}

export const validate = (ivrMenuConfig: IvrMenuConfig, t: TFunction, operator: IvrOperator): { [key: string]: string[] } => {
    let result: { [key: string]: string[] } = {}
    Object.values(ivrMenuConfig.subMenus).forEach(subMenu => {
        if (!subMenu.announcementFileName) {
            result[subMenu.id] = [t('general.form-error.ivr.announcement-file-missing')]
        }
    })

    const roots = findRoots(ivrMenuConfig)
    if (roots.length > 1) {
        findRoots(ivrMenuConfig).forEach(subMenu => {
            result[subMenu.id] = [...(result[subMenu.id] ? result[subMenu.id] : []), t('general.form-error.ivr.more-than-one-business-hour-menu')]
        })
    } else {
        roots.forEach(subMenu => {
            if (any(keyAction => keyAction.gbtfIvrSubMenuAction === 'TO_PREVIOUS_MENU', Object.values(subMenu.keyActions))) {
                result[subMenu.id] = [...(result[subMenu.id] ? result[subMenu.id] : []), t('general.form-error.ivr.to-previous-menu-not-allowed-in-root')]
            }
            if (operator.type !== 'NONE' && !hasTransferToOperatorOnZeroKey(subMenu)) {
                result[subMenu.id] = [...(result[subMenu.id] ? result[subMenu.id] : []), t('general.form-error.ivr.missing-transfer-to-operator-action')]
            }
            if (operator.type === 'NONE' && hasTransferToOperator(subMenu)) {
                result[subMenu.id] = [...(result[subMenu.id] ? result[subMenu.id] : []), t('general.form-error.ivr.missing-operator')]
            }
        })
    }

    return result
}

export const findRoots = (ivrMenuConfig: IvrMenuConfig): IvrSubMenu[] => Object.values(ivrMenuConfig.subMenus).filter(subMenu => !hasParent(subMenu, ivrMenuConfig.subMenus))

export const getSubmenuIdsExceptSelfAndAncestors = (selfId: string, ivrMenuConfig?: IvrMenuConfig): string[] => {
    if (!ivrMenuConfig?.subMenus) {
        return []
    }
    return Object.keys(ivrMenuConfig.subMenus).filter(s => s !== selfId && !isAncestor(s, selfId, ivrMenuConfig.subMenus))
}

export const isAncestor = (potentialAncestorId: string, id: string, ivrSubMenuMap: IvrSubMenuMap): boolean => {
    if (potentialAncestorId === id) {
        return true
    }
    const potentialAncestor = ivrSubMenuMap[potentialAncestorId]
    const foundAncestor = Object.values(potentialAncestor.keyActions)
        .filter(keyAction => keyAction.gbtfIvrSubMenuAction === 'MENU')
        .find(keyAction => isAncestor(keyAction.config, id, ivrSubMenuMap))
    return !!foundAncestor
}

export const hasCycles = (ivrSubMenuMap: IvrSubMenuMap): boolean => {
    return any(
        subMenu => any(keyAction => keyAction.gbtfIvrSubMenuAction === 'MENU' && isAncestor(keyAction.config, subMenu.id, ivrSubMenuMap), Object.values(subMenu.keyActions)),
        Object.values(ivrSubMenuMap)
    )
}
export const hasParent = (submenuToCheck: IvrSubMenu, ivrSubMenuMap: IvrSubMenuMap): boolean => {
    return any(subMenu => any(keyAction => keyAction.gbtfIvrSubMenuAction === 'MENU' && keyAction.config === submenuToCheck.id, Object.values(subMenu.keyActions)), Object.values(ivrSubMenuMap))
}

export const hasTransferToOperatorOnZeroKey = (submenuToCheck: IvrSubMenu): boolean => {
    return any(action => action.menuKey === '0' && action.gbtfIvrSubMenuAction === 'TRANSFER_TO_OPERATOR', Object.values(submenuToCheck.keyActions))
}

export const hasTransferToOperator = (submenuToCheck: IvrSubMenu): boolean => {
    return any(action => action.gbtfIvrSubMenuAction === 'TRANSFER_TO_OPERATOR', Object.values(submenuToCheck.keyActions))
}

export const addTransferToOperatorAction = (ivrSubmenu: IvrSubMenu): IvrSubMenu => {
    return {
        ...ivrSubmenu,
        keyActions: { ...ivrSubmenu.keyActions, '0': { menuKey: '0', gbtfIvrSubMenuAction: 'TRANSFER_TO_OPERATOR', description: null, config: '' } },
    }
}
