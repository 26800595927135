import React, {ReactElement, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {VoicemailSettings} from '../../app/types'
import {useForm, useWatch} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import TextInput from '../../common/form/TextInput'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import {useFormSubmission} from '../../common/servercall/useFormSubmission'
import ErrorAlert from '../../common/components/ErrorAlert'
import FormGroup from '../../common/form/FormGroup'
import FormContainer from '../../common/form/FormContainer'
import {FormLabel} from '@mui/material'
import FormCheckbox from '../../common/form/FormCheckbox'

export const emailRegexWithoutEmpty= /^(([^<>()[\]\\.,;:\s@\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF"]+(\.[^<>()[\]\\.,;:\s@\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const emailRegex = /^$|^(([^<>()[\]\\.,;:\s@\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF"]+(\.[^<>()[\]\\.,;:\s@\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const schema = () =>
    yup
        .object({
            carbonCopyEmailAddress: yup
                .string()
                .when(['active', 'sendCarbonCopyVoiceMessage'], {
                    is: (active: boolean, sendCarbonCopyVoiceMessage: boolean) => active && sendCarbonCopyVoiceMessage,
                    then: schema => yup.string().trim().max(70, 'too-long').required('required-m').matches(
                        emailRegex,
                        'invalid-email'
                    ).nullable(),
                    otherwise: schema => yup.string().nullable(),
                }),
            notifyEmailAddress: yup
                .string()
                .when(['active', 'notifyEmail'], {
                    is: (active: boolean, notifyEmail: boolean) => active && notifyEmail,
                    then: schema => yup.string().trim().max(70, 'too-long').required('required-m').matches(
                        emailRegex,
                        'invalid-email'
                    ).nullable(),
                    otherwise: schema => yup.string().nullable(),
                }),
            deliveryEmailAddress: yup
                .string()
                .when(['active', 'processing'], {
                    is: (active: boolean, processing: string) => active && processing === 'DELIVER_TO_EMAIL_ADDRESS_ONLY',
                    then: schema => yup.string().trim().max(70, 'too-long').required('required-m').matches(
                        emailRegex,
                        'invalid-email'
                    ).nullable(),
                    otherwise: schema => yup.string().nullable(),
                }),
            transferPhoneNumber: yup
                .string()
                .when(['active', 'transferOnZeroToPhoneNumber'], {
                    is: (active: boolean, transferOnZeroToPhoneNumber: boolean) => active && transferOnZeroToPhoneNumber,
                    then: schema => yup.string().trim().max(22, 'too-long').matches(/^\+?[0-9]*$/, {
                        message: 'invalid-phone-number',
                        excludeEmptyString: true,
                    }).required('required-m').nullable(),
                    otherwise: schema => yup.string().nullable(),
                }),
        })
        .required()

type Props = {
    settings: VoicemailSettings
    onSubmit: (formData: VoicemailSettings) => void
    readonly?: boolean
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

export const VoicemailSettingsForm = ({settings, onSubmit, readonly, buildFormButtons}: Props) => {
    const {t} = useTranslation()

    const {control, handleSubmit, setError, setValue, getFieldState} = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(schema()),
        defaultValues: {
            ...settings
        },
    })

    const {submit, isSubmitting, serverError, onError} = useFormSubmission<VoicemailSettings>(onSubmit, setError)

    const active = useWatch({
        control,
        name: 'active',
    })

    const processing = useWatch({
        control,
        name: 'processing',
    })

    const notifyEmail = useWatch({
        control,
        name: 'notifyEmail',
    })

    const carbonCopy = useWatch({
        control,
        name: 'sendCarbonCopyVoiceMessage',
    })

    const transferOnZero = useWatch({
        control,
        name: 'transferOnZeroToPhoneNumber',
    })


    const nrOfRingsOptions = useMemo(() => {
        return [{
            value: 0,
            label: t('general.none')
        }, ...Array.from({length: 19}, (_, index) => index + 2).map(seconds => ({
            value: seconds,
            label: `${seconds}`,
        }))]
    }, [t])

    const processingOptions = useMemo(
        () =>
            ['UNIFIED_VOICE_AND_EMAIL_MESSAGING', 'DELIVER_TO_EMAIL_ADDRESS_ONLY'].map(processing => ({
                value: `${processing}`,
                label: `${t(`users.voicemail-settings.processing.${processing}`)}`,
            })),
        [t]
    )

    return (
        <>
            <ErrorAlert showAlert={!!serverError}/>
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={''} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <FormCheckbox
                                label={t('users.voicemail-settings.active')}
                                name={'active'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                    </FormRow>
                    {active ? <><FormRow spacing={1}>
                        <FormFieldBox fullWidth={true}>
                            <FormLabel
                                component="legend">{t('users.voicemail-settings.send-calls-to-voicemail')}</FormLabel>
                        </FormFieldBox>
                        <FormFieldBox fullWidth={false}>
                            <FormCheckbox
                                label={t('users.voicemail-settings.settings-always')}
                                name={'alwaysRedirectToVoiceMail'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                            <FormCheckbox
                                label={t('users.voicemail-settings.settings-when-busy')}
                                name={'busyRedirectToVoiceMail'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                            <FormCheckbox
                                label={t('users.voicemail-settings.settings-when-no-answer')}
                                name={'noAnswerRedirectToVoiceMail'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                        <FormFieldBox fullWidth={false}>
                            <TextInput readonly={readonly} label={t('users.voicemail-settings.number-of-rings')}
                                       name={'noAnswerNumberOfRings'} control={control} required={false}
                                       options={nrOfRingsOptions}/>
                        </FormFieldBox>
                    </FormRow>
                        <FormRow>
                            <FormFieldBox fullWidth={false}>
                                <TextInput
                                    readonly={readonly}
                                    label={t('users.voicemail-settings.when-message-arrives')}
                                    name={'processing'}
                                    control={control}
                                    required={false}
                                    options={processingOptions}
                                    isRadio={true}
                                />
                            </FormFieldBox>
                            <FormFieldBox fullWidth={false}>
                                {processing === 'UNIFIED_VOICE_AND_EMAIL_MESSAGING' ?
                                    <FormCheckbox
                                        label={t('users.voicemail-settings.message-waiting-indicator')}
                                        name={'usePhoneMessageWaitingIndicator'}
                                        control={control}
                                        setValue={setValue}
                                        getFieldState={getFieldState}
                                    /> : <TextInput readonly={readonly} label={t('users.edit.email')}
                                                    name={'deliveryEmailAddress'} control={control} required={true}/>
                                }
                            </FormFieldBox>
                        </FormRow>
                        <FormRow>
                            <FormFieldBox fullWidth={false}>
                                <FormCheckbox
                                    label={t('users.voicemail-settings.email-notification')}
                                    name={'notifyEmail'}
                                    control={control}
                                    setValue={setValue}
                                    getFieldState={getFieldState}
                                />
                            </FormFieldBox>
                            {notifyEmail &&
                                <FormFieldBox fullWidth={false}>
                                    <TextInput readonly={readonly} label={t('users.edit.email')}
                                               name={'notifyEmailAddress'} control={control} required={true}/>
                                </FormFieldBox>
                            }
                        </FormRow>
                        <FormRow>
                            <FormFieldBox fullWidth={false}>
                                <FormCheckbox
                                    label={t('users.voicemail-settings.carbon-copy')}
                                    name={'sendCarbonCopyVoiceMessage'}
                                    control={control}
                                    setValue={setValue}
                                    getFieldState={getFieldState}
                                />
                            </FormFieldBox>
                            {carbonCopy &&
                                <FormFieldBox fullWidth={false}>
                                    <TextInput readonly={readonly} label={t('users.edit.email')}
                                               name={'carbonCopyEmailAddress'} control={control} required={true}/>
                                </FormFieldBox>
                            }
                        </FormRow>
                        <FormRow>
                            <FormFieldBox fullWidth={false}>
                                <FormCheckbox
                                    label={t('users.voicemail-settings.transfer-on-zero')}
                                    name={'transferOnZeroToPhoneNumber'}
                                    control={control}
                                    setValue={setValue}
                                    getFieldState={getFieldState}
                                />
                            </FormFieldBox>
                            {transferOnZero &&
                                <FormFieldBox fullWidth={false}>
                                    <TextInput readonly={readonly} label={t('flows.edit.number')}
                                               name={'transferPhoneNumber'} control={control} required={true}/>
                                </FormFieldBox>
                            }
                        </FormRow>

                    </> : <></>}
                </FormGroup>
                {buildFormButtons(isSubmitting)}
            </FormContainer>
        </>
    )
}
