import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {Alert, AlertTitle, Snackbar, Switch} from '@mui/material'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../../authentication/redux'
import axios from 'axios'
import {api} from "../../../common/api";
import {useTranslation} from "react-i18next";

type Props = {
    userBusinessId: string
    optIn: boolean | undefined
    executiveId: string
    readonly : boolean
}

export const AssistantOptInSwitch = ({ executiveId, userBusinessId, optIn, readonly}: Props) => {
    const [checked, setChecked] = useState<boolean | undefined>()
    const [error, setError] = useState<string | null>(null)
    const {t} = useTranslation()

    useEffect(() => setChecked(optIn) , [optIn])

    const site = useSelector(selectedSiteSelector)

    const handleChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
        if (site) {
            try {
                await api.put(`/api/site/${site.productId}/executive/${executiveId}/assistant/${userBusinessId}/opt-in?opt-in=${event.target.checked}`, {})
            } catch (err) {
                setChecked(!event.target.checked)
                if (axios.isAxiosError(err)) {
                    const data = err?.response?.data as [any]
                    if (err.response?.status === 400 && !!data) {
                        data.forEach(serverError => setError(`general.form-error.${serverError.key}`))
                    } else {
                        setError('general.unknown-server-error')
                    }
                } else {
                    setError('general.unknown-server-error')
                }
                return
            }
        }
    }, [site, executiveId, userBusinessId])

    const SwitchComp = useMemo(() => <Switch size="small" onChange={handleChange} checked={checked} disabled={readonly} />, [checked, handleChange, readonly])

    return <>
        {checked === undefined ? <></> : SwitchComp}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={!!error} autoHideDuration={5000} onClose={() => setError(null)}>
            <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
                <AlertTitle>{t('general.error')}</AlertTitle>
                {error && t(error)}
            </Alert>
        </Snackbar>
    </>
}
