import {useEffect, useState} from 'react'
import {PhoneNumber, Site} from "../../app/types";
import {api} from "../api";
import {filter} from "ramda";

export function usePhoneNumbersToForwardTo(site: Site | null): { phoneNumbersToForwardTo: PhoneNumber[] } {
    const [phoneNumbersToForwardTo, setPhoneNumbersToForwardTo] = useState<PhoneNumber[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                if (!site) return []
                const {data} = await api.get(`/api/site/${site.productId}/numbers/all-including-empty-numbers?sort=extension,asc`)
                setPhoneNumbersToForwardTo(filter((number: PhoneNumber) => number.assignedTo != null && (number.extension != null), data.content) as PhoneNumber[])
            } catch (err: any) {
            }
        }
        fetch();
    }, [site])

    return {phoneNumbersToForwardTo}
}
