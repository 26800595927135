import {useSelector} from 'react-redux'
import {maxCallQueueSelector, selectedSiteSelector} from '../authentication/redux'
import React, {ReactElement, useMemo} from 'react'
import {AnswerGroup} from '../app/types'
import {ColumnDefinition} from '../common/components/table/Table'
import {useTranslation} from 'react-i18next'
import {useFlowTable} from '../flows/useFlowTable'
import {formatPhoneNumber, formatTotalStat} from '../common/util/formatters'
import {Avatar, Card, CardContent, CardHeader, CardMedia, Link, Typography} from '@mui/material'
import {detailUrl} from '../flows'
import {AddButton} from '../common/components/AddButton'
import CallSplitIcon from '@mui/icons-material/CallSplit'
import {useAccessHelpers} from "../common/util/hooks";

export const useFlowStats = ({ onClick, type }: { onClick: () => void, type: string }): { stats: ReactElement; totalFlows: number } => {
    const site = useSelector(selectedSiteSelector)
    const { t } = useTranslation()
    const maxCallQueues = useSelector(maxCallQueueSelector)
    const {onlyWithWriteAccess} = useAccessHelpers()

    const columns: ColumnDefinition<AnswerGroup>[] = useMemo(
        () => [
            {
                title: t('site.cards.flows.table.name'),
                key: 'name',
                sorter: true,
                render: (text: string, record: AnswerGroup) => <Link href={detailUrl.replace(':id', record.uuid)}>{record.name}</Link>,
            },
            {
                title: t('site.cards.flows.table.number'),
                key: 'number',
                sorter: true,
                render: (text: string) => <>{formatPhoneNumber(text)}</>,
            },
        ],
        [t]
    )

    const { table, totalElements } = useFlowTable({
        columns: columns,
        queryKey: `answerGroups-${type}-${site?.productId}-stats`,
        initialSortField: 'name',
        maxHeight: '18vh',
        type: type
    })

    return {
        stats: (
            <Card sx={{ height: '100%' }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: theme => theme.palette.primary.main, color: theme => theme.palette.secondary.main }} variant="rounded">
                            <CallSplitIcon />
                        </Avatar>
                    }
                    title={<Link href={'/flows'}>{t(type === 'QUEUE'? 'enterprise-sites.table.queues' : 'enterprise-sites.table.hunt-groups')} </Link>}
                    subheader={
                        <Typography color={type === 'QUEUE' && maxCallQueues !== -1 &&  totalElements > maxCallQueues ? 'error.main' : 'text.secondary'} sx={{ fontSize: 14 }}>
                            {`# ${type === 'QUEUE' ? formatTotalStat(totalElements, maxCallQueues) : totalElements}`}
                        </Typography>
                    }
                    action={onlyWithWriteAccess(<AddButton small={true} onClick={onClick} label={t('flows.create-new')} />)}
                />
                <CardMedia></CardMedia>
                <CardContent>{table}</CardContent>
            </Card>
        ),
        totalFlows: totalElements,
    }
}
