import React, {ReactElement, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {FormProvider, useForm, useWatch} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {BasicUser, Executive, ExecutiveWithAssistantsForm, Language} from '../../app/types'
import ErrorAlert from '../../common/components/ErrorAlert'
import FormContainer from '../../common/form/FormContainer'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import TextInput from '../../common/form/TextInput'
import {useFormSubmission} from '../../common/servercall/useFormSubmission'
import FormGroup from '../../common/form/FormGroup'
import {Paper} from '@mui/material'
import {formatUserInDropdown} from '../../common/util/formatters'
import {getAutocompleteUsers, getAutocompleteUsersForProduct} from '../../common/util/helpers'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import {useTableWithAdjustableOrder} from "../../common/components/table/useTableWithAdjustableOrder";


const schema = yup
    .object()
    .shape({
        executiveUser: yup.object().required("required-m").nullable(),
        assistantUsers: yup.array().max(5, 'max-5-assistants-allowed'),
    })
    .required()

type Props = {
    executive: Executive
    onSubmit: (formData: ExecutiveWithAssistantsForm) => void
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

const mapExecutiveToForm = (executive: Executive) : ExecutiveWithAssistantsForm => ({
    executiveUser: !executive.userId? null : {
        uuid: executive.userId,
        firstName: executive.userFirstName || '',
        lastName: executive.userLastName || '',
        number: null,
        language: Language.en
    },
    assistantUsers: executive.assistants.map(assistant => ({
        uuid: assistant.userId,
        firstName: assistant.userFirstName,
        lastName: assistant.userLastName,
        number: null,
        language: Language.en
    }))
})

const ExecutiveAssistantsFormScreen = ({ executive, onSubmit, buildFormButtons }: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const [executiveForm] = useState<ExecutiveWithAssistantsForm>(mapExecutiveToForm(executive))

    const { control, handleSubmit, setError, clearErrors } = useForm<ExecutiveWithAssistantsForm>({
        resolver: yupResolver(schema),
        defaultValues: executiveForm,
    })

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<ExecutiveWithAssistantsForm>(onSubmit, setError)

    const executiveUser = useWatch({
        control,
        name: 'executiveUser',
    })

    const assistantUsers = useWatch({
        control,
        name: 'assistantUsers',
    })

    const assistantOptionParams = useMemo(() => {
        return getAutocompleteUsersForProduct(
            t('flows.edit.no-users-found'),
            executiveUser?.uuid? [executiveUser?.uuid] : [],
            site
        )
    }, [site, t, executiveUser?.uuid])

    const executiveOptionParams = useMemo(() => {
        return getAutocompleteUsers(t('flows.edit.no-users-found'), assistantUsers.map(it => it.uuid), false, site, executiveForm.executiveUser || undefined, (user) => `${user.lastName} ${user.firstName}`)
    }, [site, t, executiveForm.executiveUser, assistantUsers])

    const { table: UserTable, selector: UserSelector } = useTableWithAdjustableOrder<BasicUser>('assistantUsers', assistantOptionParams, control,
        formatUserInDropdown, user => user.uuid, t('users.edit.user-select-button'), false)

    return (
        // @ts-ignore
        <FormProvider clearErrors={clearErrors}>
            <ErrorAlert showAlert={!!serverError} />
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                {
                    executive.userId ? <></> : <FormGroup label={t('executives.add.section-executive')} fullWidth={true}>
                        <FormRow>
                            <FormFieldBox>
                                <TextInput readonly={false} label={t('executives.executive')} name={'executiveUser'}
                                           control={control} required={true} autoCompleteOptions={executiveOptionParams}/>
                            </FormFieldBox>
                        </FormRow>
                    </FormGroup>
                }

                <FormGroup label={''} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox mdSize={8}>
                            {UserSelector}
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox mdSize={8}>
                            <Paper elevation={2}>
                                <UserTable />
                            </Paper>
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                {buildFormButtons(isSubmitting)}
            </FormContainer>
        </FormProvider>
    )
}

export default ExecutiveAssistantsFormScreen
