import React, {ReactElement, useCallback, useEffect, useMemo, useState} from 'react'
import {ArrayPath, Control, FieldArray, FieldValues, useFieldArray, useFormState} from 'react-hook-form'
import {Box, FormHelperText, Table, TableContainer} from '@mui/material'
import {ColumnDefinition, StyledTableRow} from '../components/table/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import {omit} from 'ramda'
import {Flex} from 'rebass'
import {useTranslation} from 'react-i18next'
import {ErrorMessage} from '@hookform/error-message'

export type TableInputReturnProps<T> = {
    table: () => ReactElement
    append: addItem<T>
    prepend: addItem<T>
    remove: removeItem
    replace: replaceItems<T>
    data: T[]
}

export type moveItem = (from: number, to: number) => void
export type removeItem = (index: number) => void
export type addItem<T> = (element: T) => void
export type replaceItems<T> = (element: T[]) => void

export default function useTableInput<S extends FieldValues, T extends FieldArray<S, ArrayPath<S>>>(name: ArrayPath<S>, control: Control<S>, columns: (remove: removeItem, move: moveItem) => ColumnDefinition<T>[], noData?: string): TableInputReturnProps<T> {
    const { t } = useTranslation()
    const [colDef, setColDef] = useState<ColumnDefinition<T>[] | null>(null)

    const { errors } = useFormState({
        control,
    })

    const { fields, move, remove, append, replace } = useFieldArray({
        control,
        name: name,
    })

    useEffect(() => {
        setColDef(
            columns(
                (index: number) => remove(index),
                (from, to) => move(from, to),
            ),
        )
    }, [remove, move, columns])

    const renderCell = useCallback(
        (cellDef, row, index) => {
            return cellDef.render(row[cellDef.key], row, index, fields.length)
        },
        [fields],
    )

    const rows = useMemo(
        () =>
            fields
                ? fields.map((row, index) => (
                    <StyledTableRow key={row.id}>
                        {colDef?.map(cellDef => (
                            <TableCell key={`${row.id}-${String(cellDef.key? cellDef.key : cellDef.title)}`}>{renderCell(cellDef, row, index)}</TableCell>
                        ))}
                    </StyledTableRow>
                ))
                : undefined,
        [fields, colDef, renderCell],
    )

    const headers = useMemo(
        () =>
            !!colDef
                ? colDef.map(col => (
                    <TableCell variant='head' key={`header-${col.key? col.key.toString(): col.title}`}>
                        <Box fontWeight={700}>{col.title}</Box>
                    </TableCell>
                ))
                : [],
        [colDef],
    )

    const nodataRow = useMemo(
        () =>
            (!rows || rows.length === 0) && (
                <TableRow key={'nodataRow'}>
                    <TableCell style={{ border: 0 }} colSpan={columns.length}>
                        <Flex alignItems={'left'} justifyContent={'left'}>
                            <Box pt={1}>{noData || t('general.no-data')}</Box>
                        </Flex>
                    </TableCell>
                </TableRow>
            ),
        [columns, rows, t, noData],
    )


    // @ts-ignore
    const errorMessages = errors[`${name}`]
    const isInvalid = !!(errors && errorMessages)

    return {
        table: () => (
            // @ts-ignore
            <Box mb={2} id={`table-${name}`}>
                <TableContainer sx={{
                    maxHeight: '40vh',
                    border: isInvalid ? 2 : 0,
                    borderColor: theme => theme.palette.error.main,
                }}>
                    <Table stickyHeader size='small' aria-label='usersTable'>
                        <TableHead>
                            <TableRow>{headers}</TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                            {!isInvalid && nodataRow}
                        </TableBody>
                    </Table>
                    {isInvalid && (
                        <Box p={2}>
                            <ErrorMessage
                                errors={errors}
                                // @ts-ignore
                                name={name}
                                render={({ message }) => <FormHelperText
                                    error={true}>{t(`general.form-error.${message}`)}</FormHelperText>}
                            />
                        </Box>
                    )}
                </TableContainer>
            </Box>
        ),
        append: element => {
            append([element])
        },
        prepend: element => {
        },
        remove: index => {
            remove(index)
        },
        replace: (items: T[]) => {
            replace(items)
        },
        // @ts-ignore
        data: fields.map(field => omit(['id'], field)),
    }
}
