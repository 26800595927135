import React, {ReactElement, useCallback, useMemo} from 'react'
import {AnswerGroup, ServerPage} from '../app/types'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import {api, buildPaginatedUrl} from '../common/api'
import ErrorAlert from '../common/components/ErrorAlert'
import {Sort, useInfiniteScroll} from '../common/components/table/useInfiniteScroll'
import {ColumnDefinition, InfiniteScrollTable} from '../common/components/table/Table'
import {sorterHeader} from '../common/components/table/SorterHeader'
import {QueryObserverResult, RefetchOptions, RefetchQueryFilters} from 'react-query/types/core/types'
import {InfiniteData} from 'react-query'

export type Props = {
    columns: ColumnDefinition<AnswerGroup>[]
    queryKey: string
    initialSortField: string
    maxHeight?: string
    type?: string
}

interface ReturnProps {
    table: ReactElement
    totalElements: number
    onSearch: (searchTerm: string) => void
    refetch: <TPageData>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => Promise<QueryObserverResult<InfiniteData<ServerPage<AnswerGroup>>>>
}

export const useFlowTable = ({ columns, queryKey, initialSortField, maxHeight, type }: Props): ReturnProps => {
    const site = useSelector(selectedSiteSelector)

    const fetchAnswerGroups = useCallback(
        async (pageParam = 0, sortParam: Sort, searchTermParam: string | null): Promise<ServerPage<AnswerGroup>> => {
            if (!site) return { content: [], totalPages: 0, number: 0, totalElements: 0 }
            const { data } = await api.get(buildPaginatedUrl(`/api/site/${site.productId}/answergroups`, pageParam, sortParam, searchTermParam) + (!!type ? '&type=' + type : ''))
            return data
        },
        [site, type]
    )

    const { sort, setSort, setSearchTerm, rows, error, fetchNextPage, hasNextPage, isFetching, refetch, totalElements } = useInfiniteScroll<AnswerGroup>({
        queryKey: queryKey,
        initialSort: { field: initialSortField, order: 'asc' },
        fetchFunction: fetchAnswerGroups,
    })

    const onSearch = useCallback(async (searchTerm: string) => setSearchTerm(searchTerm), [setSearchTerm])

    const sorter = useCallback(col => sorterHeader(col, sort, setSort), [sort, setSort])

    const getRowKey = useCallback(answerGroup => answerGroup.uuid, [])

    const table = useMemo(
        () => (
            <>
                <ErrorAlert showAlert={!!error} />
                <InfiniteScrollTable<AnswerGroup>
                    hasNextPage={hasNextPage}
                    data={rows}
                    fetchNextPage={fetchNextPage}
                    isFetching={isFetching}
                    getRowKey={getRowKey}
                    sorter={sorter}
                    columns={columns}
                    tableId={'flowsTable' + type}
                    maxHeight={maxHeight}
                />
            </>
        ),
        [columns, error, fetchNextPage, getRowKey, hasNextPage, isFetching, rows, sorter, maxHeight, type]
    )

    return {
        table,
        totalElements,
        onSearch,
        refetch,
    }
}
