import React from 'react'
import {Checkbox, FormControlLabel, FormHelperText} from '@mui/material'
import {Control, useWatch} from 'react-hook-form'
import {UseFormGetFieldState, UseFormSetValue} from 'react-hook-form/dist/types/form'
import {useTranslation} from "react-i18next";

type Props = {
    label: string
    name: string
    control: Control<any>
    setValue: UseFormSetValue<any>
    getFieldState?: UseFormGetFieldState<any>
    disabled?: boolean
    checked?: boolean
}

export default function FormCheckbox({ name, control, setValue, label, disabled, checked, getFieldState }: Props) {
    const booleanValue = useWatch({
        name: name,
        control: control,
    })
    const { t } = useTranslation()

    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        id={name}
                        disabled={disabled}
                        checked={checked || booleanValue}
                        onChange={event => {
                            setValue(name, event.target.checked)
                        }}
                    />
                }
                label={label}
            />
            {getFieldState && getFieldState(name).invalid ? <FormHelperText error={true}>
                {t(`general.form-error.${getFieldState(name).error?.message}`)}</FormHelperText> : <></>}
        </div>
    )
}
