import React from 'react'
import {Box, Grid, GridSize} from '@mui/material'
import ClickableTooltip from "../components/ClickableTooltip";
import IconButton from "@mui/material/IconButton";
import {Info} from "@mui/icons-material";

interface Props {
    children: any
    mdSize?: GridSize
    lgSize?: GridSize
    fullWidth?: boolean
}

interface WithInfoProps extends Props {
    info: string
}

export default function FormFieldBox({ children, mdSize, lgSize, fullWidth }: Props) {
    return (
        <Grid item xs={12} sm={12} md={fullWidth ? 12 : mdSize || 6} lg={fullWidth ? 12 : mdSize || lgSize || 6}>
            {children}
        </Grid>
    )
}

export function FormFieldBoxWithInfo({ children, mdSize, lgSize, fullWidth, info }: WithInfoProps) {
    return (
        <Grid container item wrap={'nowrap'} xs={12} sm={12} md={fullWidth ? 12 : mdSize || 6} lg={fullWidth ? 12 : mdSize || lgSize || 6}>
            <Grid item xs={11}>
                {children}
            </Grid>
            <Box alignSelf={'end'} paddingLeft={1} >
                <ClickableTooltip title={info} buttonFn={(onClick) =>
                    <IconButton color={'secondary'} size="small" component="span" onClick={onClick} >
                        <Info />
                    </IconButton>}
                />
            </Box>
        </Grid>
    )
}