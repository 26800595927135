import React, {useMemo, useState} from 'react'

import {IvrKeyAction, IvrSubMenuFormFriendly} from '../../app/types'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import {useTranslation} from 'react-i18next'
import {Control, useFieldArray, useFormContext, useWatch} from 'react-hook-form'
import {UseFormRegister} from 'react-hook-form/dist/types/form'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import TextInput from '../../common/form/TextInput'
import {
    getAutocompleteCompanyExtensions,
    getAutocompleteSiteAnnouncements,
    getAutocompleteSiteAnswerGroups,
    getAutocompleteSiteIvrs,
    getAutocompleteUsers
} from '../../common/util/helpers'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import DeleteIcon from '@mui/icons-material/Delete'
import {Divider, IconButton} from '@mui/material'
import {AddButton} from '../../common/components/AddButton'
import {calculateFirstFreeKey, getSubmenuIdsExceptSelfAndAncestors} from '../util/IvrHelpers'
import {IvrMenuConfigContext} from './IvrMenuConfigForm'
import SubmenuNameModal from './SubmenuNameModal'
import {useParams} from 'react-router'

interface Props {
    ivrSubMenu: IvrSubMenuFormFriendly
    control: Control<IvrSubMenuFormFriendly>
    register: UseFormRegister<IvrSubMenuFormFriendly>
}

const actions = ['MENU', 'USER', 'ANSWER_GROUP', 'IVR', 'NUMBER', 'EXTENSION', 'ANNOUNCEMENT', 'REPEAT_MENU', 'TO_PREVIOUS_MENU', 'TRANSFER_TO_OPERATOR']

type ConditionalInputProps = {
    ivrSubMenu: IvrSubMenuFormFriendly
    control: Control<IvrSubMenuFormFriendly>
    index: number
    onUpdate: (keyAction: IvrKeyAction) => void
}

const ConditionalInput = ({ ivrSubMenu, control, index, onUpdate }: ConditionalInputProps) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const { id }: { id: string } = useParams()
    const { menuConfig } = React.useContext(IvrMenuConfigContext)
    const [subModelNameDialogOpen, setSubModelNameDialogOpen] = useState<boolean>(false)
    const value = useWatch({
        name: 'keyActions',
        control,
    })

    const indexValue = useMemo(() => value?.[index], [index, value])

    const userOptionParams = useMemo(() => {
        return getAutocompleteUsers(t('flows.edit.no-users-found'), [], true, site, indexValue?.dependencyInfo)
    }, [site, t, indexValue])

    const extensionOptionParams = useMemo(() => {
        return getAutocompleteCompanyExtensions(t('ivrs.sub-menu-editor.no-extensions-found'), site, indexValue?.config)
    }, [site, t, indexValue])

    const answerGroupOptionParams = useMemo(() => {
        return getAutocompleteSiteAnswerGroups(t, site, indexValue?.dependencyInfo)
    }, [site, t, indexValue])

    const ivrOptionParams = useMemo(() => {
        return getAutocompleteSiteIvrs(t, [id], site, indexValue?.dependencyInfo)
    }, [site, id, t, indexValue])

    const announcementOptionParams = useMemo(() => {
        return getAutocompleteSiteAnnouncements(t, 2000, site, indexValue?.config ?? undefined)
    }, [site, t, indexValue])

    const subMenus = useMemo(() => getSubmenuIdsExceptSelfAndAncestors(ivrSubMenu.id, menuConfig), [menuConfig, ivrSubMenu.id])

    const subMenuOptionParams = useMemo(() => {
        return [
            { value: '', label: '' },
            { value: '!new!', label: t('ivrs.sub-menu-editor.create-sub-menu') },
            ...(indexValue?.config && indexValue?.config !== '!new!' && !subMenus.includes(indexValue?.config)
                ? [
                      {
                          value: indexValue?.config,
                          label: indexValue?.config,
                      },
                  ]
                : []),
            ...subMenus.map(it => {
                return { value: it, label: it }
            }),
        ]
    }, [t, indexValue, subMenus])

    return (
        <>
            {indexValue?.gbtfIvrSubMenuAction === 'USER' ? (
                <TextInput name={`keyActions.${index}.config`} autoCompleteOptions={userOptionParams} required={true} label={t('ivrs.sub-menu-editor.user')} control={control} />
            ) : indexValue?.gbtfIvrSubMenuAction === 'ANSWER_GROUP' ? (
                <TextInput name={`keyActions.${index}.config`} autoCompleteOptions={answerGroupOptionParams} required={true} label={t('ivrs.sub-menu-editor.flow')} control={control} />
            ) : indexValue?.gbtfIvrSubMenuAction === 'IVR' ? (
                <TextInput name={`keyActions.${index}.config`} autoCompleteOptions={ivrOptionParams} required={true} label={t('ivrs.sub-menu-editor.ivr')} control={control} />
            ) : indexValue?.gbtfIvrSubMenuAction === 'ANNOUNCEMENT' ? (
                <TextInput help={t('general.max-file-size', {size: 2})} name={`keyActions.${index}.config`} autoCompleteOptions={announcementOptionParams} required={true} label={t('ivrs.sub-menu-editor.announcement')} control={control} />
            ) : indexValue?.gbtfIvrSubMenuAction === 'NUMBER' ? (
                <TextInput name={`keyActions.${index}.config`} required={true} label={t('ivrs.sub-menu-editor.number')} control={control} />
            ) : indexValue?.gbtfIvrSubMenuAction === 'EXTENSION' ? (
                <TextInput name={`keyActions.${index}.config`} autoCompleteOptions={extensionOptionParams} required={true} label={t('ivrs.sub-menu-editor.extension')} control={control} />
            ) : indexValue?.gbtfIvrSubMenuAction === 'MENU' ? (
                <TextInput
                    name={`keyActions.${index}.config`}
                    options={subMenuOptionParams}
                    onChange={e => {
                        if (e.target.value === '!new!') {
                            setSubModelNameDialogOpen(true)
                            return false
                        }
                    }}
                    required={true}
                    label={t('ivrs.sub-menu-editor.submenu')}
                    control={control}
                />
            ) : (
                <></>
            )}
            <SubmenuNameModal
                onSuccess={newName => {
                    setSubModelNameDialogOpen(false)
                    onUpdate({ ...indexValue, config: newName })
                }}
                open={subModelNameDialogOpen}
                onClose={() => {
                    setSubModelNameDialogOpen(false)
                    onUpdate({ ...indexValue, config: '' })
                }}
            />
        </>
    )
}

export default function IvrSubMenuKeys({ register, ivrSubMenu, control }: Props) {
    const { t } = useTranslation()

    const { clearErrors } = useFormContext()

    const keyActions = useWatch({
        name: 'keyActions',
        control,
    })

    const currentKeyActions = useWatch({
        name: 'keyActions',
        control,
    })

    const { fields, remove, append, update } = useFieldArray({
        control,
        name: 'keyActions',
    })

    const keyOptions = useMemo(
        () =>
            [...Array.from(Array(10).keys()).map(nr => nr.toString()), '*', '#'].map(key => {
                return {
                    value: key,
                    label: key,
                }
            }),
        []
    )

    const actionOptions = useMemo(
        () =>
            actions.map(key => {
                return {
                    value: key,
                    label: t(`ivrs.actions.${key}`),
                }
            }),
        [t]
    )

    return (
        <>
            {fields.map((field, index) => (
                <FormRow key={field.id}>
                    <FormFieldBox mdSize={1} lgSize={1}>
                        <IconButton aria-controls="demo-positioned-menu" size={'small'} onClick={() => remove(index)}>
                            <DeleteIcon fontSize={'inherit'} />
                        </IconButton>
                    </FormFieldBox>
                    <FormFieldBox mdSize={2} lgSize={2}>
                        <TextInput required={true} options={keyOptions} label={t('ivrs.sub-menu-editor.key')} name={`keyActions.${index}.menuKey`} control={control} />
                    </FormFieldBox>
                    <FormFieldBox mdSize={4} lgSize={4}>
                        <TextInput
                            required={true}
                            options={actionOptions}
                            label={t('ivrs.sub-menu-editor.action')}
                            onChange={(e, child) => {
                                clearErrors('keyActions')
                                update(index, {
                                    menuKey: keyActions?.[index]?.menuKey,
                                    gbtfIvrSubMenuAction: e.target.value,
                                    config: '',
                                    description: null
                                })
                            }}
                            name={`keyActions.${index}.gbtfIvrSubMenuAction`}
                            control={control}
                        />
                    </FormFieldBox>
                    <FormFieldBox mdSize={5} lgSize={5}>
                        <ConditionalInput
                            control={control}
                            index={index}
                            ivrSubMenu={ivrSubMenu}
                            onUpdate={submenu => {
                                clearErrors('keyActions')
                                update(index, submenu)
                            }}
                        />
                    </FormFieldBox>
                    {index !== fields.length - 1 && (
                        <FormFieldBox fullWidth={true}>
                            <Divider variant="middle" />
                        </FormFieldBox>
                    )}
                </FormRow>
            ))}
            <FormRow>
                <FormFieldBox mdSize={5} lgSize={5}>
                    <AddButton
                        onClick={e => {
                            clearErrors('keyActions')
                            append({
                                menuKey: calculateFirstFreeKey(currentKeyActions),
                                gbtfIvrSubMenuAction: '',
                                config: '',
                                description: null
                            })
                        }}
                        label={t('ivrs.sub-menu-editor.add-key')}
                    />
                </FormFieldBox>
            </FormRow>
        </>
    )
}
