import React, {ReactElement, ReactNode, useCallback} from 'react'
import {NightService} from "../../app/types";
import {FormSection} from "../../common/components/SectionedForm";
import {api} from "../../common/api";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import {useTranslation} from "react-i18next";
import PaperStack from "../../common/form/PaperStack";
import FormGroup from "../../common/form/FormGroup";
import {LabelValues, LabelValueType} from "../../common/components/LabelValues";
import BedTimeIcon from "@mui/icons-material/Bedtime";
import {useFetchData} from "../../common/servercall/useFetchData";
import {NightServiceForm} from "./NightServiceGeneralForm";
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";
import {formatNumberToForwardTo} from "../../common/util/formatters";

export const useGeneralSection = () : FormSection<NightService> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const {buildFormButtons} = useFormSubmissionButtons(false)

    const fetchData = useCallback(
        async (): Promise<NightService | undefined> => {
            if (!site) return
            const { data } = await api.get<NightService>(`/api/site/${site.productId}/night-service`)
            return data
        },
        [site]
    )
    const { data, isFetching, error, refetch } = useFetchData<NightService>(useCallback(() => fetchData(), [fetchData]))

    const onGeneralSubmit = useCallback((onSucceeded: () => void) =>
            async (formData: NightService) => {
                if (!site) return
                await api.put(`/api/site/${site.productId}/night-service`, formData)
                await refetch()
                onSucceeded();
            },
        [site, refetch],
    )

    const renderGeneralForm = useCallback((nightService: NightService, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode =>
        data ? <NightServiceForm nightService={data} buildFormButtons={buildFormButtons(cancelEditButton)}
                                       onSubmit={onGeneralSubmit(onSucceeded)}/> : <></>, [data, onGeneralSubmit, buildFormButtons])

    const infoLabelValues = useCallback(
        (value: NightService): LabelValueType[] => [
            { label: t('general.enabled'), value: <>{value.enabled? t('general.yes') : t('general.no')}</> },
            { label: t('executives.screening-alerting.forward-to-number'), value: <>{formatNumberToForwardTo(t, value.forwardNumber, [])}</> },
        ],
        [t]
    )

    const renderGeneralDetails = useCallback(
        (nightService: NightService): ReactNode => {
            return (
                <PaperStack>
                    <FormGroup key={1} label={''} fullWidth={true}>
                        <LabelValues labelValues={infoLabelValues(nightService)} />
                    </FormGroup>
                    <div></div>
                </PaperStack>
            )
        },
        [infoLabelValues]
    )

    return {
        title: t('general.general'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderGeneralDetails,
        renderForm: renderGeneralForm,
        enabled: () => true,
        icon: () => <BedTimeIcon/>
    }
}