import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {ArrayPath, Control, UseFormClearErrors} from 'react-hook-form'
import {BasicUser, Paging} from '../../app/types'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import {Paper} from '@mui/material'
import {formatUserInDropdown} from '../../common/util/formatters'
import {getAutocompleteUsers} from '../../common/util/helpers'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import {useTableWithAdjustableOrder} from "../../common/components/table/useTableWithAdjustableOrder";

type Props = {
    fieldName: ArrayPath<Paging>
    clearErrors: UseFormClearErrors<Paging>
    control: Control<Paging>
}

const PagingUserInputTable = ({ fieldName, control }: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)

    const userOptionParams = useMemo(() => {
        return getAutocompleteUsers(t('flows.edit.no-users-found'), [], false, site)
    }, [site, t])

    const { table: UserTable, selector: UserSelector } = useTableWithAdjustableOrder<BasicUser>(fieldName, userOptionParams, control, formatUserInDropdown, user => user.uuid, t('users.edit.user-select-button'))

    return (
        // @ts-ignore
        <>
            <FormRow>
                <FormFieldBox mdSize={8} lgSize={6}>
                    {UserSelector}
                </FormFieldBox>
            </FormRow>
            <FormRow>
                <FormFieldBox mdSize={12}>
                    <Paper elevation={2}>
                        <UserTable />
                    </Paper>
                </FormFieldBox>
            </FormRow>
        </>
    )
}

export default PagingUserInputTable
