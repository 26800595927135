import * as React from 'react'
import {useCallback, useEffect} from 'react'
import {useHistory, useLocation} from "react-router";
import {clearSite, selectedSiteSelector, selectSite} from "../authentication/redux";
import {useDispatch, useSelector} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {RootState} from "./store";
import * as H from "history";

function queryStringToObject(queryString = "", options: Record<string, any> = {}) {
    let queryObject: Record<string, any> = {};
    queryString && decodeURIComponent(queryString.replace('?', '')).split('&').map((itemString) => {
        let [itemKey, itemValue] = itemString.split("=");
        if (options.hasOwnProperty(itemKey)) {
            if (!queryObject[itemKey] && Array.isArray(options[itemKey])) {
                queryObject[itemKey] = [];
            }
            Array.isArray(options[itemKey]) ? queryObject[itemKey].push(itemValue) : queryObject[itemKey] = typeof options[itemKey] === "number" ? parseInt(itemValue) : itemValue;
        }
        return ''
    });
    return queryObject;
};

const SiteSwitcher = () => {
    const { search } = useLocation();
    const site = useSelector(selectedSiteSelector)

    const push = useCallback(async (h: H.History, d, toUrl: string, siteProductId: string) => {
        await h.push(toUrl)
        await d(selectSite(siteProductId))
    }, [])

    const goBack = useCallback(async (h: H.History, d, toUrl: string, siteProductId: string) => {
        if (!!siteProductId) {
            h.push(toUrl)
            await d(selectSite(siteProductId))
        } else {
            await d(clearSite())
            h.goBack()
        }
    }, [])

    const redirect = useCallback(async (h: H.History, d) => {
        const params = queryStringToObject(search, {'to-site': null, 'to-url': null, 'from-site': null, 'from-url': null})

        const siteProductId = site?.productId || ''

        if (h.action === 'PUSH' && siteProductId !== params['to-site']) {
            await push(h, d, params['to-url'], params['to-site'])
        } else if (h.action === 'POP' && siteProductId !== params['from-site']){
            await goBack(h, d, params['from-url'], params['from-site'])
        }
        /*else if (h.action === 'POP' && site?.productId === params['from-site']){
            await push(h, d, params['to-url'], params['to-site'])
        }*/

    }, [push, goBack, search, site])

    useMountEffect(redirect)
    return <></>
}

const useMountEffect  = ( f: (h: H.History, d: ThunkDispatch<RootState, any, any>) => void) => {
    const history = useHistory();
    const dispatch = useDispatch<ThunkDispatch<RootState, any, any>>()

    return useEffect( () => {
        (async () => {
            let didCancel = false;
            if (!didCancel) { await f(history, dispatch) }
            return () => { didCancel = true; };
        })();
    })
}

export default SiteSwitcher