import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import {useFetchData} from '../common/servercall/useFetchData'
import {AnswerGroupForm} from '../app/types'
import {useParams} from 'react-router'
import {api} from '../common/api'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import {Page} from '../app/navigation/redux'
import {Grid} from '@mui/material'
import {SectionedForm} from "../common/components/SectionedForm";
import {useGeneralSection} from "./general";
import {useAccessHelpers} from "../common/util/hooks";

export const DetailAnswerGroup = () => {
    const { t } = useTranslation()
    const { id }: { id: string } = useParams()
    const site = useSelector(selectedSiteSelector)
    const {hasWriteAccess} = useAccessHelpers()

    const fetchAnswerGroup = useCallback(
        async (answerGroupId: string): Promise<AnswerGroupForm | undefined> => {
            if (!site) return
            const { data } = await api.get<AnswerGroupForm>(`/api/site/${site.productId}/answergroup/${answerGroupId}`)
            return data
        },
        [site]
    )

    const { data, isFetching, error, refetch } = useFetchData<AnswerGroupForm>(useCallback(() => fetchAnswerGroup(id), [fetchAnswerGroup, id]))

    const pageInfo: Page | null = useMemo(
        () =>
            data
                ? {
                      label: `${data.name}`,
                      key: 'flow-detail',
                  }
                : null,
        [data]
    )

    const generalSection = useGeneralSection(id, isFetching, error, refetch, data);
    const formSections = useMemo(() => [
        generalSection
    ], [generalSection])

    return (
        <TitledBody title={t('flows.detail.title')} page={pageInfo}>
            <Grid container>
                <Grid item xs={12} lg={10}>
                    <SectionedForm formSections={formSections} activeTab={0} readOnly={!hasWriteAccess} />
                </Grid>
            </Grid>
        </TitledBody>
    )
}
