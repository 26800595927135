import React, {ReactElement, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useForm, useWatch} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {object} from 'yup'
import TextInput from '../../common/form/TextInput'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import SpinnableSubmitButton from '../../common/form/SpinnableSubmitButton'
import {useFormSubmission} from '../../common/servercall/useFormSubmission'
import ErrorAlert from '../../common/components/ErrorAlert'
import FormGroup from '../../common/form/FormGroup'
import CancelButton from '../../common/form/CancelButton'
import FormContainer from '../../common/form/FormContainer'
import FormButtons from '../../common/form/FormButtons'
import {
    ataNumberTranslationFromValidationRule,
    ataNumberTranslationToValidationRule,
    toAtaConfigForm
} from "../util/CPEHelpers";
import {CpeAtaConfigForm, CPESettings} from "../../app/types";
import {Grid} from "@mui/material";

const schema = () => yup
        .object()
        .shape({
            hotlineNumber: yup.string().when(['type'], {
                is: (type: string) => ['HOTLINE'].includes(type),
                then: schema => ataNumberTranslationToValidationRule(),
                otherwise: yup.string().nullable()
            }),
            numberTranslations: yup.array().when(['type'], {
                is: (type: string) => ['TRANSLATION'].includes(type),
                then: schema => yup.array().of(object().shape({
                    from: ataNumberTranslationFromValidationRule(),
                    to: ataNumberTranslationToValidationRule()
                })),
                otherwise: yup.array().nullable()
            }),
        })

type Props = {
    cpeSettings: CPESettings
    onSubmit: (cpeSettings: CPESettings) => void
    readonly?: boolean
    buildFormButtons: ((isSubmitting: boolean) => ReactElement) | undefined
}

const submitForm = (onSubmit: (cpeSettings: CPESettings) => void) => (formData: CpeAtaConfigForm) => onSubmit(
    {
        keys: [],
        features: formData.type === 'HOTLINE'? [
            {
                type: 'ATA_HOTLINE_NUMBER',
                value: formData.hotlineNumber
            },
            {
                type: 'ATA_NUMBER_TRANSLATIONS',
                value: null
            }
        ] : [
            {
                type: 'ATA_HOTLINE_NUMBER',
                value: null
            },
            {
                type: 'ATA_NUMBER_TRANSLATIONS',
                value: formData.numberTranslations
                    .filter(translation => !!translation.from && !!translation.to)
                    .map(translation => `${translation.from}>${translation.to}`
                ).join(',')
            }
        ]
    }
)

const CPEAtaConfigFormScreen = ({cpeSettings, onSubmit, readonly, buildFormButtons}: Props) => {
    const {t} = useTranslation()

    const {control, handleSubmit, setError} = useForm<CpeAtaConfigForm>({
        resolver: yupResolver(schema()),
        defaultValues: toAtaConfigForm(cpeSettings),
    })

    const {submit, isSubmitting, serverError, onError} = useFormSubmission<CpeAtaConfigForm>(submitForm(onSubmit), setError)

    const type = useWatch({
        control,
        name: 'type',
    })

    const options = useMemo(() => [
        {
            value: 'TRANSLATION', label: t('cpes.detail.number-translation')
        },
        {
            value: 'HOTLINE', label: t('cpes.detail.hotline')
        },
    ], [t])

    return (
        <>
            <ErrorAlert errorKey={typeof serverError === 'string' ? serverError : undefined} showAlert={!!serverError}/>
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={t('cpes.detail.auto-number-conversion')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput label={t('numbers.table.type')} name={'type'} control={control} options={options} isRadio={true}/>
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        {
                            type === 'HOTLINE' ?
                                <FormFieldBox>
                                    <TextInput label={t('users.table.number')} readonly={readonly}
                                               name={`hotlineNumber`} control={control}
                                    />
                                </FormFieldBox>
                                : [0,1,2,3,4,5,6,7,8,9].map(index => (
                                        <Grid container item spacing={2} key={index}>
                                            <TranslationBox>
                                                <TextInput label={t('cpes.detail.translation-from')}
                                                           name={`numberTranslations.${index}.from`} control={control}
                                                          />
                                            </TranslationBox>
                                            <TranslationBox>
                                                <TextInput label={t('cpes.detail.translation-to')}
                                                           name={`numberTranslations.${index}.to`} control={control}
                                                           />
                                            </TranslationBox>
                                        </Grid>
                                    ))
                        }
                    </FormRow>
                </FormGroup>
                {readonly ? <></> : buildFormButtons? buildFormButtons(isSubmitting) :
                    <FormButtons fullWidth={true} buttons={[<SpinnableSubmitButton label={t('general.form-update')} showSpinner={isSubmitting}/>,
                                                     <CancelButton/>]}/>}
            </FormContainer>
        </>
    )
}

function TranslationBox({ children, align, width }: { children: any; align?: string; width?: number }) {
    return (
        <Grid container={!!align} item xs={width || 3} sm={width || 3} md={width || 3} lg={width || 3}
              alignItems={align}>
            {children}
        </Grid>
    )
}


export default CPEAtaConfigFormScreen
