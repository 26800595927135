import { IvrNodeData, IvrSubMenuMap } from '../../app/types'
import { ArrowHeadType, Elements } from 'react-flow-renderer'
import { hasCycles } from '../util/IvrHelpers'

export const createGraphElements = (readonly: boolean, ivrSubMenuMap?: IvrSubMenuMap, validationErrors?: { [key: string]: string[] }): Elements<IvrNodeData> => {
    if (!ivrSubMenuMap) {
        return []
    }
    const hasSubmenuCycles = hasCycles(ivrSubMenuMap)
    const result: Elements = []
    Object.keys(ivrSubMenuMap).forEach(function(key, index) {
        result.push({
            id: key,
            type: 'ivrGraphNode',
            data: {
                readonly: readonly,
                label: ivrSubMenuMap[key].id,
                final: false,
                hasParent: true,
                ivrSubMenu: ivrSubMenuMap[key],
                validationErrors: validationErrors ? validationErrors[key] : undefined,
            },
            draggable: false,
            position: { x: 0, y: 0 },
        })
        Object.keys(ivrSubMenuMap[key].keyActions)
            .sort()
            .forEach(function(key2, index) {
                if (ivrSubMenuMap[key].keyActions[key2].gbtfIvrSubMenuAction !== 'MENU') {
                    const nodeId = `${ivrSubMenuMap[key].id}-${ivrSubMenuMap[key].keyActions[key2].menuKey}`
                    result.push({
                        id: nodeId,
                        type: 'ivrGraphNode',
                        data: {
                            readonly: readonly,
                            label: ivrSubMenuMap[key].keyActions[key2].config,
                            final: true,
                            hasParent: true,
                            ivrKeyAction: ivrSubMenuMap[key].keyActions[key2],
                        },
                        selectable: false,
                        draggable: false,
                        position: { x: 0, y: 0 },
                    })
                    result.push({
                        id: `${key}-${nodeId}`,
                        source: key,
                        target: nodeId,
                        type: hasSubmenuCycles ? 'smart' : 'bezier',
                        label: `# ${ivrSubMenuMap[key].keyActions[key2].menuKey}`,
                        arrowHeadType: ArrowHeadType.ArrowClosed,
                    })
                } else {
                    // @ts-ignore
                    !!ivrSubMenuMap[key].keyActions[key2].config &&
                    result.push({
                        id: `${ivrSubMenuMap[key].id}-${ivrSubMenuMap[key].keyActions[key2].menuKey}`,
                        source: key,
                        // @ts-ignore
                        target: ivrSubMenuMap[key].keyActions[key2].config,
                        //type: isAncestor(ivrSubMenuMap[key].keyActions[key2].config, key, ivrSubMenuMap)? 'smart' : 'smart',
                        type: hasSubmenuCycles ? 'smart' : 'bezier',
                        label: `# ${ivrSubMenuMap[key].keyActions[key2].menuKey}`,
                        arrowHeadType: ArrowHeadType.ArrowClosed,
                    })
                }
            })
    })
    return result
}
