import React, { memo, ReactElement } from 'react'
import { Handle, Position } from 'react-flow-renderer'
import { IvrNodeData } from '../../app/types'
import { Card, CardContent, List, ListItem, ListItemText, Tooltip, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import Person from '@mui/icons-material/Person'
import DialpadIcon from '@mui/icons-material/Dialpad'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ReplayIcon from '@mui/icons-material/Replay'
import CallSplitIcon from '@mui/icons-material/CallSplit'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { LibraryMusic } from '@mui/icons-material'
import { Box, Flex } from 'rebass'
import { TFunction, useTranslation } from 'react-i18next'
import { formatPhoneNumber } from '../../common/util/formatters'
import ErrorIcon from '@mui/icons-material/Error'
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg'

interface NodeTypeRenderer {
    getIcon: (input: IvrNodeData, selected?: boolean) => ReactElement
    getTitle: (t: TFunction, input: IvrNodeData, selected?: boolean) => ReactElement
    getCursor: (input: IvrNodeData, readonly: boolean) => string
}

interface NodeTypeRendererList {
    [key: string]: NodeTypeRenderer
}

const nodeTypes: NodeTypeRendererList = {
    MENU: {
        getIcon: (data, selected) => <MenuIcon color={selected ? 'primary' : 'inherit'} fontSize="inherit" />,
        getTitle: (t, data, selected) => (
            <Typography variant="body2" color={selected ? 'text.primary' : 'text.secondary'}>
                {data.ivrSubMenu?.id}
            </Typography>
        ),
        getCursor: data => (data.readonly ? 'not-allowed' : 'pointer'),
    },
    ANNOUNCEMENT: {
        getIcon: data => <LibraryMusic fontSize="inherit" />,
        getTitle: (t, data) => (
            <Typography variant="body2" color="text.secondary">
                {data.ivrKeyAction?.config}
            </Typography>
        ),
        getCursor: data => 'not-allowed',
    },
    USER: {
        getIcon: data => <Person fontSize="inherit" />,
        getTitle: (t, data) => (
            <Typography variant="body2" color="text.secondary">
                {`${data.ivrKeyAction?.dependencyInfo?.lastName} ${data.ivrKeyAction?.dependencyInfo?.firstName}`}
            </Typography>
        ),
        getCursor: data => 'not-allowed',
    },
    ANSWER_GROUP: {
        getIcon: data => <CallSplitIcon fontSize="inherit" />,
        getTitle: (t, data) => (
            <Typography variant="body2" color="text.secondary">
                {data.ivrKeyAction?.dependencyInfo?.name}
            </Typography>
        ),
        getCursor: data => 'not-allowed',
    },
    IVR: {
        getIcon: data => <PermPhoneMsgIcon fontSize="inherit" />,
        getTitle: (t, data) => (
            <Typography variant="body2" color="text.secondary">
                {data.ivrKeyAction?.dependencyInfo?.name}
            </Typography>
        ),
        getCursor: data => 'not-allowed',
    },
    REPEAT_MENU: {
        getIcon: data => <ReplayIcon fontSize="inherit" />,
        getTitle: (t, data) => (
            <Typography variant="body2" color="text.secondary">
                {t('ivrs.actions.REPEAT_MENU')}
            </Typography>
        ),
        getCursor: data => 'not-allowed',
    },
    TO_PREVIOUS_MENU: {
        getIcon: data => <ArrowBack fontSize="inherit" />,
        getTitle: (t, data) => (
            <Typography variant="body2" color="text.secondary">
                {t('ivrs.actions.TO_PREVIOUS_MENU')}
            </Typography>
        ),
        getCursor: data => 'not-allowed',
    },
    NUMBER: {
        getIcon: data => <DialpadIcon fontSize="inherit" />,
        getTitle: (t, data) => (
            <Typography variant="body2" color="text.secondary">
                {formatPhoneNumber(data.ivrKeyAction?.config)}
            </Typography>
        ),
        getCursor: data => 'not-allowed',
    },
    EXTENSION: {
        getIcon: data => <DialpadIcon fontSize="inherit" />,
        getTitle: (t, data) => (
            <Typography variant="body2" color="text.secondary">
                {data.ivrKeyAction?.config}
            </Typography>
        ),
        getCursor: data => 'not-allowed',
    },
    TRANSFER_TO_OPERATOR: {
        getIcon: data => <SupportAgentIcon fontSize="inherit" />,
        getTitle: (t, data) => (
            <Typography variant="body2" color="text.secondary">
                {t('ivrs.actions.TRANSFER_TO_OPERATOR')}
            </Typography>
        ),
        getCursor: data => 'not-allowed',
    },
    DEFAULT: {
        getIcon: data => <></>,
        getTitle: data => <Typography variant="body2" color="text.secondary"></Typography>,
        getCursor: data => 'not-allowed',
    },
}

const getNodeTypeRenderer = (data: IvrNodeData): NodeTypeRenderer => {
    if (data.ivrSubMenu) {
        return nodeTypes['MENU']
    } else {
        return nodeTypes[data.ivrKeyAction?.gbtfIvrSubMenuAction ?? 'DEFAULT'] || nodeTypes['DEFAULT']
    }
}

export default memo(({ data, selected, readonly, ...options }: { data: IvrNodeData; selected: boolean; readonly: boolean }) => {
    const { t } = useTranslation()
    const nodeTypeRenderer: NodeTypeRenderer = getNodeTypeRenderer(data)

    return (
        <Card style={{ cursor: nodeTypeRenderer.getCursor(data, readonly) }} elevation={selected ? 24 : 1} sx={{ maxWidth: 200, borderColor: 'primary.main' }}>
            <CardContent>
                {data.hasParent && (
                    <Handle
                        type="target"
                        position={Position.Top}
                        style={{
                            border: '1px solid rgb(152,152,152)',
                            backgroundColor: 'white',
                        }}
                    />
                )}
                <Flex justifyContent={'end'}>
                    {data.validationErrors?.length && (
                        <Tooltip
                            title={
                                <List dense={true}>
                                    {data.validationErrors.map(errorKey => (
                                        <ListItem key={errorKey}>
                                            <ListItemText primary={errorKey} />
                                        </ListItem>
                                    ))}
                                </List>
                            }
                            placement="top">
                            <ErrorIcon fontSize={'inherit'} color={'error'} />
                        </Tooltip>
                    )}
                </Flex>
                <Flex justifyContent={'center'}>
                    <Box>{nodeTypeRenderer.getIcon(data, selected)}</Box>
                </Flex>
                <Flex justifyContent={'center'}>
                    <Box>{nodeTypeRenderer.getTitle(t, data, selected)}</Box>
                </Flex>
                {!data.final && <Handle type="source" position={Position.Bottom} style={{ border: '1px solid rgb(152,152,152)', backgroundColor: 'white' }} />}
            </CardContent>
        </Card>
    )
})
