import {makeStyles} from "@mui/styles";
import {Avatar, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 2, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    avatar: {
        margin: theme.spacing(4),
        backgroundColor: theme.palette.primary.main,
    },
}));

export const Logout = () => {
    const classes = useStyles();
    const { t } = useTranslation()

    return <div className={classes.heroContent}>
        <Container maxWidth="sm">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography variant="h5" align="center" color="textSecondary" paragraph>
                    {t('session.signed-out-text')}
                </Typography>
                {/*<div className={classes.heroButtons}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <Button variant="outlined" color="secondary" onClick={async () => await toLoginPage()}>
                                {t('logout.login')}
                            </Button>
                        </Grid>
                    </Grid>
                </div>*/}
            </div>
        </Container>
    </div>
}