import {CPE, PhoneUser} from '../app/types'
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material'
import {useTranslation} from 'react-i18next'
import CancelButton from '../common/form/CancelButton'
import SpinnableSubmitButton from '../common/form/SpinnableSubmitButton'
import TextInput from '../common/form/TextInput'
import React, {useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useFormSubmission} from '../common/servercall/useFormSubmission'
import ErrorAlert from '../common/components/ErrorAlert'
import {api} from '../common/api'
import {ErrorOption} from 'react-hook-form/dist/types/errors'
import {useWarningDialog} from "../common/components/warning/useWarningDialog";
import {formatUserInDropdown} from "../common/util/formatters";

type Props = {
    cpe: CPE | null
    handleClose: () => void
    onSuccess: () => void
}

type form = {
    userId: string
}

const schema = yup
    .object()
    .shape({
        userId: yup.string().nullable(),
    })
    .required()

export const AttachUserToCpeDialog = ({ cpe, handleClose, onSuccess }: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)

    const { control, handleSubmit, setError } = useForm<form>({
        resolver: yupResolver(schema),
        defaultValues: { userId: cpe?.userUuid ? cpe.userUuid : 'ddf' },
    })

    const {warningDialog: WarningDialog, calls  } = useWarningDialog()

    const onSubmit = useCallback(
        async (formData: form) => {
            if (!site || !cpe) return

            await calls(
                async () => {
                    const {data} = await api.get(`/api/site/${site.productId}/cpe/${cpe.uuid}/check-user-assignment${formData.userId ? `?userId=${formData.userId}` : ''   }` )
                    return data
                },
                async () => {
                    await api.put(`/api/site/${site.productId}/cpe/${cpe.uuid}/attach-user`, {...formData});
                    onSuccess()
                    handleClose()
                }
            )


        },
        [site, cpe, handleClose, onSuccess, calls]
    )

    const checkError = useCallback(
        (name: any, error: ErrorOption) => {
            if (name.includes('cpeId')) {
                setError('userId', error)
            } else {
                setError(name, error)
            }
        },
        [setError]
    )

    const { submit, isSubmitting, serverError } = useFormSubmission<form>(onSubmit, checkError)

    const userOptionParams = useMemo(() => {
        const defaultObject = cpe?.userUuid
            ? {
                  uuid: cpe.userUuid,
                  firstName: cpe.userFirstName,
                  lastName: cpe.userLastName,
                  webexEmail: cpe.userWebexEmail,
              }
            : null

        return {
            getOptions: async () => {
                if (!site) return []
                const { data } = await api.get(`/api/site/${site.productId}/users-without-cpe`)
                const freeUsers = data as PhoneUser[]
                return defaultObject ? [...freeUsers, { ...defaultObject }] : freeUsers
            },
            getOptionLabel: (item: PhoneUser) => formatUserInDropdown(item),
            getOptionValue: (item: PhoneUser) => item.uuid,
            isOptionEqualToValue: (option: PhoneUser, value: PhoneUser) => option.uuid === value.uuid,
            defaultObject: defaultObject ? { ...defaultObject } : null,
            noOptionsText: t('cpes.userdialog.no-free-users-left'),
        }
    }, [cpe, site, t])

    return (
        <>
            <WarningDialog />
            <Dialog open={!!cpe} onClose={handleClose}>
                <form onSubmit={handleSubmit(submit)}>
                    <DialogTitle>{t('cpes.userdialog.title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{t('cpes.userdialog.text')}</DialogContentText>
                        <ErrorAlert showAlert={!!serverError} />
                        <TextInput readonly={false} label={t('cpes.userdialog.user')} name={'userId'} control={control} required={false} autoCompleteOptions={userOptionParams} />
                    </DialogContent>
                    <DialogActions>
                        <SpinnableSubmitButton label={t('cpes.userdialog.submit-button')} showSpinner={isSubmitting} />
                        <CancelButton onClick={handleClose} />
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
