import React, { useCallback, useEffect, useState } from 'react'
import ReactFlow, {
    ConnectionLineType,
    Controls,
    Edge,
    Elements,
    FlowElement,
    Node,
    OnLoadParams,
    Position,
    ReactFlowProvider,
    useStoreState,
} from 'react-flow-renderer'
import IvrGraphNode from './IvrGraphNode'
import { IvrNodeData } from '../../app/types'
import { SmartEdge } from '@tisoap/react-flow-smart-edge'

var dagre = require('dagre')

const layoutElements = (flowNodeStates: Node[], edges: Edge[]): Elements => {
    const dagreGraph = new dagre.graphlib.Graph()
    dagreGraph.setDefaultEdgeLabel(() => ({}))

    const direction = 'TB'
    dagreGraph.setGraph({ rankdir: direction, ranker: 'longest-path' })

    flowNodeStates.forEach(el => {
        dagreGraph.setNode(el.id, { width: el.__rf.width + 100, height: el.__rf.height })
    })
    edges.forEach(el => {
        dagreGraph.setEdge(el.source, el.target)
    })
    dagre.layout(dagreGraph)

    const resultNodes: FlowElement[] = flowNodeStates.map(el => {
        const node = dagreGraph.node(el.id)
        return {
            ...el,
            targetPosition: Position.Top,
            sourcePosition: Position.Bottom,
            position: {
                x: node.x - node.width / 2 + Math.random() / 1000,
                y: node.y - node.height / 2,
            },
        }
    })
    return resultNodes.concat(
        edges.map((edge: Edge) => {
            return { ...edge }
        }),
    )
}

const nodeTypes = {
    ivrGraphNode: IvrGraphNode,
}

const edgeTypes = {
    smart: SmartEdge,
}

const nodeHasDimension = (node: Node) => node.__rf.height != null && node.__rf.width != null && node.position != null

interface GraphTypes {
    elements: Elements
    onNodeClick?: (el: IvrNodeData) => void
    readonly?: boolean
}

const InnerIvrGraph = ({ elements, onNodeClick, readonly }: GraphTypes) => {
    const nodeStates = useStoreState(store => store.nodes)
    const edgeStates = useStoreState(store => store.edges)
    const [layedoutElements, setLayedoutElements] = useState<Elements>(elements)
    const [shouldLayout, setShouldLayout] = useState<boolean>(true)
    const [reactflowInstance, setReactflowInstance] = useState<OnLoadParams>()
    const [shouldFitView, setShouldFitView] = useState<boolean>(false)

    useEffect(() => {
        if (shouldLayout && nodeStates.length > 0 && edgeStates.length > 0 && nodeStates.every(nodeHasDimension)) {
            const elementsWithLayout: Elements = layoutElements(nodeStates, edgeStates)
            setLayedoutElements(elementsWithLayout)
            setShouldLayout(false)
            setShouldFitView(true)
        }
    }, [shouldLayout, nodeStates, edgeStates])

    useEffect(() => {
        if (shouldFitView && reactflowInstance) {
            reactflowInstance.fitView()
            setShouldFitView(false)
        }
    }, [shouldFitView, reactflowInstance])

    const onLoad = useCallback(rfi => {
        setReactflowInstance(rfi)
    }, [])

    const onElementClick = useCallback(
        (event, element: FlowElement<IvrNodeData>) => {
            onNodeClick && element.data && onNodeClick(element.data)
        },
        [onNodeClick],
    )

    const [mouseDown, setMouseDown] = useState(false);

    const handleMouseDown = () => {
        setMouseDown(true);
    };

    const handleMouseUp = () => {
        setMouseDown(false);
    };

    useEffect(() => {
        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mousedown', handleMouseDown);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    return (
        <ReactFlow
            style={{ cursor: mouseDown ? "grabbing" : "grab" }}
            elements={layedoutElements}
            onLoad={onLoad}
            onConnect={() => {
            }}
            onElementsRemove={() => {
            }}
            connectionLineType={ConnectionLineType.Straight}
            nodesDraggable={false}
            nodesConnectable={false}
            elementsSelectable={!readonly}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            defaultZoom={2}
            onElementClick={onElementClick}
        >
            <Controls showInteractive={false} />
        </ReactFlow>
    )
}

const IvrGraph = ({ elements, onNodeClick, readonly }: GraphTypes) => {
    return (
        <ReactFlowProvider>
            <InnerIvrGraph elements={elements} onNodeClick={onNodeClick} readonly={readonly} />
        </ReactFlowProvider>
    )
}

export default IvrGraph
