import {Box, Flex} from 'rebass'
import {InfiniteScrollWrapper} from './InfiniteScrollWrapper'
import {Table, TableContainer} from '@mui/material'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import {Spinner} from '../Spinner'
import React, {CSSProperties, ReactElement, useMemo} from 'react'
import {styled} from '@mui/material/styles'
import {useTranslation} from 'react-i18next'

type Props<T> = {
    hasNextPage: boolean | undefined
    data?: T[]
    fetchNextPage: () => void
    isFetching?: boolean
    getRowKey: (row: T) => string
    sorter: (col: ColumnDefinition<T>) => ReactElement
    columns: ColumnDefinition<T>[]
    maxHeight?: string
    tableId: string
    size?: 'small' | 'medium'
}

export type ColumnDefinition<T> = {
    key?: keyof T
    render: (cellValue: any, row: T, index: number, totalNrOfItems: number) => ReactElement
    sorter: boolean
    title: string
    style?: CSSProperties
}

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

export function InfiniteScrollTable<T>({
                                           hasNextPage,
                                           data,
                                           fetchNextPage,
                                           isFetching,
                                           getRowKey,
                                           sorter,
                                           columns,
                                           maxHeight,
                                           tableId,
                                           size
                                       }: Props<T>) {
    const { t } = useTranslation()

    const header = useMemo(() => columns.map(sorter), [columns, sorter])

    const rows = useMemo(
        () =>
            data
                ? data.map((row, index) => (
                    <StyledTableRow key={getRowKey(row)}>
                        {columns.map(cellDef => (
                            <TableCell style={cellDef.style} key={`${getRowKey(row)}-${cellDef.key? cellDef.key.toString() : cellDef.title}`}>
                                {cellDef.render(cellDef.key? row[cellDef.key]: null, row, index, data.length)}
                            </TableCell>
                        ))}
                    </StyledTableRow>
                ))
                : undefined,
        [data, getRowKey, columns],
    )

    const loadingRow = useMemo(
        () =>
            isFetching && (
                <TableRow key={'loadingRow'}>
                    <TableCell key='loadingKey' colSpan={columns.length}>
                        <Flex alignItems={'center'} justifyContent={'center'}>
                            <Box pt={1}>
                                <Spinner size={'2rem'} />
                            </Box>
                        </Flex>
                    </TableCell>
                </TableRow>
            ),
        [isFetching, columns],
    )

    const nodataRow = useMemo(
        () =>
            (!rows || rows.length === 0) &&
            !isFetching && (
                <TableRow key={'nodataRow'}>
                    <TableCell key='noData' colSpan={columns.length}>
                        <Flex alignItems={'left'} justifyContent={'left'}>
                            <Box pt={1}>{t('general.no-data')}</Box>
                        </Flex>
                    </TableCell>
                </TableRow>
            ),
        [isFetching, columns, rows, t],
    )

    return (
        <Flex mb={1}>
            <Box width={1}>
                <InfiniteScrollWrapper isInfiniteScrollOn={true} hasMore={hasNextPage || false}
                                       lengthData={data ? data.length : 0} functionNext={fetchNextPage}
                                       scrollableTarget={tableId}>
                    <TableContainer sx={{ maxHeight: maxHeight ?? '65vh' }} id={tableId}>
                        <Table stickyHeader size={size ?? 'small'} aria-label='usersTable'>
                            <TableHead>
                                <TableRow>{header}</TableRow>
                            </TableHead>
                            <TableBody>
                                {rows}
                                {loadingRow}
                                {nodataRow}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </InfiniteScrollWrapper>
            </Box>
        </Flex>
    )
}
