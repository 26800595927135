import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import React, {useCallback, useMemo, useState} from "react";
import {Executive, ServerPage} from "../../app/types";
import {Sort, useInfiniteScroll} from "../../common/components/table/useInfiniteScroll";
import {api, buildPaginatedUrl} from "../../common/api";
import {ColumnDefinition, InfiniteScrollTable} from "../../common/components/table/Table";
import {Link} from "@mui/material";
import TableRowActionsMenu from "../../common/components/table/TableRowActionsMenu";
import {sorterHeader} from "../../common/components/table/SorterHeader";
import {SearchField} from "../../common/components/SearchField";
import ErrorAlert from "../../common/components/ErrorAlert";
import DeletionModal from "../../common/components/DeletionModal";
import {RouteDefinition} from "../../app/App";
import {TitledBody} from "../../common/components/TitledBody";
import {AddExecutive} from "./addExecutive";
import {AddButton} from "../../common/components/AddButton";
import {DetailExecutive} from "./detail";
import {useAccessHelpers} from "../../common/util/hooks";

export const detailExecutiveUrl = '/services/executive/:id'
export const addExecutiveUrl = '/services/executive/new'

export const routes: RouteDefinition[] = [
    {
        href: addExecutiveUrl,
        component: AddExecutive,
        id: 'executive-add',
        parentId: 'executive-assistant-overview',
        translationKey: 'executives.add.title',
    },
    {
        href: detailExecutiveUrl,
        component: DetailExecutive,
        id: 'executive-detail',
        parentId: 'executive-assistant-overview',
    }
].map(routeDefinition => ({ ...routeDefinition, menuId: 'executive-assistant-overview' }))

export const ExecutiveList = () => {
    const history = useHistory()
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const [executiveToDelete, setExecutiveToDelete] = useState<Executive | null>(null)
    const {hasWriteAccess, onlyWithWriteAccess} = useAccessHelpers()

    const fetch = useCallback(
        async (pageParam = 0, sortParam: Sort, searchTermParam: string | null): Promise<ServerPage<Executive>> => {
            if (!site) return { content: [], totalPages: 0, number: 0, totalElements: 0 }
            const { data } = await api.get(buildPaginatedUrl(`/api/site/${site.productId}/executives`, pageParam, sortParam, searchTermParam))
            return data
        },
        [site]
    )

    const { sort, setSort, setSearchTerm, rows, error, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteScroll<Executive>({
        queryKey: `callPickupGroups-${site?.productId}`,
        initialSort: { field: 'lastName', order: 'asc' },
        fetchFunction: fetch,
    })

    const rowActions = useMemo(
        () => [
            {
                key: 'edit-executive-assistants',
                text: t('executives.assistants'),
                onClick: (record: Executive) => {
                    history.push(detailExecutiveUrl.replace(':id', record.id || '') + '?edit=true#0')
                },
                needsWriteAccess: true
            },
            {
                key: 'edit-executive-filtering',
                text: t('executives.section-filtering'),
                onClick: (record: Executive) => {
                    history.push(detailExecutiveUrl.replace(':id', record.id || '') + '?edit=true#1')
                },
                needsWriteAccess: true
            },
            {
                key: 'edit-executive-screening-alerting',
                text: t('executives.section-screening-alerting'),
                onClick: (record: Executive) => {
                    history.push(detailExecutiveUrl.replace(':id', record.id || '') + '?edit=true#2')
                },
                dividerBeneath: true,
                needsWriteAccess: true
            },
            {
                key: 'delete',
                text: t('general.delete'),
                onClick: (record: Executive) => {
                    setExecutiveToDelete(record)
                },
                needsWriteAccess: true
            },
        ],
        [t, history]
    )

    const columns: ColumnDefinition<Executive>[] = useMemo(
        () => [
            {
                title: t('call-pickup-groups.table.name'),
                key: 'userLastName',
                sorter: true,
                render: (text: string, record: Executive) => <Link href={detailExecutiveUrl.replace(':id', record.id || '')}>{`${record.userLastName} ${record.userFirstName}`}</Link>,
            },
            {
                title: t('executives.nr-of-assistants'),
                key: 'userFirstName',
                sorter: false,
                render: (text: string, record: Executive) => <>{record.assistants.length}</>,
            },
            {
                title: '',
                key: 'id',
                sorter: false,
                render: (text: string, record: Executive) => <TableRowActionsMenu record={record} menuItems={rowActions} readOnly={!hasWriteAccess} />,
                style: { width: '25%' },
            },
        ],
        [t, rowActions, hasWriteAccess]
    )

    const onSearch = useCallback(async (searchTerm: string) => setSearchTerm(searchTerm), [setSearchTerm])

    const sorter = useCallback(col => sorterHeader(col, sort, setSort), [sort, setSort])

    const getRowKey = useCallback(callPickupGroup => callPickupGroup.uuid, [])

    const getDeleteUrl = useCallback((e: Executive | null) => (site && e ? `/api/site/${site.productId}/executive/${e.id}` : null), [site])

    return (
        <TitledBody title={t('executives.title')} buttonsPanel={
            onlyWithWriteAccess(<AddButton
                onClick={() => {
                    history.push(addExecutiveUrl)
                    return null
                }}
                label={`${t('executives.create-new')}`}
            />)
        }>
            <SearchField onSearch={onSearch} />
            <ErrorAlert showAlert={!!error} />
            <InfiniteScrollTable<Executive>
                hasNextPage={hasNextPage}
                data={rows}
                fetchNextPage={fetchNextPage}
                isFetching={isFetching}
                getRowKey={getRowKey}
                sorter={sorter}
                columns={columns}
                tableId={'executivesTable'}
            />
            <DeletionModal<Executive | null> itemToDelete={executiveToDelete} deleteUrl={getDeleteUrl} onClose={() => setExecutiveToDelete(null)} onSuccess={refetch} />
        </TitledBody>
    )
}