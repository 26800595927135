import React, {ReactElement, useCallback} from 'react'
import FormButtons from "./FormButtons";
import SpinnableSubmitButton from "./SpinnableSubmitButton";
import {useTranslation} from "react-i18next";


export const useFormSubmissionButtons = (isCreation: boolean) => {
    const { t } = useTranslation()

    const buildFormButtons = useCallback( (cancelEditButton: ReactElement) => (isSubmitting: boolean) =>
        <FormButtons buttons={[<SpinnableSubmitButton label={t(`${isCreation? 'general.form-create' : 'general.form-update'}`)} showSpinner={isSubmitting} />, cancelEditButton]} fullWidth={true}/>
        , [t, isCreation])

    return {buildFormButtons}
}
