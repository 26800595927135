import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import {Ivr} from '../app/types'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import {RouteDefinition} from '../app/App'
import {AddButton} from '../common/components/AddButton'
import {SearchField} from '../common/components/SearchField'
import {Add} from './add'
import {useHistory} from 'react-router'
import {ColumnDefinition} from '../common/components/table/Table'
import {Link} from '@mui/material'
import {formatPhoneNumber} from '../common/util/formatters'
import TableRowActionsMenu from '../common/components/table/TableRowActionsMenu'
import DeletionModal from '../common/components/DeletionModal'
import {DetailIvr} from './detail'
import {useIvrTable} from './useIvrTable'
import {useAccessHelpers} from "../common/util/hooks";

export const detailUrl = '/ivrs/:id'
export const addUrl = '/ivrs/new'
export const editMenuConfigUrl = '/ivrs/menu-config/:id'

export const routes: RouteDefinition[] = [
    {
        href: addUrl,
        component: Add,
        id: 'ivr-add',
        parentId: 'ivr-overview',
        translationKey: 'ivrs.add.title',
    },
    {
        href: detailUrl,
        component: DetailIvr,
        id: 'ivr-detail',
        parentId: 'ivr-overview',
    },
].map(routeDefinition => ({ ...routeDefinition, menuId: 'ivr-overview' }))

export const Ivrs = () => {
    const history = useHistory()
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const [ivrToDelete, setIvrToDelete] = useState<Ivr | null>(null)
    const {hasWriteAccess, onlyWithWriteAccess} = useAccessHelpers()

    const rowActions = useMemo(
        () => [
            {
                key: 'edit',
                text: t('general.edit'),
                onClick: (record: Ivr) => {
                    history.push(detailUrl.replace(':id', record.uuid) + '?edit=true#0')
                },
                needsWriteAccess: true
            },
            {
                key: 'settings',
                text: t('ivrs.table.menu-edit-config'),
                onClick: (record: Ivr) => {
                    history.push(detailUrl.replace(':id', record.uuid) + '?edit=true#1')
                },
                dividerBeneath: true,
                needsWriteAccess: true
            },
            {
                key: 'delete',
                text: t('general.delete'),
                onClick: (record: Ivr) => {
                    setIvrToDelete(record)
                },
                needsWriteAccess: true
            },
        ],
        [t, history],
    )

    const columns: ColumnDefinition<Ivr>[] = useMemo(
        () => [
            {
                title: t('ivrs.table.name'),
                key: 'name',
                sorter: true,
                render: (text: string, record: Ivr) => <Link
                    href={detailUrl.replace(':id', record.uuid)}>{record.name}</Link>,
            },
            {
                title: t('users.table.extension'),
                key: 'extension',
                sorter: true,
                render: (text: string) => <>{text}</>,
            },
            {
                title: t('ivrs.table.number'),
                key: 'number',
                sorter: true,
                defaultSortOrder: 'ascend' as 'ascend',
                render: (text: string) => <>{formatPhoneNumber(text)}</>
            },
            {
                title: '',
                key: 'uuid',
                sorter: false,
                render: (text: string, record: Ivr) => <TableRowActionsMenu record={record} menuItems={rowActions} readOnly={!hasWriteAccess}/>,
            },
        ],
        [t, rowActions, hasWriteAccess],
    )

    const getDeleteUrl = useCallback(i => (site && i ? `/api/site/${site.productId}/ivr/${i.uuid}` : null), [site])

    const addButton = useMemo(
        () => (
            onlyWithWriteAccess(<AddButton
                onClick={() => {
                    history.push(addUrl)
                    return null
                }}
                label={`${t('ivrs.create-new')}`}
            />)
        ),
        [history, t, onlyWithWriteAccess],
    )

    const { table, onSearch, refetch } = useIvrTable({
        columns: columns,
        queryKey: `answerGroups-${site?.productId}`,
        initialSortField: 'name',
    })

    return (
        <>
            <TitledBody title={t('ivrs.title')} buttonsPanel={addButton}>
                <SearchField onSearch={onSearch} />
                {table}
                <DeletionModal<Ivr | null> itemToDelete={ivrToDelete} deleteUrl={getDeleteUrl}
                                           onClose={() => setIvrToDelete(null)} onSuccess={refetch} />
            </TitledBody>
        </>
    )
}
