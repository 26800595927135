import {useCallback, useState} from 'react'
import axios from 'axios'
import {UseFormSetError} from 'react-hook-form/dist/types/form'
import {scrollIntoView} from '../util/scrollIntoView'

type actionCallback<T> = (values: T) => void

export function useFormSubmission<T>(
    onSubmit: actionCallback<T>,
    setError: UseFormSetError<any>
): { submit: actionCallback<T>; isSubmitting: boolean; serverError: any; onError: (errors: any, e: any) => void } {
    const [serverError, setServerError] = useState<any>(null)
    const [isSubmitting, setSubmitting] = useState<boolean>(false)

    const scrollToError = useCallback((fieldName: string) => {
        try {
            const escapedFieldName = fieldName.replaceAll(".", "\\.")
            let input = document.querySelector(`input[name=${escapedFieldName}]`)
            if (!input) {
                input = document.querySelector(`input[id=${escapedFieldName}]`)
            }
            if (!input) {
                input = document.querySelector(`#table-${escapedFieldName}`)
            }
            if (input) {
                const main = document.querySelector('#gbtf-main')
                scrollIntoView(main, input)
                // @ts-ignore
                /*input.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'end',
                })*/
            } else {
                console.error('Input for ' + fieldName + " not found");
            }
        } catch (e) {
            //ignore __ best effort
        }
    }, [])

    const scrollToServerError = useCallback(() => {
        const errorBox = document.querySelector(`#server-error-box`)

        if (errorBox) {
            const main = document.querySelector('#gbtf-main')
            scrollIntoView(main, errorBox)
        }
    }, [])

    const submit = useCallback(
        async (values: T) => {
            try {
                setSubmitting(true)
                setServerError(null)
                await onSubmit(values)
            } catch (err) {
                if (axios.isAxiosError(err)) {
                    const data = err?.response?.data as [any]
                    if (err.response?.status === 400 && !!data) {
                        data.forEach(serverError =>
                            setError(serverError.context, {
                                type: 'server',
                                message: serverError.key,
                            })
                        )
                        if (data[0].context) {
                            scrollToError(data[0].context)
                        } else {
                            setServerError(`general.form-error.${data[0].key}`)
                            scrollToServerError()
                        }
                    } else {
                        setServerError(err)
                        scrollToServerError()
                    }
                } else {
                    setServerError(err)
                    scrollToServerError()
                }
            }
            setSubmitting(false)
        },
        [onSubmit, setError, scrollToError, scrollToServerError]
    )

    const onError = useCallback(
        (errors: any, e: any) => {
            function checkErrors(prefixField: string, obj: any) {
                let keys = Object.keys(obj)
                if (keys.length) {
                    if (Object.hasOwn(obj[keys[0]], 'message')) {
                        scrollToError(prefixField + (prefixField.length ? ".": "") + keys[0])
                    }
                    else checkErrors(prefixField + (prefixField.length ? ".": "") + keys[0], obj[keys[0]])
                }
            }
            checkErrors('', errors)
        },
        [scrollToError]
    )

    return { submit, isSubmitting, serverError, onError }
}
