import React from 'react'
import {useTranslation} from 'react-i18next'
import {Control, useFieldArray} from 'react-hook-form'
import {Grid, IconButton, Paper} from '@mui/material'
import TextInput from '../../common/form/TextInput'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import FormCheckbox from "../../common/form/FormCheckbox";
import {UseFormGetFieldState, UseFormSetValue} from "react-hook-form/dist/types/form";
import {AddButton} from "../../common/components/AddButton";

type Props = {
    control: Control<any>
    setValue: UseFormSetValue<any>
    getFieldState?: UseFormGetFieldState<any>
}

export const CallControlNumberForm = ({ control, setValue, getFieldState }: Props) => {
    const { fields, remove, append, update } = useFieldArray({
        control,
        name: `phoneNumberAnywhereList`,
    })

    const { t } = useTranslation()

    return (
        <>  {}
            {fields.length === 0 ?
                <AddButton label={t('users.edit.office-anywhere.add-number')} onClick={() =>
                    append({
                        number: '',
                        active: false,
                        callControl: false,
                        useDiversionInhibitor: false,
                        answerConfirmationRequired: false,
                    })}/> : fields.map((item, index) => (
                <Grid container item spacing={4} key={index}>
                    <Grid item md={10} mb={2}>
                        <NumberAnywhereForm index={index} control={control} update={update} getFieldState={getFieldState} setValue={setValue} />
                    </Grid>
                    <Grid item container md={2} alignItems={'center'} justifyContent={'center'} flexWrap={'nowrap'}>
                        {index === fields.length - 1 && (
                            <IconButton
                                color={'secondary'}
                                component='div'
                                onClick={() =>
                                    append({
                                        number: '',
                                        active: false,
                                        callControl: false,
                                        useDiversionInhibitor: false,
                                        answerConfirmationRequired: false,
                                    })
                                }
                            >
                                <AddIcon />
                            </IconButton>
                        )}
                        <IconButton
                                aria-label='delete'
                                component='div'
                                onClick={() => remove(index)}
                            >
                                <DeleteIcon fontSize='inherit' />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
        </>
    )
}

const NumberAnywhereForm = ({
                       index,
                       control,
                       update,
                       setValue,
                       getFieldState
                   }: {
    index: number
    control: Control<any>
    update: (index: number, period: any) => void
    setValue: UseFormSetValue<any>
    getFieldState?: UseFormGetFieldState<any>
}) => {
    const { t } = useTranslation()

    return (
        <Paper sx={{ p: 4 }}>
            <Grid container spacing={4}>
                <AnywhereBox width={6}>
                    <TextInput required={true} label={t('users.table.number')}
                               name={`phoneNumberAnywhereList[${index}].number`} control={control} />
                </AnywhereBox>
                <AnywhereBox width={6} align={'end'} justifyContent={'start'}>
                    <FormCheckbox
                        label={t('users.edit.active')}
                        name={`phoneNumberAnywhereList[${index}].active`}
                        control={control}
                        setValue={setValue}
                        getFieldState={getFieldState}
                    />
                </AnywhereBox>
                <AnywhereBox width={12} align={'end'} justifyContent={'start'}>
                    <FormCheckbox
                        label={t('users.edit.office-anywhere.do-not-forward')}
                        name={`phoneNumberAnywhereList[${index}].useDiversionInhibitor`}
                        control={control}
                        setValue={setValue}
                        getFieldState={getFieldState}
                    />
                </AnywhereBox>
                <AnywhereBox width={12} align={'end'} justifyContent={'start'}>
                    <FormCheckbox
                        label={t('users.edit.office-anywhere.answer-confirmation')}
                        name={`phoneNumberAnywhereList[${index}].answerConfirmationRequired`}
                        control={control}
                        setValue={setValue}
                        getFieldState={getFieldState}
                    />
                </AnywhereBox>
                <AnywhereBox width={12} align={'end'} justifyContent={'start'}>
                    <FormCheckbox
                        label={t('users.edit.office-anywhere.call-control')}
                        name={`phoneNumberAnywhereList[${index}].callControl`}
                        control={control}
                        setValue={setValue}
                        getFieldState={getFieldState}
                    />
                </AnywhereBox>
            </Grid>
        </Paper>
    )
}

function AnywhereBox({
                        children,
                        align,
                        width,
                        justifyContent = 'center',
                    }: { children: any; align?: string; width?: number; justifyContent?: string }) {
    return (
        <Grid container={!!align} item xs={12} sm={6} md={width || 3} lg={width || 3} alignItems={align}
              justifyContent={justifyContent}>
            {children}
        </Grid>
    )
}
