import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../../common/components/TitledBody'
import {useFetchData} from '../../common/servercall/useFetchData'
import {useParams} from 'react-router'
import {api} from '../../common/api'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import {Page} from '../../app/navigation/redux'
import {Executive} from "../../app/types";
import {Grid} from "@mui/material";
import {SectionedForm} from "../../common/components/SectionedForm";
import {useGeneralSection} from "./sections/general";
import {useFilterSection} from "./sections/filters";
import {useScreeningAlertSection} from "./sections/screeningAlert";
import {useAccessHelpers} from "../../common/util/hooks";

export const DetailExecutive = () => {
    const { t } = useTranslation()
    const { id }: { id: string } = useParams()
    const site = useSelector(selectedSiteSelector)
    const {hasWriteAccess} = useAccessHelpers()
    const fetchExecutive = useCallback(
        async (executiveId: string): Promise<Executive | undefined> => {
            if (!site) return
            const { data } = await api.get<Executive>(`/api/site/${site.productId}/executive/${executiveId}`)
            return data
        },
        [site]
    )

    const { data, isFetching, error, refetch } = useFetchData<Executive>(useCallback(() => fetchExecutive(id), [fetchExecutive, id]))

    const pageInfo: Page | null = useMemo(
        () =>
            data
                ? {
                      label: `${data.userLastName} ${data.userFirstName}`,
                      key: 'executive-detail',
                  }
                : null,
        [data]
    )

    const generalSection = useGeneralSection(id, isFetching, error, refetch, data);
    const filteringSection = useFilterSection(id)
    const screeningAlertSection = useScreeningAlertSection(id)
    const formSections = useMemo(() => [
        generalSection,filteringSection,screeningAlertSection
    ], [generalSection, filteringSection, screeningAlertSection])

    return (
        <TitledBody title={t('executives.executive')} page={pageInfo}>
            <Grid container>
                <Grid item xs={12} lg={10}>
                    <SectionedForm formSections={formSections} readOnly={!hasWriteAccess}/>
                </Grid>
            </Grid>
        </TitledBody>
    )
}
