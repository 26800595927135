import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../../common/components/TitledBody'
import {Day, OpeningHoursForm} from '../../app/types'
import {api} from '../../common/api'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import {useHistory} from 'react-router'
import Form from './Form'
import {transformOpeningHoursFormToSchedule} from '../util/scheduleHelpers'
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";
import CancelButton from "../../common/form/CancelButton";
import {Grid} from "@mui/material";

type DayKey = keyof typeof Day
export const days = Object.keys(Day).filter(element => {
    return isNaN(Number(element))
}) as DayKey[]

const initialOpeningHours: OpeningHoursForm = {
    uuid: '',
    name: '',
    type: 'TIME',
    days: {
        [days[0]]: { entries: [{ startTime: '', endTime: '' }] },
        [days[1]]: { entries: [{ startTime: '', endTime: '' }] },
        [days[2]]: { entries: [{ startTime: '', endTime: '' }] },
        [days[3]]: { entries: [{ startTime: '', endTime: '' }] },
        [days[4]]: { entries: [{ startTime: '', endTime: '' }] },
        [days[5]]: { entries: [{ startTime: '', endTime: '' }] },
        [days[6]]: { entries: [{ startTime: '', endTime: '' }] },
    },
}

export const AddOpeningHours = () => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const history = useHistory()
    const {buildFormButtons} = useFormSubmissionButtons(true)

    const onSubmit = useCallback(
        async (formData: OpeningHoursForm) => {
            if (!site) return
            await api.post(`/api/site/${site.productId}/schedule`, transformOpeningHoursFormToSchedule(formData))
            history.push('/opening-hours')
        },
        [site, history],
    )

    return (
        <TitledBody title={t('schedules.opening-hours.add.title')}>
            <Grid container>
                <Grid item xs={12} lg={10}>
                    <Form onSubmit={onSubmit} openingHours={initialOpeningHours} buildFormButtons={buildFormButtons(<CancelButton/>)} />
                </Grid>
            </Grid>
        </TitledBody>
    )
}
