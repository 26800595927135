import { useEffect, useMemo, useState } from 'react'
import { ServerPage } from '../../../app/types'
import { InfiniteData, useInfiniteQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { selectSite } from '../../../authentication/redux'
import { FetchNextPageOptions, InfiniteQueryObserverResult, QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query/types/core/types'

export type Sort = {
    field: string
    order: 'asc' | 'desc'
}

type Props<T> = {
    queryKey: string
    initialSort: Sort
    fetchFunction: (pageParam: number, sort: Sort, searchTerm: string | null) => Promise<ServerPage<T>>
}

interface ReturnProps<T> {
    sort: Sort
    setSort: (sort: Sort) => void
    searchTerm: string | null
    setSearchTerm: (term: string | null) => void
    rows: T[]
    error: any
    fetchNextPage: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult<ServerPage<T>>>
    hasNextPage?: boolean
    isFetching?: boolean
    totalElements: number
    refetch: <TPageData>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => Promise<QueryObserverResult<InfiniteData<ServerPage<T>>>>
}

export function useInfiniteScroll<T>({ queryKey, initialSort, fetchFunction }: Props<T>): ReturnProps<T> {
    const [sort, setSort] = useState<Sort>(initialSort)
    const site = useSelector(selectSite)
    const [searchTerm, setSearchTerm] = useState<string | null>(null)

    const { data, error, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteQuery([queryKey, site, searchTerm, sort], ({ pageParam = 0 }) => fetchFunction(pageParam, sort, searchTerm), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        getNextPageParam: lastPage => {
            return lastPage.number < lastPage.totalPages - 1 ? lastPage.number + 1 : undefined
        },
    })

    useEffect(() => {
        const doRefetch = async () => {
            await refetch()
        }
        doRefetch().catch(console.error)
    }, [sort, searchTerm, refetch])

    const rows = useMemo(() => (data ? data.pages.map(it => it.content).flat() : []), [data])

    const totalElements = useMemo(() => (data && data.pages && data.pages.length ? data.pages[0].totalElements : 0), [data])

    return { sort, setSort, searchTerm, setSearchTerm, rows, error, fetchNextPage, hasNextPage, isFetching, totalElements, refetch }
}
