import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import {useFetchData} from '../common/servercall/useFetchData'
import {CPE, CpeFeature, CPESettings} from '../app/types'
import {useHistory, useParams} from 'react-router'
import {api} from '../common/api'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import {WithErrorAndFetching} from '../common/servercall/WithErrorAndFetching'
import {Page} from '../app/navigation/redux'
import {formatCPE} from "../common/util/formatters";
import {detailUrl} from "./index";
import {CPEConfigMapping, cpeFeatureTypes, getConfigForm} from "./util/CPEHelpers";

export const EditConfigCPE = () => {
    const {t} = useTranslation()
    const {id}: { id: string } = useParams()
    const site = useSelector(selectedSiteSelector)
    const history = useHistory()

    const fetchCPE = useCallback(
        async (cpeId: string): Promise<CPE | undefined> => {
            if (!site) return
            const {data} = await api.get<CPE>(`/api/site/${site.productId}/cpe/${cpeId}`)
            return data
        },
        [site],
    )

    const {data, isFetching, error} = useFetchData<CPE>(useCallback(() => fetchCPE(id), [fetchCPE, id]))

    const fetchSettings = useCallback(
        async (): Promise<CPESettings | undefined> => {
            if (!site) return
            const {data} = await api.get(`/api/site/${site.productId}/cpe/${id}/settings`)
            return {...data, features: data.features.map((config : CpeFeature) => cpeFeatureTypes().find(t => t.type === config.type)?.fieldType === 'CHECKBOX' ? {type: config.type, value: !config.value || config.value === '0' ? '': 'true'} : config)}
        },
        [site, id],
    )

    const {
        data: settings,
        isFetching: isFetchingSettings,
        error: settingsError
    } = useFetchData<CPESettings>(useCallback(() => fetchSettings(), [fetchSettings]))

    const pageInfo: Page | null = useMemo(
        () =>
            data
                ? {
                    label: `${t('cpes.detail.configuration')} ${formatCPE(data)}`,
                    key: 'cpe-config',
                }
                : null,
        [data, t],
    )

    const onSubmit = useCallback(
        async (data: CPESettings) => {
            if (!site) return
            await api.put(`/api/site/${site.productId}/cpe/${id}/settings`, data)
            history.push(detailUrl.replace(":id", id))
        },
        [site, history, id],
    )

    const renderForm = useCallback((settings: CPESettings) => getConfigForm(CPEConfigMapping(settings), data?.model, onSubmit), [data, onSubmit])

    return (
        <TitledBody title={t('cpes.detail.configuration')} page={pageInfo}>
            <WithErrorAndFetching<CPESettings> isFetching={isFetching || isFetchingSettings}
                                               error={error || settingsError} data={settings} render={renderForm}/>
        </TitledBody>
    )
}
