import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import FormGroup from '../common/form/FormGroup'
import FormRow from '../common/form/FormRow'
import FormFieldBox from '../common/form/FormFieldBox'
import TextInput from '../common/form/TextInput'
import {Control} from 'react-hook-form'
import FormCheckbox from "../common/form/FormCheckbox";
import {UseFormGetFieldState, UseFormSetValue} from "react-hook-form/dist/types/form";

type Props = {
    control: Control<any>
    setValue: UseFormSetValue<any>
    getFieldState?: UseFormGetFieldState<any>
}

export const CallRecordingForm = ({ control, setValue, getFieldState }: Props) => {
    const { t } = useTranslation()

    const modeOptions = useMemo(
        () =>
            ['ALWAYS', 'ALWAYS_WITH_PAUSE_RESUME', 'ON_DEMAND', 'NEVER'].map(mode => ({
                value: `${mode}`,
                label: `${t(`call-recording.modes.${mode}`)}`,
            })),
        [t]
    )

    return (
        <>
            <FormGroup label={t('call-recording.section-header')} fullWidth={true}>
                <FormRow>
                    <FormFieldBox>
                        <TextInput
                            readonly={false}
                            label={t('call-recording.mode')}
                            name={'callRecordingUserSettings.callRecordingMode'}
                            control={control}
                            required={true}
                            isRadio={true}
                            options={modeOptions}
                        />
                    </FormFieldBox>

                </FormRow>
                <FormRow>
                    <FormFieldBox>
                        <FormCheckbox
                            label={t('call-recording.play-announcement')}
                            name={'callRecordingUserSettings.playAnnouncement'}
                            control={control}
                            setValue={setValue}
                            getFieldState={getFieldState}
                        />
                    </FormFieldBox>
                    <FormFieldBox>
                        <FormCheckbox
                            label={t('call-recording.record-voice-messaging')}
                            name={'callRecordingUserSettings.recordVoiceMessaging'}
                            control={control}
                            setValue={setValue}
                            getFieldState={getFieldState}
                        />
                    </FormFieldBox>
                </FormRow>
            </FormGroup>
        </>
    )
}
