import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { PhoneBookEntry } from '../app/types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import TextInput from '../common/form/TextInput'
import FormRow from '../common/form/FormRow'
import FormFieldBox from '../common/form/FormFieldBox'
import SpinnableSubmitButton from '../common/form/SpinnableSubmitButton'
import { useFormSubmission } from '../common/servercall/useFormSubmission'
import ErrorAlert from '../common/components/ErrorAlert'
import { UseFormSetValue } from 'react-hook-form/dist/types/form'

const schema = yup
    .object()
    .shape({
        name: yup
            .string()
            .trim()
            .max(50, 'too-long')
            .required('required-m')
            .matches(/^[^\\/]*$/, 'invalid-entry-name'),
        number: yup
            .string()
            .trim()
            .max(22, 'too-long')
            .required('required-m')
            .matches(/^\+[1-9]\d{2,14}$/, 'invalid-entry-number'),
    })
    .required()

type Props = {
    entry: PhoneBookEntry | null
    onSubmit: (formData: PhoneBookEntry) => void
}

export type ReturnProps = {
    handleSubmit: () => void
    form: () => ReactElement
    submitButton: () => ReactElement
    reset: () => void
    setValue: UseFormSetValue<PhoneBookEntry>
}

const usePhoneBookForm = ({ entry, onSubmit }: Props): ReturnProps => {
    const { t } = useTranslation()

    const { control, handleSubmit, setError, reset, setValue } = useForm<PhoneBookEntry>({
        resolver: yupResolver(schema),
        defaultValues: entry
            ? {
                  ...entry,
              }
            : {},
    })

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<PhoneBookEntry>(onSubmit, setError)

    return {
        setValue: setValue,
        reset: reset,
        handleSubmit: handleSubmit(submit, onError),
        form: () =>
            entry ? (
                <>
                    <ErrorAlert errorKey={typeof serverError === 'string' ? serverError : undefined} showAlert={!!serverError} />
                    <FormRow>
                        <FormFieldBox fullWidth={true}>
                            <TextInput autofocus={true} label={t('phonebook.table.name')} name={'name'} control={control} required={true} />
                        </FormFieldBox>
                        <FormFieldBox fullWidth={true}>
                            <TextInput label={t('phonebook.table.number')} name={'number'} control={control} required={true} />
                        </FormFieldBox>
                    </FormRow>
                </>
            ) : (
                <div />
            ),
        submitButton: () => (entry ? <SpinnableSubmitButton label={t(entry.uuid ? 'general.form-update' : 'general.form-create')} showSpinner={isSubmitting} /> : <></>),
    }
}

export default usePhoneBookForm
