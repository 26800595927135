import * as yup from 'yup'
import {
    CpeAtaConfigForm,
    CpeConfigResult,
    CpeFeature,
    CpeFeatureMetaData,
    CpeKey,
    CPEKeyTypeMetaData,
    CpeMPPConfigForm,
    CPESettings,
    CpeYealinkKeyConfigForm,
    HotDeskBasicSettings,
    PhoneUser
} from "../../app/types";
import {emailRegex} from "../../users/UserForm";
import {TFunction} from "i18next";
import {LabelValueType} from "../../common/components/LabelValues";
import {formatPhoneNumber, formatUser} from "../../common/util/formatters";
import React, {ReactElement} from "react";
import {List, ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {find, head} from "ramda";
import CPE2NConfigFormScreen from "../config/Cpe2NConfigForm";
import CpeYealinkConfigForm from "../config/CpeYealinkConfigForm";
import CPEAtaConfigFormScreen from "../config/CpeAtaConfigForm";
import CPEMPPConfigFormScreen from "../config/CpeMPPConfigForm";

export const numberValidationRule = () =>
        yup.string().trim().matches(/^$|^\+32[0-9]{8,9}$|^0[0-9]{8,9}$|^[0-9]{4}$/, 'invalid-phone-number').nullable(true);

export const ataNumberTranslationFromValidationRule = () =>
        yup.string().trim().max(15, 'invalid-phone-number').matches(/^[0-9]*$/, {message: 'invalid-phone-number', excludeEmptyString: true}).nullable(true);

export const ataNumberTranslationToValidationRule = () =>
    yup.string().trim().notRequired().min(4, 'invalid-phone-number').max(15, 'invalid-phone-number').matches(/^[0-9]*$/, {message: 'invalid-phone-number', excludeEmptyString: true}).nullable(true).transform((value) => !!value ? value : null);

export type CpeConfigType = {
    models : string[]
    details : (user: PhoneUser | undefined, t: TFunction) => LabelValueType[]
    form: (onSubmit: (data: CPESettings | CpeConfigResult) => void, buildFormButtons ?: (isSubmitting: boolean) => ReactElement, user?: PhoneUser ) => ReactElement
}

const formatKeys = (t: TFunction, cpeSettings: CPESettings, fromInclusive: number, toInclusive: number, mpp: boolean, user?: PhoneUser) => {
    const filteredKeys = cpeSettings.keys
        .filter(it => !!it.type && Number(it.key) >= fromInclusive && Number(it.key) <= toInclusive)
    return filteredKeys.length === 0 ?
        <>{t('general.none')}</> :
        <List dense={true}>
            {filteredKeys.map(it => (
                <ListItem key={it.type}>
                    <ListItemText primary={
                        formatKey(t, mpp?
                            {   ...it,
                                key: Number(it.key) < 11 ? Number(it.key).toString() : (((Number(it.key) -11) % 28) + 1).toString(),
                                value: it.type === 'MPP_BLF_USER' ? formatUser(user?.busyLampUsers.find(blu => blu.userBusinessId === it.value)) : it.value
                            } : it)
                    }></ListItemText>
                </ListItem>
            ))
            }
        </List>}


export const isMppAutoFillBlfUsers = (cpeSettings: CPESettings | null) : boolean => {
    const value = cpeSettings?.features?.find((config : CpeFeature) => 'MPP_AUTOFILL_BLF' === config.type)?.value
    return !value || value === 'true'
}

export const CPEConfigMapping = (cpeSettings: CPESettings | null) : CpeConfigType[] => [
    {
        models: ['Voicecloud-2N'],
        details: (user, t) => cpeFeatureTypes().map(field => {
            const value = cpeSettings?.features.find((config : CpeFeature) => field.type === config.type)?.value
            return field.fieldType === 'CHECKBOX' ?
                {label: t(`cpes.config.${field.type}`), value: <>{value === '1' ? t('general.yes') : t('general.no')}</>} : field.fieldType === 'PHONENUMBER' ?
                    {label: t(`cpes.config.${field.type}`), value: <>{value?.length === 4? value : formatPhoneNumber(value)}</>} : {
                        label: t(`cpes.config.${field.type}`),
                        value: <>{value}</>
                    }
        }),
        form: (onSubmit, buildFormButtons) => !!cpeSettings ? <CPE2NConfigFormScreen buildFormButtons={buildFormButtons} cpeSettings={cpeSettings} onSubmit={onSubmit}/> : <></>
    },
    {
        models: ['Voicecloud-Cisco-MPP'],
        details: (user: PhoneUser | undefined, t) => [
            {
                label: t(`cpes.config.MPP_AUTOFILL_BLF`),
                value: <>{isMppAutoFillBlfUsers(cpeSettings) ? t('general.yes') : t('general.no')}</>
            },
            ...(cpeSettings?.keys? [
                {
                    label: `${t('cpes.detail.keys')} (${t('cpes.detail.main-phone')})`,
                    value: formatKeys(t, cpeSettings, 2, 10, true, user),
                    type: 'FULL_LINE' as 'NORMAL' | 'FULL_LINE' | undefined ,
                }
            ] : []),
            ...(cpeSettings?.keys? [
                {
                    label: `${t('cpes.detail.keys')} (${t('cpes.detail.kem', { index: '1' })})`,
                    value: formatKeys(t, cpeSettings, 11, 38, true, user),
                    type: 'FULL_LINE' as 'NORMAL' | 'FULL_LINE' | undefined ,
                }
            ] : []),
            ...(cpeSettings?.keys? [
                {
                    label: `${t('cpes.detail.keys')} (${t('cpes.detail.kem', { index: '2' })})`,
                    value: formatKeys(t, cpeSettings, 39, 66, true, user),
                    type: 'FULL_LINE' as 'NORMAL' | 'FULL_LINE' | undefined ,
                }
            ] : []),
            ...(cpeSettings?.keys? [
                {
                    label: `${t('cpes.detail.keys')} (${t('cpes.detail.kem', { index: '3' })})`,
                    value: formatKeys(t, cpeSettings, 67, 94, true, user),
                    type: 'FULL_LINE' as 'NORMAL' | 'FULL_LINE' | undefined ,
                }
            ] : [])
        ],
        form: (onSubmit, buildFormButtons, user) => !!cpeSettings ? <CPEMPPConfigFormScreen user={user} buildFormButtons={buildFormButtons} cpeSettings={cpeSettings} onSubmit={onSubmit}/> : <></>
    },
    {
        models: ['VoiceCloud-Yealink-T41S', 'VoiceCloud-Yealink-T42G', 'VoiceCloud-Yealink-T42U'],
        details: (user, t) => [
            ...(cpeSettings?.keys? [
                {
                    label: `${t('cpes.detail.keys')}`,
                    value: formatKeys(t, cpeSettings, 2, 14, false),
                    type: 'FULL_LINE' as 'NORMAL' | 'FULL_LINE' | undefined ,
                }
            ] : [])
        ],
        form: (onSubmit, buildFormButtons) => !!cpeSettings ? <CpeYealinkConfigForm buildFormButtons={buildFormButtons} cpeSettings={cpeSettings} onSubmit={onSubmit} nrOfButtons={14}/> : <></>
    },
    {
        models: ['VoiceCloud-Yealink-T46U', 'VoiceCloud-Yealink-T46S', 'VoiceCloud-Yealink-T46G'],
        details: (user, t) => [
            ...(cpeSettings?.keys? [
                {
                    label: `${t('cpes.detail.keys')}`,
                    value: formatKeys(t, cpeSettings, 2, 27, false),
                    type: 'FULL_LINE' as 'NORMAL' | 'FULL_LINE' | undefined ,
                }
            ] : [])
        ],
        form: (onSubmit, buildFormButtons) => !!cpeSettings ? <CpeYealinkConfigForm buildFormButtons={buildFormButtons} cpeSettings={cpeSettings} onSubmit={onSubmit} nrOfButtons={27}/>: <></>
    },
    {
        models: ['VoiceCloud-Yealink-T48U', 'VoiceCloud-Yealink-T48S', 'VoiceCloud-Yealink-T48G'],
        details: (user, t) => [
            ...(cpeSettings?.keys? [
                {
                    label: `${t('cpes.detail.keys')}`,
                    value: formatKeys(t, cpeSettings, 2, 29, false),
                    type: 'FULL_LINE' as 'NORMAL' | 'FULL_LINE' | undefined ,
                }
            ] : [])
        ],
        form: (onSubmit, buildFormButtons) => !!cpeSettings ? <CpeYealinkConfigForm buildFormButtons={buildFormButtons} cpeSettings={cpeSettings} onSubmit={onSubmit} nrOfButtons={29}/> : <></>
    },
    {
        models: ['Voicecloud-Cisco-ATA-PORT'],
        details: (user, t) => [
                {
                    label: `${t('cpes.detail.auto-number-conversion')}`,
                    value: <>{
                        cpeSettings?.features && cpeSettings.features.length > 0 ?
                        cpeSettings.features.find((config : CpeFeature) => "ATA_NUMBER_TRANSLATIONS" === config.type)?.value?
                            formatAtaNumberTranslations(t, cpeSettings.features.find((config : CpeFeature) => "ATA_NUMBER_TRANSLATIONS" === config.type)?.value ) :
                                cpeSettings.features.find((config : CpeFeature) => "ATA_HOTLINE_NUMBER" === config.type)?.value?
                                    `${t('cpes.detail.hotline')} ${formatAtaHotline(t, cpeSettings.features.find((config : CpeFeature) => "ATA_HOTLINE_NUMBER" === config.type)?.value)}` : t('general.none') : t('general.none')
                    }</>,
                }
            ],
        form: (onSubmit, buildFormButtons) => !!cpeSettings ? <CPEAtaConfigFormScreen buildFormButtons={buildFormButtons} cpeSettings={cpeSettings} onSubmit={onSubmit}/> : <></>
    }
]

export const getConfigLabelValuesForModel = (configTypes : CpeConfigType[], model: string | undefined, t: TFunction, user?: PhoneUser) : LabelValueType[] | undefined =>
    head(configTypes.filter(cpeConfig=> model && cpeConfig.models.includes(model)))?.details(user, t)

export const getConfigForm = (configTypes : CpeConfigType[], model: string | undefined | null, onSubmit: (data: any) => void, buildFormButtons ?: (isSubmitting: boolean) => ReactElement, user ?: PhoneUser) : ReactElement | undefined =>
    head(configTypes.filter(cpeConfig=> !!model && cpeConfig.models.includes(model)))?.form(onSubmit, buildFormButtons, user)

export const cpeFeatureTypes = (): CpeFeatureMetaData[] => [
    {
        type: "ACCESS_CODE",
        validationRule: () => yup.string().trim().matches(/^$|^[0-9]{4}$/, 'invalid-cpe-access-code').nullable(),
        required: false,
        fieldType: "TEXT"
    },
    {
        type: "TO_MAIL_ADDRESS",
        validationRule: () => yup.string().trim().max(70, 'too-long').matches(
            emailRegex,
            'invalid-email'
        ).nullable(true),
        required: false,
        fieldType: "TEXT"
    },
    {
        type: "RINGTIME",
        validationRule: () => yup.string().trim().matches(/^[1-5][0-9]$|^60$/, 'invalid-cpe-ringtime').nullable(true),
        required: true,
        fieldType: "TEXT"
    },
    {
        type: "NUMBER1",
        validationRule: numberValidationRule,
        required: false,
        fieldType: "PHONENUMBER"
    },
    {
        type: "NUMBER2",
        validationRule: numberValidationRule,
        required: false,
        fieldType: "PHONENUMBER"
    },
    {
        type: "NUMBER3",
        validationRule: numberValidationRule,
        required: false,
        fieldType: "PHONENUMBER"
    },
    {
        type: "GROUPED1",
        validationRule: () => yup.string().trim().nullable(true),
        required: false,
        fieldType: "CHECKBOX"
    },
    {
        type: "GROUPED2",
        validationRule: () => yup.string().trim().nullable(true),
        required: false,
        fieldType: "CHECKBOX"
    },
    {
        type: "DIVINHIBIT",
        validationRule: () => yup.string().trim().nullable(true),
        required: false,
        fieldType: "CHECKBOX"
    },
]

const formatAtaNumberTranslations = (t: TFunction, value: string | null | undefined) =>  value ? value.split(",").map(
    number => number.replace(">", " > ")
).join(", ") : t('general.none')

const formatAtaHotline = (t: TFunction, value: string | null | undefined) =>  value ? value : t('general.none')

export const toAtaConfigForm = (cpeSettings: CPESettings) : CpeAtaConfigForm => {
    if (!cpeSettings.features || cpeSettings.features.length === 0) {
        return {
            type: 'TRANSLATION',
            hotlineNumber: '',
            numberTranslations: []
        }
    } else {
        return {
            type: !!cpeSettings.features.find(setting => setting.type === 'ATA_HOTLINE_NUMBER')?.value? 'HOTLINE' : 'TRANSLATION',
            hotlineNumber: cpeSettings.features.find(setting => setting.type === 'ATA_HOTLINE_NUMBER')?.value || '',
            numberTranslations: cpeSettings.features.find(setting => setting.type === 'ATA_NUMBER_TRANSLATIONS')?.value?.split(",").map(translation => ({
                    from: translation.split(">")[0],
                    to: translation.split(">")[1]
                })) || []
        }
    }
}

const mppNumberValidationRule = () => yup.string().trim().max(22, 'too-long').required('required-m').matches(/^\+?[0-9]*$/, {
    message: 'invalid-phone-number',
    excludeEmptyString: false,
}).nullable(true);

export const cpeYealinkKeyTypes = (t: TFunction): CPEKeyTypeMetaData[] => [
    {
        value: null,
        needsArg: false,
        validationRule: () => yup.string().trim().nullable(true)
    },
    {
        value: "YEALINK_SPEED_DIAL",
        needsArg: true,
        validationRule: () => yup.string().trim().max(22, 'too-long').required('required-m').matches(/^[#*]?\+?[0-9]*$/, {
            message: 'invalid-phone-number',
            excludeEmptyString: false,
        }).nullable(true)
    }
].map(it => (
    {
        ...it,
        label: !it.value ? t('general.none') : t(`cpes.key-types.${it.value}`),
    }))

export const cpeMppKeyTypes = (t: TFunction): CPEKeyTypeMetaData[] => [
    {
        value: null,
        needsArg: false,
        validationRule: () => yup.string().trim().nullable(true)
    },
    {
        value: "MPP_SPEED_DIAL",
        needsArg: true,
        validationRule: () => yup.string().trim().max(22, 'too-long').required('required-m').matches(/^[#*]?\+?[0-9]*$/, {
            message: 'invalid-phone-number',
            excludeEmptyString: false,
        }).nullable(true)
    },
    {
        value: "MPP_HUNT_GROUP_BUSY_ACTIVATION",
        needsArg: true,
        validationRule: mppNumberValidationRule
    },
    {
        value: "MPP_HUNT_GROUP_BUSY_DEACTIVATION",
        needsArg: true,
        validationRule: mppNumberValidationRule
    },
    {
        value: "MPP_HUNT_GROUP_BUSY_INTERROGATION",
        needsArg: true,
        validationRule: mppNumberValidationRule
    },
    {
        value: "MPP_CONSULT_GROUP_VOICEMAIL",
        needsArg: true,
        validationRule: mppNumberValidationRule
    },
    {
        value: "MPP_GROUP_PICKUP",
        needsArg: false,
        validationRule: () => yup.string().trim().nullable(true)
    },
    {
        value: "MPP_DEACTIVATE",
        needsArg: false,
        validationRule: () => yup.string().trim().nullable(true)
    },
    {
        value: "MPP_EXEC_ASSISTANT",
        needsArg: false,
        validationRule: () => yup.string().trim().nullable(true),
        onlyReadOnly: true
    },
    {
        value: "MPP_BLF_USER",
        needsArg: true,
        validationRule: () => yup.string().trim().required('required-m').nullable(),
        needsNoLabel: true
    }
].map(it => (
    {
        ...it,
        label: !it.value ? t('general.none') : t(`cpes.key-types.${it.value}`),
    }))

export const isConfigurableModel = (model: string | undefined | null) => !!find(config => !!model && config.models.includes(model) , CPEConfigMapping(null))

export const formatKey = (t: TFunction, key: CpeKey): string => `#${key.key} - ${t(`cpes.key-types.${key.type}`)} ${key.value || ''}${key.label? ` - '${key.label}'` : ''}`

export const toMppKeyConfigForm = (cpeSettings: CPESettings, hotDeskSettings?: HotDeskBasicSettings) : CpeMPPConfigForm => {
    if (!cpeSettings.keys || cpeSettings.keys.length === 0) {
        return {
            autoFillBlf: isMppAutoFillBlfUsers(cpeSettings),
            mainPhone: {keys: Array.from({length: 10}, (x, i) => i + 1).map(i => i).map(index => ({type: null, key: (index).toString(), value: "", label: ""}))},
            kem1: {keys: Array.from({length: 28}, (x, i) => i).map(index => ({type: null, key: (index + 11).toString(), value: "", label: ""}))},
            kem2: {keys: Array.from({length: 28}, (x, i) => i).map(index => ({type: null, key: (index + 39).toString(), value: "", label: ""}))},
            kem3: {keys: Array.from({length: 28}, (x, i) => i).map(index => ({type: null, key: (index + 67).toString(), value: "", label: ""}))},
            hotDeskSettings: !hotDeskSettings ? {hotDeskingEnabled: false, hotDeskAssociationLimitEnabled: false, hotDeskAssociationLimitHours: 12} : hotDeskSettings
        }
    } else {
        return {
            autoFillBlf: isMppAutoFillBlfUsers(cpeSettings),
            mainPhone: {keys: [{type: null, key: '1', value: "", label: ""},...getCpeKeys(cpeSettings.keys, 2, 10)]},
            kem1: { keys : getCpeKeys(cpeSettings.keys, 11, 38)},
            kem2: { keys : getCpeKeys(cpeSettings.keys, 39, 66)},
            kem3: { keys : getCpeKeys(cpeSettings.keys, 67, 94)},
            hotDeskSettings: !hotDeskSettings ? {hotDeskingEnabled: false, hotDeskAssociationLimitEnabled: false, hotDeskAssociationLimitHours: 12} : hotDeskSettings
        }
    }
}

export const removeBlfFields = (cpeConfigResult: CpeConfigResult) : CpeConfigResult => (
        {
            ...cpeConfigResult,
            cpeSettings: {
                ...cpeConfigResult.cpeSettings,
                keys: cpeConfigResult.cpeSettings.keys.map(key => key.type === 'MPP_BLF_USER' ? {
                    type: null,
                    key: key.key,
                    value: "",
                    label: "",
                    editable: true
                } : key)
            }
        })

export const toYealinkKeyConfigForm = (cpeSettings: CPESettings, nrOfKeys: number) : CpeYealinkKeyConfigForm => {
    if (!cpeSettings.keys || cpeSettings.keys.length === 0) {
        return {
            mainPhone: {keys: Array.from({length: nrOfKeys}, (x, i) => i + 1).map(i => i).map(index => ({type: null, key: (index).toString(), value: "", label: ""}))}
        }
    } else {
        return {
            mainPhone: {keys: [{type: null, key: '1', value: "", label: ""},...getCpeKeys(cpeSettings.keys, 2, nrOfKeys)]},
        }
    }
}

const getCpeKeys = (cpeKeys: CpeKey[], fromInclusive: number, toInclusive: number) =>
    cpeKeys.filter(it => Number(it.key) >= fromInclusive && Number(it.key) <= toInclusive)