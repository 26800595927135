import React, {ReactElement} from 'react'

import {Box, Card, CardContent, CardHeader, FormLabel, Grid, Typography} from '@mui/material'

export type LabelValueType = {
    label: string
    value: ReactElement
    type?: 'NORMAL' | 'FULL_LINE'
    compNextToLabel?: ReactElement
    subSectionId?: string
}

export type subSection = {
    id: string
    label: string | null
}

type Props = {
    labelValues: LabelValueType[]
    subSections?: subSection[]
}

export const LabelValues = ({ labelValues, subSections }: Props) => {
    const calcSubSections = [{id: 'dummy', label: null}, ...(subSections || [])]
    const calcLabelValues = labelValues.map(it => ({...it, subSectionId: it.subSectionId || 'dummy'}))

    return (
        <>
            {calcSubSections.map((section, index) =>
                {
                    return section.label ?
                            <Grid container mt={2} key={index}>
                                <Grid item xs={12}>
                                    <Card variant="outlined">
                                        <CardHeader subheader={section.label}/>
                                        <CardContent>
                                            <Grid container rowSpacing={3}>
                                                <LabelValuesSection labelValues={calcLabelValues.filter(labelValues => labelValues.subSectionId === section.id)} />
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        : <LabelValuesSection key={index} labelValues={calcLabelValues.filter(labelValues => labelValues.subSectionId === section.id)} />
                }
            )}
        </>
    )
}


const LabelValuesSection = ({labelValues} : {labelValues : LabelValueType[]}) : ReactElement => {
    return <>
        {labelValues.map(labelValue =>
            ( <Grid item xs={12} md={labelValue.type === 'FULL_LINE' ? 12 : 6} lg={labelValue.type === 'FULL_LINE' ? 12 : 4} key={labelValue.label}>
                    {labelValue.compNextToLabel ? (
                        <Grid container flexWrap={'nowrap'} spacing={3} justifyContent={'space-between'}>
                            <Grid item>
                                <FormLabel>{labelValue.label}</FormLabel>
                            </Grid>
                            <Grid item>{labelValue.compNextToLabel}</Grid>
                        </Grid>
                    ) : (
                        <Box>
                            <FormLabel>{labelValue.label}</FormLabel>
                        </Box>
                    )}
                    <Box style={{ wordBreak: 'break-all', paddingRight: 3 }}>
                        <Typography variant={'body1'} component={'div'}>
                            {labelValue.value}
                        </Typography>
                    </Box>
                </Grid> ))
        }
    </>
}
