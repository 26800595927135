import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import React, {ReactNode, useCallback, useMemo} from 'react'
import {CPEInfo} from '../app/types'
import {api} from '../common/api'
import {useFetchData} from '../common/servercall/useFetchData'
import {Avatar, Card, CardContent, CardHeader, CardMedia, Link} from '@mui/material'
import {WithErrorAndFetching} from '../common/servercall/WithErrorAndFetching'
import {ColumnDefinition, InfiniteScrollTable} from '../common/components/table/Table'
import {sorterHeader} from '../common/components/table/SorterHeader'
import {useTranslation} from 'react-i18next'
import {formatCPEModel} from '../common/util/formatters'
import {sum} from 'ramda'
import {Fax} from "@mui/icons-material";

export const CpeStats = () => {
    const site = useSelector(selectedSiteSelector)
    const { t } = useTranslation()

    const fetchCpeStats = useCallback(async (): Promise<CPEInfo[]> => {
        if (!site) return []
        const { data } = await api.get(`/api/site/${site.productId}/stats/cpes`)
        return data
    }, [site])

    const { data, isFetching, error } = useFetchData<CPEInfo[]>(useCallback(() => fetchCpeStats(), [fetchCpeStats]))

    const columns: ColumnDefinition<CPEInfo>[] = useMemo(
        () => [
            {
                title: t('site.cards.cpe.table.model'),
                key: 'model',
                sorter: false,
                render: (text: string, record) => <>{t(`${formatCPEModel(text)}`)}</>,
            },
            {
                title: t('site.cards.cpe.table.number'),
                key: 'total',
                sorter: false,
                render: (text: number, record) => <>{`${record.taken} / ${record.total}`}</>,
            },
        ],
        [t]
    )

    const sorter = useCallback(col => sorterHeader(col, null, () => {}), [])

    const renderCpeInfo = useCallback(
        (cpes: CPEInfo[]): ReactNode => (
            <Card sx={{ height: '100%' }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: theme => theme.palette.primary.main, color: theme => theme.palette.secondary.main }} variant="rounded">
                            <Fax />
                        </Avatar>
                    }
                    title={<Link href={'/cpe'}>{t('navigation.cpe')}</Link>}
                    subheader={<>{`# ${sum(cpes.map(cpe => cpe.taken))} / ${sum(cpes.map(cpe => cpe.total))}`}</>}
                    action={<></>}
                />
                <CardMedia></CardMedia>
                <CardContent>
                    <InfiniteScrollTable<CPEInfo>
                        data={cpes}
                        hasNextPage={false}
                        fetchNextPage={() => {}}
                        getRowKey={row => row.model}
                        sorter={sorter}
                        columns={columns}
                        tableId={'licenceInfo'}
                        maxHeight={'18vh'}
                    />
                </CardContent>
            </Card>
        ),
        [columns, sorter, t]
    )

    return <WithErrorAndFetching<CPEInfo[]> isFetching={isFetching} error={error} data={data} render={renderCpeInfo} />
}
