import React, {ReactElement, ReactNode, useCallback, useEffect, useMemo, useState} from 'react'
import {FormSection} from "../../common/components/SectionedForm";
import {api} from "../../common/api";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import {useTranslation} from "react-i18next";
import PaperStack from "../../common/form/PaperStack";
import FormGroup from "../../common/form/FormGroup";
import {LabelValues, LabelValueType} from "../../common/components/LabelValues";
import {IvrForm, IvrMenuConfig, IvrOperator} from "../../app/types";
import {Box, Fab} from "@mui/material";
import IvrGraph from "../graph/IvrGraph";
import DeletionModal from "../../common/components/DeletionModal";
import {Flex} from "rebass";
import DeleteIcon from "@mui/icons-material/Delete";
import {Elements} from "react-flow-renderer";
import {Settings} from "@mui/icons-material";
import {TFunction} from "i18next";
import {isEmpty} from "ramda";
import IvrMenuConfigFormScreen from "../menuconfig/IvrMenuConfigForm";
import {useAccessHelpers} from "../../common/util/hooks";

const initialMenuConfig = (t: TFunction): IvrMenuConfig => {
    return {
        subMenus: {
            Welcome: {
                id: 'Welcome',
                businessHoursRoot: true,
                keyActions: {
                    '1': {
                        menuKey: '1',
                        gbtfIvrSubMenuAction: '',
                        description: null,
                        config: '',
                    },
                    '2': {
                        menuKey: '2',
                        gbtfIvrSubMenuAction: '',
                        description: null,
                        config: '',
                    },
                },
                announcementFileName: null,
            },
        },
    }
}

export const useMenuSection = (ivrId: string, isFetching: boolean, error: any, refetch: () => void, graphElements: Elements, data?: IvrForm) : FormSection<IvrForm> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const [ivrConfigToDelete, setIvrConfigToDelete] = useState<IvrMenuConfig | null>(null)
    const [operator, setOperator] = useState<IvrOperator>()
    const {onlyWithWriteAccess} = useAccessHelpers()

    useEffect(() => {if (data) {setOperator(data.operator)}} , [data, setOperator])

    const operatorLabelValues = useCallback(
        (value: IvrForm): LabelValueType[] => [{ label: t('ivrs.edit.operator'), value: <>{value.operator.user ?
                `${value.operator.user.lastName} ${value.operator.user.firstName}`  :
                value.operator.answerGroup ? value.operator.answerGroup.name :  t('general.none')}</> }],
        [t]
    )

    const deleteMenuConfig = useCallback(async () => {
        if (!site) return

        await api.put(`/api/site/${site.productId}/ivr/${ivrId}`, {
            ...data,
            operatorUserId: null,
            operatorAnswerGroupId: null,
            operatorType: 'NONE',
            configJson: null,
        })
    }, [data, ivrId, site])

    const onOperatorUpdate = useCallback(async (operator: IvrOperator) => {
        setOperator(operator)
    }, [])

    const onSubmit = useCallback((onSucceeded: () => void) =>
        async (formData: IvrMenuConfig) => {
            if (!site) return
            await api.put(`/api/site/${site.productId}/ivr/${ivrId}`, {
                ...data,
                alternateNumbers: data ? data.alternateNumbers.map(nr => nr.number) : [],
                operatorUserId: operator?.user?.uuid,
                operatorAnswerGroupId: operator?.answerGroup?.uuid,
                operatorType: operator?.type,
                configJson: formData,
            })
            await refetch()
            onSucceeded();
        },
        [site, ivrId, data, operator, refetch]
    )

    const menuButtonsPanel = useMemo(
        () => (
            onlyWithWriteAccess(<Flex justifyContent={'flex-end'}>
                <Box mr={1}>
                    <Fab title={''} onClick={() => setIvrConfigToDelete(data?.menuConfig ?? null)} color="primary" size={'small'}>
                        <DeleteIcon />
                    </Fab>
                </Box>
            </Flex>)
        ),
        [data?.menuConfig, onlyWithWriteAccess]
    )

    const renderDetails = useCallback(
        (ivr: IvrForm): ReactNode => {
            return (
                <PaperStack>
                    {graphElements.length ? (
                        <FormGroup fullWidth={true} key={2} label={' '} buttonsPanel={menuButtonsPanel}>
                            <LabelValues labelValues={operatorLabelValues(ivr)} />
                            <Box mt={1} style={{ height: 350, width: '100%' }}>
                                <IvrGraph elements={graphElements} readonly={true} />
                            </Box>
                        </FormGroup>
                    ) : (
                        <></>
                    )}
                    <DeletionModal<IvrMenuConfig | null>
                        itemToDelete={ivrConfigToDelete}
                        deleteAction={async ivrMenuConfig => await deleteMenuConfig()}
                        onClose={() => setIvrConfigToDelete(null)}
                        onSuccess={refetch}
                    />
                </PaperStack>
            )
        },
        [operatorLabelValues, graphElements, menuButtonsPanel, deleteMenuConfig, ivrConfigToDelete, refetch]
    )

    const renderMenuForm = useCallback(
        (ivr: IvrForm, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode =>
            <IvrMenuConfigFormScreen
                operator={operator || {type : "NONE", user: null, answerGroup : null}}
                onOperatorUpdate={onOperatorUpdate}
                initialMenuConfig={isEmpty(ivr?.menuConfig?.subMenus) ? initialMenuConfig(t) : ivr.menuConfig}
                onSubmit={onSubmit(onSucceeded)}
                CancelEditButton={cancelEditButton}
            />
        , [onSubmit, t, operator, onOperatorUpdate])

    return {
        title: t('ivrs.edit.section.menu-config'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderDetails,
        renderForm: renderMenuForm,
        enabled: () => true,
        icon: () => <Settings/>
    }
}