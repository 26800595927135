import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

type Props = {
    children: ReactElement[]
}
export default function PaperStack({ children }: Props) {
    return (
        <>
            {children.map((child, index) => (
                <Box sx={{ mb: index === children.length - 1 ? 0 : { xs: 2, md: 3 } }} key={index}>
                    {child}
                </Box>
            ))}
        </>
    )
}
