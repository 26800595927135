import React from 'react'
import { Button, CircularProgress } from '@mui/material'

type Props = {
    label: string
    showSpinner: boolean
    onClick?: () => void
    disabled?: boolean
}

export default function SpinnableSubmitButton({ label, showSpinner, onClick, disabled }: Props) {
    return (
        <Button
            type={!!onClick ? 'button' : 'submit'}
            color={'primary'}
            variant='contained'
            size={'medium'}
            disabled={showSpinner || disabled}
            onClick={onClick}
            endIcon={showSpinner && <CircularProgress color='secondary' size='1rem' />}
        >
            {label}
        </Button>
    )
}
