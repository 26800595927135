import React from 'react'
import {RouteDefinition} from '../../app/App'
import {DetailSchedule} from '../detail'
import {ScheduleList} from "../holidays";
import {AddOpeningHours} from "./addOpeningHours";

export const detailUrlOpeningHours = '/opening-hours/:id'
export const addUrlOpeningHours = '/opening-hours/new'

export const routes: RouteDefinition[] = [
    {
        href: addUrlOpeningHours,
        component: AddOpeningHours,
        id: 'schedule-add-opening-hours',
        parentId: 'opening-hours-overview',
        translationKey: 'schedules.holidays.add.title',
    },
    {
        href: detailUrlOpeningHours,
        component: DetailSchedule,
        id: 'schedule-detail-opening-hours',
        parentId: 'opening-hours-overview',
    },
].map(routeDefinition => ({ ...routeDefinition, menuId: 'opening-hours-overview' }))

export const OpeningHours = () => {
    return (<ScheduleList type={'opening-hours'} />)
}