import React, {ReactElement, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Control, useFieldArray, useFormContext, useWatch} from 'react-hook-form'
import {Checkbox, FormControlLabel, Grid, IconButton, Paper} from '@mui/material'
import TextInput, {MaskProps} from '../../common/form/TextInput'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

type Props = {
    control: Control<any>
    getValues: (payload?: string | string[]) => Object
}

const timeMaskProps: MaskProps = {
    mask: '#0:00',
    definitions: {
        '#': /[0-9]/,
    },
}

export const HolidayPeriodForm = ({ control, getValues }: Props) => {
    const { fields, remove, append, replace, update } = useFieldArray({
        control,
        name: `holidayPeriods`,
    })

    return (
        <>
            {fields.map((item, index) => (
                <Grid container item spacing={4} key={item.id}>
                    <Grid item md={10}>
                        <EventForm index={index} control={control} update={update} getValues={getValues} />
                    </Grid>
                    <Grid item container md={2} alignItems={'center'} justifyContent={'center'} flexWrap={'nowrap'}>
                        {index === fields.length - 1 && (
                            <IconButton
                                color={'secondary'}
                                component='div'
                                onClick={() =>
                                    append({
                                        from: null,
                                        to: null,
                                        eventName: '',
                                        allDay: true,
                                    })
                                }
                            >
                                <AddIcon />
                            </IconButton>
                        )}
                        {fields.length > 1 && (
                            <IconButton
                                aria-label='delete'
                                component='div'
                                onClick={() =>
                                    fields.length === 1
                                        ? replace({
                                            from: null,
                                            to: null,
                                            eventName: '',
                                            allDay: true,
                                        })
                                        : remove(index)
                                }
                            >
                                <DeleteIcon fontSize='inherit' />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            ))}
        </>
    )
}

const EventForm = ({
                       index,
                       control,
                       update,
                       getValues,
                   }: {
    index: number
    control: Control<any>
    update: (index: number, period: any) => void
    getValues: (payload?: string | string[]) => Object
}) => {
    const { t } = useTranslation()

    const { clearErrors } = useFormContext()

    const isAllDay = useWatch({
        name: `holidayPeriods[${index}].allDay`,
        control: control,
    })

    const AllDayCheckBox: ReactElement = useMemo(
        () => (
            <Checkbox
                checked={isAllDay}
                onChange={event => {
                    update(index, {
                        ...getValues(`holidayPeriods[${index}]`),
                        allDay: event.target.checked,
                        ...(isAllDay ? { startTime: '', endTime: '' } : {}),
                    })
                    clearErrors('holidayPeriods')
                }}
            />
        ),
        [isAllDay, index, update, getValues, clearErrors],
    )

    const TimeSlots = useMemo(
        () => () =>
            isAllDay ? (
                <></>
            ) : (
                <>
                    <HolidayBox width={6}>
                        <TextInput required={true} label={t('schedules.holidays.startTime')}
                                   name={`holidayPeriods[${index}].startTime`} control={control}
                                   maskProps={timeMaskProps} />
                    </HolidayBox>
                    <HolidayBox width={6}>
                        <TextInput required={true} label={t('schedules.holidays.endTime')}
                                   name={`holidayPeriods[${index}].endTime`} control={control}
                                   maskProps={timeMaskProps} />
                    </HolidayBox>
                </>
            ),
        [isAllDay, index, control, t],
    )

    return (
        <Paper sx={{ p: 4 }}>
            <Grid container spacing={4}>
                <HolidayBox width={6}>
                    <TextInput required={true} label={t('schedules.holidays.event-name')}
                               name={`holidayPeriods[${index}].eventName`} control={control} />
                </HolidayBox>
                <HolidayBox width={6} align={'end'} justifyContent={'start'}>
                    <FormControlLabel control={AllDayCheckBox} label={t('schedules.holidays.all-day') as string} />
                </HolidayBox>
                <HolidayBox width={6}>
                    <TextInput required={true} label={t('schedules.holidays.from')}
                               name={`holidayPeriods[${index}].from`} control={control} isDate={true} />
                </HolidayBox>
                <HolidayBox width={6}>
                    <TextInput label={t('schedules.holidays.to')} name={`holidayPeriods[${index}].to`} control={control}
                               isDate={true} />
                </HolidayBox>
                <TimeSlots />
            </Grid>
        </Paper>
    )
}

function HolidayBox({
                        children,
                        align,
                        width,
                        justifyContent = 'center',
                    }: { children: any; align?: string; width?: number; justifyContent?: string }) {
    return (
        <Grid container={!!align} item xs={12} sm={6} md={width || 3} lg={width || 3} alignItems={align}
              justifyContent={justifyContent}>
            {children}
        </Grid>
    )
}
