import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import FormGroup from '../form/FormGroup'
import FormRow from '../form/FormRow'
import FormFieldBox from '../form/FormFieldBox'
import TextInput from '../form/TextInput'
import {Control} from 'react-hook-form'
import {getAutocompleteSchedules} from '../util/helpers'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import {PhoneNumber, SelectiveCallForward} from '../../app/types'
import * as yup from 'yup'
import {ForwardToNumberField} from "./ForwardToNumberField";

type Props = {
    control: Control<any>
    selectiveCallForward: SelectiveCallForward | null
    nightServiceMode?: string
    fullWidth?: boolean
    phoneNumbersToForwardTo: PhoneNumber[]
}

export const selectiveForwardSchema = yup.object().shape({
    outsideOpeningHoursPhoneNumber: yup
        .string()
        .when(['outsideOpeningHoursScheduleUuid'], {
            is: (schedule: string) => !!schedule,
            then: schema => yup.string().trim().max(22, 'too-long').required('required-m').matches(/^\+?[0-9]*$/, {
                message: 'invalid-phone-number',
                excludeEmptyString: true,
            }).nullable(),
            otherwise: schema => yup.string().trim().max(22, 'too-long').matches(/^\+?[0-9]*$/, {
                message: 'invalid-phone-number',
                excludeEmptyString: true,
            }).nullable(),
        })
    ,
    holidayPhoneNumber: yup
        .string()
        .when(['holidayScheduleUuid'], {
            is: (schedule: string) => !!schedule,
            then: schema => yup.string().trim().max(22, 'too-long').required('required-m').matches(/^\+?[0-9]*$/, {
                message: 'invalid-phone-number',
                excludeEmptyString: true,
            }).nullable(),
            otherwise: schema => yup.string().trim().max(22, 'too-long').matches(/^\+?[0-9]*$/, {
                message: 'invalid-phone-number',
                excludeEmptyString: true,
            }).nullable(),
        }),
})

export const SelectiveCallForwardForm = ({ control, selectiveCallForward, nightServiceMode, fullWidth, phoneNumbersToForwardTo}: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)

    const holidayOptions = useMemo(() => {
        return getAutocompleteSchedules(t, 'holidays', site, selectiveCallForward?.holidaySchedule || null)
    }, [site, t, selectiveCallForward])

    const openingHoursOptions = useMemo(() => {
        return getAutocompleteSchedules(t, 'opening-hours', site, selectiveCallForward?.outsideOpeningHoursSchedule || null)
    }, [site, t, selectiveCallForward])

    const nightServiceOptions = useMemo(() =>
        ['OFF', 'ON', 'USE_GROUP'].map(mode => ({
            value: `${mode}`,
            label: `${t(`flows.edit.night-service-modes.${mode}`)}`,
        })), [t])

    return (
        <>
            <FormGroup label={t('selective-call-forward.section-header')} fullWidth={fullWidth || false}>
                {
                    nightServiceMode ? <FormRow>
                        <FormFieldBox>
                            <TextInput
                                readonly={false}
                                label={t('flows.edit.night-service')}
                                name={'nightServiceMode'}
                                control={control}
                                required={false}
                                options={nightServiceOptions}
                            />
                        </FormFieldBox>
                    </FormRow> : <></>
                }
                <FormRow>
                    <FormFieldBox>
                        <TextInput
                            readonly={false}
                            label={t('selective-call-forward.holiday-schedule')}
                            name={'selectiveCallForward.holidayScheduleUuid'}
                            control={control}
                            required={false}
                            autoCompleteOptions={holidayOptions}
                        />
                    </FormFieldBox>
                    <FormFieldBox>
                        <ForwardToNumberField phoneNumbersToForwardTo={phoneNumbersToForwardTo} defaultNumber={selectiveCallForward?.holidayPhoneNumber || ''} readonly={false} required={false} label={t('selective-call-forward.holiday-number')} name={'selectiveCallForward.holidayPhoneNumber'} control={control} />
                    </FormFieldBox>
                </FormRow>
                <FormRow>
                    <FormFieldBox>
                        <TextInput
                            readonly={false}
                            label={t('selective-call-forward.openinghours-schedule')}
                            name={'selectiveCallForward.outsideOpeningHoursScheduleUuid'}
                            control={control}
                            required={false}
                            autoCompleteOptions={openingHoursOptions}
                        />
                    </FormFieldBox>
                    <FormFieldBox>
                        <ForwardToNumberField phoneNumbersToForwardTo={phoneNumbersToForwardTo} defaultNumber={selectiveCallForward?.outsideOpeningHoursPhoneNumber || ''} readonly={false} required={false} label={t('selective-call-forward.openinghours-number')} name={'selectiveCallForward.outsideOpeningHoursPhoneNumber'} control={control} />
                    </FormFieldBox>
                </FormRow>
            </FormGroup>
        </>
    )
}
