import React from 'react'
import './app.less'
import './index.css'
import './i18n'
import { Box, Flex } from 'rebass'
import { Paper } from '@mui/material'
import ErrorAlert from '../common/components/ErrorAlert'

type ErrorPageProps = {
    status: string
}

//TODO
export const ErrorPage = ({ status }: ErrorPageProps) => {
    return (
        <Paper>
            <Flex alignItems={'center'} justifyContent={'center'} style={{ minHeight: '90vh' }}>
                <Box width={[1 / 2]}>
                    <ErrorAlert showAlert={true} />
                </Box>
            </Flex>
        </Paper>
    )
}
