import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import React, {ReactNode, useCallback, useState} from 'react'
import {api} from '../common/api'
import {useFetchData} from '../common/servercall/useFetchData'
import {WithErrorAndFetching} from '../common/servercall/WithErrorAndFetching'
import {useTranslation} from 'react-i18next'
import {Box, FormLabel, Grid, IconButton, Stack, Typography} from '@mui/material'
import {ModeEdit} from '@mui/icons-material'
import {MainNumberForm} from './MainNumberForm'
import {useAccessHelpers} from "../common/util/hooks";

export const MainNumber = () => {
    const site = useSelector(selectedSiteSelector)
    const { t } = useTranslation()
    const [editingMode, setEditingMode] = useState<boolean>(false)
    const {onlyWithWriteAccess} = useAccessHelpers()

    const fetchMainNumber = useCallback(
        async (): Promise<string> => {
            if (!site) return ''
            const { data } = await api.get(`/api/site/${site.productId}/numbers/main-number`)
            return data || ' '
        },
        [site],
    )

    const {
        data: mainNumber,
        isFetching: fetchingMainNumber,
        error: mainNumberError,
        refetch,
    } = useFetchData<string>(useCallback(() => fetchMainNumber(), [fetchMainNumber]))

    const nonEditing = useCallback((mainNumber: string): ReactNode => {
            return (
                <Stack direction={'row'}>
                    <Box alignSelf={'center'} sx={{ paddingRight: 1 }}>
                        <Typography variant={'body1'} component={'span'}>
                            {mainNumber}
                        </Typography>
                    </Box>
                    {onlyWithWriteAccess(<Box alignSelf={'center'}>
                        <IconButton size={'small'} color='secondary' aria-label='Change main number'
                                    component='span'
                                    onClick={() => {
                                        setEditingMode(true)
                                    }}>
                            <ModeEdit />
                        </IconButton>
                    </Box>)}
                </Stack>
            )
        },
        [setEditingMode, onlyWithWriteAccess],
    )

    const renderMainNumber = useCallback(
        (mainNumber: string): ReactNode => {
            return (
                <Grid container direction='row'
                      justifyContent='start'
                      alignItems='center' spacing={0}>
                    <Grid item xs={12} md={3} lg={2} xl={2} sx={{ paddingRight: 1 }}>
                        <FormLabel>{`${t('site.cards.general.main-number')}:`}</FormLabel>
                    </Grid>
                    < Grid item xs>
                        {editingMode ? <MainNumberForm onSuccess={() => {
                                refetch()
                                setEditingMode(false)
                            }} onCancel={() => {
                                setEditingMode(false)
                            }} mainNumberForm={{ number: mainNumber === ' ' ? '' : mainNumber }} />
                            : nonEditing(mainNumber)}
                    </Grid>
                </Grid>
            )
        },
        [editingMode, setEditingMode, t, nonEditing, refetch],
    )

    return <WithErrorAndFetching<string> isFetching={fetchingMainNumber} error={mainNumberError} data={mainNumber}
                                         render={renderMainNumber} />
}