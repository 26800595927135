import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router'
import {BrowserRouter as Router} from 'react-router-dom'
import {basepath} from './config'
import './app.less'
import './index.css'
import './i18n'
import {ScrollToTop} from '../common/ScrollToTop'
import {AppContainer} from './AppContainer'
import {NavigationDefinition, routeDefinitions} from './navigation/menu'
import {routes as UserRoutes} from '../users/index'
import {routes as CpeRoutes} from '../cpes/index'
import {routes as FlowRoutes} from '../flows/index'
import {routes as IvrRoutes} from '../ivrs/index'
import {routes as HolidayRoutes} from '../schedules/holidays'
import {routes as OpeningHoursRoutes} from '../schedules/openinghours'
import {routes as PagingRoutes} from '../services/pagings/index'
import {routes as CallPickupGroupRoutes} from '../services/callpickupgroups/index'
import {routes as ExecutiveRoutes} from '../services/executiveassistants/index'
import {useDispatch, useSelector} from 'react-redux'
import {ThunkDispatch} from '@reduxjs/toolkit'
import {RootState} from './store'
import {
    fetchMe,
    inEnterpriseViewSelector,
    loadingFailureSelector,
    loadingSelector,
    meSelector,
    selectSite
} from '../authentication/redux'
import {Spinner} from '../common/components/Spinner'
import {Box, Flex} from 'rebass'
import {QueryClient, QueryClientProvider} from 'react-query'
import SiteSwitcher from "./SiteSwitcher";
import {LoginCallback, SecureRoute, Security, useOktaAuth} from "@okta/okta-react";
import OktaAuth, {toRelativeUrl} from "@okta/okta-auth-js";
import {api} from "../common/api";
import {Logout} from "./Logout";
import {useOktaSession} from "../common/servercall/useOktaSession";
import {UnAuthorized} from "./Unauthorized";
import {Container} from "@mui/material";
import ErrorAlert from "../common/components/ErrorAlert";

export type RouteDefinition = {
    translationKey?: string
    href: string
    icon?: any
    component: any
    id: string
    parentId?: string
    menuId?: string
    menuParentId?: string
    showInEnterpriseMode?: boolean
    hideInSiteMode?: boolean
    hidden?: (pagingEnabled: boolean, callPickupEnabled: boolean ) => boolean
}

export const RouteDefinitions: RouteDefinition[] = [...IvrRoutes, ...UserRoutes, ...CpeRoutes, ...FlowRoutes, ...OpeningHoursRoutes, ...HolidayRoutes, ...PagingRoutes, ...CallPickupGroupRoutes, ...ExecutiveRoutes, ...NavigationDefinition]

const definitionToRoute = (useOAuth: boolean) => (definition: any) => useOAuth ?
    <SecureRoute path={definition.href} component={definition.component} key={definition.id}/> :
    <Route path={definition.href} component={definition.component} key={definition.id}/>

export function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const oktaCallBackUrl = '/login/callback'

const oktaAuth = new OktaAuth({
    issuer: process.env.NODE_ENV === 'development' ? 'https://dev-73414924.okta.com/oauth2/default' : window.issuerUrl,
    clientId: process.env.NODE_ENV === 'development' ? '0oaculw3iiY8Hgxt85d7' : window.clientId,
    redirectUri: window.location.origin + oktaCallBackUrl,
    postLogoutRedirectUri: window.location.origin,
    pkce: true,
    scopes: ['openid', 'profile', 'email'],
    storageManager: {
        token: {
            storageType: 'sessionStorage'
        }
    }
});

const JwtSetup = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, any, any>>()
    const query = useQuery();
    const me = useSelector(meSelector)

    const setup = useCallback(async () => {
        let productId = query.get("productId")
        if (!me) {
            await dispatch(fetchMe(null))
            await dispatch(selectSite(productId))
        }
    }, [dispatch, me, query])

    useEffect(() => {
        setup()
    }, [setup])

    return <></>
}

const OAuthSetup = () => {
    const {authState} = useOktaAuth()
    const dispatch = useDispatch<ThunkDispatch<RootState, any, any>>()
    const query = useQuery();
    const me = useSelector(meSelector)
    const { loggedOut } = useOktaSession()

    const setup = useCallback(async () => {
        if (authState?.isAuthenticated) {
            api.defaults.headers.common['Authorization'] = "Bearer " + authState?.accessToken?.accessToken;
        }
        let productId = query.get("productId")
        if (!me) {
            const me = await dispatch(fetchMe(oktaAuth))
            if (me.payload === 'unauthorized') {
                await loggedOut()
                return
            } else if (me.payload !== '401') {
                await dispatch(selectSite(productId))
            }
        }
    }, [dispatch, me, query, authState?.accessToken, authState?.isAuthenticated, loggedOut])

    useEffect(() => {
        setup()
    }, [setup])

    return <></>
}

const AuthenticatedRoutes = ({useOAuth}: { useOAuth: boolean }) => {
    const inEnterpriseView = useSelector(inEnterpriseViewSelector)
    const isLoading = useSelector(loadingSelector)
    const loadingFailure = useSelector(loadingFailureSelector)

    const MainScreen = useMemo(() =>
        isLoading && !loadingFailure? <Loading/> : <Switch>
            <Route path='/'>
                <AppContainer>
                    <Switch>
                        <Redirect from='/' exact to={routeDefinitions(inEnterpriseView)[0].href}/>,
                        <Route path={'/switch-site'} component={SiteSwitcher} key={'siteSwitcher'}/>,
                        {RouteDefinitions.map(definitionToRoute(useOAuth))}
                    </Switch>
                </AppContainer>
            </Route>
        </Switch>
        , [useOAuth, inEnterpriseView, isLoading, loadingFailure])

    const SetupMemo = useMemo(() => useOAuth? <OAuthSetup/> : <JwtSetup/>, [useOAuth])

    return (
        <>
            {SetupMemo}
            {MainScreen}
        </>
    )
}

export const Loading = () => <Flex alignItems={'center'} justifyContent={'center'}>
    <Box style={{marginTop: '20%'}}>
        <Spinner size={36}/>
    </Box>
</Flex>

const queryClient = new QueryClient()

const OAuthSecuredApp = () => {
    const history = useHistory();
    const query = useQuery();
    const relayState = query.get('RelayState')

    const triggerLogin = useCallback(async () => {
        await oktaAuth.signInWithRedirect({originalUri: '/', state: relayState || undefined});
    }, [relayState]);

    const customAuthHandler = useCallback(async () => {
        const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
        if ((!previousAuthState || !previousAuthState.isAuthenticated)) {
            await triggerLogin()
        } else {
            // Ask the user to trigger the login process during token autoRenew process
            //todo
        }
    }, [triggerLogin]);

    const restoreOriginalUri = useCallback(async (_oktaAuth: OktaAuth, originalUri: string) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    }, [history])

    return <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={customAuthHandler}>
        <Switch>
            <Route path='/sign-out' component={Logout}/>
            <Route path='/unauthorized' component={UnAuthorized}/>
            <Route path={oktaCallBackUrl} component={LoginCallback}/>
            <SecureRoute path='/' render={() => <AuthenticatedRoutes useOAuth={true}/>}/>
        </Switch>
    </Security>
}

const EdgeApp = () => {
    return <Switch>
        <Route path='/' render={() => <AuthenticatedRoutes useOAuth={false}/>}/>
    </Switch>
}

export const App = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [loggedIn, setLoggedIn] = useState<boolean>(false)
    const [noProductFoundInEdge, setNoProductFoundInEdge] = useState<boolean>(false)
    useEffect(() => {
        const fetch = async () => {
            try {
                const {data: user} = await api.get('/api/me')
                setLoggedIn(!!user)
            } catch (err: any) {
                if (err?.response?.status === 400) {
                    setNoProductFoundInEdge(true)
                }
            }
            setLoading(false)
        }
        fetch();
    }, [])

    return noProductFoundInEdge ? <Container maxWidth="sm" sx={{ marginTop: 8 }}>
        <ErrorAlert showAlert={true} body={<>Product not found.</>} />
    </Container> : <QueryClientProvider client={queryClient}>
                <Router basename={basepath}>
                    <ScrollToTop/>
                    {loading && <Loading/>}
                    {!loggedIn && !loading && <OAuthSecuredApp/>}
                    {loggedIn && !loading && <EdgeApp/>}
                </Router>
            </QueryClientProvider>
}
