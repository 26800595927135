import {api, buildPaginatedUrl} from '../api'
import {formatPhoneNumber, formatUserInDropdown} from './formatters'
import {Announcement, AnswerGroup, BasicUser, Ivr, PhoneUser, Schedule, Site} from '../../app/types'
import {TFunction} from 'react-i18next'
import {AutoCompleteOptions} from '../form/AutocompleteWithAsyncFetch'
import {find, none} from 'ramda'

export const getAutocompleteSiteAnswerGroups = (t: TFunction, site?: Site | null, currentAnswerGroup?: AnswerGroup): AutoCompleteOptions<any> => {
    return {
        getOptions: async searchTermParam => {
            if (!site) return []
            const { data } = await api.get(
                buildPaginatedUrl(
                    `/api/site/${site.productId}/answergroups`,
                    0,
                    {
                        field: 'extension',
                        order: 'asc',
                    },
                    searchTermParam,
                    1000
                )
            )
            const answerGroups = data.content as AnswerGroup[]
            return answerGroups
        },
        getOptionLabel: (item: AnswerGroup) => `${item.extension} (${item.name})`,
        getOptionValue: (item: any) => item,
        isOptionEqualToValue: (option: any, value: any) => option.name === value.name,
        defaultObject: currentAnswerGroup ?? null,
        noOptionsText: t('flows.no-flow-available'),
        filterOnServer: false,
    }
}

export const getAutocompleteSiteIvrs = (t: TFunction, ivrIdsToDisallow: string[], site?: Site | null, currentIvr?: Ivr): AutoCompleteOptions<any> => {
    return {
        getOptions: async searchTermParam => {
            if (!site) return []
            const { data } = await api.get(
                buildPaginatedUrl(
                    `/api/site/${site.productId}/ivrs`,
                    0,
                    {
                        field: 'extension',
                        order: 'asc',
                    },
                    searchTermParam,
                    1000
                )
            )
            const ivrs = data.content as Ivr[]
            return ivrs
        },
        getOptionLabel: (item: Ivr) => `${item.extension} (${item.name})`,
        getOptionValue: (item: any) => item,
        isOptionEqualToValue: (option: any, value: any) => option.name === value.name,
        defaultObject: currentIvr ?? null,
        noOptionsText: t('flows.no-ivr-available'),
        filterOnServer: false,
        getOptionDisabled: ivr => {
            return !!find(ivrId => ivrId === ivr.uuid, ivrIdsToDisallow)
        },
    }
}

const fetchAnnouncements = async (searchTermParam: string, site?: Site | null): Promise<Announcement[]> => {
    if (!site) return []
    const { data } = await api.get(
        buildPaginatedUrl(
            `/api/site/${site.productId}/announcements`,
            0,
            {
                field: 'name',
                order: 'asc',
            },
            searchTermParam,
            1000
        )
    )
    return data.content as Announcement[]
}

export const DEFAULT_ANNOUNCEMENT = '##DEFAULT_TELENET##'

export const getAutocompleteSiteAnnouncementsWithDefaultSelection = (t: TFunction, maxSizeKb: number | null, site?: Site | null, currentFileName?: string): AutoCompleteOptions<any> => {
    return {
        ...getAutocompleteSiteAnnouncements(t, maxSizeKb, site, currentFileName),
        getOptions: async searchTermParam => {
            const announcements = await fetchAnnouncements(searchTermParam, site)
            return [{ name: '##DEFAULT_TELENET##' }, ...announcements]
        },
    }
}

export const getAutocompleteSiteAnnouncements = (t: TFunction, maxSizeKb: number | null, site?: Site | null, currentFileName?: string): AutoCompleteOptions<any> => {
    return {
        getOptions: async searchTermParam => {
            return fetchAnnouncements(searchTermParam, site)
        },
        getOptionLabel: (item: Announcement) => `${item.name === DEFAULT_ANNOUNCEMENT ? t('general.default') : item.name}${!!maxSizeKb && item.fileSize > maxSizeKb ? ` (${t('general.file-too-large')})` : ''}`,
        getOptionValue: (item: Announcement) => item.name,
        isOptionEqualToValue: (option: any, value: any) => option.name === value.name,
        getOptionDisabled: (item: Announcement) => !!maxSizeKb && item.fileSize > maxSizeKb,
        defaultObject: currentFileName ? { name: currentFileName } : null,
        noOptionsText: t('announcements.no-announcement-available'),
        filterOnServer: false,
    }
}

export const getAutocompleteSiteNumbers = (t: TFunction, forUser: boolean, site?: Site | null, currentNumber?: string | null, numbers?: string[]): AutoCompleteOptions<any> => {
    return {
        getOptions: async () => {
            if (!site) return []
            const { data } = await api.get(`/api/site/${site.productId}/free-numbers?for-user=${forUser}`)
            const freeNumbers = data as string[]
            return [...(currentNumber ? [...freeNumbers, currentNumber] : freeNumbers), ...(numbers || [])]
        },
        getOptionLabel: (item: any) => formatPhoneNumber(item),
        getOptionValue: (item: any) => item,
        isOptionEqualToValue: (option: any, value: any) => option === value,
        defaultObject: currentNumber ?? null,
        noOptionsText: t('users.edit.no-free-numbers-left'),
        filterOnServer: false,
    }
}

export const getAutocompleteAlternateNumbers = (t: TFunction, forUser: boolean, currentAlternateNumbers: string[], disabledNumbers: string[], site?: Site | null): AutoCompleteOptions<any> => {
    return {
        getOptions: async () => {
            if (!site) return []
            const { data } = await api.get(`/api/site/${site.productId}/free-numbers?for-user=${forUser}`)
            const freeNumbers = data as string[]
            return [...freeNumbers, ...currentAlternateNumbers].sort()
        },
        getOptionLabel: (item: any) => formatPhoneNumber(item),
        getOptionValue: (item: any) => item,
        isOptionEqualToValue: (option: any, value: any) => option === value,
        defaultObject: null,
        noOptionsText: t('users.edit.no-free-numbers-left'),
        filterOnServer: false,
        getOptionDisabled: nr => {
            return !!find(number => nr === number, disabledNumbers)
        },
    }
}

export const getAutocompleteOutgoingNumbers = (t: TFunction, forUser: boolean, site?: Site | null, currentNumber?: string | null): AutoCompleteOptions<any> => {
    return {
        getOptions: async () => {
            if (!site) return []
            const { data } = await api.get(`/api/site/${site.productId}/all-numbers`)
            return data
        },
        getOptionLabel: (item: any) => formatPhoneNumber(item),
        getOptionValue: (item: any) => item,
        isOptionEqualToValue: (option: any, value: any) => option === value,
        defaultObject: currentNumber ?? null,
        noOptionsText: t('users.edit.no-free-numbers-left'),
        filterOnServer: false,
    }
}

export const getAutocompleteSiteMsisdns = (t: TFunction, site?: Site | null, currentMsisdn?: string | null): AutoCompleteOptions<any> => {
    return {
        getOptions: async () => {
            if (!site) return []
            const { data } = await api.get(`/api/site/${site.productId}/free-fmu-msisdns`)
            const freeNumbers = data as string[]
            return currentMsisdn ? [...freeNumbers, currentMsisdn] : freeNumbers
        },
        getOptionLabel: (item: any) => formatPhoneNumber(item),
        getOptionValue: (item: any) => item,
        isOptionEqualToValue: (option: any, value: any) => option === value,
        defaultObject: currentMsisdn ?? null,
        noOptionsText: t('users.edit.no-free-msisdns-left'),
        filterOnServer: false,
    }
}

export const getAutocompleteMainNumberCandidates = (t: TFunction, site?: Site | null, currentNumber?: string | null): AutoCompleteOptions<any> => {
    return {
        getOptions: async () => {
            if (!site) return []
            const { data } = await api.get(`/api/site/${site.productId}/numbers/main-number-candidates`)
            return data as string[]
        },
        getOptionLabel: (item: any) => formatPhoneNumber(item),
        getOptionValue: (item: any) => item,
        isOptionEqualToValue: (option: any, value: any) => option === value,
        defaultObject: currentNumber ?? null,
        noOptionsText: t('users.edit.no-free-numbers-left'),
        filterOnServer: false,
        disableClearable: true,
    }
}

export const getAutocompleteSchedules = (t: TFunction, type: string, site: Site | null, defaultSchedule: Schedule | null): AutoCompleteOptions<any> => {
    return {
        getOptions: async () => {
            if (!site) return []
            const { data } = await api.get(
                buildPaginatedUrl(
                    `/api/site/${site.productId}/${type}`,
                    0,
                    {
                        field: 'name',
                        order: 'asc',
                    },
                    null,
                    1000
                )
            )
            const schedules = data.content as Schedule[]
            return schedules
        },
        getOptionLabel: (item: any) => item.name,
        getOptionValue: (item: any) => (item as Schedule).uuid,
        isOptionEqualToValue: (option: any, value: any) => option.uuid === value.uuid,
        defaultObject: defaultSchedule ?? null,
        noOptionsText: t('schedules.no-schedule-available'),
        filterOnServer: false,
    }
}

const buildAutoCompleteOptionsForUser = (urlBuilder: (searchTerm: string) => string | null, noOptionsText: string, usersIdsToDisallow: string[], filterOnServer: boolean, currentUser?: BasicUser, formatUserFn?: (user: BasicUser) => string)
        : AutoCompleteOptions<any> => {
    return {
        getOptions: async searchTermParam => {
            const url = urlBuilder(searchTermParam)
            if (!url) return []
            const { data } = await api.get(url)
            const users = data.content as PhoneUser[]
            return currentUser && none(u => u.uuid === currentUser.uuid, users) ? [...users, currentUser] : users
        },
        getOptionLabel: (item: PhoneUser) => {return formatUserFn? formatUserFn(item) : formatUserInDropdown(item)},
        getOptionValue: (item: any) => item,
        isOptionEqualToValue: (option: any, value: any) => option.uuid === value.uuid,
        defaultObject: currentUser ?? null,
        noOptionsText: noOptionsText,
        filterOnServer: filterOnServer,
        getOptionDisabled: user => {
            return !!find(userId => userId === user.uuid, usersIdsToDisallow)
        },
    }
}

export const getAutocompleteUsers = (noOptionsText: string, usersIdsToDisallow: string[], filterOnServer: boolean, site?: Site | null, currentUser?: BasicUser, formatUserFn?: (user: BasicUser) => string): AutoCompleteOptions<any> => {
    const urlBuilder = (searchTermParam: string) =>
        site
            ? buildPaginatedUrl(
                  `/api/site/${site.productId}/users`,
                  0,
                  {
                      field: 'extension',
                      order: 'asc',
                  },
                  searchTermParam,
                  1000
              )
            : null

    return buildAutoCompleteOptionsForUser(urlBuilder, noOptionsText, usersIdsToDisallow, filterOnServer, currentUser, formatUserFn)
}

export const getAutocompleteUsersForProduct = (noOptionsText: string, usersIdsToDisallow: string[], site?: Site | null, currentUser?: BasicUser): AutoCompleteOptions<any> => {
    const urlBuilder = (searchTermParam: string) =>
        site
            ? buildPaginatedUrl(
                  `/api/site/${site.productId}/users`,
                  0,
                  {
                      field: 'extension',
                      order: 'asc',
                  },
                  searchTermParam,
                  1000
              ) + `&product=true`
            : null

    return buildAutoCompleteOptionsForUser(urlBuilder, noOptionsText, usersIdsToDisallow, true, currentUser)
}

export const getAutocompleteCompanyExtensions = (noOptionsText: string, site?: Site | null, currentExtension?: string | null): AutoCompleteOptions<any> => {
    return {
        getOptions: async searchTermParam => {
            if (!site) return []
            const { data } = await api.get(`/api/site/${site.productId}/numbers/company-extensions?searchTerm=` + searchTermParam)
            const freeNumbers = data as string[]
            return freeNumbers
        },
        getOptionLabel: (item: any) => item,
        getOptionValue: (item: any) => item,
        isOptionEqualToValue: (option: any, value: any) => option === value,
        defaultObject: currentExtension ?? null,
        noOptionsText: noOptionsText,
        filterOnServer: true,
    }
}
