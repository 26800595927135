import React, {ReactElement, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useFieldArray, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {array, object, string} from 'yup'
import TextInput from '../../common/form/TextInput'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import SpinnableSubmitButton from '../../common/form/SpinnableSubmitButton'
import {useFormSubmission} from '../../common/servercall/useFormSubmission'
import ErrorAlert from '../../common/components/ErrorAlert'
import FormGroup from '../../common/form/FormGroup'
import CancelButton from '../../common/form/CancelButton'
import FormContainer from '../../common/form/FormContainer'
import FormButtons from '../../common/form/FormButtons'
import {cpeFeatureTypes} from "../util/CPEHelpers";
import {CpeFeature, CpeFeatureMetaData, CPESettings, PhoneNumber, Site} from "../../app/types";
import {filter, findIndex, propEq} from "ramda";
import FormCheckbox from "../../common/form/FormCheckbox";
import {api} from "../../common/api";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";

const schema = (typeOptions: CpeFeatureMetaData[]) =>
    yup
        .object()
        .shape({
            features: array().of(
                object().shape({
                    type: string().nullable(),
                    value: yup.lazy(value => {
                        return yup.string().when('type', type => {
                            let meta = typeOptions.find(t => t.type === type)
                            return meta?.validationRule() || yup.string().nullable()
                        })
                    })
                }),
            ),
        })
        .required()

const numberDropdown = (site: Site | null, defaultObject: any | null) => ({
    getOptions: async () => {
        if (!site) return []
        const {data} = await api.get(`/api/site/${site.productId}/numbers/all-including-empty-numbers?sort=extension,asc`)
        return filter((number: PhoneNumber) => number.assignedTo != null && (number.number != null || number.extension != null), data.content) as PhoneNumber[]
    },
    getOptionLabel: (item: any) => {
        return typeof item === 'string' ? item : `${item.extension} (${item.assignedTo})`
    },
    getOptionValue: (item: any) => {
        return typeof item === 'string' ? item : item.extension
    },
    isOptionEqualToValue: (option: PhoneNumber, value: PhoneNumber) => option?.assignedTo === value?.assignedTo,
    filterOnServer: false,
    defaultObject: defaultObject || '',
    freeText: true,
})

type Props = {
    cpeSettings: CPESettings
    onSubmit: (cpeSettings: CPESettings) => void
    readonly?: boolean
    buildFormButtons: ((isSubmitting: boolean) => ReactElement) | undefined
}

const submitForm = (onSubmit: (cpeSettings: CPESettings) => void) => (formData: CPESettings) => onSubmit(
    {
        keys: [],
        features : formData.features.map((config : CpeFeature) => cpeFeatureTypes().find(t => t.type === config.type)?.fieldType === 'CHECKBOX' ? {type: config.type, value: config.value && config.value === 'true' ? '1': '0'} : config)
    }
)

const CPE2NConfigFormScreen = ({cpeSettings, onSubmit, readonly, buildFormButtons}: Props) => {
    const {t} = useTranslation()
    const site = useSelector(selectedSiteSelector)

    const {control, handleSubmit, setError, setValue} = useForm<CPESettings>({
        resolver: yupResolver(schema(cpeFeatureTypes())),
        defaultValues: {
            ...cpeSettings,
        },
    })

    const {submit, isSubmitting, serverError, onError} = useFormSubmission<CPESettings>(submitForm(onSubmit), setError)

    const {fields: featureFields} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "features", // unique name for your Field Array
    });

    const numberOptions = useCallback((type: string) => {
        return numberDropdown(site, !!featureFields ? featureFields.find(feature => feature.type === type)?.value : undefined)
    }, [site, featureFields])


    return (
        <>
            <ErrorAlert errorKey={typeof serverError === 'string' ? serverError : undefined} showAlert={!!serverError}/>
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={t('general.general')} fullWidth={true}>
                    <FormRow>
                        {cpeFeatureTypes().map((field, index) => (
                            <FormFieldBox mdSize={3} fullWidth={false} >
                                {field.fieldType === 'CHECKBOX' ?
                                    <FormCheckbox
                                        label={t(`cpes.config.${field.type}`)}
                                        name={`features.${findIndex(propEq('type', field.type), featureFields)}.value`}
                                        control={control}
                                        setValue={setValue}
                                    /> : field.fieldType === 'PHONENUMBER' ?
                                        <TextInput readonly={readonly} autofocus={true}
                                                   label={t(`cpes.config.${field.type}`)}
                                                   name={`features.${findIndex(propEq('type', field.type), featureFields)}.value`}
                                                   control={control} required={field.required}
                                                   autoCompleteOptions={numberOptions(field.type)}/>
                                        : <TextInput readonly={readonly} autofocus={true}
                                                     label={t(`cpes.config.${field.type}`)}
                                                     name={`features.${findIndex(propEq('type', field.type), featureFields)}.value`}
                                                     control={control} required={field.required}/>
                                }

                            </FormFieldBox>
                        ))}
                    </FormRow>
                </FormGroup>

                {readonly ? <></> : buildFormButtons? buildFormButtons(isSubmitting)
                    : <FormButtons fullWidth={false} buttons={[<SpinnableSubmitButton label={t('general.form-update')}
                                                                                  showSpinner={isSubmitting}/>,
                                                     <CancelButton/>]}/>}
            </FormContainer>
        </>
    )
}



export default CPE2NConfigFormScreen
