import React, {ReactElement, ReactNode, useCallback} from 'react'
import {EmergencyCallNotification} from "../../app/types";
import {FormSection} from "../../common/components/SectionedForm";
import {api} from "../../common/api";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import {useTranslation} from "react-i18next";
import PaperStack from "../../common/form/PaperStack";
import FormGroup from "../../common/form/FormGroup";
import {LabelValues, LabelValueType} from "../../common/components/LabelValues";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {useFetchData} from "../../common/servercall/useFetchData";
import {EmergencyCallNotificationForm} from "./EmergencyCallNotificationForm";
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";

export const useGeneralSection = () : FormSection<EmergencyCallNotification> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const {buildFormButtons} = useFormSubmissionButtons(false)

    const fetchData = useCallback(
        async (): Promise<EmergencyCallNotification | undefined> => {
            if (!site) return
            const { data } = await api.get<EmergencyCallNotification>(`/api/site/${site.productId}/emergency-call-notification`)
            return data
        },
        [site]
    )
    const { data, isFetching, error, refetch } = useFetchData<EmergencyCallNotification>(useCallback(() => fetchData(), [fetchData]))

    const onGeneralSubmit = useCallback((onSucceeded: () => void) =>
            async (formData: EmergencyCallNotification) => {
                if (!site) return
                await api.put(`/api/site/${site.productId}/emergency-call-notification`, formData)
                await refetch()
                onSucceeded();
            },
        [site, refetch],
    )

    const renderGeneralForm = useCallback((callNotification: EmergencyCallNotification, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode =>
        data ? <EmergencyCallNotificationForm emergencyCallNotification={data} buildFormButtons={buildFormButtons(cancelEditButton)}
                                       onSubmit={onGeneralSubmit(onSucceeded)}/> : <></>, [data, onGeneralSubmit, buildFormButtons])

    const infoLabelValues = useCallback(
        (value: EmergencyCallNotification): LabelValueType[] => [
            { label: t('general.enabled'), value: <>{value.notificationEnabled? t('general.yes') : t('general.no')}</> },
            { label: t('users.edit.email'), value: <>{value.notificationEmail}</> },
        ],
        [t]
    )

    const renderGeneralDetails = useCallback(
        (notification: EmergencyCallNotification): ReactNode => {
            return (
                <PaperStack>
                    <FormGroup key={1} label={''} fullWidth={true}>
                        <LabelValues labelValues={infoLabelValues(notification)} />
                    </FormGroup>
                    <div></div>
                </PaperStack>
            )
        },
        [infoLabelValues]
    )

    return {
        title: t('general.notification'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderGeneralDetails,
        renderForm: renderGeneralForm,
        enabled: () => true,
        icon: () => <NotificationsIcon/>
    }
}