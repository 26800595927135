import {UserOutgoingCalls} from "../../app/types";
import {FormSection} from "../../common/components/SectionedForm";
import {TFunction, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import React, {ReactElement, ReactNode, useCallback} from "react";
import {api} from "../../common/api";
import {useFetchData} from "../../common/servercall/useFetchData";
import {LabelValues, LabelValueType} from "../../common/components/LabelValues";
import PaperStack from "../../common/form/PaperStack";
import FormGroup from "../../common/form/FormGroup";
import {PhoneForwarded} from "@mui/icons-material";
import {OutgoingCallsForm} from "./outgoingCallsForm";
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";

const activeNotActive = (active: boolean, t: TFunction) : string => t(`users.edit.${!active? 'not-' : ''}active`)

export const useOutgoingCallsSection = (userId: string, enabled: boolean) : FormSection<UserOutgoingCalls> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const {buildFormButtons} = useFormSubmissionButtons(false)

    const fetchUserOutgoingCalls = useCallback(
        async (userId: string): Promise<UserOutgoingCalls | undefined> => {
            if (!site || !enabled) return
            const { data } = await api.get<UserOutgoingCalls>(`/api/site/${site.productId}/user/${userId}/outgoing-calls-settings`)
            return data
        },
        [site, enabled]
    )
    const { data, isFetching, error, refetch } = useFetchData<UserOutgoingCalls>(useCallback(() => fetchUserOutgoingCalls(userId), [fetchUserOutgoingCalls, userId]))

    const onSubmit = useCallback((onSucceeded: () => void) =>
            async (formData: UserOutgoingCalls) => {
                if (!site) return
                await api.put(`/api/site/${site.productId}/user/${userId}/outgoing-calls-settings`, formData)
                await refetch()
                onSucceeded();
            },
        [site, userId, refetch],
    )

    const generalLabelValues = useCallback(
        (value: UserOutgoingCalls): LabelValueType[] => {
            return [
                { label: t('users.edit.clid'), value: <>{t(`users.edit.clid-options.${value.callingLineIdPolicy}`)}</> },
                { label: t('users.edit.block-my-caller-id'), value: <>{activeNotActive(value.blockCallerIdActive, t)}</> },
            ]}, [t]
    )

    const renderOutgoingCallsDetails = useCallback(
        (value: UserOutgoingCalls): ReactNode => {
            return (
                <PaperStack>
                    <FormGroup key={1} label={t('general.general')} fullWidth={true}>
                        <LabelValues labelValues={generalLabelValues(value)} />
                    </FormGroup>
                    <div></div>
                </PaperStack>
            )
        },
        [t, generalLabelValues]
    )

    const renderForm = useCallback((outgoingCalls: UserOutgoingCalls, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode =>
            <OutgoingCallsForm outgoingCalls={outgoingCalls} buildFormButtons={buildFormButtons(cancelEditButton)} onSubmit={onSubmit(onSucceeded)} />,
        [onSubmit, buildFormButtons])

    return {
        title: t('users.sections.outgoing-calls'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderOutgoingCallsDetails,
        renderForm: renderForm,
        enabled: () => enabled,
        icon: () => <PhoneForwarded />
    }
}