import {useTranslation} from "react-i18next";
import React, {ReactElement, ReactNode, useCallback, useMemo, useState} from "react";
import {FormSection} from "../../common/components/SectionedForm";
import {CpeConfigResult, CPESettings, PhoneUser} from "../../app/types";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import {api} from "../../common/api";
import {useFetchData} from "../../common/servercall/useFetchData";
import PaperStack from "../../common/form/PaperStack";
import FormGroup from "../../common/form/FormGroup";
import {LabelValues} from "../../common/components/LabelValues";
import {CPEConfigMapping, getConfigForm, getConfigLabelValuesForModel} from "../../cpes/util/CPEHelpers";
import FaxIcon from "@mui/icons-material/Fax";
import {CloneCpeConfigToUserDialog} from "../CloneCpeConfigToUserDialog";
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";

export const useCpeSection = (userId: string, model: string | undefined | null, enabled: boolean, user ?: PhoneUser) : FormSection<CPESettings> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const {buildFormButtons} = useFormSubmissionButtons(false)
    const [showCloneToDialog, setShowCloneToDialog] = useState<boolean>(false)

    const fetchCpeSettings = useCallback(
        async (userId: string): Promise<CPESettings | undefined> => {
            if (!site || !enabled) return
            const { data } = await api.get<CPESettings>(`/api/site/${site.productId}/user/${userId}/cpe-settings?model=${model}`)
            return data
        },
        [site, enabled, model],
    )
    const { data, isFetching, error, refetch } = useFetchData<CPESettings>(useCallback(() => fetchCpeSettings(userId), [fetchCpeSettings, userId]))

    const configs = useMemo(() => data ? CPEConfigMapping(data) : [], [data])

    const onSubmit = useCallback((onSucceeded: () => void) =>
        async (formData: CPESettings | CpeConfigResult) => {
                if (!site) return

                if ('cpeSettings' in formData) {
                    formData = formData.cpeSettings
                }

                await api.put(`/api/site/${site.productId}/user/${userId}/cpe-settings`, {model: model, ...formData})
                await refetch()
                onSucceeded()
            }, [site, userId, refetch, model],)

    const renderForm = useCallback((settings: CPESettings, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode =>
        !!data ?  getConfigForm(CPEConfigMapping(settings), model, onSubmit(onSucceeded), buildFormButtons(cancelEditButton), user)
            : <></>, [onSubmit, data, buildFormButtons, model, user])

    const configLabelValues = useCallback((value: CPESettings) =>
        getConfigLabelValuesForModel(configs, model || undefined, t, user),  [configs, t, model, user]
    )

    /*const [configAnchorEl, setConfigAnchorEl] = React.useState(null);

    const handleConfigClick = (event: any) => {
        setConfigAnchorEl(event.currentTarget);
    };

    const handleConfigClose = useCallback(() => {
        setConfigAnchorEl(null);
    }, [setConfigAnchorEl]);

    const configurationButtonsPanel = useMemo(
        () => (
            <Grid container spacing={2} >
                <Grid item pt={1}>
                    <Fab
                        title={''}
                        onClick={handleConfigClick}
                        color='primary'
                        size={'small'}
                    >
                        <MoreHorizIcon/>
                    </Fab>
                    <Menu
                        id="simple-menu"
                        anchorEl={configAnchorEl}
                        open={Boolean(configAnchorEl)}
                        onClose={handleConfigClose}
                    >
                        <MenuItem onClick={() => { setShowCloneToDialog(true); handleConfigClose();}}>
                            <ListItemIcon>
                                <PublishIcon fontSize="small" />
                            </ListItemIcon>
                            {t("users.clone-config-to")}
                        </MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        ),
        [configAnchorEl, handleConfigClose, t],
    )*/

    const renderCpeSettings = useCallback(
        (value: CPESettings): ReactNode => {
            const labelValues = configLabelValues(value) || []
            return (
                <>
                    <PaperStack>
                        <FormGroup key={1} label={t('general.general')} fullWidth={true}
                        //           buttonsPanel={configurationButtonsPanel}
                        >
                            <LabelValues labelValues={labelValues} />
                        </FormGroup>
                        <div></div>
                    </PaperStack>
                    {showCloneToDialog && <CloneCpeConfigToUserDialog onSuccess={() => {}} userId={userId} handleClose={() => setShowCloneToDialog(false)} /> }
            </>
            )
        },
        [t, configLabelValues, showCloneToDialog, userId]
    )

    return {
        title: t('users.sections.cpe-settings'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderCpeSettings,
        renderForm: renderForm,
        enabled: () => enabled,
        icon: () => <FaxIcon />
    }
}