import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'rebass'
import { api } from '../api'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import ErrorAlert from './ErrorAlert'
import axios from 'axios'

function DeletionModal<itemClass>({
                                      title,
                                      text,
                                      deleteAction,
                                      deleteUrl,
                                      deleteAfterWarningUrl,
                                      itemToDelete,
                                      onSuccess,
                                      onClose,
                                  }: {
    title?: string
    text?: (itemToDelete: any) => string
    deleteAction?: (itemToDelete: itemClass) => void
    deleteUrl?: (itemDoDelete: itemClass) => string | null
    deleteAfterWarningUrl?: (itemDoDelete: itemClass) => string | null
    onSuccess?: () => void
    onClose: () => void
    itemToDelete?: itemClass
}) {
    const [error, setError] = useState<string | null>(null)
    const [warning, setWarning] = useState<string | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const { t } = useTranslation()

    useEffect(() => {
        setError(null)
    }, [itemToDelete])

    const handleOk = async () => {
        try {
            setLoading(true)
            if (warning && deleteAfterWarningUrl && itemToDelete) {
                const url = deleteAfterWarningUrl(itemToDelete)
                url && (await api.delete(url))
            } else if (deleteAction && itemToDelete) {
                await deleteAction(itemToDelete)
            } else if (deleteUrl && itemToDelete) {
                const url = deleteUrl(itemToDelete)
                url && (await api.delete(url))
            }
            setError(null)
            setWarning(null)
            onSuccess && onSuccess()
            onClose()
        } catch (err) {
            setError(null)
            setWarning(null)
            if (axios.isAxiosError(err)) {
                const data = err?.response?.data as [any]
                err.response?.status === 400 && !!data ?
                    data[0].key.startsWith('warning-') ? setWarning(`general.form-error.${data[0].key}`)
                        : setError(`general.form-error.${data[0].key}`) :
                    setError('general.unknown-server-error')
            } else {
                setError('general.unknown-server-error')
            }
        }
        setLoading(false)
    }

    return (
        <Dialog open={itemToDelete !== null} onClose={onClose} aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>{title || t('general.delete')}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {error && (
                        <Flex mb={2}>
                            <Box width={1}>
                                <ErrorAlert errorKey={error ?? undefined} showAlert={true} />
                            </Box>
                        </Flex>
                    )}
                    {warning && (
                        <Flex mb={2}>
                            <Box width={1}>
                                <ErrorAlert errorKey={warning ?? undefined} showAlert={true} severity={'warning'} />
                            </Box>
                        </Flex>
                    )}
                    {!warning && (text && itemToDelete ? text(itemToDelete) : t('general.delete-confirmation'))}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose}>
                    {t('general.cancel')}
                </Button>
                <Button variant='contained' onClick={handleOk} color='error'
                        endIcon={loading && <CircularProgress color='primary' size='1rem' />}>
                    {t('general.delete-after-confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeletionModal
