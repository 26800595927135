import React, {ReactElement, ReactNode, useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import {useFetchData} from '../common/servercall/useFetchData'
import {CPE} from '../app/types'
import {useParams} from 'react-router'
import {api} from '../common/api'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../authentication/redux'
import {WithErrorAndFetching} from '../common/servercall/WithErrorAndFetching'
import {Page} from '../app/navigation/redux'
import FormGroup from '../common/form/FormGroup'
import {LabelValues, LabelValueType} from '../common/components/LabelValues'
import {formatCPE, formatCPEModel, formatIpei, formatMacAddress, formatUserNames} from '../common/util/formatters'
import {ModeEdit} from '@mui/icons-material'
import {Box, Flex} from 'rebass'
import {IconButton} from '@mui/material'
import {AttachUserToCpeDialog} from './AttachUserToCpeDialog'
import PaperStack from "../common/form/PaperStack";
import {UserLink} from "../users/UserLink";
import {useAccessHelpers} from "../common/util/hooks";

export const DetailCpe = () => {
    const { t } = useTranslation()
    const { id }: { id: string } = useParams()
    const [cpeToChangeUser, setCpeToChangeUser] = useState<CPE | null>(null)
    const site = useSelector(selectedSiteSelector)
    const {onlyWithWriteAccess} = useAccessHelpers()

    const fetchCpe = useCallback(
        async (cpeId: string): Promise<CPE | undefined> => {
            if (!site) return
            const { data } = await api.get<CPE>(`/api/site/${site.productId}/cpe/${cpeId}`)
            return data
        },
        [site],
    )
    const { data, isFetching, error, refetch } = useFetchData<CPE>(useCallback(() => fetchCpe(id), [fetchCpe, id]))

    const pageInfo: Page | null = useMemo(
        () =>
            data
                ? {
                    label: `${formatCPE(data)}`,
                    key: 'cpe-update',
                }
                : null,
        [data],
    )

    const userValue = useCallback(
        (value: CPE): ReactElement => (
            <Flex alignItems='center' justifyContent={'flex-start'} flexDirection={'row'}>
                <Box>
                    { !!value.userUuid && !!site ? <UserLink uuid={value.userUuid} siteProductId={site.productId} formattedUser={formatUserNames(value.userLastName || '', value.userFirstName || '')}/> : <></> }
                </Box>
                {onlyWithWriteAccess(<Box>
                    <IconButton size={'small'} color='secondary' aria-label='Change user' component='span'
                                onClick={() => setCpeToChangeUser(value)}>
                        <ModeEdit />
                    </IconButton>
                </Box>)}

            </Flex>
        ),
        [setCpeToChangeUser, site, onlyWithWriteAccess],
    )

    const infoLabelValues = useCallback(
        (value: CPE): LabelValueType[] => [
            { label: t('cpes.table.identifier'), value: <>{value.identifier}</> },
            { label: t('cpes.table.name'), value: <>{value.name}</> },
            {
                label: t('cpes.detail.mac-address'),
                value: <>{value.macAddress ? formatMacAddress(value.macAddress) : t('general.none')}</>,
            },
            { label: 'IPEI', value: <>{value.ipei ? formatIpei(value.ipei) : t('general.none')}</> },
            { label: t('cpes.detail.type'), value: <>{formatCPEModel(value.model)}</> },
            { label: t('cpes.detail.user'), value: userValue(value) },
            ...(value.hotDeskExtension ? [
                {label: t('cpes.hot-desk-dialog.title'), value: <>{value.hotDeskExtension}</>}
            ] : [])
        ],
        [t, userValue],
    )

    const renderDetails = useCallback(
        (cpe?: CPE): ReactNode => {
            return cpe && (
                <PaperStack>
                    <FormGroup key={2} label={t('users.edit.section.info')}>
                        <LabelValues labelValues={infoLabelValues(cpe)} />
                        <AttachUserToCpeDialog onSuccess={refetch} cpe={cpeToChangeUser}
                                               handleClose={() => setCpeToChangeUser(null)} />
                    </FormGroup>
                    <></>
                </PaperStack>
            )
        },
        [infoLabelValues, t, cpeToChangeUser, setCpeToChangeUser, refetch]
    )

    return (
        <TitledBody title={t('cpes.detail.title')} page={pageInfo}>
            <WithErrorAndFetching<CPE> isFetching={isFetching} error={error} data={data} render={renderDetails} />
        </TitledBody>
    )
}