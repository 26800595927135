import React, {ReactElement, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {FormProvider, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {BasicUser, CallPickupGroup} from '../../app/types'
import ErrorAlert from '../../common/components/ErrorAlert'
import FormContainer from '../../common/form/FormContainer'
import FormRow from '../../common/form/FormRow'
import FormFieldBox from '../../common/form/FormFieldBox'
import TextInput from '../../common/form/TextInput'
import {useFormSubmission} from '../../common/servercall/useFormSubmission'
import FormGroup from '../../common/form/FormGroup'
import {Paper} from '@mui/material'
import {formatUserInDropdown} from '../../common/util/formatters'
import {getAutocompleteUsers} from '../../common/util/helpers'
import {useSelector} from 'react-redux'
import {selectedSiteSelector} from '../../authentication/redux'
import {useTableWithAdjustableOrder} from "../../common/components/table/useTableWithAdjustableOrder";

const schema = yup
    .object()
    .shape({
        name: yup.string().trim().max(30, 'too-long').required('required-m').matches(/^[^\\/]*$/, 'invalid-schedule-name'),
        users: yup.array().min(2, 'user-min-2-required'),
    })
    .required()

type Props = {
    callPickupGroup: CallPickupGroup
    onSubmit: (formData: CallPickupGroup) => void
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

const CallPickupGroupFormScreen = ({ callPickupGroup, onSubmit, buildFormButtons }: Props) => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)

    const { control, handleSubmit, setError, clearErrors } = useForm<CallPickupGroup>({
        resolver: yupResolver(schema),
        defaultValues: {
            ...callPickupGroup,
        },
    })

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<CallPickupGroup>(onSubmit, setError)

    const userOptionParams = useMemo(() => {
        return getAutocompleteUsers(
            t('flows.edit.no-users-found'),
            [],
            false,
            site,
        )
    }, [site, t])

    const { table: UserTable, selector: UserSelector } = useTableWithAdjustableOrder<BasicUser>('users', userOptionParams, control,
        formatUserInDropdown, user => user.uuid, t('users.edit.user-select-button'), true)

    return (
        // @ts-ignore
        <FormProvider clearErrors={clearErrors}>
            <ErrorAlert errorKey={typeof serverError === 'string' ? serverError : undefined} showAlert={!!serverError} />
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={t('call-pickup-groups.edit.section.info')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox>
                            <TextInput autofocus={true} label={t('call-pickup-groups.edit.name')} name={'name'}
                                       control={control} required={true} />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                <FormGroup label={t('call-pickup-groups.edit.section.users')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox mdSize={8} lgSize={6}>
                            {UserSelector}
                        </FormFieldBox>
                    </FormRow>
                    <FormRow>
                        <FormFieldBox mdSize={12}>
                            <Paper elevation={2}>
                                <UserTable />
                            </Paper>
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                {buildFormButtons(isSubmitting)}
            </FormContainer>
        </FormProvider>
    )
}

export default CallPickupGroupFormScreen
