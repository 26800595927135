import React, {ReactElement, ReactNode, useCallback} from 'react'
import {FormSection} from "../../common/components/SectionedForm";
import {api} from "../../common/api";
import {useSelector} from "react-redux";
import {selectedSiteSelector} from "../../authentication/redux";
import {useTranslation} from "react-i18next";
import PaperStack from "../../common/form/PaperStack";
import FormGroup from "../../common/form/FormGroup";
import {LabelValues, LabelValueType} from "../../common/components/LabelValues";
import {IvrForm} from "../../app/types";
import {formatPhoneNumber} from "../../common/util/formatters";
import {SelectiveCallForwardDetail} from "../../common/components/SelectiveCallForwardDetail";
import {usePhoneNumbersToForwardTo} from "../../common/servercall/usePhoneNumbersToForwardTo";
import {useFormSubmissionButtons} from "../../common/form/useFormSubmissmionButtons";
import {Mediation} from "@mui/icons-material";
import IvrFormScreen from "../IvrForm";

export const useGeneralSection = (ivrId: string, isFetching: boolean, error: any, refetch: () => void, data?: IvrForm) : FormSection<IvrForm> => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const {phoneNumbersToForwardTo} = usePhoneNumbersToForwardTo(site)
    const {buildFormButtons} = useFormSubmissionButtons(false)

    const onSubmit = useCallback((onSucceeded: () => void) =>
        async (formData: IvrForm) => {
            if (!site) return
            await api.put(`/api/site/${site.productId}/ivr/${ivrId}`, {
                ...formData,
                configJson: data?.menuConfig,
                operatorType: data?.operator?.type,
                operatorUserId: data?.operator?.user?.uuid,
                operatorAnswerGroupId: data?.operator?.answerGroup?.uuid,
                alternateNumbers: formData.alternateNumbers.map(nr => nr.number)
            })
            await refetch()
            onSucceeded();
        },
        [site, ivrId, data, refetch]
    )

    const renderGeneralForm = useCallback(
        (ivr: IvrForm, cancelEditButton: ReactElement, onSucceeded: () => void): ReactNode =>
            <IvrFormScreen ivr={ivr} onSubmit={onSubmit(onSucceeded)} buildFormButtons={buildFormButtons(cancelEditButton)}/>
        , [onSubmit, buildFormButtons])

    const infoLabelValues = useCallback(
        (value: IvrForm): LabelValueType[] => [
            { label: t('ivrs.edit.name'), value: <>{value.name}</> },
            { label: t('users.edit.language'), value: <>{t(`users.language.${value.language}`)}</> },
            {
                label: t('ivrs.edit.number'),
                value: <>{value.number ? formatPhoneNumber(value.number) : t('general.none')}</>,
            },
            {
                label: t('users.edit.alternate-numbers'),
                value: <>{value.alternateNumbers.length > 0 ?
                    value.alternateNumbers.map(alternateNumber => formatPhoneNumber(alternateNumber.number)).join(", ")
                    : t('general.none')}</>,
            },
            { label: t('ivrs.edit.extension'), value: <>{value.extension || t('general.none')}</> },
            {
                label: t('users.edit.ncos'),
                value: <>{t(`users.ncos-names.${value.ncos}`)}</>,
            },
            { label: t('ivrs.edit.transfer-to-operator-after'), value: <>{value.transferToOperatorAfter || t('general.none')}</> },
        ],
        [t]
    )

    const renderDetails = useCallback(
        (ivr: IvrForm): ReactNode => {
            return (
                <PaperStack>
                    <FormGroup fullWidth={true} key={1} label={t('ivrs.edit.section.info')}>
                        <LabelValues labelValues={infoLabelValues(ivr)} />
                    </FormGroup>
                    <SelectiveCallForwardDetail key={2} selectiveCallForward={ivr.selectiveCallForward || null}
                                                nightServiceMode={null} phoneNumbersToForwardTo={phoneNumbersToForwardTo} fullWidth={true} />
                </PaperStack>
            )
        },
        [infoLabelValues, phoneNumbersToForwardTo, t]
    )

    return {
        title: t('general.general'),
        data: data,
        isFetching: isFetching,
        error: error,
        refetch: refetch,
        renderDetails: renderDetails,
        renderForm: renderGeneralForm,
        enabled: () => true,
        icon: () => <Mediation/>
    }
}