import {UserOutgoingCalls} from "../../app/types";
import {useTranslation} from "react-i18next";
import React, {ReactElement, useMemo} from "react";
import FormGroup from "../../common/form/FormGroup";
import ErrorAlert from "../../common/components/ErrorAlert";
import FormContainer from "../../common/form/FormContainer";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useFormSubmission} from "../../common/servercall/useFormSubmission";
import * as yup from "yup";
import FormFieldBox from "../../common/form/FormFieldBox";
import FormCheckbox from "../../common/form/FormCheckbox";
import FormRow from "../../common/form/FormRow";
import TextInput from "../../common/form/TextInput";

const schema = () => yup.object()

type Props = {
    outgoingCalls: UserOutgoingCalls
    onSubmit: (formData: UserOutgoingCalls) => void
    readonly?: boolean
    buildFormButtons: (isSubmitting: boolean) => ReactElement
}

export const OutgoingCallsForm = ({ outgoingCalls, onSubmit, readonly, buildFormButtons }: Props) => {
    const { t } = useTranslation()

    const { control, handleSubmit, setError, setValue, getFieldState } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(schema()),
        defaultValues: outgoingCalls
    })

    const clidOptions = useMemo(
        () =>
            ['USE_DN', "USE_CONFIGURABLE_CLID", "USE_GROUP_CLID"].map(av => ({
                value: av,
                label: t(`users.edit.clid-options.${av}`),
            })),
        [t]
    )

    const { submit, isSubmitting, serverError, onError } = useFormSubmission<UserOutgoingCalls>(onSubmit, setError)

    return <>
        <ErrorAlert showAlert={!!serverError} />
            <FormContainer onSubmit={handleSubmit(submit, onError)}>
                <FormGroup label={t('general.general')} fullWidth={true}>
                    <FormRow>
                        <FormFieldBox >
                            <TextInput readonly={false} label={t('users.edit.clid')} name={'callingLineIdPolicy'} control={control} required={true} options={clidOptions} />
                        </FormFieldBox>
                        <FormFieldBox >
                            <FormCheckbox
                                label={t('users.edit.block-my-caller-id')}
                                name={'blockCallerIdActive'}
                                control={control}
                                setValue={setValue}
                                getFieldState={getFieldState}
                            />
                        </FormFieldBox>
                    </FormRow>
                </FormGroup>
                {buildFormButtons(isSubmitting)}
            </FormContainer>
        </>
}
