import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {TitledBody} from '../common/components/TitledBody'
import {FlowForm} from './FlowForm'
import {AnswerGroupForm, Language} from '../app/types'
import {api} from '../common/api'
import {useSelector} from 'react-redux'
import {defaultNcosSelector, selectedSiteSelector} from '../authentication/redux'
import {useHistory} from 'react-router'
import {useFormSubmissionButtons} from "../common/form/useFormSubmissmionButtons";
import CancelButton from "../common/form/CancelButton";
import {Grid} from "@mui/material";

export const initialHuntgroup = (defaultNcos: string | null): AnswerGroupForm => ({
    answerGroupType: 'HUNT_GROUP',
    uuid: '',
    name: '',
    language: Language.nl,
    extension: '',
    number: '',
    voicemailEmail: '',
    additionalVoicemailEmails: '',
    userAgents: [],
    agentRingTime: 12,
    huntPolicy: 'CIRCULAR',
    overflowDestination: 'VOICEMAIL',
    overflowAnnouncementFileName: '',
    overflowNumber: '',
    webexLogin: false,
    welcomeMessageFileName: '',
    musicOnHoldFileName: '',
    comfortMessageFileName: '',
    comfortMessageGapTime: 60,
    queueLength: null,
    forwardTimeoutSeconds: 120,
    overflowTimeout: null,
    selectiveCallForward: {
        outsideOpeningHoursScheduleUuid: '',
        holidayPhoneNumber: '',
        holidayScheduleUuid: '',
        outsideOpeningHoursPhoneNumber: '',
    },
    allowAgentLogoff: false,
    allowCallWaitingForAgents: false,
    voicemailEnabled: true,
    voicemailNoAnswerAnnouncement: '##DEFAULT_TELENET##',
    callForwardAnnouncement: '',
    ncos: defaultNcos || '',
    voicemailUsage: 'WITHOUT_EMAIL',
    nightServiceMode: 'USE_GROUP',
    prioExternalCalls: true
})

export const AddHuntGroup = () => {
    const { t } = useTranslation()
    const site = useSelector(selectedSiteSelector)
    const history = useHistory()
    const defaultNcos = useSelector(defaultNcosSelector)
    const {buildFormButtons} = useFormSubmissionButtons(true)

    const onSubmit = useCallback(
        async (formData: AnswerGroupForm) => {
            if (!site) return
            await api.post(`/api/site/${site.productId}/answergroup`, {
                ...formData,
                agentUserIds: formData.userAgents?.map(agent => agent.uuid).join(','),
                voicemailEnabled: formData.overflowDestination === 'VOICEMAIL' ? true : formData.voicemailEnabled,
            })
            history.push('/flows')
        },
        [site, history]
    )

    return (
        <TitledBody title={t('flows.add-huntgroup.title')}>
            <Grid container>
                <Grid item xs={12} lg={9}>
                    <FlowForm onSubmit={onSubmit} flow={initialHuntgroup(defaultNcos)} buildFormButtons={buildFormButtons(<CancelButton />)} />
                </Grid>
            </Grid>
        </TitledBody>
    )
}
