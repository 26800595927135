import React from 'react'
import { AutocompleteValue } from '@mui/material'
import AutocompleteWithAsyncFetchAndState from './AutocompleteWithAsyncFetchAndState'

export type AutoCompleteOptions<ItemType> = {
    getOptions: (inputValue: string) => Promise<any>
    getOptionLabel: (item: ItemType) => string
    getOptionValue: (item: ItemType) => string
    isOptionEqualToValue: (option: ItemType, value: ItemType) => boolean
    defaultObject: any
    noOptionsText?: string
    filterOnServer?: boolean
    getOptionDisabled?: (option: ItemType) => boolean
    disableClearable?: boolean
    freeText?: boolean
}

type Props = {
    label: string
    name: string
    autoCompleteOptions: AutoCompleteOptions<any>
    readonly?: boolean
    help?: string
    required?: boolean
    invalid: boolean
    errorMessage?: string
    onChange: (element: AutocompleteValue<any, any, any, any>) => void
    innerRef?: any
    preventDefaultEnterBehavior?: boolean
    keepEmpty?: boolean
}

export default function AutocompleteWithAsyncFetch({
                                                       label,
                                                       name,
                                                       help,
                                                       required,
                                                       autoCompleteOptions,
                                                       readonly,
                                                       invalid,
                                                       errorMessage,
                                                       onChange,
                                                       innerRef,
                                                       preventDefaultEnterBehavior,
                                                       keepEmpty
                                                   }: Props) {
    const [inputValue, setInputValue] = React.useState(autoCompleteOptions.freeText ? autoCompleteOptions?.defaultObject : '')

    return (
        <AutocompleteWithAsyncFetchAndState
            clearAfterSelect={keepEmpty}
            label={label}
            name={name}
            help={help}
            required={required}
            autoCompleteOptions={autoCompleteOptions}
            readonly={readonly}
            invalid={invalid}
            errorMessage={errorMessage}
            onChange={onChange}
            innerRef={innerRef}
            preventDefaultEnterBehavior={preventDefaultEnterBehavior}
            controlled={false}
            inputValue={inputValue}
            setInputValue={setInputValue}
        ></AutocompleteWithAsyncFetchAndState>
    )
}
