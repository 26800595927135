import React, {ReactElement, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'rebass'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import axios from 'axios'
import ErrorAlert from '../common/components/ErrorAlert'
import PinInput from 'react-pin-input'

const pinCodeRuleKeys: string[] = [
    'user-extension',
    'user-extension-reversed',
    'same-digit',
    'asc-or-desc-digit',
    'repeating-patterns',
    'last-passcodes',
    'old-passcode-reversed',
]

function PinCodeModal({
    title,
    text,
    onSubmit,
    onClose,
    buttonLabel,
    open,
}: {
    title: string
    text: string
    onSubmit: (newPinCode: string) => void
    onClose: () => void
    buttonLabel: string
    open: boolean
}) {
    const [error, setError] = useState<ReactElement | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [pinCode, setPinCode] = useState<string>('')
    const { t } = useTranslation()

    useEffect(() => {
        setError(null)
        setPinCode('')
        setLoading(false)
    }, [])

    const handleOk = async () => {
        try {
            setError(null)
            setLoading(true)
            pinCode && (await onSubmit(pinCode))
        } catch (err) {
            setError(null)
            if (axios.isAxiosError(err)) {
                const data = err?.response?.data as [any]
                err.response?.status === 400 && !!data ?
                    data[0].key === 'invalid-pin-code' ? setError(<>{t('general.form-error.invalid-pin-code.title')}
                            <ul>{pinCodeRuleKeys.map(rule =>
                                <li>{t(`general.form-error.invalid-pin-code.${rule}`)}</li>)}</ul>
                        </>) :
                        setError(<>{t(`general.form-error.${data[0].key}`)}</>) : setError(<>{t('general.unknown-server-error')}</>)
            } else {
                setError(t('general.unknown-server-error'))
            }
        }
        setLoading(false)
    }

    const onNewCode = (newCode: string) => {
        const regex = /^[0-9]{4}$/
        const isComplete = newCode.match(regex)
        setPinCode(isComplete ? newCode : '')
    }

    const onCancel = () => {
        setError(null)
        onClose();
    }

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title || t('general.delete')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error && (
                        <Flex mb={2}>
                            <Box width={1}>
                                <ErrorAlert body={error} showAlert={true}/>
                            </Box>
                        </Flex>
                    )}
                    {text}
                </DialogContentText>
                <PinInput
                    length={4}
                    initialValue=""
                    focus={true}
                    onChange={(value: any, index: number) => onNewCode(value)}
                    type="numeric"
                    inputMode="number"
                    style={{ padding: '8px' }}
                    onComplete={(value: any, index: number) => onNewCode(value)}
                    autoSelect={true}
                    regexCriteria={/^[0-9]*$/}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color={'secondary'}>
                    {t('general.cancel')}
                </Button>
                <Button variant="contained" onClick={handleOk} disabled={!pinCode} endIcon={loading && <CircularProgress color="secondary" size="1rem" />}>
                    {buttonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PinCodeModal
