import React, {useCallback, useMemo} from 'react'
import {useForm, useWatch} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {ErrorOption} from 'react-hook-form/dist/types/errors'
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {IvrOperator, Language} from '../../app/types'
import {getAutocompleteSiteAnswerGroups, getAutocompleteUsers} from '../../common/util/helpers'
import {selectedSiteSelector} from '../../authentication/redux'
import {useFormSubmission} from '../../common/servercall/useFormSubmission'
import TextInput from '../../common/form/TextInput'
import SpinnableSubmitButton from "../../common/form/SpinnableSubmitButton";
import CancelButton from "../../common/form/CancelButton";
import FormButtons from "../../common/form/FormButtons";
import FormRow from "../../common/form/FormRow";
import FormFieldBox from "../../common/form/FormFieldBox";
import FormGroup from "../../common/form/FormGroup";
import {initialHuntgroup} from "../../flows/addHuntGroup";

const schema = yup.object({
    operator: yup.object({
        type: yup.string().required('required-m'),
        user: yup.object().when(['type'], {
            is: (type: string) => ['USER'].includes(type),
            then: schema => yup.object().required('required-m').nullable(),
            otherwise: yup.object().nullable()
        }),
        answerGroup: yup.object().when(['type'], {
            is: (type: string) => ['ANSWER_GROUP'].includes(type),
            then: schema => yup.object().required('required-m').nullable(),
            otherwise: yup.object().nullable()
        })
    })
})
export type OperatorFormType = {
    operator: IvrOperator
}

type props = {
    open: boolean
    operatorForm: OperatorFormType
    onCancel: () => void
    onSuccess: (operatorForm: OperatorFormType) => void
}

export const OperatorFormScreen = ({open, operatorForm, onSuccess, onCancel}: props) => {
    const site = useSelector(selectedSiteSelector)
    const {t} = useTranslation()

    const {control, handleSubmit, setError} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {operator: operatorForm.operator},
    })

    const onSubmit = useCallback(
        async (formData: OperatorFormType) => {
            onSuccess({
                    operator: {
                        ...formData.operator,
                        user: formData.operator.type === 'USER' ? formData.operator.user : null,
                        answerGroup: formData.operator.type === 'ANSWER_GROUP' ? formData.operator.answerGroup : null
                    }
                }
            )
        },
        [onSuccess]
    )

    const checkError = useCallback(
        (name: any, error: ErrorOption) => {
            setError(name, error)
        },
        [setError]
    )

    const {submit, isSubmitting} = useFormSubmission<OperatorFormType>(onSubmit, checkError)

    const userOptionParams = useMemo(() => {
        return getAutocompleteUsers(t('flows.edit.no-users-found'), [], false, site, operatorForm.operator.user ?
            {uuid: operatorForm.operator.user.uuid,
                firstName: operatorForm.operator.user.firstName,
                lastName: operatorForm.operator.user.lastName,
                language:  Language.en,
                number: null
            } : undefined, (user) => `${user.lastName} ${user.firstName}`)
    }, [site, t, operatorForm.operator])

    const agOptionParams = useMemo(() => {
        return getAutocompleteSiteAnswerGroups(t, site, operatorForm.operator.answerGroup? {
            ...initialHuntgroup(null),
            uuid: operatorForm.operator.answerGroup.uuid,
            name: operatorForm.operator.answerGroup.name
        } : undefined)
    }, [site, t, operatorForm.operator])


    const typeOptions = useMemo(
        () =>
            ['NONE', 'USER', 'ANSWER_GROUP'].map(destination => ({
                value: `${destination}`,
                label: `${t(`ivrs.operator.types.${destination}`)}`,
            })),
        [t]
    )

    const type = useWatch({
        control,
        name: 'operator.type',
    })

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" fullWidth={true} >
            <form onSubmit={handleSubmit(submit)}>
                <DialogTitle id="alert-dialog-title">{t('ivrs.edit.operator')}</DialogTitle>
                <DialogContent>
                    <FormGroup fullWidth={true}>
                        <FormRow>
                            <FormFieldBox>
                                <TextInput
                                    readonly={false}
                                    label={t('ivrs.operator.type')}
                                    name={'operator.type'}
                                    control={control}
                                    required={false}
                                    options={typeOptions}
                                    isRadio={true}
                                />
                            </FormFieldBox>
                        </FormRow>
                       <FormRow>
                            <FormFieldBox>
                                {type === 'USER' &&
                                    <TextInput readonly={false} label={t('ivrs.operator.types.USER')} name={'operator.user'}
                                           control={control} required={false} autoCompleteOptions={userOptionParams}/>
                                }
                                {type === 'ANSWER_GROUP' &&
                                    <TextInput readonly={false} label={t('ivrs.operator.types.ANSWER_GROUP')} name={'operator.answerGroup'}
                                               control={control} required={false} autoCompleteOptions={agOptionParams}/>
                                }
                            </FormFieldBox>
                        </FormRow>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <FormButtons
                        buttons={[<SpinnableSubmitButton label={t('general.form-update')} showSpinner={isSubmitting}/>,
                            <CancelButton onClick={onCancel}/>]}/>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default OperatorFormScreen
